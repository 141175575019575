import React, { Component } from 'react';
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword';
import { resetPasswordMessages, errorMessages } from '../../helpers/messages';
import { matchExpression, passwordRegexMatch, passwordStrength } from '../../helpers/PasswordValidate';
import { forgotPassword, forgotPasswordSubmit } from '../../services/aws/aws-services';

export default class ForgotPasswordContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            username: '',
            authcode: '',
            newPassword: '',
            confirmPassword: '',
            errors: {},
            successMessage: '',
            errorMessage: '',
            pwdStrengthLabel: '',
            pwdStrengthProgress: '',
            pwdStrengthVarient: '',
            expMatch: {},
            isAuthCodeReceived: false,
            isAuthCodeError: false,
            authCodeObj: '',
            authCodeDeliveryMsg: false,
            reSendAuthCode: false
        };
    }

    /**
    * @description Function to handle changes in forgot password form
    * @memberof ForgotPasswordContainer
    */
    handleChange = (value, field) => {
        var errors = { ...this.state.errors };
        errors.cognitoError = ''
        this.setState({ errors, successMessage: '', errorMessage: '' });
        if (field === 'username' && !this.state.isAuthCodeReceived) {
            errors.username = '';
            this.setState({ errors, username: value });
        } else if (field === 'authcode') {
            errors.authcode = '';
            this.setState({ errors, authcode: value });
        } else if (field === 'newPassword') {
            var strengthObj = passwordStrength(value, this.state.username);
            this.setState({ ...strengthObj, expMatch: {} });

            if (value && !passwordRegexMatch(value, this.state.username)) {
                errors.newPassword = '';
                errors.newPasswordMatch = true;
            } else {
                errors.newPasswordMatch = '';
            }
            if (value === '') {
                this.setState({ pwdStrengthLabel: '' });
            }
            this.setState({ errors, newPassword: value });
        } else if (field === 'confirmPassword') {
            errors.confirmPassword = '';
            this.setState({ errors, confirmPassword: value });
        }
    }

    /**
    * @description Function to handle blur event in reset password form
    * @memberof ForgotPasswordContainer
    */
    handleBlur = (value, field) => {
        this.handleChange(value, field);
        setTimeout(() => {
            if (field === 'newPassword') {
                var matchResults = matchExpression(value, this.state.expMatch, this.state.username);
                this.setState({ expMatch: matchResults });
            }
        }, 0);
    }

    /**
    * @description Function to validate username
    * @memberof ForgotPasswordContainer
    */
    validateUsernameInputs = () => {
        const username = this.state.username;
        let errors = {};
        let isValid = true;
        if (!username) {
            isValid = false;
            errors["username"] = resetPasswordMessages.error.username;
        }
        this.setState({
            errors: errors
        });
        return isValid;
    }

    /**
    * @description Function to validate reset password form
    * @memberof ForgotPasswordContainer
    */
    validateResetPassInputs = () => {
        const { username, newPassword, confirmPassword, authcode } = this.state;
        let errors = {};
        let isValid = true;
        if (!authcode) {
            isValid = false;
            errors["authcode"] = resetPasswordMessages.error.authCode;
        } else if (!newPassword) {
            isValid = false;
            errors["newPassword"] = resetPasswordMessages.error.newPassword;
        } else if (!confirmPassword) {
            isValid = false;
            errors["confirmPassword"] = resetPasswordMessages.error.confirmPassword;
        } else if (typeof newPassword !== "undefined" && typeof confirmPassword !== "undefined") {
            this.handleBlur(newPassword, 'newPassword');
            if (!passwordRegexMatch(newPassword, username)) {
                isValid = false;
                errors["newPasswordMatch"] = true;
            }
            if (newPassword !== confirmPassword) {
                isValid = false;
                errors["confirmPassword"] = resetPasswordMessages.error.notMatch;
            }
        }
        this.setState({
            errors: errors
        });
        return isValid;
    }

    /**
    * @description Function to request authentication code
    * @memberof ForgotPasswordContainer
    */
    requestAuthenticationCode = (event, reqType) => {
        event.preventDefault();
        if (this.validateUsernameInputs()) {
            reqType === 'reset' ? this.setState({ loading: true, }) : this.setState({ reSendAuthCode: true })
            this.setState({ errorMessage: '', authCodeDeliveryMsg: false });
            forgotPassword(this.state.username)
                .then(result => {
                    this.setState({
                        isAuthCodeReceived: true, isAuthCodeError: false, authCodeObj: result,
                        authCodeDeliveryMsg: true, loading: false, errorMessage: '', reSendAuthCode: false
                    });
                })
                .catch(error => {
                    if (error && (error.code === 'UserNotFoundException')) {
                        error.message = errorMessages['ResetPwdInvalidUserName']
                    }
                    if (error && (error.code === 'InvalidParameterException')) {
                        error.message = errorMessages['InvalidParameterException']
                    }

                    this.setState({
                        isAuthCodeReceived: false, isAuthCodeError: true, errorMessage: error,
                        authCodeDeliveryMsg: false, loading: false, reSendAuthCode: false
                    });
                })
        }
    }

    /**
    * @description Function to submit forgot password form
    * @memberof ForgotPasswordContainer
    */
    handleResetPassword = (event) => {
        event.preventDefault();
        if (this.validateResetPassInputs()) {
            this.setState({ loading: true, errorMessage: '', successMessage: '' })
            const { newPassword, authcode, username } = this.state;

            forgotPasswordSubmit(username, authcode, newPassword)
                .then(result => {
                    this.setState({
                        successMessage: resetPasswordMessages.resetPassword, errorMessage: '', loading: false,
                        authCodeDeliveryMsg: false, pwdStrengthLabel: ''
                    });
                    setTimeout(() => {
                        this.props.closeAction();
                    }, 2000);
                })
                .catch(error => {
                    if (error && (error.code === 'NotAuthorizedException')) {
                        error.message = errorMessages['ResetPwdOtpExpired']
                    }
                    if (error && (error.code === 'UserNotFoundException')) {
                        error.message = errorMessages['ResetPwdInvalidUserName']
                    }
                    this.setState({ successMessage: '', pwdStrengthLabel: '', errorMessage: error, authCodeDeliveryMsg: false, loading: false });
                })
        }
    }

    /**
     * @description function to submit the form on press of enter key
     * @param {*}
     * @memberof ForgotPasswordContainer
     */
    /* istanbul ignore next  */
    onEnterPress = (e) => {
        if (e.which === 13) {
            this.handleResetPassword(e);
        }
    }

    /**
     * @description function to submit the form on press of enter key
     * @param {*}
     * @memberof ForgotPasswordContainer
     */
    /* istanbul ignore next  */
    onEnterRequestCode = (e) => {
        if (e.which === 13) {
            this.requestAuthenticationCode(e, 'reset');
        }
    }

    render() {
        return (
            <ForgotPassword
                loading={this.state.loading}
                handleResetPassword={this.handleResetPassword}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                formErrors={this.state.errors}
                successMessage={this.state.successMessage}
                onEnterPress={this.onEnterPress}
                onEnterRequestCode={this.onEnterRequestCode}
                pwdStrengthLabel={this.state.pwdStrengthLabel}
                pwdStrengthProgress={this.state.pwdStrengthProgress}
                pwdStrengthVarient={this.state.pwdStrengthVarient}
                pwdMatchObj={this.state.expMatch}
                resetPwdErrMsg={resetPasswordMessages.error.passwordErrors}
                isAuthCodeReceived={this.state.isAuthCodeReceived}
                isAuthCodeError={this.state.isAuthCodeError}
                requestAuthenticationCode={this.requestAuthenticationCode}
                authCodeObj={this.state.authCodeObj}
                errorMessage={this.state.errorMessage}
                authCodeDeliveryMsg={this.state.authCodeDeliveryMsg}
                reSendAuthCode={this.state.reSendAuthCode}
                closeAction={this.props.closeAction}
            />
        )
    }
}
