import React, { Component } from 'react';
import GlobalVsMyCaseDetails from '../Surgeon/GlobalVsMyCaseDetails';
import AlignmentDetails from '../TKADashboard/AlignmentDetails';
import AverageCaseTimeDetails from '../Surgeon/AverageCaseTimeDetails';
import { getImgSrc } from '../../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';
import { operativeProcedureTypes } from '../../../helpers/constants';
import AvgPROMScores from '../Surgeon/AvgPROMScores';

export default class TKADashboardComponent extends Component {

	render() {
		const { totalGlobalCasesCount, alignmentData, averageCaseTime, totalTimeData,defaultScoreType, timeFrameValue,
			handleOutcomeScoreValueChange, totalCasesDetails, activeTab, handleTabs, outcomeScoreMessage, isRarView, surgeonDetails,
			outcomeLoading, avgCasetimeLoading, globalCaseLoading, alignmentLoading, outcomeError, avgCasetimeError, globalCaseError, alignmentError,setuptimePreference} = this.props;

		const globalId = surgeonDetails && surgeonDetails.surgeonGlobalId ? surgeonDetails.surgeonGlobalId : 'N/A';

		return (
			<>
				{isRarView && <>
					<div className="row">
						<div className="col-12">
							<div className="surgeon-details-card mb-3 py-3 px-3" data-testid="tka-dashboard">
								<div className="row">
									<div className="col-lg-4">
										<div className="d-flex align-items-center justify-content-left">
											<div className="profile-surgeoncard d-flex align-items-center">
												<img src={getImgSrc('no-profile-image.png')} alt="Profile" />
											</div>
											<div className="px-3">
												<div className="surgeon-id-label">{surgeonDetails.name}</div>
												<div>Surgeon ID: {globalId}</div>
												<div>{surgeonDetails.noOfcases} Cases in RI.Insights</div>
											</div>
										</div>
									</div>
									<div className="col-md-8">
										<div className="customer-wrap">
											{surgeonDetails.customerInfo && surgeonDetails.customerInfo.length ?
												surgeonDetails.customerInfo.map((customer, i) => {
													return (
														<div className="customer-detail" key={`customer-${i}`}>
															<div className="surgeon-id-label">{customer.customerName || 'N/A'}</div><br />
															<div>Customer ID: {customer.customerId || 'N/A'}</div>
														</div>
													)
												})
												: ''}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>}
				<div className="row">
					{/* My Cases and Global Cases */}
					<div className="col-md-6 height dashboard-left-block">
						{globalCaseLoading ?
							<div className="loading-img loading-img-center"><img className="" src={getImgSrc('loading.gif')} alt="loading" /></div>
							: totalGlobalCasesCount ?
								<GlobalVsMyCaseDetails timeFrameValue={timeFrameValue} totalGlobalCasesCount={totalGlobalCasesCount} titleText="TKA Cases" />
								: globalCaseError ?
									<ErrorMsgBlockComponent errorObject={globalCaseError} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />
									: <ErrorMsgBlockComponent noData={true} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />}
					</div>

					{/* Alignment Details */}
					<div className="col-md-6 height">
						{alignmentLoading ?
							<div className="loading-img loading-img-center"><img className="" src={getImgSrc('loading.gif')} alt="loading" /></div>
							: alignmentData ?
								<AlignmentDetails alignmentData={alignmentData} />
								: alignmentError ?
									<ErrorMsgBlockComponent errorObject={alignmentError} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />
									: <ErrorMsgBlockComponent noData={true} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />}
					</div>
				</div>
				<div className="row" data-testid="totalGlobalCasesCount">
					{/* Average Case Time */}
					<div className="col-md-6 bottom-row height dashboard-left-block ">
						{avgCasetimeLoading ?
							<div className="loading-img loading-img-center"><img className="" src={getImgSrc('loading.gif')} alt="loading" /></div>
							: averageCaseTime && totalTimeData ?
								<AverageCaseTimeDetails averageCaseTime={averageCaseTime}totalTimeData={totalTimeData} setuptimePreference={setuptimePreference} operativeProcedure={operativeProcedureTypes.TKA.text} />
								: avgCasetimeError ?
									<ErrorMsgBlockComponent errorObject={avgCasetimeError} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />
									: <ErrorMsgBlockComponent noData={true} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />}
					</div>

					{/* Outcome Scores */}
					<div className="col-md-6 bottom-row height">
						{outcomeLoading ?
							<div className="loading-img loading-img-center"><img className="" src={getImgSrc('loading.gif')} alt="loading" /></div>
							: totalCasesDetails ?
								<AvgPROMScores
									titleText="Outcomes Scores"
									operativeProcedure={operativeProcedureTypes.TKA.inLowerCase}
									outcomeScoreMessage={outcomeScoreMessage}
									handleTabs={handleTabs}
									defaultScoreType={defaultScoreType}
									activeTab={activeTab}
									handleOutcomeScoreValueChange={handleOutcomeScoreValueChange}
									totalCasesDetails={totalCasesDetails}
								/>
								: outcomeError ?
									<ErrorMsgBlockComponent errorObject={outcomeError} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />
									: <ErrorMsgBlockComponent noData={true} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />}
					</div>
				</div>
			</>
		)
	}
}
