import Highcharts from 'highcharts';
import { formatDateString, getImgSrc } from './GlobalFunctions';

/* istanbul ignore next  */
export const exportAddOns = (chart) => {
    // DETAILS ON TOP LEFT
    var html = '';
    html += `<div>Date Downloaded: ${formatDateString(new Date())}`;
    var details = chart.renderer.label(html)
        .css({
            width: '400px',
            fontSize: '7px'
        })
        .attr({
            'padding': 5
        })
        .add();

    details.align(Highcharts.extend(details.getBBox(), {
        align: 'left',
        x: 20, // offset
        verticalAlign: 'top',
        y: 5 // offset
    }), null, 'spacingBox');

    // LOGO ON TOP RIGHT
    var logo = getImgSrc('login-logo.png');
    chart.renderer.image(logo, 770, 10, 100, 40).add();

    // DISCLAIMER ON LEFT BOTTOM
    // var disclaimer = chart.renderer.label(exportTexts.disclaimer)
    //     .css({
    //         width: '400px',
    //         fontSize: '7px',
    //         fontStyle: 'italic'
    //     })
    //     .attr({
    //         'padding': 5
    //     })
    //     .add();

    // disclaimer.align(Highcharts.extend(disclaimer.getBBox(), {
    //     align: 'left',
    //     x: 20, // offset
    //     verticalAlign: 'bottom',
    //     y: 5 // offset
    // }), null, 'spacingBox');
}