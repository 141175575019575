import React, { Component } from 'react';
import { PROMScoreTypes } from '../../containers/CaseDetails/constants';
import { isPositiveNumber } from '../../helpers/GlobalFunctions';
import { actions, promScoreMessages } from '../../helpers/messages'
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
class EditProms extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }

    /**
	 * @description Function to make sure only numbers are input
	 * @memberof EditProms
	 */
    /* istanbul ignore next  */
    checkNumeric = e => {
        const isValid = isPositiveNumber(e);
        if (!isValid) {
            e.preventDefault();
            return false;
        }
        if (e.keyCode === 13) {
			this.props.onEnterPress(e);
		}
    }

    render() {
        const { operativeProcedure, handleInputChange, editPromScore, isSubScore, scoreType, subScoreType, subScoreFields, timeFrame, scoreValue, formErrors, promScoreSave, 
            promsErrorMsg, isLoading, cancelProms, onEnterPress } = this.props;
        const scoreTypeDropdown = PROMScoreTypes[operativeProcedure.toLowerCase()];
        
        return (<div className="proms-blocks">
            <div className="proms-edit-wrap px-3">
                {promScoreSave === false && promsErrorMsg ? <ErrorMsgBlockComponent className="py-0 m-0" errorObject={promsErrorMsg} /> : ''}
                <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                    <div className="form-container ms-0 d-flex justify-content-between">
                        <div className="proms-form-lable text-start">Score Type</div>
                        <div className="proms-form-input">
                            <select value={scoreType} id="scoresType" onChange={(e) => handleInputChange(e.target.value, 'scoreType')} className="form-select">
                                <option value="">Select Score Type</option>
                                {scoreTypeDropdown && scoreTypeDropdown.length && scoreTypeDropdown.map((score, index) => <option key={`edit-score-type-${index}`} value={score.value}>{score.label}</option>)}
                            </select>
                            {formErrors && formErrors.scoreType ?
                                <div className="proms-error-msg">
                                    <p>{formErrors.scoreType}</p>
                                </div>
                                : ''}
                        </div>
                    </div>

                    {isSubScore ?
                        <div className="form-container ms-0 d-flex justify-content-between">
                            <div className="proms-form-lable text-start">Sub-Score</div>
                            <div className="proms-form-input">
                                <select value={subScoreType} id="subScoreType" onChange={(e) => handleInputChange(e.target.value, 'subScoreType')} className="form-select">
                                    <option value="">Select Sub-Score</option>
                                    {subScoreFields && subScoreFields.length ?
                                        subScoreFields.map((field, index) => <option value={field.type} key={`subscore-${index+1}`}>{field.label}</option>)
                                    : ''}
                                    
                                </select>
                                {formErrors && formErrors.subScoreType ?
                                    <div className="proms-error-msg">
                                        <p>{formErrors.subScoreType}</p>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    : ''}        
                    <div className="form-container ms-0 d-flex justify-content-between">
                        <div className="proms-form-lable text-start">Time Frame</div>
                        <div className="proms-form-input">
                            <select value={timeFrame} id="timeFrame" onChange={(e) => handleInputChange(e.target.value, 'timeFrame')} className="form-select">
                                <option value="">Select Time Frame</option>
                                <option value="6 weeks" >6 Weeks</option>
                                <option value="12 weeks">12 Weeks</option>
                                <option value="6 months">6 Months</option>
                                <option value="12 months">12 Months</option>
                                <option value="2 years">2 Years</option>
                            </select>
                            {formErrors && formErrors.timeFrame ?
                                <div className="proms-error-msg">
                                    <p>{formErrors.timeFrame}</p>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="form-container ms-0 d-flex justify-content-between">
                        <div className="proms-form-lable text-start">Score</div>
                        <div className="proms-form-input">
                            <input value={scoreValue} id="scoreValue" type='number' min='0' max='100' autoComplete="off"
                                onKeyDown={e => this.checkNumeric(e)} onChange={(e) => handleInputChange(e.target.value, 'scoreValue')} className="form-control" />
                            {scoreType && scoreType === 'KOOS JR.' ?
                                    <div className="proms-help-msg">
                                        <p>{promScoreMessages.scoreHelpText}</p>
                                    </div>
                                    : ''}
                            {formErrors && formErrors.scoreValue ?
                                <div className="proms-error-msg">
                                    <p>{formErrors.scoreValue}</p>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="form-container ms-0">
                        <div className="proms-form-btn d-flex justify-content-end">
                            <button className="btn btn-primary proms-btn-color" type="button" onClick={editPromScore} id="editProms">Update</button>
                            <button className="btn btn-secondary proms-btn-cancel" type="button" onClick={cancelProms} id="cancelProms">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
            { isLoading ? <div className="loading-overlay"><span>{actions.load}</span></div> : null}
        </div>);
    }
}
export default EditProms;

