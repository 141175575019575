import React, { Component } from 'react';
import { useContext } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext'
import { parseHTML } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

function CustomAccordionToggle(props) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
        props.eventkey,
         /* istanbul ignore next  */
        () => props.callback && props.callback(props.eventkey),
    );
    const isCurrentEventKey = currentEventKey.activeEventKey === props.eventkey;
    
         /* istanbul ignore next  */
         return (<Card.Header variant="link" className={isCurrentEventKey ? 'active' : ''} onClick={decoratedOnClick} {...props}>
        <div className="d-flex align-items-center justify-content-between">
            <span>{props.children}</span>
            <span>
                {!isCurrentEventKey ?
                    <svg
                        className="bi bi-chevron-down"
                        width="1.2em"
                        height="1.2em"
                        viewBox="0 0 16 16"
                        stroke="#73BC51"
                        strokeWidth="2"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
                            clipRule="evenodd"
                        />
                    </svg>
                    :
                    <svg
                        className="bi bi-chevron-up"
                        width="1.2em"
                        height="1.2em"
                        viewBox="0 0 16 16"
                        stroke="#73BC51"
                        strokeWidth="2"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
                            clipRule="evenodd"
                        />
                    </svg>
                }
            </span>
        </div>
    </Card.Header>)
}

export default class FAQComponent extends Component {
    render() {
        const { faqList, errorObject } = this.props;

        return (
            <div className="faq-wrapper">
                <div className="row faq-block">
                    <div className="col-sm-12 pe-0">
                        {faqList && faqList.length ?
                            faqList.map((faq, i) => (
                                <Accordion key={`faq-${i + 1}`} className="mb-1" defaultActiveKey="0">
                                    <Card>
                                        <CustomAccordionToggle eventkey={i.toString()}>{faq.question}</CustomAccordionToggle>
                                        <Accordion.Collapse eventKey={i.toString()}>
                                            <Card.Body>{parseHTML(faq.faqtext)}</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            ))
                            : errorObject ? <ErrorMsgBlockComponent errorObject={errorObject} /> : <ErrorMsgBlockComponent noData={true} />}
                    </div>
                </div>
            </div>
        )
    }
}
