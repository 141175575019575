import React, { Component } from 'react'
import { resetPasswordMessages } from '../helpers/messages';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'
import { useState } from 'react';

export class PasswordCriteria extends Component {
    /**
     * @memberof PasswordCriteria
     * @returns Speciofic error message string
     */
    getErrorMessage = () => {
        const { pwdMatchObj, errorMessages } = this.props;
        for (const key in pwdMatchObj) {
            /* istanbul ignore next  */
             if (Object.hasOwnProperty.call(pwdMatchObj, key)) {
                if (!pwdMatchObj[key]) {
                    return resetPasswordMessages.error.getErrorMsg(errorMessages[key]);
                }
            }
        }
    }

    render() {
        const { pwdMatchObj } = this.props;
        return pwdMatchObj ? <p className="m-0 text-danger">{this.getErrorMessage()}</p> : '';
    }
}

export function PasswordHints(props) {
    const [showHints, setShowHints] = useState(false);
    const toggleShowHints = () => setShowHints(!showHints);

    return (
        <div className="position-relative">
            <button type="button" className="btn btn-link p-0" onClick={toggleShowHints}>
                <div className="hint-block text-muted na-help-text d-flex align-items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-info-circle-fill me-1 mt-1" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                    </svg>
                    {resetPasswordMessages.passwordHint}
                </div>
            </button>
            <ToastContainer containerPosition="absolute" className={`password-hint-popup ${props.containerClass}`}>
                <Toast show={showHints} bg="light" onClose={toggleShowHints}>
                    <Toast.Header>
                        <strong className="me-auto">Password Rules</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <label className="mb-2">Your password must have:</label>
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="password-hints-list">
                                    <li>{resetPasswordMessages.error.passwordErrors.stringLength}</li>
                                    <li>{resetPasswordMessages.error.passwordErrors.containsLowerCase}</li>
                                    <li>{resetPasswordMessages.error.passwordErrors.containsUpperCase}</li>
                                    <li>{resetPasswordMessages.error.passwordErrors.containsNumber}</li>
                                    <li>{resetPasswordMessages.error.passwordErrors.containsSpecialChr}</li>
                                    <li>{resetPasswordMessages.error.passwordErrors.containsUsername}</li>
                                </ul>
                            </div>
                        </div>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}
