import React, { Component } from 'react'
import { CSVLink } from 'react-csv';
import { getUserRoleType } from '../../services/aws/aws-services';
import SurgeonSearch from '../../components/SurgeonList/SurgeonSearch'
export default class CSVSurgeon extends Component {
    constructor() {
        super()
        this.exportRef = React.createRef();
        this.state = {
            activeUserRole: getUserRoleType()
        }
    }

    /**
     * @description function to handle export action 
     * @param {*}
     * @memberof CSVSurgeon
     */
    handleExportAction = () => {
        /* istanbul ignore next  */
        setTimeout(() => {
            this.exportRef.current.link.click();
            this.props.exportComplete();
        }, 1000);
    }

    render() {
        const { exportCsvHeader, exportSurgeonData, isExport, isLoading, exportToCsv, getCsvFileName, errorMessage, handleSearchSubmit, handleReset } = this.props;
        const isAdmin = this.state.activeUserRole.includes('admin');
        if (isExport) {
            this.handleExportAction();
        }
        return (
            <>
                <div className="row">
                    <div className="col-md-8">
                        {isAdmin ? <h1 className="file-list-heading">Surgeon List</h1> : ''}
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-6">
                        <SurgeonSearch handleSearchSubmit={handleSearchSubmit} handleReset={handleReset} />
                    </div>
                    <div className="col-md-6">
                        {isLoading ? 
                            <button className="btn btn-primary disabled add-customer-btn expor-customer-to-csv-btn">
                                <span className="spinner-border spinner-border-sm"></span>
                                &nbsp;Exporting...
                            </button> 
                            : <button type="button" id="exportToExcel" className="btn btn-primary add-customer-btn expor-customer-to-csv-btn" onClick={(e) => exportToCsv()}>Export to CSV</button>}
                        {exportSurgeonData && <CSVLink
                            headers={exportCsvHeader}
                            data={exportSurgeonData}
                            filename={getCsvFileName}
                            ref={this.exportRef}
                            target="_blank" />}
                    </div>
                    <div className="col-md-12">
                        {errorMessage ? <div className="export-customer-error-block">{errorMessage} &nbsp;</div> : ''}
                    </div>
                </div>
            </>
        )
    }
}
