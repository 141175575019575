import React, { Component } from 'react';
import { GapDataFileNames } from '../../helpers/constants';
import { gapMessages } from '../../helpers/messages';
import GapsComponent from '../../components/CaseDetails/GapsComponent';
import { checkLocalStorage, getLocalStorage, setLocalStorage } from '../../services/storage/storage-service';

export default class GapsContainer extends Component {
	constructor(props) {
		super(props);
		var checkBoxes;
		var gapData;
		if (checkLocalStorage('gapData') && props.isTensioner) {
			gapData = JSON.parse(getLocalStorage('gapData'));
			let gapForCurrentCase = gapData.find(x => x.caseID === props.caseDetails.caseID);

			if (gapForCurrentCase) {
				checkBoxes = gapForCurrentCase.checkBoxes;
			} else {
				checkBoxes = this.getDefaultCheckboxes();
			}
		} else {
			checkBoxes = this.getDefaultCheckboxes();
		}

		const defaultSelection = checkBoxes.filter(x => x.checked).map(x => x.value);
		this.state = {
			checkBoxes: checkBoxes,
			showGapGraph: defaultSelection,
			gapData: gapData,
			gapsCollected: props.caseDetails && props.caseDetails.gapsCollected ? props.caseDetails && props.caseDetails.gapsCollected : null,
			noGraphSelected: {
				message: gapMessages.noSelection
			}
		}
	}

	componentDidMount = async () => {
		this.binGapData();
	}

	/**
	* @description function to get default checkboxes
	*/
	getDefaultCheckboxes = () => {
		const { caseDetails, isTensioner } = this.props;
		const gapsCollected = caseDetails && caseDetails.gapsCollected ? caseDetails && caseDetails.gapsCollected : null;
		let manualChecked = !isTensioner || (isTensioner && gapsCollected.toLowerCase() === 'manual');
		let tensionerChecked = isTensioner && !manualChecked;
		return [
			{ value: 'manual', checked: manualChecked },
			{ value: 'tensioner', checked: tensionerChecked }
		]
	}

	/**
	* @description function to bin the data points
	* @param {*}
	* @memberof GapsContainer
	*/
	getBinnedManualDataSet = (gapData, fileNames) => {
		let preOperativeLateralGaps = [];
		let preOperativeMedialGaps = [];
		let postOperativeLateralGaps = [];
		let postOperativeMedialGaps = [];

		gapData.forEach(element => {
			if (fileNames.preOpLateral.indexOf(element.name) > -1) {
				element.flexionData && element.flexionData.forEach(item => {
					let flexionGapData = {
						x: parseFloat(item.flexion),
						y: parseFloat(item.gap)
					};
					preOperativeLateralGaps.push(flexionGapData)
				})
			}

			if (fileNames.preOpMedial.indexOf(element.name) > -1) {
				element.flexionData && element.flexionData.forEach(item => {
					let flexionGapData = {
						x: parseFloat(item.flexion),
						y: parseFloat(item.gap)
					};
					preOperativeMedialGaps.push(flexionGapData)
				})
			}

			if (fileNames.postOpLateral.indexOf(element.name) > -1) {
				element.flexionData && element.flexionData.forEach(item => {
					let flexionGapData = {
						x: parseFloat(item.flexion),
						y: parseFloat(item.gap)
					};
					postOperativeLateralGaps.push(flexionGapData)
				})
			}

			if (fileNames.postOpMedial.indexOf(element.name) > -1) {
				element.flexionData && element.flexionData.forEach(item => {
					let flexionGapData = {
						x: parseFloat(item.flexion),
						y: parseFloat(item.gap)
					};
					postOperativeMedialGaps.push(flexionGapData)
				})
			}
		});


		// We group these data elements into 14 "bins" at an interval of 10.
		// For each "bin", we pick the data point with the largest flexion
		// angle in that range, and we do this for both medial and lateral
		// gaps. For example, if we had data points at 3 and 7 degrees of
		// flexion, we would use the data at 7 degrees.

		let preOpLatGapsBinned = new Array(14).fill(null);
		let preOpMedGapsBinned = new Array(14).fill(null);
		let postOpMedGapsBinned = new Array(14).fill(null);
		let postOpLatGapsBinned = new Array(14).fill(null);

		for (let i = 0; i < preOperativeLateralGaps.length; i++) {
			let binInd = Math.floor(preOperativeLateralGaps[i]['x'] / 10);
			preOpLatGapsBinned[binInd + 2] = preOperativeLateralGaps[i]['y'];
		}

		for (let i = 0; i < preOperativeMedialGaps.length; i++) {
			let binInd = Math.floor(preOperativeMedialGaps[i]['x'] / 10);
			preOpMedGapsBinned[binInd + 2] = preOperativeMedialGaps[i]['y'];
		}

		for (let i = 0; i < postOperativeLateralGaps.length; i++) {
			let binInd = Math.floor(postOperativeLateralGaps[i]['x'] / 10);
			postOpLatGapsBinned[binInd + 2] = postOperativeLateralGaps[i]['y'];
		}

		for (let i = 0; i < postOperativeMedialGaps.length; i++) {
			let binInd = Math.floor(postOperativeMedialGaps[i]['x'] / 10);
			postOpMedGapsBinned[binInd + 2] = postOperativeMedialGaps[i]['y'];
		}

		return {
			preOpLatGapsBinned,
			preOpMedGapsBinned,
			postOpMedGapsBinned,
			postOpLatGapsBinned
		};
	}

	/**
	* @description Function to bin the data points
	* @param gapData Raw dataset to be rearranged for the graph
	* @param fileNames Files to look at for the raw dataset
	* @memberof GapsContainer
	*/
	getBinned17DataSet = (gapData, fileNames, isManual = false) => {
		const dynamicPropertyNames = {
			medial: isManual ? 'medialCollectionManual' : 'medialCollection',
			lateral: isManual ? 'lateralCollectionManual' : 'lateralCollection',
			bin: isManual ? 'binManual' : 'bin'
		}
		let preOperativeLateralGaps = [];
		let preOperativeMedialGaps = [];
		let postOperativeLateralGaps = [];
		let postOperativeMedialGaps = [];

		const filteredGapData = gapData.filter(x => fileNames.preOp.indexOf(x.name) > -1 || fileNames.postOp.indexOf(x.name) > -1);
		filteredGapData.forEach(element => {
			if (fileNames.preOp.indexOf(element.name) > -1) {
				if (element[dynamicPropertyNames.medial] && element[dynamicPropertyNames.medial][dynamicPropertyNames.bin] && element[dynamicPropertyNames.medial][dynamicPropertyNames.bin].length) {
					element[dynamicPropertyNames.medial][dynamicPropertyNames.bin] && element[dynamicPropertyNames.medial][dynamicPropertyNames.bin].forEach(item => {
						let flexionGapData = {
							x: parseFloat(item.flexion),
							y: parseFloat(item.maxGap)
						};
						preOperativeMedialGaps.push(flexionGapData);
					})
				}

				if (element[dynamicPropertyNames.lateral] && element[dynamicPropertyNames.lateral][dynamicPropertyNames.bin] && element[dynamicPropertyNames.lateral][dynamicPropertyNames.bin].length) {
					element[dynamicPropertyNames.lateral][dynamicPropertyNames.bin] && element[dynamicPropertyNames.lateral][dynamicPropertyNames.bin].forEach(item => {
						let flexionGapData = {
							x: parseFloat(item.flexion),
							y: parseFloat(item.maxGap)
						};
						preOperativeLateralGaps.push(flexionGapData);
					})
				}
			}

			if (fileNames.postOp.indexOf(element.name) > -1) {
				if (element[dynamicPropertyNames.medial] && element[dynamicPropertyNames.medial][dynamicPropertyNames.bin] && element[dynamicPropertyNames.medial][dynamicPropertyNames.bin].length) {
					element[dynamicPropertyNames.medial][dynamicPropertyNames.bin] && element[dynamicPropertyNames.medial][dynamicPropertyNames.bin].forEach(item => {
						let flexionGapData = {
							x: parseFloat(item.flexion),
							y: parseFloat(item.maxGap)
						};
						postOperativeMedialGaps.push(flexionGapData);
					})
				}

				if (element[dynamicPropertyNames.lateral] && element[dynamicPropertyNames.lateral][dynamicPropertyNames.bin] && element[dynamicPropertyNames.lateral][dynamicPropertyNames.bin].length) {
					element[dynamicPropertyNames.lateral][dynamicPropertyNames.bin] && element[dynamicPropertyNames.lateral][dynamicPropertyNames.bin].forEach(item => {
						let flexionGapData = {
							x: parseFloat(item.flexion),
							y: parseFloat(item.maxGap)
						};
						postOperativeLateralGaps.push(flexionGapData);
					})
				}
			}
		});


		// We group these data elements into 14 "bins" at an interval of 10.
		// For each "bin", we pick the data point with the largest flexion
		// angle in that range, and we do this for both medial and lateral
		// gaps. For example, if we had data points at 3 and 7 degrees of
		// flexion, we would use the data at 7 degrees.

		let preOpLatGapsBinned = new Array(14).fill(null);
		let preOpMedGapsBinned = new Array(14).fill(null);
		let postOpMedGapsBinned = new Array(14).fill(null);
		let postOpLatGapsBinned = new Array(14).fill(null);

		for (let i = 0; i < preOperativeLateralGaps.length; i++) {
			let binInd = Math.floor(preOperativeLateralGaps[i]['x'] / 10);
			preOpLatGapsBinned[binInd + 2] = preOperativeLateralGaps[i]['y'];
		}

		for (let i = 0; i < preOperativeMedialGaps.length; i++) {
			let binInd = Math.floor(preOperativeMedialGaps[i]['x'] / 10);
			preOpMedGapsBinned[binInd + 2] = preOperativeMedialGaps[i]['y'];
		}

		for (let i = 0; i < postOperativeLateralGaps.length; i++) {
			let binInd = Math.floor(postOperativeLateralGaps[i]['x'] / 10);
			postOpLatGapsBinned[binInd + 2] = postOperativeLateralGaps[i]['y'];
		}

		for (let i = 0; i < postOperativeMedialGaps.length; i++) {
			let binInd = Math.floor(postOperativeMedialGaps[i]['x'] / 10);
			postOpMedGapsBinned[binInd + 2] = postOperativeMedialGaps[i]['y'];
		}

		return {
			preOpLatGapsBinned,
			preOpMedGapsBinned,
			postOpMedGapsBinned,
			postOpLatGapsBinned
		};
	}

	/**
	* @description Get manual dataset based on CORI system used
	* 
	* @memberof GapsContainer
	*/
	getManualGapData = () => {
		const { gapData } = this.props;
		if (gapData && gapData.manual17 && gapData.manual17.length) {
			return { dataset: gapData.manual17, system: "1.7" };
		} else if (gapData && gapData.manual15 && gapData.manual15.length) {
			return { dataset: gapData.manual15, system: "1.5" };
		} else if (gapData && gapData.manual && gapData.manual.length) {
			return { dataset: gapData.manual, system: "1.5" };
		} else {
			return null;
		}
	}

	/**
	* @description function to create data series for graph
	* @param {*}
	* @memberof GapsContainer
	*/
	binGapData = () => {
		const { gapData, isTensioner } = this.props;
		const tensionerData = isTensioner && gapData && gapData.tensioner && gapData.tensioner.length ? gapData.tensioner : null;
		const manualDataSet = this.getManualGapData();
		var manualData = null;
		const isManualDisabled = isTensioner && !(manualDataSet && manualDataSet.system === '1.7');
		if (manualDataSet) {
			switch (manualDataSet.system) {
				case "1.5":
					manualData = this.getBinnedManualDataSet(manualDataSet.dataset, GapDataFileNames.manual15);
					break;
				case "1.7":
					manualData = this.getBinned17DataSet(manualDataSet.dataset, GapDataFileNames.manual17, true);
					break;

				default:
					break;
			}
		}

		this.setState({
			isManualDisabled: isManualDisabled,
			manualBinned: manualData,
			tensionerBinned: tensionerData ? this.getBinned17DataSet(tensionerData, GapDataFileNames.tensioner) : null
		});
	}

	/**
	* @description function to handle graph view
	* @param event Checkbox change event
	* @memberof GapsContainer
	*/
	handleGapGraphChange = (event) => {
		var value = event.target.value;
		var { checkBoxes, gapData } = this.state;
		var checkBox = checkBoxes.find(x => x.value === value);
		checkBox.checked = !checkBox.checked;
		var checkedList = checkBoxes.filter(x => x.checked).map(x => x.value);
		if (gapData && gapData.length) {
			let currentCaseIndex = gapData.findIndex(x => x.caseID === this.props.caseDetails.caseID);
			if (currentCaseIndex > -1) {
				gapData[currentCaseIndex].checkedList = checkedList;
				gapData[currentCaseIndex].checkBoxes = checkBoxes;
			} else {
				gapData.push({
					caseID: this.props.caseDetails.caseID,
					checkedList: checkedList,
					checkBoxes: checkBoxes
				})
			}
		} else {
			gapData = [{
				caseID: this.props.caseDetails.caseID,
				checkedList: checkedList,
				checkBoxes: checkBoxes
			}]
		}
		setLocalStorage('gapData', JSON.stringify(gapData));
		this.setState({ showGapGraph: checkedList, gapData: gapData, initialState: false });
	}

	render() {
		const { gapError, isTensioner } = this.props;
		const { showGapGraph, noGraphSelected, isManualDisabled, manualBinned, tensionerBinned, gapsCollected } = this.state;

		return (
			<GapsComponent
				gapError={gapError}
				isTensioner={isTensioner}
				gapsCollected={gapsCollected}
				isManualDisabled={isManualDisabled}
				showGapGraph={showGapGraph}
				noGraphSelected={noGraphSelected}
				manualBinned={manualBinned}
				tensionerBinned={tensionerBinned}
				handleGapGraphChange={this.handleGapGraphChange}
			/>
		)
	}
}
