import React, { Component } from 'react';
import { convertUTCToLocalDateTime, formatDate, isValidDate } from '../../helpers/GlobalFunctions';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../helpers/messages';
import IdleTimer from 'react-idle-timer';
import { logger } from '../../services/logger/logger-service';
import { eventSource } from '../../helpers/constants';
import { getUserRoleType } from '../../services/aws/aws-services';

export default class SubRowsReviewPlans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remaining: this.timeout,
            lastActive: new Date(),
            elapsed: 0,
            logSent: false,
            userIsCustomer: getUserRoleType()?.includes('customersupport'),
        };
        this.idleTimer = null;
        this.timeout = 1000 * 60 * 10; // 10 minute timeout

    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.timeSpentLogger);

        /* istanbul ignore next  */
        this.timer = setInterval(() => {
            this.setState({
                remaining: this.idleTimer.getRemainingTime(),
                elapsed: this.idleTimer.getElapsedTime(),
            });
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
        clearInterval(this.timer);
    }

    /**
     * @description function to handle application logs for time spent on the page
     * @memberof SubRowsReviewPlans
     */
    /* istanbul ignore next  */
    timeSpentLogger = () => {
        // calculate the time since we loaded this page
        if (!this.state.logSent) {
            const timeSinceLoad = (new Date().getTime() - this.state.lastActive.getTime()) / 1000;

            const loggerObj = {
                EventOutcome: loggerEventOutcome.success,
                EventType: loggerEventTypes.read,
                EventName: loggerEventName.planDetailView,
                EventSource: eventSource.kpopPlans.value,
                Content: {
                    Data: loggerEventMessage.planDetailView(),
                    TimeSpent: timeSinceLoad,
                    CaseId: this.props.row?.original?.VisionaierId.toString(),
                },
            };
            this.setState({ logSent: true });
            logger(loggerObj);
        }
    };

    /**
     * @description function to handle rendering the body of table if field is empty show N/A
     * @param {*} patientDetails Obj containing all data for patient
     * @memberof SubRowsReviewPlans
     */
    renderPatientDetails(patientDetails) {
         /* istanbul ignore next  */
        if (!patientDetails) return {};

        const {
            patientFirstName ,
            patientMiddleName ,
            patientLastName,
            patientSex = 'N/A',
            operativeSide = 'N/A',
            operativeCondyle = 'N/A',
            orgHostpialInfo = 'N/A',
            salesRepName = 'N/A',
            procedureType = 'N/A',
            patientDOB,
            surgeryDate,
            lastUpdate,
        } = patientDetails;

        
        const patientName = this.formatFullName(patientLastName, patientFirstName, patientMiddleName);
         /* istanbul ignore next  */
        const condyleAndSide = `${operativeSide} ${operativeCondyle?.trim() || 'N/A'}`;
        var patientDOBText = isValidDate(patientDOB) ? patientDOB : 'N/A';
        var surgeryDateText = 'N/A';
        if (isValidDate(surgeryDate)) {
            let surgeryDateArray = surgeryDate.split('T');
            surgeryDateText = surgeryDateArray?.length ? surgeryDateArray[0] : 'N/A';
        }
        
        return (
            <tr className='file-details-tr-background text-capitalize' {...this.props.rowProps}>
                <td className='patient-td'>{patientName || 'N/A'}</td>
                <td>{patientDOBText}</td>
                <td>{patientSex?.trim() || 'N/A'}</td>
                <td className='hide-column-on-mobile hospital-name-td'>{orgHostpialInfo?.trim() || 'N/A'}</td>
                {this.state.userIsCustomer && <td className='hide-column-on-mobile show-sName-only-customer-td'>{salesRepName.trim() || 'N/A'}</td>}
                <td>{surgeryDateText}</td>
                <td className='procedure-type-td'>{procedureType?.trim() || 'N/A'}</td>
                <td>{condyleAndSide}</td>
                <td className='hide-column-on-mobile updated-at'>{this.formatUpdateDate(lastUpdate)}</td>
            </tr>
        );
    }

    /**
     * @description Constructs a formatted full name from the given last name, first name, and middle name.
     * If the last name or the first name is not provided, 'Unknown' is used as a placeholder.
     * If the middle name is provided, it is included in the formatted name.
     * @param {string} lastName - The last name of the individual.
     * @param {string} firstName - The first name of the individual.
     * @param {string} middleName - The middle name of the individual.
     * @returns {string} The formatted full name in the format: 'LastName, FirstName, MiddleName' or 'LastName, FirstName' if middle name is absent.
     */
    formatFullName(lastName, firstName, middleName) {
        let firstN = firstName?.trim() || 'Unknown';
        let middleN = middleName?.trim() || '';
        let lastN = lastName?.trim() || 'Unknown';
        return `${lastN}, ${firstN}${middleN && ','} ${middleN}`;
    }


     /**
     * @description Formats the given date into a human-readable format if the date is valid.
     * If the date is not provided or invalid, appropriate placeholders are returned.
     * @param {string|Date} date - The date to be formatted.
     * @returns {string} The formatted date, 'N/A' if the date is not provided, or 'Invalid Date' if the date is invalid.
     */
    formatDate(date) {
        if (!date) {
            return 'N/A';
        } else if (isValidDate(date)) {
            return formatDate(date);
        } else {
            return 'Invalid Date';
        }
    }


     /**
     * @description Formats the given date into a human-readable local date-time format if the date is valid.
     * If the date is not provided or invalid, appropriate placeholders are returned.
     * @param {string|Date} date - The date to be formatted.
     * @returns {string} The formatted local date-time, 'N/A' if the date is not provided, or 'Invalid Date' if the date is invalid.
     */
    formatUpdateDate(date) {
        if (!date) {
            return 'N/A';
        } else if (isValidDate(date)) {
            return convertUTCToLocalDateTime(date);
        } else {
            return 'Invalid Date';
        }
    }

 
    render() {
        const { row, visibleColumns, loading } = this.props;

        const patientDetails = row?.original?.patientDetails;

        if (loading) {
            return (
                <tr>
                    <td className='loading' colSpan={visibleColumns.length}>
                        Loading...
                    </td>
                </tr>
            );
        }

        const dynamicColSpan = this.state.userIsCustomer ? `10` : `9`

        return (
            <>
                {Object?.keys(patientDetails).length > 0 ? (
                    <>
                        <IdleTimer
                            ref={(ref) => {
                                this.idleTimer = ref;
                            }}
                            timeout={this.timeout}
                            onIdle={this.timeSpentLogger}
                        />

                        <tr className='file-details-tr-text'>
                            <td colSpan='1'></td>

                            <td colSpan={dynamicColSpan} className='dynamic-td-container'>
                                <table data-testid='subrow-reviews' className='file-details-table p-2 border'>
                                    <thead>
                                        <tr>
                                            <th colSpan='1' className='border-0 text-aign-left'>
                                                Plan Details
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        {Object.keys(patientDetails).length > 0 && (
                                            <tr className='file-details-col-heading'>
                                                <th>Patient Name</th>
                                                <th>Patient DOB</th>
                                                <th>Patient Sex</th>
                                                <th className='hide-column-on-mobile'>Org. Hospital info</th>
                                                {this.state.userIsCustomer && <th className='hide-column-on-mobile show-sName-only-customer'>Sales Rep Name</th>}
                                                <th>Surgery Date</th>
                                                <th>Procedure Type</th>
                                                <th>Op. Side & Condyle</th>
                                                <th className='hide-column-on-mobile'>Last Update</th>
                                            </tr>
                                        )}
                                    </thead>
                                    <tbody>{this.renderPatientDetails(patientDetails)}</tbody>
                                </table>
                            </td>
                        </tr>
                    </>
                ) : (
                    <tr>
                        <td colSpan='5' className='file-details-nodata'>
                            No case data found
                        </td>
                    </tr>
                )}
            </>
        );
    }
}
