import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation, withRouter, Switch } from 'react-router-dom';
import PrivateRoute from './privateroute';
import { Provider } from 'react-redux';
import store from '../store/store';
import FolderUpload from '../containers/FileUpload';
import Login from '../containers/Login';
import Home from '../containers/Home';
import Dashboard from '../containers/Dashboard'
import FilesList from '../containers/FilesList';
import CaseList from '../containers/CaseList';
import CaseDetails from '../containers/CaseDetails'
import Calculator from '../containers/PlanningReviewTool';
import ReviewCases from '../containers/ReviewCase';
import Settings from '../components/Settings/Settings'
import RarViewOfSurgeon from '../containers/Dashboard/RarViewOfSurgeon';
import Notifications from '../containers/Notifications/index';
import ReviewCaseDetails from '../containers/ReviewCaseDetails';
import CustomerList from '../containers/CustomerList';
import CustomerDetails from '../containers/CustomerDetails';
import SurgeonList from '../containers/SurgeonList';
import SurgeonDetails from '../containers/SurgeonDetails';
import UserListContainer from '../containers/UserList';
import Logs from '../components/Logs/Logs';
import Help from '../components/Help/Help';
import DeviceListContainer from '../containers/DeviceList'
import ResetTempPasswordContainer from '../containers/ResetTempPassword';
import ErrorComponent from '../components/Error/Error';
import RARLookupContainer from '../containers/RARLookup/RARLookup';
import SaleRepTableContainer from '../containers/ReviewPlanList/SalesRepTableContainer';
import CustomerContainer from '../containers/UpdateCustomerSupport/CustomerContainer';


/**
* @description function to move the scroll bar to top after each transition
* @param {*}
* @memberof routes
*/
function ResetWindowScroll(props) {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return props.children
}
const ScrollToTop = withRouter(ResetWindowScroll)


const routes = (
	<Provider store={store}>
		<Router>
			<ScrollToTop>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/login" component={Login} />
					<PrivateRoute authentication={true} exact path="/home" component={Home} />
					<PrivateRoute authentication={true} exact path="/dashboard" component={Dashboard} />
					<PrivateRoute authentication={true} exact path="/upload" component={FolderUpload} />
					<PrivateRoute authentication={true} exact path="/fileslist" component={FilesList} />
					<PrivateRoute authentication={true} exact path="/caselist" component={CaseList} />
					<PrivateRoute authentication={true} exact path="/casedetails/:id" component={CaseDetails} />
					<PrivateRoute authentication={true} exact path="/caseplanningtool" component={Calculator} />
					<PrivateRoute authentication={true} exact path="/reviewcases" component={ReviewCases} />
					<PrivateRoute authentication={true} exact path="/reviewcasedetails/:id" component={ReviewCaseDetails} />
					<PrivateRoute authentication={true} exact path="/settings" component={Settings} />
					<PrivateRoute authentication={true} exact path="/surgeon" component={RarViewOfSurgeon} />
					<PrivateRoute authentication={true} exact path="/notifications" component={Notifications} />
					<PrivateRoute authentication={true} exact path="/customerlist" component={CustomerList} />
					<PrivateRoute authentication={true} exact path="/surgeonlist" component={SurgeonList} />
					<PrivateRoute authentication={true} exact path="/customer/:id" component={CustomerDetails} />
					<PrivateRoute authentication={true} exact path="/surgeondetails/:id" component={SurgeonDetails} />
					<PrivateRoute authentication={true} exact path="/users" component={UserListContainer} />
					<PrivateRoute authentication={true} exact path="/logs" component={Logs} />
					<PrivateRoute authentication={true} exact path="/help" component={Help} />
					<PrivateRoute authentication={true} exact path="/deviceList" component={DeviceListContainer} />
					<PrivateRoute authentication={true} exact path="/resetpassword" component={ResetTempPasswordContainer} />
					<PrivateRoute authentication={true} exact path="/lookup" component={RARLookupContainer} />
					<PrivateRoute authentication={true} exact path="/preOpPlans" component={CustomerContainer}/>
					<PrivateRoute authentication={true} exact path="/planlist" component={SaleRepTableContainer}/>
					<Route path="*" component={ErrorComponent} />
				</Switch>
			</ScrollToTop>
		</Router>
	</Provider>
);
export default routes;