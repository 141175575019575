import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceList from '../../components/DeviceList/DeviceList';

class DeviceListContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fromDashboard: (props.location && props.location.state && props.location.state.fromDashboard === true),
			refreshDeviceList: '',
			loading: false,
			searchString: '',
			searchSubmit: '',
		}
	}

	/** 
	 * 	@description Function to handle search submit
	 *  @param searchString The value in the input field
	 *  @memberof DeviceListContainer
	 */
	handleSearchSubmit = (searchString) => {
		this.setState({ searchString: searchString, searchSubmit: true, refreshDeviceList: '' });
	}

	/** 
	 *  @description Function to handle search reset
	 *  @memberof DeviceListContainer
	 */
	handleReset = () => {
		this.setState({ searchString: '', searchSubmit: '', refreshDeviceList: '' });
	}

	/** 
	 *  @description Function to handle device added event
	 *  @memberof DeviceListContainer
	 */
	deviceAdded = () => {
		this.setState({ refreshDeviceList: true });
	}

	render() {
		return (
			<DeviceList
				refreshDeviceList={this.state.refreshDeviceList}
				loading={this.state.loading}
				searchString={this.state.searchString}
				searchSubmit={this.state.searchSubmit}
				handleSearchSubmit={this.handleSearchSubmit}
				handleReset={this.handleReset}
				deviceAdded={this.deviceAdded}
				fromDashboard={this.state.fromDashboard}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(DeviceListContainer);