import React, { Component } from 'react'
import { getImgSrc } from '../../../helpers/GlobalFunctions'
import { wholeNumberRoundOff } from '../../../helpers/GlobalFunctions';

export default class PlacementSummaryShared extends Component {
	render() {
		const { data } = this.props;
		return (
			<div className="row">
				<div className="col-sm-12">
					<div className="d-flex align-items-center justify-content-between" data-testid="placement-summary-shared">
						<div className="d-flex flex-column align-items-center justify-content-center hip-dashboard-tilt-img hip-dashboard-tilt-img-left">
							<img className="hip-posterior-img" src={getImgSrc('HIP_PosteriorPelvicTilt.png')} alt="HIP_PosteriorPelvicTilt" />
							<div className="d-flex flex-column align-items-between justify-content-center">
								<p className="fs-9-rem fw-500 m-0">Anatomical Reference</p>
								<div className="d-flex align-items-center justify-content-between">
									<div className="d-flex flex-column align-items-center justify-content-center">
										<div className="fs-8-rem">Inclination</div>
										<div className="hip-tilt-value">{data && !isNaN(parseFloat(data.inclinationApp)) ? `${wholeNumberRoundOff(data.inclinationApp)}` : 'N/A'}</div>
									</div>
									<div className="d-flex flex-column align-items-center justify-content-center">
										<div className="fs-8-rem">Anteversion</div>
										<div className="hip-tilt-value">{data && !isNaN(parseFloat(data.anteversionApp)) ? `${wholeNumberRoundOff(data.anteversionApp)}` : 'N/A'}</div>
									</div>
								</div>
							</div>
						</div>



						<div className="d-flex flex-column align-items-center justify-content-center hip-dashboard-tilt-img hip-dashboard-tilt-img-right">
							<img className="hip-anterior-img" src={getImgSrc('HIP_AnteriorPelvicTilt.png')} alt="HIP_AnteriorPelvicTilt" />
							<div className="d-flex flex-column align-items-between justify-content-center">
								<p className="fs-9-rem fw-500 m-0">Functional Reference</p>
								<div className="d-flex align-items-center justify-content-between">
									<div className="d-flex flex-column align-items-center justify-content-center">
										<div className="fs-8-rem">Inclination</div>
										<div className="hip-tilt-value">{data && !isNaN(parseFloat(data.inclinationXRay)) ? `${wholeNumberRoundOff(data.inclinationXRay)}` : 'N/A'}</div>
									</div>
									<div className="d-flex flex-column align-items-center justify-content-center">
										<div className="fs-8-rem">Anteversion</div>
										<div className="hip-tilt-value">{data && !isNaN(parseFloat(data.anteversionXRay)) ? `${wholeNumberRoundOff(data.anteversionXRay)}` : 'N/A'}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
