import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ErrorComponent from '../components/Error/Error';
import { definedRoutesForRoles } from '../constants/definedRoutes/definedRoutes';
import { isAuthenticated, isFirstLogin } from '../helpers/GlobalFunctions';
import { errorMessages } from '../helpers/messages';
import { getUserRoleType } from '../services/aws/aws-services';
import { allowedResponsive } from '../constants/allowedResponsive/allowedResponsive';

function isRouteAllowed(routeProps) {
	const userRole = getUserRoleType();
	if (!userRole) {
		return false;
	}

	const isCommonPath = definedRoutesForRoles.common.indexOf(routeProps.path) > -1;
	var rolePaths = userRole.map(role => {
		return role && definedRoutesForRoles[role] && definedRoutesForRoles[role].indexOf(routeProps.path) > -1;
	});
	
	return isCommonPath || rolePaths.includes(true);
}



function PrivateRoute({ component: Component, authentication, ...rest }) {

	function isResponsive(){
		const userRole = getUserRoleType();
		return  allowedResponsive.some(x=>userRole.includes(x))
	}
	
	// Check if device is IOS
	function iOS() {
		return /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
	  }

	const resClassName = isResponsive() ? 'responsive-ios' : 'main';
	const isIosClass = iOS() ? 'ios-device' : 'non-ios-device';
	
	return (
		<Route
			{...rest}
			render={(props) => authentication === isAuthenticated()
				? isFirstLogin() && rest.path !== '/resetpassword'
					? <Redirect to={{ pathname: '/resetpassword', state: { from: props.location } }} /> 
					: isRouteAllowed(rest) 
						? <div className={resClassName +' '+isIosClass}><Component {...props} /></div>
						: <ErrorComponent errorCode="403" message={errorMessages.noPermission} />
				: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
		/>
	)
}

export default PrivateRoute;