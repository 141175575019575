import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { convertUTCToLocalDateTime, getLicenseTypeName, getLoadingTemplate, isValidDate } from '../../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';
import { DataTable } from '../../DataTable/DataTable';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

class AdminComponent extends Component {

	/**
	* @description Formats the given date into a human-readable local date-time format if the date is valid.
	* If the date is not provided or invalid, appropriate placeholders are returned.
	* @param {string|Date} date - The date to be formatted.
	* @returns {string} The formatted local date-time, 'N/A' if the date is not provided, or 'Invalid Date' if the date is invalid.
	*/
	/* istanbul ignore next  */
	formatUpdateDate(date) {
		if (!date) {
			return 'N/A';
		} else if (isValidDate(date)) {
			return convertUTCToLocalDateTime(date);
		} else {
			return 'Invalid Date';
		}
	}
	

	render() {
		const { loggedInUserType, deviceLoading, planLoading, deviceCountErr, totalDeviceCount, totalActivePlansCount, activePlansErr } = this.props;
		const rowClass = `row top-row ${loggedInUserType.includes('customersupport') ? 'cs-role' : ''}`
		/* istanbul ignore next  */
		const columns = [
			{
				Header: 'Customer Name',
				accessor: 'customername',
				Filter: '',
				Cell: ({ row }) => {
					/* istanbul ignore next  */
					return <div className='text-capitalize'>{row.original.customername || 'N/A'}</div>;
				},
			},
			{
				Header: 'Type',
				accessor: 'licensetype',
				Cell: ({ row }) => {
					/* istanbul ignore next  */
					return row.original.licenseType ? getLicenseTypeName(row.original.licenseType) : '';
				},
				Filter: ''
			},
			{
				Header: 'Date of Expiry',
				accessor: 'enddate',
				Filter: '',
				Cell: ({ row }) => {
					/* istanbul ignore next  */
					return this.formatUpdateDate(row.original.enddate);
				},
			},
			{
				Header: 'Status',
				accessor: 'status',
				Filter: '',
				Cell: ({ row }) => {
					/* istanbul ignore next  */
					return row.original.status || 'N/A';
				},
			},
			{
				Header: ' ',
				ExpirationAction: true
			}
		]
		return (
			<div className="data-list-tabs admin-dashboard-wrap">
				<div className={rowClass}>
					<div className="col user-box">
						<div className="dashboard-blocks">
							<div className="dashboard-blocks-body">
								<div className="d-flex flex-column align-items-between justify-content-between h-100">
									<div className="dashboard-block-title">Users</div>
									<div className="text-center">
										<svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
											<path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
										</svg>
									</div>
									<Link className="btn btn-primary rar-btn-color" id="add-user" to={{ pathname: "/users", state: { fromDashboard: true } }}>Add New</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col customer-box">
						<div className="dashboard-blocks">
							<div className="dashboard-blocks-body">
								<div className="d-flex flex-column align-items-between justify-content-between h-100">
									<div className="dashboard-block-title">Customers</div>
									<div className="text-center">
										<svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
											<path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
											<path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
										</svg>
									</div>
									<Link className="btn btn-primary rar-btn-color" id="add-customer" to={{ pathname: "/customerlist", state: { fromDashboard: true } }}>Add New</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col log-box">
						<div className="dashboard-blocks">
							<div className="dashboard-blocks-body">
								<div className="d-flex flex-column align-items-between justify-content-between h-100">
									<div className="dashboard-block-title">Audit Logs</div>
									<div className="text-center">
										<svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" fill="currentColor" className="bi bi-list-columns" viewBox="0 0 16 16">
											<path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 0 .5Zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-13 2A.5.5 0 0 1 .5 2h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5Zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-13 2A.5.5 0 0 1 .5 4h10a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5Zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-13 2A.5.5 0 0 1 .5 6h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-13 2A.5.5 0 0 1 .5 8h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-13 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5Zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-13 2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-13 2a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5Zm13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z" />
										</svg>
									</div>
									<Link className="btn btn-primary rar-btn-color" id="vieww-logs" to={"/logs"}>View All</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col device-box">
						<div className="dashboard-blocks">
							<div className="dashboard-blocks-body">
								<div className="d-flex flex-column align-items-between justify-content-between h-100">
									<div className="dashboard-block-title">Active Devices</div>
									<div className="text-center">
										{deviceLoading ? getLoadingTemplate()
											: totalDeviceCount ?
												<h2 className='device-count'>{totalDeviceCount}</h2>
												: deviceCountErr ?
													<ErrorMsgBlockComponent errorObject={deviceCountErr} />
													: <ErrorMsgBlockComponent noData={true} />}
									</div>
									<Link className="btn btn-primary rar-btn-color" id="view-devices" to={"/deviceList"}>View All</Link>
								</div>
							</div>
						</div>
					</div>
					{loggedInUserType.includes('customersupport') ?
						<div className="col plan-box">
							<div className="dashboard-blocks">
								<div className="dashboard-blocks-body">
									<div className="d-flex flex-column align-items-between justify-content-between h-100">
										<div className="dashboard-block-title">Active Plans</div>
										<div className="text-center">
											{planLoading ? getLoadingTemplate()
												: totalActivePlansCount ?
													<h2 className='device-count'>{totalActivePlansCount}</h2>
													: activePlansErr ?
														<ErrorMsgBlockComponent errorObject={activePlansErr} />
														: <ErrorMsgBlockComponent noData={true} />}
										</div>
										<Link className="btn btn-primary rar-btn-color" id="view-devices" to={"/preOpPlans?activeAll=true"}>View All</Link>
									</div>
								</div>
							</div>
						</div>
						: ''}
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="dashboard-blocks">
							<div className="rar-dashboard-blocks-body rar-dashboard-surgeon-list-body license-body">
								<div className="dashboard-block-title mb-4">Expiration List</div>
								{deviceLoading || planLoading ? ''
									: <ErrorBoundary>
										<DataTable
										listType="license-expiry-list"
										columns={columns}
										showPagination={false}
										disableColmSort={false}/>
									</ErrorBoundary>
								}
							</div>

						</div>
					</div>
				</div>

			</div>
		);
	}
}
const mapStateToProps = (state) => ({ state })
const mapDispatchToProps = (dispatch) => ({ })
export default connect(mapStateToProps, mapDispatchToProps)(AdminComponent);