import React, { Component } from 'react';
import PreOpFilterComponent from '../../components/PrePlanFilter/PreOpFilterComponent';
import { getAllPreOpCustomersService, getAllSalesRepListService, getCustomerFilterService } from '../../services/java/java-services';
import { getUserName } from '../../services/aws/aws-services';
import { salesRepMessages } from '../../helpers/messages';
import { setSessionStorage } from '../../services/storage/storage-service';

class PreOpFilterContainer extends Component {
    constructor() {
        super();
        this.state = {
            status: 1,
            customer: 'all',
            procedureType: 'all',
            salesRep: 'all',
            searchString: '',
            errorMessage: '',
            customerList: [],
            salesRepList: [],
            disableFilter: false
        };
    }

    /**
     * @description function to set input filter values
     * @param {*}
     * @memberof PreOpFilterContainer
     */
    /* istanbul ignore next  */
    onInputChange = (e, field) => {
        // we have to trim the input only if there is nothing but if it has some value we need to keep space
        const notEmpty = e.target.value.length === 0 ? e.target.value.trim() : e.target.value;
        this.setState(prevState => ({
            ...prevState,
            [field]: notEmpty
        }));
        if (field === 'searchString' && e.target.value.length > 3) {
            this.setState(prevState => ({
                ...prevState,
                searchErrorMessage: ''
            }));

        }
        // unselect row when start filter
        setSessionStorage('selectedRow', null);
    };



    /**
     * @description function to validate filter form and submit
     * @param {*}
     * @memberof PreOpFilterContainer
     */
    /* istanbul ignore next  */
    filterLogs = (e) => {
        const { status, customer, procedureType, salesRep, searchString } = this.state;
        const { filterObj, setPreFilterObj } = this.props;

        let filterObjToUpdate = {};
        let errorMessage, searchErrorMessage = '';

        if (searchString && searchString.length < 3) {
            searchErrorMessage = salesRepMessages.searchError;
        } else if (!status && !customer && !procedureType) {
            setPreFilterObj(filterObj);
        } else {
            filterObjToUpdate = {
                status,
                customer,
                procedureType,
                salesRep,
                searchString: searchString.replace(',', ' ').replace(/  +/g, ' ')
            };
            setPreFilterObj(filterObjToUpdate);
            this.setState({
                disableFilter: true
            });

            setTimeout(() => {
                this.setState({
                    disableFilter: false
                });
            }, 1000);
        }

        this.setState({
            errorMessage,
            searchErrorMessage
        });
        e.preventDefault();


    };




    /**
     * @description function to reset filter form
     * @memberof PreOpFilterContainer
     */
    /* istanbul ignore next  */
    resetLogsFilter = () => {
        const initialState = {
            status: 1,
            customer: 'all',
            procedureType: 'all',
            salesRep: 'all',
            errorMessage: '',
            searchErrorMessage: '',
            searchString: ''
        };
        const { status, customer, procedureType, salesRep } = initialState;
        this.setState(initialState);
        this.props.setPreFilterObj({ status, customer, procedureType, salesRep });
    };


    /**
     * @description get the list of the customers
     * @memberof PreOpFilterContainer
     */
    /* istanbul ignore next  */

    getCustomerList = () => {
        let userName = getUserName();
        /* istanbul ignore next  */
        const handleServiceResponse = (err, result) => {
            if (err) {
                this.setState({
                    errorMessage: typeof err === 'string' ? err : JSON.stringify(err),
                });
            } else {
                this.setState({
                    customerList: this.props.enableSalesRep ? result.data : result.data.customerList,
                });
            }
        };
        // if it is enabled then we need to call all customers API
        if (this.props.enableSalesRep) {
            getAllPreOpCustomersService({ userName }, handleServiceResponse);
        } else {
            getCustomerFilterService({userName}, handleServiceResponse);
        }
    };


    /**
     * @description get the list of the sales reps users for admin
     * @memberof PreOpFilterContainer
     */
    /* istanbul ignore next  */
    getSalesRepList = () => {
        getAllSalesRepListService(getUserName(), (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({
                    errorMessage: typeof err === 'string' ? err : JSON.stringify(err),
                });
            } else {
                
                this.setState({
                    salesRepList: result.data.SalesRepList,
                });
            }
        });
    };

    componentDidMount() {
        this.getCustomerList();
        // call this API only for admin 
        if (this.props.enableSalesRep) {
            this.getSalesRepList()
        }
    }

    render() {
        const { customer, procedureType, errorMessage, customerList, salesRep, salesRepList, searchString, searchErrorMessage, disableFilter } = this.state;
        const { showFilter, showFilterBlock, status, filterObj, enableSalesRep } = this.props;
        return (
            <PreOpFilterComponent
                key={JSON.stringify(filterObj)}
                showFilter={showFilter}
                showFilterBlock={showFilterBlock}
                onInputChange={this.onInputChange}
                filterLogs={this.filterLogs}
                resetLogsFilter={this.resetLogsFilter}
                status={status}
                customer={customer}
                procedureType={procedureType}
                errorMessage={errorMessage}
                customerList={customerList}
                filterObj={filterObj}
                salesRep={salesRep}
                salesRepList={salesRepList}
                showSalesRep={enableSalesRep ? true : false}
                searchString={searchString}
                searchErrorMessage={searchErrorMessage}
                disableFilter={disableFilter}
            />
        );
    }
}

export default PreOpFilterContainer;
