import React, { Component } from 'react'
import CSVCustomer from '../../components/CustomerList/CSVCustomer'
import { exportCustomerListService } from '../../services/java/java-services';

export default class CSVContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddCustomerModal: props.fromDashboard,
            exportCustomerData: '',
            isLoading: false,
            isExport: false,
            errorMessage: '',
            getCsvFileName: '',
            csvError: null,
            exportCsvHeader: [
                { label: "Customer Name", key: "customername" },
                { label: "Address Line 1", key: "addressline1" },
                { label: "Address Line 2", key: "addressline2" },
                { label: "State", key: "state" },
                { label: "Country", key: "country" },
                { label: "Country Dial Code", key: "countryDialCode" },
                { label: "Postal Code", key: "postalcode" },
                { label: "Phone number", key: "phonenumber" },
                { label: "Customer ID", key: "customerid" },
                { label: "CORIOGRAPH License", key: "preopLicense" },
                { label: "CORIOGRAPH License Expiry", key: "preopLicenseExpiry" },
                { label: "Cumulus License", key: "cumulusLicense" },
                { label: "Cumulus License Expiry", key: "cumulusLicenseExpiry" },
                { label: "Deployment Region", key: "deploymentRegion" }
            ]
        }
    }

    /**
     * @description function to open and close modal popup 
     * @param {*}
     * @memberof CSVContainer
     */
    enableAddCustomerModal = (isShow) => {
        this.setState({ refreshCustomerList: false, exportCustomerData: '', isExport: false });
        this.state.showAddCustomerModal ? this.setState({ showAddCustomerModal: false })
            : this.setState({ showAddCustomerModal: true });
    }

    /**
     * @description function to export data to csv file
     * @param {*}
     * @memberof CSVContainer
     */
    exportToCsv = () => {
        this.setState({ isLoading: true, exportCustomerData: '', isExport: false, errorMessage: '', refreshCustomerList: false });
        this.generateFileName();
        exportCustomerListService((err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ isLoading: false, csvError: err });
            } else {
                this.setState({ exportCustomerData: result.data && result.data.user ? result.data.user : '', isLoading: false, isExport: true, csvError: null });
            }
        })
    }

    /**
     * @description function to generate file name
     * @param {*}
     * @memberof CSVContainer
     */
    generateFileName() {
        let d = new Date();
        let dformat = `${d.getDate()}${d.getMonth() + 1}${d.getFullYear()} ${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
        this.setState({ getCsvFileName: "CustomerList_" + dformat + ".csv" });
    }
    
    /**
    * @description Function to end export
    * @memberof CSVSurgeonContainer
    */
     exportComplete = () => {
        this.setState({ isExport: false });
    }

    render() {
        const{handleSearchSubmit,handleReset}=this.props
        return (
            <CSVCustomer
                showAddCustomerModal={this.state.showAddCustomerModal}
                enableAddCustomerModal={this.enableAddCustomerModal}
                exportCsvHeader={this.state.exportCsvHeader}
                exportCustomerData={this.state.exportCustomerData}
                exportToCsv={this.exportToCsv}
                getCsvFileName={this.state.getCsvFileName}
                csvError={this.state.csvError}
                isLoading={this.state.isLoading}
                isExport={this.state.isExport}
                exportComplete={this.exportComplete}
                handleReset={handleReset} 
                handleSearchSubmit={handleSearchSubmit}
            />
        )
    }
}
