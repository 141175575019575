import React, { Component } from 'react';
import { connect } from 'react-redux';
import AlertMessage from '../AlertMessage/AlertMessage';
import RangeInput from '../../shared/RangeInput';
import MultiSelectComponent from '../../shared/MultiSelectComponent';
import { operativeProcedureTypes } from '../../helpers/constants';
class CaseFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.filterRef = React.createRef();
	}

	/**
	 * @description Function to reset filter form
	 * @memberof CaseFilterComponent
	 */
	resetForm = () => {
		this.filterRef.reset();
		this.props.resetCaseFilter();
	}

	/**
	 * @description Function to get conditional anatomical options based on procedure type
	 * @memberof CaseFilterComponent
	 */
	switchAnatomicalSliders = () => {
		const { operativeProcedure, defaults, handleRangeChange, preopDeformity, pelvicTilt } = this.props;
		switch (operativeProcedure) {
			case operativeProcedureTypes.TKA.raw:
				return <li>
					Deformity
					<RangeInput
						id="preop-deformity"
						step="1"
						min={defaults.preopDeformity.min}
						max={defaults.preopDeformity.max}
						openRange={true}
						showExtremeLabels={true}
						minExtremeLabel="Valgus"
						maxExtremeLabel="Varus"
						openMinLabel="< -7"
						openMaxLabel="> 7"
						minVal={preopDeformity.min}
						maxVal={preopDeformity.max}
						handleChange={(values) => handleRangeChange(values, 'preopDeformity')}
					/>
				</li>
			case operativeProcedureTypes.HIP.raw:
				return <li>
					Pelvic Tilt
					<RangeInput
						id="pelvic-tilt"
						step="1"
						min={defaults.pelvicTilt.min}
						max={defaults.pelvicTilt.max}
						openRange={true}
						showExtremeLabels={true}
						minExtremeLabel="< -15"
						maxExtremeLabel="> 15"
						openMinLabel="< -15"
						openMaxLabel="> 15"
						minVal={pelvicTilt.min}
						maxVal={pelvicTilt.max}
						handleChange={(values) => handleRangeChange(values, 'pelvicTilt')}
					/>
				</li>
			default:
				return null;
		}
	}

	render() {
		const { showFilter, onInputChange, showFilterBlock, errorMessage, todayDate, surgeryFromDate, surgeryToDate, operativeProcedure, 
			disableFilter, disableReset, surgeons, patientSex, patinetSexList, handlePatientSexChange, defaults, initialState, handleRangeChange, implants, 
			side, implantValue, patientAge, selectedSurgeon, handleSurgeonChange, userRoles,gapsCollectedData } = this.props;
		return (
			<div>
				<div className="case-list-top-btn">
					<button className="btn btn-primary" onClick={showFilter}>{showFilterBlock ? 'Close' : 'Show'} Filter</button>
				</div>
				{showFilterBlock ? <div className="case-list-filter">
					{errorMessage ? <AlertMessage errorMessage={errorMessage} /> : null}
					<form id="casefilter" ref={(el) => this.filterRef = el}>
						<ul className="filter-options">
							<li>
								Procedure Type
								<select id="operativeProcedure" className="form-select" value={operativeProcedure} onChange={(e) => onInputChange(e, 'operativeProcedure')}>
									<option value="" >All Procedures</option>
									<option value={operativeProcedureTypes.TKA.raw}>{operativeProcedureTypes.TKA.longText}</option>
									<option value={operativeProcedureTypes.HIP.raw}>{operativeProcedureTypes.HIP.longText}</option>
								</select>
							</li>
							<li>
								Surgery From Date
								<input className="form-control" type="date" id="surgeryFromDate" value={surgeryFromDate || ''} max={todayDate} onChange={(e) => onInputChange(e, 'surgeryFromDate')} />
							</li>
							<li>
								Surgery To Date
								<input className="form-control" type="date" id="surgeryToDate" max={todayDate} value={surgeryToDate || ''} onChange={(e) => onInputChange(e, 'surgeryToDate')} />
							</li>
						</ul>
						<ul className="filter-options">
							<li>
								Patient Age
								<RangeInput 
									id="patient-age"
									step="1"
									showExtremeLabels={true}
									openRange={true}
									minExtremeLabel={defaults.patientAge.min}
									maxExtremeLabel={defaults.patientAge.maxLabel}
									min={defaults.patientAge.min}
									max={defaults.patientAge.max}
									minVal={patientAge.min}
									maxVal={patientAge.max}
									openMinLabel="0"
									openMaxLabel="> 100"
									reset={initialState}
									handleChange={(values) => handleRangeChange(values, 'patientAge')} 
								/>
							</li>
							<li>
								Patient Sex
								<MultiSelectComponent
									id="patient-sex"
									options={patinetSexList}
									displayValue="label"
									selectedValues={patientSex}
									handleSelectChange={handlePatientSexChange}
									placeholder={patientSex ? "Select" : "All"}
								/>
							</li>
							<li>
								Operative Side
								<select id="side" className="form-select" value={side} onChange={(e) => onInputChange(e, 'side')}>
									<option value="" >All Sides</option>
									<option value="left">Left</option>
									<option value="right">Right</option>
								</select>
							</li>
						</ul>
						<ul className="filter-options">
							{userRoles ? <li>
								Surgeon
								<MultiSelectComponent
									id="surgeon-select"
									options={surgeons && surgeons.length ? surgeons : []}
									displayValue="name"
									selectedValues={selectedSurgeon}
									handleSelectChange={handleSurgeonChange}
									placeholder={selectedSurgeon ? "Select" : "All Surgeons"}
								/>
							</li> : ''}
							{this.switchAnatomicalSliders()}
							{operativeProcedure ===  operativeProcedureTypes.TKA.raw ? <li>
								Implant Type
								<select id="implant-select" className="form-select" name="implant" value={implantValue} onChange={(e) => onInputChange(e, 'implant')}>
									<option value="">All Implants</option>
									{implants && implants.length ? implants.map((implant, index) => {
										return <option key={index} value={implant.name}>{implant.name}</option>;
									}) : ''}
								</select>
							</li> : ''}
							{operativeProcedure ===  operativeProcedureTypes.TKA.raw ? <li>
								Gaps Collected
								<select id="gaps-select" className="form-select" value={gapsCollectedData} onChange={(e) => onInputChange(e, 'gapsCollectedData')}>
								<option value="">All</option>
									<option value="Manual">Manual</option>
									<option value="Tensioner">Tensioner</option>
								</select>
							</li> : ''}


						</ul>
						<ul className="filter-options">
							<li className="filter-btns text-end">
								<button className="btn btn-primary filter-btn-width" type="button" disabled={disableFilter} onClick={e => { e.preventDefault(); this.props.filterCaseList();}} id="caseFilter">Filter</button>
								<button className="btn btn-primary filter-btn-width" type="button" disabled={disableReset} onClick={this.resetForm} id="caseReset">Reset</button>
							</li>
						</ul>
					</form>
				</div> : null}
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(CaseFilterComponent);
