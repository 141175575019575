import * as types from '../constants/actionTypes/upload';
import { updateMetadata } from '../services/java/java-services';
export function updateMetadataSuccess(res) {
	return { type: types.UPDATE_METADATA_SUCCESS, res };
}

export function updateMetadataError(err) {
	return { type: types.UPDATE_METADATA_ERROR, err };
}

/* istanbul ignore next  */
export function updateFileMetadata(fileDetails) {
	return (dispatch) => {
		return updateMetadata(fileDetails, (err, result) => {
			if (err) {
				dispatch(updateMetadataError(err));
			} else {
				dispatch(updateMetadataSuccess(result));
			}
		})
	}
}