import React, { Component } from 'react';

export default class ReviewDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
        };
    }

    /**
     * @description Function to disable selected option also trigger update API
     * @param  event Event: to get the value of option changed
     * @param  id Number:	VisionaireID for which we want to update data
     * @param  key String: key for updating the data by update API identify it is for the status / Hospital Association
     * @memberof ReviewDropdown
     **/
    handleSelectChange = (event, row, type) => {
        const { value, selectedIndex, options: selectedOption } = event.target;
        const { VisionaierId, salesrepName } = row;
        this.setState({ selected: true });
        const selectedOptionText = selectedOption[selectedIndex].text;

        let customerId; // if hospital get id from the array
    
        if (type === 'hospitalId') {
            customerId = row.hospitalAssociation.find((x) => x.hospitalName === value)?.hospitalId;
        }
        if (type === 'currentStatus') {
            customerId = value;
        }
        if(value === 'unselect'){
            customerId = value;
        }
        

        // disable the selected option to prevent deadlock
        const options = event.target.options;
        for (let option of options) {
            if (option.value === value) {
                option.disabled = true;
            } else {
                option.disabled = false;
            }
        }
        // hits API for update data
        this.props.updateReviewDataInDB({ value: customerId, VisionaierId, type, selectedOptionText, salesrepName });
    };

    render() {
        const { row, type } = this.props;
        const defaultHospital = row?.hospitalName;

        // Check if the default hospital exists in the list
        const exists = row?.hospitalAssociation.some((item) => item.hospitalName === defaultHospital);

        // If it doesn't exist, add it to the list and mark it as deleted
        if (!exists && defaultHospital) {
            row.hospitalAssociation.push({ hospitalName: defaultHospital, hospitalId: '', deleted: true });
        }

        return (
            <div>
                {type === 'hospitalAssociation' &&  row?.currentStatus !=='9' && (
                    <select
                        data-testid='select-hospital'
                        className='form-select form-select-sm'
                        defaultValue={row?.hospitalName?.toString() ? row?.hospitalName?.toString() : 'unselect'}
                        onChange={(e) => this.handleSelectChange(e, row, 'hospitalId')}
                    >
                        {!this.state?.selected && <option value='unselect' >Select Any Customer</option>}
                        {row?.hospitalAssociation?.map((item) => {
                            return (
                                <option key={item?.hospitalId} value={item?.hospitalName} disabled={item?.deleted}>
                                    {item?.hospitalName}
                                </option>
                            );
                        })}
                    </select>
                )}
                {type === 'procedureStatus' &&  row?.currentStatus !=='9' && (
                    <select data-testid='select-status' className='form-select form-select-sm' defaultValue={row?.currentStatus} onChange={(e) => this.handleSelectChange(e, row, 'currentStatus')}>
                        {row?.procedureStatus?.map((item) => (
                            <option key={item?.planStatus} value={item?.planStatus}>
                                {item?.status}
                            </option>
                        ))}
                    </select>
                )}
                {type === 'procedureStatus' &&  row?.currentStatus ==='9' && (
                    <p>Operation Complete</p>
                )}
                {type === 'hospitalAssociation' &&  row?.currentStatus ==='9' && (
                    <p className='read-only'>{row.hospitalName}</p>
                )}
            </div>
        );
    }
}
