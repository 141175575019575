import React, { Component } from 'react';
import { DataTable } from '../DataTable/DataTable';
import CSVContainer from '../../containers/CustomerList/CSVContainer';
import { getUserRoleType } from '../../services/aws/aws-services';

export default class CustomerListTable extends Component {
    constructor() {
        super()
        this.state = {
            activeUserRole: getUserRoleType()
        }
    }
    render() {
        const isAdmin = this.state.activeUserRole.includes('admin');
        const { fromDashboard, handleSearchSubmit, handleReset, refreshCustomerListTable, searchString, searchSubmit } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Name',
                accessor: 'customername',
                Cell: ({ row }) => (<span>{row.original.customername ? row.original.customername : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Address',
                accessor: 'combineAddressLines',
                Cell: ({ row }) => (<span>{row.original.combineAddressLines ? row.original.combineAddressLines : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Phone Number',
                accessor: 'phonenumber',
                Cell: ({ row }) => (<span>{row.original.phonenumber ? row.original.phonenumber : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Customer ID',
                accessor: 'customerid',
                Cell: ({ row }) => (<span>{row.original.customerid ? row.original.customerid : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: ' ',
                CustomerAction: isAdmin
            }

        ];
        return (
            <>
                <CSVContainer
                    fromDashboard={fromDashboard}
                    handleReset={handleReset}
                    handleSearchSubmit={handleSearchSubmit}
                />
                <DataTable listType="customer-list"
                    showPagination={true}
                    columns={columns}
                    searchString={searchString && searchSubmit ? searchString : ''}
                    refreshDataList={searchSubmit || refreshCustomerListTable}
                />
            </>
        );
    }
}