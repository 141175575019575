import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TKADashboardContainer from '../../../containers/Dashboard/TKADashboard';
import THADashboardContainer from '../../../containers/Dashboard/THADashboard';
import { operativeProcedureTypes } from '../../../helpers/constants';
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';

class SurgeonComponent extends Component {
    render() {
        const { selectedTab, caseTypeFetchError, timeFrameChange, timeFrameValue, isRarView, surgeons, surgeonId, surgeonSelectChange, surgeonDetails, handleTabChange } = this.props;

        return (
            <div className="data-list-tabs dashboard-container position-relative surgeon-dashboard" data-testid="surgeon-dashboard">
                <div className="dashboard-select">
                    {isRarView && <div className="d-flex align-items-center">
                        <div className="dashboard-select-surgeon-lable text-end">Surgeon</div>
                        <select id="surgeon-select" data-testid="surgeon-select" className="form-select" defaultValue={surgeonId} onChange={(e) => surgeonSelectChange(e)}>
                            {surgeons && surgeons.length && surgeons.map((surgeon) => (
                                <option key={surgeon.id} value={surgeon.id}>{surgeon.name}</option>
                            ))}
                        </select>
                    </div>}
                    {selectedTab ?
                        <div className="d-flex align-items-center ms-2">
                            <div className="dashboard-select-lable">Time Frame</div>
                            <select id="timeFrameRange" data-testid="timeFrameRange" className="form-select" onChange={(e) => timeFrameChange(e)}>
                                <option value="30D" >Last 30 Days</option>
                                <option value="12M">Last 12 Months</option>
                                <option value="ALL">All Time</option>
                            </select>
                        </div>
                        : ''}
                </div>
                <Tabs activeKey={selectedTab} onSelect={handleTabChange} unmountOnExit={true}>
                    <Tab eventKey={operativeProcedureTypes.TKA.text} title={operativeProcedureTypes.TKA.text}>
                        {caseTypeFetchError ? <ErrorMsgBlockComponent className="py-2" errorObject={caseTypeFetchError} /> : ''}
                        <TKADashboardContainer isRarView={isRarView} surgeonDetails={surgeonDetails} timeFrameValue={timeFrameValue} />
                    </Tab>
                    <Tab eventKey={operativeProcedureTypes.HIP.text} title={operativeProcedureTypes.HIP.text}>
                        <THADashboardContainer isRarView={isRarView} surgeonDetails={surgeonDetails} timeFrameValue={timeFrameValue} />
                    </Tab>
                </Tabs>
            </div>);
    }
}
const mapStateToProps = (state) => (
    {
        state
    }
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(SurgeonComponent);
