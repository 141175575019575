import React, { Component } from 'react'
import CommentsHistoryComponent from '../../components/CaseDetails/CommentsHistory'
import {getCommentsHistoryDetails} from '../../services/java/java-services';
export default class CommentsHistoryContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
			caseId:props.caseId,
            viewDetails: false,
            historyData: null,
            selectedComment: null,
            commentHistoryLoading:false,
            commentsHistoryError:null
        }
    }
    componentDidMount() {
		this.getCommentsHistory(this.state.caseId);
	}
    /** @description function to view the comments history
	 *  @memberof CommentsHistoryContainer
	 */
    toggleCommentDetails = (toggleDetailsView, comment = null) => {
        this.setState({ viewDetails: toggleDetailsView, selectedComment: comment });
    }

    /** @description function to back to the comments history section
	 *  @memberof CommentsHistoryContainer
	 */
    backToListView = (toggleDetailsView, comment = null) => {
        this.setState({ viewDetails: false, selectedComment: null });
    }

    /** @description function to handle API call to get the comments history
	 *  @caseId  {}
	 *  @memberof CommentsHistoryContainer
	 */
	getCommentsHistory = async () => {
        const {caseId}=this.state
        this.setState({ commentHistoryLoading: true })
		getCommentsHistoryDetails(caseId,(err, result) => {
			if (err) {
				this.setState({  commentHistoryLoading: false, selectedComment: null,commentsHistoryError:err});
			} else {
				this.setState({ selectedComment: result && result.data && result.data.case && result.data.case.length ? result.data.case[0].comments : null,
                    historyData: result && result.data && result.data.case && result.data.case.length ? result.data.case[0].comments : null,
                    commentHistoryLoading: false, 
                    commentsHistoryError: null  
                });
			}

		})
	}
    render() {
        const { historyData, viewDetails, selectedComment,caseId,commentHistoryLoading,commentsHistoryError } = this.state;
        return (
            <CommentsHistoryComponent
                historyData={historyData}
                viewDetails={viewDetails}
                selectedComment={selectedComment}
                toggleCommentDetails={this.toggleCommentDetails}
                backToListView={this.backToListView}
                caseId={caseId}
                commentHistoryLoading={commentHistoryLoading}
                commentsHistoryError={commentsHistoryError}
            />
        )
    }
}
