import React, { Component } from 'react';
import Sidemenu from '../SideMenu/SideMenu';
import { convertUTCToLocalDateTime, formatDateTimeLocal } from '../../helpers/GlobalFunctions';
import { DataTable } from '../DataTable/DataTable';
import { auditLogs } from '../../helpers/messages';
import ExportData from '../../containers/Logs/ExportData';
import LogsFilterContainer from '../../containers/LogsFilter';


class Logs extends Component {
	constructor() {
		super()
		this.state = {
			reloadList: '', filterObj: '', count: '', refreshDataList: true,
		}
	}

	/**
	 * @description function to get count 
	 * @param {*}
	 * @memberof Logs
	 */
	/* istanbul ignore next  */
	setTotalCount = (val) => {
		// this.setState({ count: val, refreshDataList: false })
	}


	/**
	 * @description function to get filter obj as props from filter component 
	 * @param {*}
	 * @memberof Logs
	 */
	getLogsFilterObj = (obj) => {
		this.setState({ filterObj: obj, refreshDataList: true });
	}

	/**
	 * @description function to handle refreshing logs list
	 * @memberof Logs
	 */
	refreshLogs = () => {
		this.setState({ reloadList: true, refreshDataList: true });
		/* istanbul ignore next  */
		setTimeout(() => {
			this.setState({ reloadList: false });
		}, 2000);
	}


	/**
	 * @description function to generate the dynamic message
	 * @memberof Logs
	 */
	generateMessage = (info) => {
		const startDateFormatted = info?.StartDate ? ` Start Date: ${formatDateTimeLocal(info.StartDate)}` : '';
		const endDateFormatted = info?.EndDate ? ` End Date: ${formatDateTimeLocal(info.EndDate)}` : '';
		return info?.Data ? `${info.Data}${startDateFormatted}${endDateFormatted}` : '';
	}

	render() {
		/* istanbul ignore next  */
		const columns = [
			{
				Header: 'Event Date & Time',
				accessor: 'EventDate',
				Cell: ({ row }) => row.original.EventDate ? convertUTCToLocalDateTime(row.original.EventDate) : '',
				Filter: ''
			},
			{
				Header: 'Event Name',
				accessor: 'EventName',
				Filter: ''
			},
			{
				Header: 'Event Outcome',
				accessor: 'EventOutcome',
				Filter: ''
			},
			{
				Header: 'Event Type',
				accessor: 'EventType',
				Filter: ''
			},
			{
				Header: 'Username',
				accessor: 'UserID',
				Filter: ''
			},
			{
				Header: 'Time Spent (Seconds)',
				accessor: 'Content.TimeSpent',
				Cell: ({ row }) => row.original.Content && row.original.Content.TimeSpent ? parseFloat(row.original.Content.TimeSpent).toFixed(2) : '',
				Filter: ''
			},
			{
				Header: 'Case ID/Plan ID',
				accessor: 'Content.CaseId',
				Cell: ({ row }) => {
					const id = row.original.Content?.CaseId || row.original?.PlanId;
					return id ? <div title={id}>{id}</div> : <span>N/A</span>;
				},
				Filter: ''
			},
			{
				Header: 'Message',
				accessor: 'Content.Data',
				Cell: ({ row }) => <div className='log-content'>{this.generateMessage(row.original.Content || '')}</div>,
				Filter: ''
			}
		]
		return (<div>
			{/* <Header /> */}
			<div className="bgGray">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-1">
							<Sidemenu />
						</div>
						<div className="col-md-11">
							<div className="data-list-tabs caselist-tabs">
								<LogsFilterContainer id="logs-filter" setLogsFilterObj={this.getLogsFilterObj} />
								<div className="app-logs">
									<ExportData totalCount={this.state.count} filterObj={this.state.filterObj} refreshLogs={this.refreshLogs} />
									{this.state.reloadList ?
										<p className="text-success text-center py-4">{auditLogs.refreshing}</p>
										: <DataTable totalCount={this.setTotalCount} listType="audit-logs" columns={columns} showPagination={true} disableColmSort={true}
											filterObj={this.state.filterObj || ''}
										/>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>);
	}
}
export default Logs;
