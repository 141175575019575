import React, { Component } from 'react';

class SalesRepInput extends Component {
    render() {
        const { onEnterPress, handleInputChange, findSalesRepCustomers, formErrors, searchKey } = this.props;
        return (
            <div className='row col-md-12'>
                <div className='col-md-8'>
                    <input
                        placeholder="Enter Customer's name or Customer ID"
                        className='form-control'
                        onKeyPress={onEnterPress}
                        type='text'
                        id='customerSearchKey'
                        onChange={(e) => handleInputChange(e.target.value)}
                    />
                    {formErrors && formErrors.searchKey && (
                        <div className='proms-error-msg'>
                            <p>{formErrors.searchKey}</p>
                        </div>
                    )}
                </div>
                <div className='col-md-4'>
                    <svg
                        onClick={() => findSalesRepCustomers(searchKey)}
                        width='2em'
                        height='2em'
                        viewBox='0 0 16 16'
                        className='bi bi-search serach-icon'
                        id='searchIcon'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path fillRule='evenodd' d='M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z' />
                        <path fillRule='evenodd' d='M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z' />
                    </svg>
                </div>
            </div>
        );
    }
}
export default SalesRepInput;
