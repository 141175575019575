import React, { Component } from 'react';
import ReviewDropdown from '../../components/ReviewPlanList/ReviewDropdown';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { updateReviewPlanCustomerForSalesRepService, updateReviewPlanStatusService } from '../../services/java/java-services';
import { getSessionStorage, removeSessionStorage } from '../../services/storage/storage-service';
import { eventSource } from '../../helpers/constants';

export default class UpdateReviewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
        this.updateReviewDataInDB = this.updateReviewDataInDB.bind(this);

    }

    /**
     * @description Function to create update parameters based on the current status and other variables
     * @param {boolean} isCurrentStatus - Whether the update is for the current status
     * @param {Object} reviewUpdateObj - Object containing review update details
     * @param {Array} idsToUpdate - Array of IDs to update
     * @param {string} value - The value to update
     * @returns {Object} The update parameters
     */
    createUpdateParams(isCurrentStatus, reviewUpdateObj, idsToUpdate, value) {
        const idFieldName = 'caseNumbers'
        const valueFieldName = isCurrentStatus ? 'status' : 'customerId';

        return {
            [idFieldName]: idsToUpdate,
            [valueFieldName]: value,
        };
    }

    /**
     * @description Function to handle the update callback, including error handling and successful update
     * @param {Object} err - Error object, if any
     * @param {Object} res - Response object from the update service
     * @param {boolean} isCurrentStatus - Whether the update is for the current status
     * @param {Object} reviewUpdateObj - Object containing review update details
     * @param {Array} idsToUpdate - Array of IDs to update
     * @param {string} selectedOptionText - The text of the selected option in the update dropdown
     * @param {string} type - The type of update to perform ("currentStatus" or "customerId")
     */
    handleUpdateCallback(err, res, isCurrentStatus, reviewUpdateObj, idsToUpdate, selectedOptionText, type) {
        if (err) {
            this.props.updateError(err);
            this.props.updateLoader(false);
            return;
        }

        if (res.status === 200) {
            setTimeout(() => {
                // Prepare data to update based on the current status
                const dataToUpdate = { ...reviewUpdateObj, id: idsToUpdate };
                let logMessage = loggerEventMessage.reviewPlanListUpdated(selectedOptionText, type);

                if(selectedOptionText === 'Select Any Customer'){
                    logMessage = loggerEventMessage.unassignMessage(idsToUpdate)
                }

                this.props.reviewDataUpdated(dataToUpdate);
                this.reviewDataUpdateLogger(
                    {
                        outcome: loggerEventOutcome.success,
                        message: logMessage,
                    },
                    dataToUpdate,
                    isCurrentStatus
                );
            }, 0);

            this.props.isUpdated(true);
            removeSessionStorage('selectedRow');
        }
    }

    /**
     * @description Function to update review table's data in backend DB for customer assigned to hospital and status
     * @memberof UpdateReviewContainer
     * @param {Object} params - Object containing parameters for the update operation
     * @param {string} params.value - The value to update
     * @param {string} params.VisionaierId - The ID of the review to update
     * @param {string} params.type - The type of update to perform ("currentStatus" or "customerId")
     * @param {string} params.selectedOptionText - The text of the selected option in the update dropdown
     */
    updateReviewDataInDB({ value, VisionaierId, type, selectedOptionText }) {
        // Create review update object
        const reviewUpdateObj = {
            type,
            value,
            id: `${VisionaierId}`,
            selectedOptionText,
        };

        this.props.updateLoader(true);
        const isCurrentStatus = type === 'currentStatus';
        const selectedRows = JSON.parse(getSessionStorage('selectedRow')) || [];
        const multiSelectedIds = selectedRows.map((item) => item.VisionaierId);
        const idsToUpdate = multiSelectedIds.length > 0 ? multiSelectedIds : [reviewUpdateObj.id];
        // Create update parameters and call the appropriate update function
        const updateParams = this.createUpdateParams(isCurrentStatus, reviewUpdateObj, idsToUpdate, value);
        const updateFunction = this.getUpdateFunction(isCurrentStatus);
        updateFunction(updateParams, (err, res) => this.handleUpdateCallback(err, res, isCurrentStatus, {...updateParams,...reviewUpdateObj}, idsToUpdate, selectedOptionText, type));
    }

    /**
     * @description Function to determine the appropriate update service function based on current status
     * @param {boolean} currentStatus - Whether the update is for the current status
     * @returns {Function} The appropriate update service function (either updateReviewPlanStatusService or updateReviewPlanCustomerForSalesRepService)
     */
    getUpdateFunction(currentStatus) {
        // If current status is true, update status; otherwise, update customer association for sales representative
        return currentStatus === true ? updateReviewPlanStatusService : updateReviewPlanCustomerForSalesRepService;
    }

    /**
     * @description Function to handle errors
     * @param error Error object with error code and message
     * @memberof UpdateReviewContainer
     */
    handleError = (error) => {
        this.props.updateLoader(false);
        this.setState({ error: error });
        this.reviewDataUpdateLogger({ outcome: loggerEventOutcome.failure, message: error.message });
        this.props.updateError(error);
    };

    /**
     * @description function to log for review table dropdown update event(Hospital Association/status)
     * @param params Params with outcome and message
     * @memberof UpdateReviewContainer
     */

    reviewDataUpdateLogger = (params, updateParams, isCurrentStatus) => {
            // logged data for the multiselect
            updateParams?.id?.forEach((number) => {
                const loggerObj = {
                    EventOutcome: params.outcome,
                    EventType: loggerEventTypes.update,
                    EventName: loggerEventName.updateReviewPlanList,
                    EventSource: eventSource.kpopPlans.value,
                    Content: {
                        Data: params.message,
                        CaseId: number.toString(),
                    },
                };
                logger(loggerObj);
            });
    };

    render() {
        const { row, type } = this.props;
        return (
            <div>
                <ReviewDropdown row={row} updateReviewDataInDB={this.updateReviewDataInDB} type={type} />
            </div>
        );
    }
}
