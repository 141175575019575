/**
 * @description Function to set local storage
 * @param key Name of the storage variable
 * @param value Value of the storage variable
 */
export const setLocalStorage = (key, value) => {
	localStorage.setItem(key, value);
}

/**
 * @description Function to get local storage
 * @param key Name of the storage variable
 * @returns Local storage value for the supplied key
 */
export const getLocalStorage = (key) => {
	return localStorage.getItem(key);
}

/**
 * @description Function to clear local storage
 */
export const clearLocalStorage = () => {
    localStorage.clear();
}

/**
 * @description Function to get total number of local storage variables set
 * @returns Local storage length
 */
export const getLocalStorageLength = () => {
    return localStorage.length;
}

/**
 * @description Function to remove local storage item
 * @param key Name of the storage variable
 */
export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}

/**
 * @description Function to check if local storage item exists
 * @param key Name of the storage variable
 */
export const checkLocalStorage = (key) => {
    if (localStorage.getItem(key)) {
        return true;
    } 

    return false;
}

/**
 * @description Function to set session storage
 * @param key Name of the storage variable
 * @param value Value of the storage variable
 */
 export const setSessionStorage = (key, value) => {
	sessionStorage.setItem(key, value);
}

/**
 * @description Function to get session storage
 * @param key Name of the storage variable
 * @returns Local storage value for the supplied key
 */
 export const getSessionStorage = (key) => {
	return sessionStorage.getItem(key);
}

/**
 * @description Function to clear session storage
 */
 export const clearSessionStorage = () => {
    sessionStorage.clear();
}

/**
 * @description Function to get total number of session storage variables set
 * @returns Local storage length
 */
export const getSessionStorageLength = () => {
    return sessionStorage.length;
}

/**
 * @description Function to remove session storage item
 * @param key Name of the storage variable
 */
export const removeSessionStorage = (key) => {
    sessionStorage.removeItem(key);
}

/**
 * @description Function to check if session storage item exists
 * @param key Name of the storage variable
 */
export const checkSessionStorage = (key) => {
    if (sessionStorage.getItem(key)) {
        return true;
    } 

    return false;
}