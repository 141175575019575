import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserDetailsComponent from '../../components/UserDetails/UserDetails'
import { getUserDetails, updateUserDetails } from '../../services/java/java-services';
import { sendEmail, updateUserDetailsCognito, addUserToGroupCognito } from '../../services/aws/aws-services';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes, userMessages } from '../../helpers/messages';
import { validateAlphanumeric, validateEmail, validateUserFields } from './ValidateUser';
import { charset, getUserEmailChangedTemplate, sourceEmail, subjectLines } from '../../helpers/Email';
import { logger } from '../../services/logger/logger-service';
import { Roles } from '../../helpers/constants';
import { validateSalesRepEmail } from '../SalesRepDetails/ValidateSalesRep';

let startDate;
class UserDetailsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMultipleRole: false,
            secondRoleArray: [],
            loading: false,
            editMode: false,
            userName: props.userName ? props.userName : '',
            user: '',
            userError: null,
            showSuccessMsg: '',
            showEmailSuccessMsg: '',
            showErrorMsg: '',
            editObj: {
                firstName: '',
                lastName: '',
                email: '',
                secondRole: '',
            },
            errors: {
                firstName: '',
                lastName: '',
                email: ''
            }
        }
    }

    componentDidMount() {
        startDate = new Date();
        window.addEventListener('beforeunload', this.timeSpentLogger);
        this.getUserDetails(this.props.userName);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
    }

    /**
     * @description function to handle application logs for time spent on the page
     * @param {*}
     * @memberof UserDetailsContainer
     */
    timeSpentLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.userDetails,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description Function to get the user details
     * @param userName Unique username of the user to be fetched
     * @memberof UserDetailsContainer
     */
    getUserDetails = async (userName, rippleProp = false) => {
        this.setState({ loading: true })
        getUserDetails(userName, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false, userError: err })
            } else {
                const user = result.data && result.data.user && result.data.user.length ? result.data.user[0] : '';
                this.setState({
                    loading: false,
                    user: user,
                    userError: null,
                    ...this.getEditObject(user)
                });
                if (rippleProp) {
                    setTimeout(() => {
                        this.props.userUpdated(true);
                    }, 0);
                }
            }
        })
    }
    /**
       * @description Function to get user role
       * @param user Row element for each user
        */
    /* istanbul ignore next  */
    getUserRole(userGroup) {
        if (userGroup) {
            const userRole = Roles.find(x => x.cognitoGroupName === userGroup);
            return userRole ? [userRole.cognitoGroupName] : [userGroup];
        }

        return [];
    }
    /**
     * @description Function to handle state when edit is cancelled
     * @param user User Details
     * @memberof UserDetailsContainer
     */
    getEditObject(user) {
        const editObj = {
            firstName: user && user.firstName ? user.firstName : '',
            lastName: user && user.lastName ? user.lastName : '',
            email: user && user.email ? user.email : '',
            group: user && user.group ? user.group : '',
        }
        return {
            editObj: editObj,
            errors: {
                firstName: '',
                lastName: '',
                email: ''
            }
        }
    }

    /**
         * @description Function to enable/disable edit mode
         * @memberof UserDetailsContainer
         */
    toggleEditMode = () => {
        this.setState(prevState => {
            return {
                ...this.getEditObject(prevState.user),
                editMode: !prevState.editMode,
                isMultipleRole: false
            };
        });
    }

    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field name
     * @memberof UserDetailsContainer
     */
    handleInputChange = (field, value) => {
        const { editObj, errors } = this.state
        var errorMessage = !value ? userMessages[field] : '';
        if ((field === 'firstName' || field === 'lastName') && value) {
            const surgeonNameCheck = validateAlphanumeric(value);
            errorMessage = surgeonNameCheck.error;
        }

        if (field === 'email' && value) {
            // validate sales rep email
            if ((editObj?.role && editObj?.role === 'salesrep') || (editObj?.secondRole && editObj?.secondRole === 'salesrep')) {
                let validateSEmail = validateSalesRepEmail(value);
                errorMessage = validateSEmail.error;
            } else {
                const emailCheck = validateEmail(value);
                errorMessage = emailCheck.error;
            }
        }

        if (field === 'secondRole' && value) {
            if (value === 'salesrep' && editObj?.email) {
                let validateSalesRep = validateSalesRepEmail(editObj?.email);
                errors.email = validateSalesRep.error;
            } else if (editObj.email) {
                const emailCheck = validateEmail(editObj.email);
                errors.email = emailCheck.error;
            }
        } else if (field === 'secondRole' && value === 'undefined' && editObj?.email) { 
            //to handle validation, on removal of secondRole
            const emailCheck = validateEmail(editObj?.email);
            errors.email = emailCheck.error;
        }

        this.setState(prevState => {
            return {
                [field]: value,
                editObj: {
                    ...prevState.editObj,
                    [field]: value
                },
                errors: {
                    ...prevState.errors,
                    [field]: errorMessage
                }
            }
        });
    }
    /**
       * @description Function to handle multiple role selection enable/disable
       * @memberof AddUserContainer
       */
    handleMultipleRoleSelect = (value) => {
        //to handle validation onClick of '-' sign of secondRole
        this.handleInputChange('secondRole', 'undefined')
        const { editObj } = this.state;
        if (!value) {
            this.setState({
                isMultipleRole: false,
                secondRoleArray: [],
                editObj: {
                    ...editObj,
                    secondRole: ''
                }
            });
            return;
        } else if (value && this.state.isMultipleRole) {
            return;
        }
        const userRoles = this.state.user && this.state.user.groups.length ? this.state.user.groups : this.getUserRole(this.state.user.group)
        var secondRoleArray = Roles.filter(x => userRoles.some(y => y === x.secondRoleOption))
        this.setState({
            isMultipleRole: true,
            secondRoleArray,
            editObj: {
                ...editObj,
                secondRole: ''
            }
        });
    }
    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof UserDetailsContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveUser();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof UserDetailsContainer
     */
    validateFields = () => {
        const { editObj } = this.state;
        let { errorFlag, errors } = validateUserFields({ editObj: editObj, errors: this.state.errors });
        if (!errorFlag && editObj.firstName) {
            let firstName = validateAlphanumeric(editObj.firstName);
            errorFlag = firstName.errorFlag;
            errors['firstName'] = firstName.error;
        }
        if (!errorFlag && editObj.lastName) {
            let lastName = validateAlphanumeric(editObj.lastName);
            errorFlag = lastName.errorFlag;
            errors['lastName'] = lastName.error;
        }
        if (!errorFlag && editObj.email) {
            let email = validateEmail(editObj.email);
            errorFlag = email.errorFlag;
            errors['email'] = email.error;
        }
        if (!errorFlag && editObj.secondRole && editObj.secondRole === 'salesrep') {
            let email = validateSalesRepEmail(editObj.email);
            errorFlag = email.errorFlag;
            errors['email'] = email.error;
        }
        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }

        return errorFlag;
    }

    /**
     * @description function to validate and save user details
     * @param {*}
     * @memberof UserDetailsContainer
     */
    saveUser = () => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '' });
        const formHasErrors = this.validateFields();
        if (!formHasErrors) {
            const { editObj, user } = this.state;
            this.setState({ loading: true });
            const isUpdateCognito = editObj.firstName !== user.firstName || editObj.lastName !== user.lastName || editObj.email !== user.email;
            let updateAttributes = {
                groups: user.groups.length ? user.groups : user.group ? [user.group] : []
            };
            if (editObj.firstName !== user.firstName) {
                updateAttributes['firstname'] = editObj.firstName;
            }

            if (editObj.lastName !== user.lastName) {
                updateAttributes['lastname'] = editObj.lastName;
            }

            if (editObj.email !== user.email) {
                updateAttributes['email'] = editObj.email;
            }
            if (editObj.secondRole && updateAttributes.groups.length === 1) {
                updateAttributes.groups.push(editObj.secondRole)
            }

            if (isUpdateCognito) {
                this.updateCognito(updateAttributes);
            } else {
                if (editObj.secondRole) {
                    addUserToGroupCognito(user.userName, editObj.secondRole)
                        .then((response) => {
                            if (response) {
                                this.updateUserAPI(updateAttributes);
                            }
                        })
                        .catch((err) => {
                            this.setState({
                                loading: false,
                                showErrorMsg:
                                    err && err.message
                                        ? err.message
                                        : userMessages.saveError,
                            });
                        });
                } else {
                    this.updateUserAPI(updateAttributes);
                }
            }
        }
    }

    /**
   * @description function to update user data
   * @param {*}
   * @memberof UserDetailsContainer
   */
    commonDbUpdate = (isSendEmail, user, updateAttributes) => {
        if (isSendEmail) {
            const toEmailIDs = [
                user.email,
                updateAttributes.email
            ]
            this.sendEmailToUser(toEmailIDs, updateAttributes);
        }
        this.updateUserAPI(updateAttributes);
    }
    /**
     * @description function to update cognito userpool
     * @param {*}
     * @memberof UserDetailsContainer
     */
    updateCognito = (updateAttributes) => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '' });
        const { userName, user, editObj } = this.state;
        var isSendEmail = false;
        let cognitoUserAttributes = [];

        if (updateAttributes.firstname) {
            cognitoUserAttributes.push({ Name: 'given_name', Value: updateAttributes.firstname });
        }

        if (updateAttributes.lastname) {
            cognitoUserAttributes.push({ Name: 'family_name', Value: updateAttributes.lastname });
        }

        if (updateAttributes.email) {
            isSendEmail = true;
            cognitoUserAttributes.push({ Name: 'email', Value: updateAttributes.email });
            cognitoUserAttributes.push({ Name: 'email_verified', Value: "true" });
        }

        updateUserDetailsCognito(userName, cognitoUserAttributes)
            .then(async res => {
                if (res) {
                    if (editObj.secondRole) {
                        addUserToGroupCognito(user.userName, editObj.secondRole)
                            .then((response) => {
                                if (response) {
                                    this.commonDbUpdate(
                                        isSendEmail,
                                        user,
                                        updateAttributes
                                    );
                                }
                            })
                            .catch((err) => {
                                this.setState({
                                    loading: false,
                                    showErrorMsg:
                                        err && err.message
                                            ? err.message
                                            : userMessages.saveError,
                                });
                            });
                    } else {
                        this.commonDbUpdate(isSendEmail, user, updateAttributes);
                    }
                }
            })
            .catch(err => {
                this.setState({ loading: false, showErrorMsg: err && err.message ? err.message : userMessages.saveError });
            });
    }

    /**
     * @description function to notify user of an email update
     * @param {*}
     * @memberof UserDetailsContainer
     */
    sendEmailToUser = (toEmailIDs, updatedDetails) => {
        const user = {
            firstName: updatedDetails.firstname ? updatedDetails.firstname : this.state.user.firstName,
            lastName: updatedDetails.lastname ? updatedDetails.lastname : this.state.user.lastName,
            email: updatedDetails.email
        }
        const params = {
            Destination: {
                ToAddresses: toEmailIDs
            },
            Message: {
                Body: {
                    Html: {
                        Charset: charset,
                        Data: getUserEmailChangedTemplate(user)
                    }
                },
                Subject: {
                    Charset: charset,
                    Data: subjectLines.emailChange
                }
            },
            Source: sourceEmail
        };
        sendEmail(params, (res) => {
            this.setState({ showEmailSuccessMsg: userMessages.emailSent })
        })
    }

    /**
     * @description function to update user in db
     * @param {*}
     * @memberof UserDetailsContainer
     */
    updateUserAPI = (updateAttributes) => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '' });
        const { userName } = this.state;
        const params = {
            ...updateAttributes,
            usertype: 'all'
        }

        updateUserDetails(userName, params, (err, res) => {
            if (err) {
                const errMsg = err && err.message ? err.message : userMessages.saveError;
                this.setState({ loading: false, showErrorMsg: errMsg });
                this.applicationLogger({ outcome: loggerEventOutcome.failure, message: `${errMsg} (For ${userName})` });
                this.props.userUpdated(false);
            } else {
                this.setState({ showSuccessMsg: userMessages.saveUserMsg, showErrorMsg: '', loading: false, editMode: false, isMultipleRole: false });
                this.applicationLogger({ outcome: loggerEventOutcome.success, message: loggerEventMessage.userUpdated(userName) });
                this.getUserDetails(userName, true);
            }
        });
    }

    /**
     * @description function to log user update event
     * @memberof UserDetailsContainer
     */
    /* istanbul ignore next  */
    applicationLogger = (params) => {
        const loggerObj = {
            "EventOutcome": params.outcome,
            "EventType": loggerEventTypes.update,
            "EventName": loggerEventName.userUpdate,
            "Content": {
                "Data": params.message
            }
        }
        logger(loggerObj);
    }

    render() {
        return (
            <UserDetailsComponent
                user={this.state.user}
                loading={this.state.loading}
                userError={this.state.userError}
                editMode={this.state.editMode}
                toggleEditMode={this.toggleEditMode}
                editObj={this.state.editObj}
                handleInputChange={this.handleInputChange}
                onEnterPress={this.onEnterPress}
                showSuccessMsg={this.state.showSuccessMsg}
                showEmailSuccessMsg={this.state.showEmailSuccessMsg}
                showErrorMsg={this.state.showErrorMsg}
                formErrors={this.state.errors}
                saveUser={this.saveUser}
                handleCancel={this.props.handleCancel}
                isMultipleRole={this.state.isMultipleRole}
                secondRoleArray={this.state.secondRoleArray}
                handleMultipleRoleSelect={this.handleMultipleRoleSelect}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(UserDetailsContainer);