import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditProms from '../../components/CaseDetails/EditProms';
import { updatePromScoreService } from '../../services/java/java-services';
import { validatePromFields, validatePromScore } from './ValidateProms';
import { manualPromScoreFields, scoreTypesForManualScore } from './constants';
import { promScoreMessages } from '../../helpers/messages';

class EditPromsContainer extends Component {
	constructor(props) {
		super(props);
		let isSubScore = props.promsEditObj && scoreTypesForManualScore.indexOf(props.promsEditObj.scoreType) > -1;
		this.state = {
			caseDetails: '',
			loading: false,
			refreshProms: '',
			operativeProcedure: props.promsEditObj && props.promsEditObj.operativeProcedure ? props.promsEditObj.operativeProcedure : 'TKA',
			caseId: props.promsEditObj ? props.promsEditObj.caseId : '',
			scoreType: props.promsEditObj ? props.promsEditObj.scoreType : '',
			scoreId: props.promsEditObj ? props.promsEditObj.scoreId : '',
			timeFrame: props.promsEditObj ? props.promsEditObj.timeInterval : '',
			scoreValue: props.promsEditObj ? props.promsEditObj.score : '',
			subScoreType: props.promsEditObj ? props.promsEditObj.subScoreType : '',
			isSubScore: isSubScore,
			subScoreFields: isSubScore ? manualPromScoreFields[props.promsEditObj.scoreType] : [],
			promScoreSave: '',
			promsErrorMsg: null,
			errors: {
				scoreValue: '',
				scoreType: '',
				timeFrame: '',
				subScoreFields: ''
			}
		}
	}


	/**
	 * @description function to update Proms score to DB
	 * @param {*}
	 * @memberof EditPromsContainer
	 */
	updatePromScoreEntry = async (caseId, promsDetails) => {
		this.setState({ loading: true })
		updatePromScoreService(caseId, promsDetails, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ loading: false, promScoreSave: false, promsErrorMsg: err });
			} else {
				this.setState({ loading: false, promScoreSave: true, refreshProms: true, promsErrorMsg: null });
				this.refreshProms();
			}
		})
	}

	/**
	* @description function for validate score field
	* @param scoreType Selected score type  
	* @param value Field value
	* @param errors State error object
	* @returns Object with error messages if there is an error, true if not
	* @memberof EditPromsContainer
	*/
	validateAndSetScoreError = (scoreType, value, errors, formSubmit = false, subScoresObj = null) => {
		const { isSubScore } = this.state;
		let validationRes = validatePromScore(scoreType, value, errors, formSubmit, subScoresObj);
		let obj = { errorFlag: validationRes.errorFlag, errors: {} };
		if (isSubScore && subScoresObj) {
			let field = Object.keys(subScoresObj);
			obj['errors'] = {
				...validationRes.errors,
				scoreValue: validationRes.errors[field] ? validationRes.errors[field] : ''
			}

			if (field !== 'scoreValue') {
				obj[field] = ''
			}
		}

		return obj;
	}

	/**
	* @description function for validate each input field
	* @param field Field name
	* @param value Field value
	* @returns Object with error messages if there is an error, true if not
	* @memberof EditPromsContainer
	*/
	validateFields = (field, value, formSubmit = false) => {
		this.setState({ promScoreSave: '', refreshDataList: false });
		const { errors, scoreType, subScoreType, scoreValue } = this.state;
		var validationRes;
		if (field === 'scoreValue') {
			if (value === '' || value === null) {
				validationRes = {
					errorFlag: true,
					errors: {
						scoreValue: promScoreMessages.error.scoreValue
					}
				}
			} else {
				if (scoreType && scoreTypesForManualScore.indexOf(scoreType) > -1) {
					let scoreFields = manualPromScoreFields[scoreType] ? manualPromScoreFields[scoreType] : [];
					if (scoreFields.length) {
						let obj = {};
						if (subScoreType) {
							let subScoreObj = scoreFields.find(x => x.type === subScoreType);
							if (subScoreObj && Object.keys(subScoreObj).length) {
								obj[subScoreObj.fieldName] = value;
								validationRes = this.validateAndSetScoreError(scoreType, null, errors, formSubmit, obj);
							}
						} else {
							validationRes = this.validateAndSetScoreError(scoreType, null, errors, formSubmit, { scoreValue: value });
						}
					}
				} else {
					validationRes = this.validateAndSetScoreError(scoreType, value, errors, formSubmit);
				}
			}
		} else if (field === 'scoreType' && value && !isNaN(parseFloat(this.state.scoreValue))) {
			errors.scoreType = '';
			if (scoreTypesForManualScore.indexOf(value) > -1) {
				let scoreFields = manualPromScoreFields[value] ? manualPromScoreFields[value] : [];
				if (scoreFields.length) {
					let obj = {};
					if (subScoreType) {
						let subScoreObj = scoreFields.find(x => x.type === subScoreType);
						if (subScoreObj && Object.keys(subScoreObj).length) {
							obj[subScoreObj.fieldName] = scoreValue;
							validationRes = this.validateAndSetScoreError(value, null, errors, formSubmit, obj);
						}
					} else {
						validationRes = this.validateAndSetScoreError(scoreType, null, errors, formSubmit, { scoreValue: scoreValue });
					}

				}
			} else if (!isNaN(parseInt(scoreValue))) {
				validationRes = this.validateAndSetScoreError(value, scoreValue, errors, formSubmit);
			} else {
				validationRes = validatePromFields(field, value, errors);
			}
		} else {
			validationRes = validatePromFields(field, value, errors);
		}

		this.setState(prevState => {
			return {
				errors: {
					...prevState.errors,
					...validationRes.errors
				}
			}
		});

		return validationRes.errorFlag ? validationRes.errors : true;
	}

	/**
	* @description function for validate Prom score entry
	* @param {*}
	* @memberof EditPromsContainer
	*/
	formValidation() {
		const scoreTypeValidation = this.validateFields('scoreType', this.state.scoreType, true);
		const subScoreTypeValidation = scoreTypeValidation === true && this.state.isSubScore ? this.validateFields('subScoreType', this.state.subScoreType, true) : '';
		const timeFrameValidation = scoreTypeValidation === true ? this.validateFields('timeFrame', this.state.timeFrame, true) : '';
		const scoreValueValidation = scoreTypeValidation === true && timeFrameValidation === true ? this.validateFields('scoreValue', this.state.scoreValue, true) : '';

		if (this.state.isSubScore) {
			return scoreTypeValidation === true && subScoreTypeValidation === true && timeFrameValidation === true && scoreValueValidation === true;
		} else {
			return scoreTypeValidation === true && timeFrameValidation === true && scoreValueValidation === true;
		}
	}

	/**
	 * @description function to handle input changes..
	 * @param  {} inputType
	 * @memberof EditPromsContainer
	 */
	handleInputChange = async (inputValue, inputType) => {
		let stateObj = { [inputType]: inputValue };
		const { scoreType, subScoreType } = this.state;
		if (inputType === 'scoreType') {
			let isSubScore = scoreTypesForManualScore.indexOf(inputValue) > -1;
			stateObj['isSubScore'] = isSubScore;
			stateObj['subScoreFields'] = isSubScore ? manualPromScoreFields[inputValue] : [];
			stateObj['subScoreType'] = isSubScore && scoreType === inputValue ? subScoreType : ''
		}
		this.setState(stateObj);

		// Allowing time for the state to be set before field validations are triggered
		setTimeout(() => {
			this.validateFields(inputType, inputValue);
		}, 0);
	}

	/** @description function to update PROM score
	 *  @param  {}
	 *  @memberof EditPromsContainer
	 */
	updatePromScore = async () => {
		let verifyValidation = this.formValidation();
		if (verifyValidation === true) {
			let promsDetails = {
				"scoreType": this.state.scoreType,
				"score": this.state.scoreValue,
				"timeIntervals": this.state.timeFrame,
				"scoreId": this.state.scoreId,
				"source": "Manual"
			}
			if (this.state.isSubScore) {
				promsDetails['subScoreType'] = this.state.subScoreType;
			}

			await this.updatePromScoreEntry(this.state.caseId, promsDetails);
		}
	}
	/** @description function to refresh PROM score
	*  @param  {}
	*  @memberof EditPromsContainer
	*/
	refreshProms = () => {
		this.props.refreshProms(this.state.refreshProms);
	}
	/** @description function to cancel PROM score
	 *  @param  {}
	 *  @memberof EditPromsContainer
	 */
	cancelProms = () => {
		this.props.cancelProms();
	}

	/** @description function to update prom score by clicking on enter
	*  @param  {}
	*  @memberof EditPromsContainer
	*/
	onEnterPress = (e) => {
		if (e.which === 13) {
			e.preventDefault();
			this.updatePromScore();
		}
	}

	render() {
		return (
			<div >
				<EditProms
					operativeProcedure={this.state.operativeProcedure}
					handleInputChange={this.handleInputChange}
					editPromScore={this.updatePromScore}
					cancelProms={this.cancelProms}
					promScoreSave={this.state.promScoreSave}
					promsErrorMsg={this.state.promsErrorMsg}
					scoreType={this.state.scoreType}
					scoreValue={this.state.scoreValue}
					timeFrame={this.state.timeFrame}
					isSubScore={this.state.isSubScore}
					subScoreType={this.state.subScoreType}
					subScoreFields={this.state.subScoreFields}
					isLoading={this.state.loading}
					formErrors={this.state.errors}
					onEnterPress={this.onEnterPress} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}


export default connect(mapStateToProps)(EditPromsContainer);
