import { RegularExpressions } from "../../helpers/constants";
import { surgeonMessages } from "../../helpers/messages";

/**
 * @description function for validate each input field
 * @param stateObj Current state object
 * @returns Object with error messages
 */
export const validateSurgeonFields = (stateObj) => {
    const fields = ['firstName', 'lastName', 'email', 'surgeonID'];
    var { errors, editObj } = stateObj;
    var errorFlag = false;
    
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const reWhiteSpace = new RegExp(/^\s+$/);
        if (!editObj[field]  || reWhiteSpace.test(editObj[field]) === true) {
            errorFlag = true;
            errors[field] = surgeonMessages[field];
            break;
        } else if (field === 'surgeonID' && editObj[field].length !== 10) {
            errorFlag = true;
            errors[field] = surgeonMessages.surgeonIDRegex;
            break;
        }
    }

    return { errorFlag, errors };
}

/**
 * @description function for validate phone number
 * @param value Phone number
 * @returns Object with error messages
 */
export const validateSurgeonID = (value) => {
    var errorFlag = !RegularExpressions.surgeonID.test(value);
    return { errorFlag, error: errorFlag ? surgeonMessages.surgeonIDRegex : '' }
}

/**
 * @description function for validate Alphanumeric
 * @param value Alphanumeric fields
 * @returns Object with error messages
 */
export const validateAlphanumeric = (value) => {
    var errorFlag = !RegularExpressions.name.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? surgeonMessages.alphaNumericRegex : '' }
}

/**
 * @description function for validate email
 * @param value Email field value
 * @returns Object with error messages
 */
export const validateEmail = (value) => {
    var errorFlag = !RegularExpressions.email.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? surgeonMessages.emailRegex : '' }
}