import React, { Component } from 'react';
import { connect } from 'react-redux';
import CORISetupPdf from '../../../../src/assets/pdf/CORI_setup_guide.pdf';
import { DataTable } from '../../DataTable/DataTable';
import CustomModal from '../../../shared/CustomModal';
import AddSalesRepCustomer from './AddSalesRepCustomer';
import ExpirationTooltip from '../../ExpirationTooltip/ExpirationTooltip';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import DashBoardPlanCount from './DashBoardPlanCount';

class SalesRepComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth <= 1023, // to check device is mobile or not
            // istanbul ignore next 
            isIos: /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
            // we are using this because without this 1024 px was being treated as desktop but that is normal ipad pro size     
        };
    }
    render() {
        const { refreshSalesRepCustomerList, refreshDataList, enablePreopCustomerSearchModal, showAddCustomerModal } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Customer Name',
                accessor: 'customerName',
                Filter: '',
                Cell: ({ row }) => <div className='text-capitalize'>{row.original.customerName}</div>,
                disableSortBy: true
            },
            {
                Header: 'Customer ID',
                accessor: 'customerId',
                Filter: '',
                Cell: ({ row }) => <div className='text'>{row.original.customerId}</div>,
                disableSortBy: true
            },
            {
                Header: 'Pending Plans',
                accessor: 'pendingPlans',
                Filter: '',
                Cell: ({ row }) => <div className='text'>{row.original.pendingPlans}</div>,
                disableSortBy: true
            },
            {
                Header: 'Completed Plans',
                accessor: 'completedPlans',
                Filter: '',
                Cell: ({ row }) => <div className='text'>{row.original.completedPlans}</div>,
                disableSortBy: true
            },
            {
                Header: 'License Status',
                accessor: 'licenseStatus',
                Filter: '',
                Cell: ({ row }) => <div className='text'>{row.original.licenseStatus}</div>,
                disableSortBy: true
            },
            {
                Header: 'Date Of Expiration',
                accessor: 'licenseExpireDate',
                Filter: '',
                Cell: ({ row }) => <ExpirationTooltip row={row?.original} />,
                disableSortBy: true
            },
            {
                Header: 'Delete',
                deleteAction: true,
            },
        ];
        const hiddenColumns = columns.filter((col) => col.accessor !== 'pendingPlans' && col.accessor !== 'completedPlans' && col.accessor !== 'licenseStatus');
        const { isIos, isMobile } = this.state

        // object to handle the columns data dynamically we are hiding some fields in mobile
        const dataTableProps = {
            listType: 'preop-customer-list',
            columns: isMobile || isIos ? hiddenColumns : columns,
            refreshSalesRepCustomerList: { refreshSalesRepCustomerList },
            showPagination: false
        };

        return (
            <div className='data-list-tabs'>
                <div className='row'>
                    <div className='col-md-3 view-case-block'>
                        <ErrorBoundary>
                            <DashBoardPlanCount />
                        </ErrorBoundary>
                        <div className='dashboard-blocks' id='coriSetupGuide'>
                            <div className='rar-dashboard-blocks-body rar-dashboard-block-height d-flex flex-column justify-content-evenly'>
                                <div className='rar-dashboard-block-sub-title'>Learn how to setup CORI for new RI.Insights customers</div>
                                <div className='rar-dashboard-btn'>
                                    <a href={CORISetupPdf} without='true' rel='noopener noreferrer' className='btn btn-primary rar-btn-color' target='_blank'>
                                        CORI Setup
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9 responsive-main-container'>
                        <div className='dashboard-blocks'>
                            <div className='rar-dashboard-blocks-body rar-dashboard-surgeon-list-body d-flex flex-column justify-content-start' data-testid="SalesRep-dashboard">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='dashboard-block-title'>Customers</div>
                                    <div className='add-surgeon-btn mt-1'>
                                        <div className='find-surgeon' data-testid="openCustomerSearchModal" id='openCustomerSearchModal' onClick={() => enablePreopCustomerSearchModal(true)}>
                                            Customer Search {'>>'}
                                        </div>
                                    </div>
                                </div>

                                {isIos ? (
                                    <div className='show-on-mobile ios-device-visible'>
                                        <DataTable {...dataTableProps}  />
                                    </div>
                                    
                                ) : isMobile && (
                                    <div className='show-on-mobile only-mobile-visible'>
                                        <DataTable {...dataTableProps}  />
                                    </div>
                                )}
                                {!isMobile && (
                                    <div className='hide-column-on-mobile normal-desktop'>
                                        <DataTable {...dataTableProps}  />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='add-surgeon-modal'>
                        {/* Modal to add customer to mapping list */}
                        <CustomModal
                            id='addSalesRepCustomerModal'
                            data-testid="addSalesRepCustomerModal"
                            dialogClassName='add-surgeon-dialog'
                            show={showAddCustomerModal}
                            isComponent={true}
                            isAlert={true}
                            alertText='Close'
                            title='Add Customers'
                            closeAction={() => enablePreopCustomerSearchModal(false)}
                        >
                            <AddSalesRepCustomer refreshDataList={refreshDataList} action='salesRepCustomerMap' />
                        </CustomModal>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({ state });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SalesRepComponent);
