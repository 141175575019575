import React, { Component } from 'react';
import { connect } from 'react-redux';
import AlertMessage from '../AlertMessage/AlertMessage';
import { eventSource } from '../../helpers/constants';

class LogsFilterComponent extends Component {
    constructor(props) {
		super(props);
		this.filterRef = React.createRef();
	}

	/**
	 * @description function to reset filter form
	 * @param {*}
	 * @memberof LogsFilterComponent
	 */
	resetForm = () => {
        this.filterRef.reset();
		this.props.resetLogsFilter();
    }
    render() {
        const { showFilter, showFilterBlock, todayDate, eventFromDate, eventToDate, filterLogs, 
            onInputChange, errorMessage, disableFilter, disableReset, username, eventname, caseid } = this.props;
        return (
            <div>
                <div className="logs-list-top-btn">
                    {showFilterBlock ? <button className="btn btn-primary" onClick={showFilter}>Close Filter</button> :
                        <button className="btn btn-primary" onClick={showFilter}>Show Filter</button>}
                </div>
                {showFilterBlock ? <div className="case-list-filter">
                {errorMessage ? <AlertMessage errorMessage={errorMessage} /> : null}
                    <form id="logsfilter" ref={(el) => this.filterRef = el}>
                        <ul className="filter-options">
                            <li>
                                Event From Date
								<input className="form-control" type="date" id="eventFromDate"
									value={eventFromDate || ''} max={todayDate} 
                                    onChange={(e) => onInputChange(e, 'eventFromDate')}/>
                            </li>
                            <li>
                                Event To Date
								<input className="form-control" type="date" id="eventToDate"
									value={eventToDate || ''} max={todayDate} 
                                    onChange={(e) => onInputChange(e, 'eventToDate')}/>
                            </li>
                            <li>
                                Username
								<input className="form-control" type="text" id="username"
									value={username} onChange={(e) => onInputChange(e, 'username')} />
                            </li>
                        </ul>
                        <ul className="filter-options">
                            <li>
                                Case ID/Plan ID
								<input className="form-control" type="text" id="caseid"
									value={caseid} onChange={(e) => onInputChange(e, 'caseid')}/>
                            </li>
                            <li>
                                Event Name
								<input className="form-control" type="text" id="eventname"
									value={eventname} onChange={(e) => onInputChange(e, 'eventname')}/>
                            </li>
                            <li>
							    Event Source
								<select id="event-source" className="form-select" defaultValue={eventSource.riInsights.value} onChange={(e)=> this.props.eventSourceHandler(e.target.value)}>
									<option value={eventSource.riInsights.value} >{eventSource.riInsights.label}</option>
                                    <option value={eventSource.visionaireKpop.value} >{eventSource.visionaireKpop.label}</option>
                                    <option value={eventSource.kpopPlans.value}>{eventSource.kpopPlans.label}</option>
								</select>
							</li>
                        </ul>
                        <ul className="filter-options">
							<li className="filter-btns text-end">
								<button className="btn btn-primary filter-btn-width" type="button" id="logsFilter" disabled={disableFilter} onClick={filterLogs}>Filter</button>
								<button className="btn btn-primary filter-btn-width" type="button" id="logsReset" disabled={disableReset} onClick={this.resetForm}>Reset</button>
							</li>
						</ul>
                    </form>
                </div> : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => (
    {
        state
    }
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(LogsFilterComponent);
