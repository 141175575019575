import React, { Component } from 'react';
import { connect } from 'react-redux';
import CareTeamComponent from '../../components/Dashboard/CareTeam/CareTeam';
import { getLastCaseUploadService, searchSurgeonsCareTeamService,getReviewCasesCount } from '../../services/java/java-services';
import { surgeonMapping } from '../../helpers/messages';
class CareTeamContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sinceLastCaseUpload: '',
            surgeonList: '',
            searchKey: '',
            searchResults: '',
            showAddSurgeonModal: false,
            loading: false,
            refreshSurgeonList: '',
            reviewCaseCount: '',
            errors: {
                searchKey: ''
            },
            lastCaseUploadError: null,
            reviewCaseCountError: null,
            searchResultsError: null
        }
    }

    componentDidMount() {
        this.getLastCaseUpload();
        this.getReviewCasesCount();
    }

    /**
     * @description function to get since last case upload
     * @param {*}
     * @memberof CareTeamContainer
     */
    getLastCaseUpload = () => {
        this.setState({ sinceLastCaseUpload: '' });
        getLastCaseUploadService((err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ lastCaseUploadError: err });
            } else {
                this.setState({ sinceLastCaseUpload: result.data.gapSinceLastUpload ? result.data.gapSinceLastUpload : '0 days', lastCaseUploadError: null })
            }
        })
    }

    /**
     * @description function to get total review cases count
     * @memberof CareTeamContainer
     */
    getReviewCasesCount = () => {
    	this.setState({ sinceLastCaseUpload: '' });
    	getReviewCasesCount((err, result) => {
    		/* istanbul ignore next  */
    		if (err) {
    			this.setState({ reviewCaseCountError: err });
    		} else {
    			this.setState({ reviewCaseCount: result.data.case && result.data.case.length ? result.data.case[0].count : 0, reviewCaseCountError: null })
    		}
    	})
    }

    /**
    * @description function to handle input changes..
    * @param  {} inputValue
    * @memberof CareTeamContainer
    */
    handleInputChange = async (inputValue) => {
        var errors = { ...this.state.errors }
        errors.searchKey = '';
        this.setState({ errors })
        this.setState({ searchKey: inputValue.trim() });
    }

    /** @description function to find surgeons
     *  @param  {}
     *  @memberof CareTeamContainer
     */
    findSurgeons = async () => {
        var errors = { ...this.state.errors }
        if (this.state.searchKey.trim() === '') {
            errors.searchKey = surgeonMapping.emptySearchBox;
            this.setState({ errors })
            return errors;
        } else {
            errors.searchKey = '';
            this.setState({ errors })
        }
        await this.getSearchResults(this.state.searchKey);
    }

    /** @description function to search surgeons by clicking on enter
    *  @param  {}
    *  @memberof CareTeamContainer
    */
    onEnterPress = (e) => {
        if (e.which === 13) {
            this.findSurgeons();
        }
    }

    /**
    * @description function to get surgeons list based on search inputs 
    * @param {*}
    * @memberof CareTeamContainer
    */
    getSearchResults = async (searchKey) => {
        this.setState({ loading: true, searchResults: '', searchResultsError: null })
        searchSurgeonsCareTeamService(searchKey, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false, searchResultsError: err });
            } else {
                this.setState({ loading: false, searchResults: result.data.user[0] ? result.data.user[0].careteamsurgeonlist : [], searchResultsError: null })
            }
        })
    }

    /**
     * @description function to open and close modal popup 
     * @param {*}
     * @memberof CareTeamContainer
     */
    enableAddSurgeonModal = (isShow) => {
        this.setState({ refreshSurgeonList: false });
        this.state.showAddSurgeonModal ? this.setState({ showAddSurgeonModal: false })
            : this.setState({ showAddSurgeonModal: true });
        this.clearSearch();
    }

    /**
     * @description function to open clear search results 
     * @param {*}
     * @memberof CareTeamContainer
     */
    clearSearch = () => {
        var errors = { ...this.state.errors }
        errors.searchKey = '';
        this.setState({ searchResults: '', searchKey: '', errors, searchResultsError: null })
    }

    /**
    * @description function to refresh surgeon list
    * @param {*}
    * @memberof CareTeamContainer
    */
    refreshDataList = (status) => {
        this.setState({ refreshSurgeonList: status });
    }

    render() {
        return (
            <CareTeamComponent
                sinceLastCaseUpload={this.state.sinceLastCaseUpload}
                handleInputChange={this.handleInputChange}
                findSurgeons={this.findSurgeons}
                formErrors={this.state.errors}
                searchResults={this.state.searchResults}
                enableAddSurgeonModal={this.enableAddSurgeonModal}
                showAddSurgeonModal={this.state.showAddSurgeonModal}
                isLoading={this.state.loading}
                refreshSurgeonList={this.state.refreshSurgeonList}
                refreshDataList={this.refreshDataList}
                onEnterPress={this.onEnterPress}
                reviewCaseCount={this.state.reviewCaseCount}
                lastCaseUploadError={this.state.lastCaseUploadError}
                reviewCaseCountError={this.state.reviewCaseCountError}
                searchResultsError={this.state.searchResultsError}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(CareTeamContainer);
