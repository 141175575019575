import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink,  } from "react-csv";
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

class ExportDataComponent extends Component {
  constructor() {
    super()
    this.exportRef = React.createRef();
  }

/**
   * @description function to handle export action 
   * @param {*}
   * @memberof CaseListComponent
   */
  handleExportAction = () => {
    /* istanbul ignore next  */
   setTimeout(() => {
      this.exportRef.current.link.click();
    }, 1000);
  }

  render() {
    const { exportToCsv, exportCaseHeader,  exportCaseData, isLoading, isExport, loggedInUserType, exportFileName, filterObj, errorMessage } = this.props;
    if(isExport) {
      this.handleExportAction();
    }
   
    return (
      <div className="position-relative">        
        { loggedInUserType.includes('surgeon')? <div className="export-btn">
        { errorMessage ? <ErrorMsgBlockComponent className="pt-2 m-0" errorObject={errorMessage} /> : '' }
        { isLoading ? <button className="btn btn-primary disabled">
          <span className="spinner-border spinner-border-sm"></span>
          &nbsp;Exporting...
        </button> : <button type="button" id="exportToExcel" className="btn btn-primary" onClick={(e) => exportToCsv(filterObj)}>Export to CSV</button> }
        {exportCaseData ? <CSVLink 
            headers={exportCaseHeader}
            data={exportCaseData} 
            filename={exportFileName}
            ref={this.exportRef}
            target="_blank" /> : null }
        </div> : null }        
      </div>
    );
  }
}
const mapStateToProps = (state) => (
  {
    state
  }
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(ExportDataComponent);
