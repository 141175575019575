import React, { Component } from 'react';
import { connect } from 'react-redux';
import SurgeonComponent from '../../components/Dashboard/Surgeon/Surgeon';
import { operativeProcedureTypes } from '../../helpers/constants';
import { errorMessages } from '../../helpers/messages';
import { getSurgeonCaseTypes } from '../../services/java/java-services';

class SurgeonContainer extends Component {
	constructor(props) {
		super(props);
		const selectedSurgeon = props.surgeons && props.surgeons.length ? props.surgeons.find(x => parseInt(x.id) === parseInt(props.surgeonId)) : null;
		this.state = {
			timeFrameValue: "30D", // default timeframe
			selectedTab: null,
			caseTypeFetchError: null,
			loading: false,
			selectedSurgeonId: props.surgeonId ? props.surgeonId : '',
			surgeonDetails: selectedSurgeon && Object.keys(selectedSurgeon).length ? selectedSurgeon : ''
		}
	}

	componentDidMount() {
		this.getCaseTypes();
	}

	/**
	* @description function to get case types for default tab selection
	* @memberof SurgeonContainer
	*/
	getCaseTypes = () => {
		const { surgeons,  surgeonId } = this.props;
		this.props.toggleLoading(true);
		var params = {};
		if (surgeonId) {
			const surgeon = surgeons.find(x => x.id.toString() === surgeonId.toString());
			if (surgeon) {
				params['surgeonname'] = surgeon.userName;
			}
		}
		getSurgeonCaseTypes(params, (err, res) => {
			this.props.toggleLoading(false);
			if (err) {
				this.setState({ 
					selectedTab: operativeProcedureTypes.TKA.text, 
					caseTypeFetchError: {
						errorCode: null,
						message: errorMessages.dashboard.caseTypeError
					} 
				});
			} else {
				if (res.data && res.data.casetype && res.data.casetype === operativeProcedureTypes.HIP.text) {
					this.setState({ selectedTab: operativeProcedureTypes.HIP.text, caseTypeFetchError: null });
				} else {
					this.setState({ selectedTab: operativeProcedureTypes.TKA.text, caseTypeFetchError: null });
				}
			}
		})
	}

	/**
	* @description function to handle change of tabs
	* @param tab Key of the selected tab
	* @memberof SurgeonContainer
	*/
	handleTabChange = (tab) => {
		this.setState({ selectedTab: tab });
	}

	/**
	* @description function to handle change of timeframe
	* @param {*}
	* @memberof SurgeonContainer
	*/
	handleTimeFrameChange = (e) => {
		this.setState({ timeFrameValue: e.target.value });
	}

	/**
	* @description function to get handle change of surgeon selection by RAR
	* @param {*}
	* @memberof SurgeonContainer
	*/
	handleSurgeonChange = (e) => {
		const surgeonDetails = this.props.surgeons && this.props.surgeons.length ? this.props.surgeons.find(x => parseInt(x.id) === parseInt(e.target.value)) : '';
		this.setState({
			surgeonId: e.target.value,
			surgeonDetails: surgeonDetails,
		});
	}

	render() {
		const { isRarView, surgeons } = this.props;
		return (
			<SurgeonComponent
				selectedTab={this.state.selectedTab}
				caseTypeFetchError={this.state.caseTypeFetchError}
				handleTabChange={this.handleTabChange}
				timeFrameValue={this.state.timeFrameValue}
				timeFrameChange={this.handleTimeFrameChange}
				isRarView={isRarView}
				surgeons={surgeons}
				surgeonId={this.state.selectedSurgeonId}
				surgeonDetails={this.state.surgeonDetails}
				surgeonSelectChange={this.handleSurgeonChange}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(SurgeonContainer);
