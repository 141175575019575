import React, { Component } from "react";
import { totalActivePlans } from "../../services/java/java-services";
import CustomerSupport from "../../components/Dashboard/CustomerSupport/CustomerSupport";
import { getUserName } from '../../services/aws/aws-services';

class CSDashContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			totalActivePlansCount: null,
			activePlansErr: null,
		};
	}

	componentDidMount() {
		this.getTotalActivePlansCount();
	}

	/**
	 * @description Function to fetch total active plans count
	 * @memberof CSDashContainer
	 */
	getTotalActivePlansCount = () => {
		this.setState({
			loading: true,
			totalActivePlansCount: null,
			activePlansErr: null,
		});
		let userName = getUserName();
		totalActivePlans(userName, (err, result) => {
			if (err) {
				this.setState({ loading: false, activePlansErr: err });
			} else {
				this.setState({
					loading: false,
					activePlansErr: null,
					totalActivePlansCount:
						result.data && !isNaN(parseInt(result.data.count))
							? result.data.count
							: null,
				});
			}
		});
	};

	render() {
		const { loading, activePlansErr, totalActivePlansCount } = this.state;
		return (
			<CustomerSupport
				loading={loading}
				activePlansErr={activePlansErr}
				totalActivePlansCount={totalActivePlansCount}
			/>
		);
	}
}



export default CSDashContainer;
