import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import RarDashboard from '../../containers/Dashboard/Rar';
import SalesRepDashboard from '../../containers/Dashboard/SalesRep';

class RarSalesRepDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: 'SalesRep',

        }
    }
    /**
 * @description function to handle tab changes
 * @param tab Eventkey of the tab clicked
 * @memberof RarSalesRepDashboard
 */
    /* istanbul ignore next  */
    handleTabChange = (tab) => {
        this.setState({ selectedTab: tab });
    }
    render() {
        const { selectedTab } = this.state;

        return (
            <div className=" data-list-tabs dashboard-container salesrep-dashboard">
                <Tabs defaultActiveKey={selectedTab} onSelect={this.handleTabChange} unmountOnExit={true} className="data-list-tabs">
                    <Tab eventKey="SalesRep" title="Pre-Op Plans">
                        <SalesRepDashboard />
                    </Tab>
                    <Tab eventKey="Rar" title="RI.INSIGHTS" >
                        <RarDashboard />
                    </Tab>
                </Tabs>
            </div>);
    }
}
const mapStateToProps = (state) => (
    {
        state
    }
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(RarSalesRepDashboard);
