import React, { Component } from 'react'
import ScreenshotsComponent from '../../components/CaseDetails/Screenshots';
import { userHasPermission } from '../../helpers/GlobalFunctions';
import { getAllScreenshots } from '../../services/java/java-services';

const PAGINATION_DEFAULTS = {
	page: 0,
	pageSize: 12,
	rangeFrom: 0,
	rangeTo: 12
}
export default class ScreenshotsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			caseID: props.caseID,
			page: PAGINATION_DEFAULTS.page,
			pageSize: PAGINATION_DEFAULTS.pageSize,
			rangeFrom: PAGINATION_DEFAULTS.rangeFrom,
			rangeTo: PAGINATION_DEFAULTS.rangeTo,
			images: null,
			totalImages: 0,
			error: null
		}
	}

	componentDidMount() {
		if (userHasPermission('screenshots')) {
			this.getAllImages(PAGINATION_DEFAULTS);
		}
	}

	/**
	 * @description Function to get the screenshots
	 * @param pageParams Start and end of pagination params
	 * @memberof ScreenshotsContainer
	 */
	getAllImages = (pageParams) => {
		const params = {
			caseId: this.props.caseID,
			rangefrom: pageParams.rangeFrom,
			rangeto: pageParams.rangeTo
		}
		this.setState({ loading: true });
		getAllScreenshots(params, (err, res) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ loading: false, error: err });
			} else {
				const allScreenshots = res.data && res.data.case && res.data.case.length ? res.data.case[0] : null;
				const screenshots = allScreenshots && allScreenshots.sceenshots ? allScreenshots.sceenshots : [];
				var images = [];
				screenshots.forEach(x => {
					images.push(`data:image/png;base64,${x}`);
				})

				this.setState({
					error: null,
					totalImages: res.data && res.data.response_metadata && res.data.response_metadata.total ? res.data.response_metadata.total : 0
				});
				setTimeout(() => {
					this.handleImagesArray(images);
				}, 0);
			}
		})
	}

	/**
	 * @description Function to handle screenshots and append them
	 * @param newImages New set of paginated images array
	 * @memberof ScreenshotsContainer
	 */
	handleImagesArray = (newImages) => {
		const { images } = this.state;
		let stateObj = {}
		if (images && images.length) {
			stateObj = {
				images: [
					...images,
					...newImages
				]
			}
		} else {
			stateObj = { images: newImages };
		}

		this.setState({
			loading: false,
			...stateObj
		})
	}

	/**
	 * @description Function to fetch next set of images on scrolling
	 * @param pageParams Start and end of pagination params
	 * @memberof ScreenshotsContainer
	 */
	fetchNextSet = (pageParams) => {
		const { images, totalImages } = this.state;
		if (images && images.length && images.length < totalImages) {
			this.setState({ ...pageParams });
			this.getAllImages(pageParams);
		}
	}

	render() {
		const { loading, pageSize, rangeFrom, rangeTo, error, images, totalImages } = this.state;
		const { selectedTab } = this.props;

		return (
			selectedTab === 'screenshots' ?
				<ScreenshotsComponent
					loading={loading}
					pageSize={pageSize}
					rangeFrom={rangeFrom}
					rangeTo={rangeTo}
					error={error}
					images={images}
					totalImages={totalImages}
					fetchNextSet={this.fetchNextSet}
				/>
				: ''
		)
	}
}
