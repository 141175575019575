import React, { Component } from 'react'
import CommentsHistoryContainer from '../../containers/CaseDetails/CommentsHistory';
import { notesMessages } from '../../helpers/messages';
import CustomModal from '../../shared/CustomModal';
import TextEditor from '../../shared/textEditor';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

export default class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHistoryModal: false
        }
    }

    toggleHistoryModal = () => {
        this.state.showHistoryModal ? this.setState({ showHistoryModal: false })
            : this.setState({ showHistoryModal: true });
    }

    render() {
        const { caseData, handleNoteSubmit, notesError, notesSaved, isReadOnlyNotes, handleNotesInputChange, getUserRoleType, height,caseId } = this.props;
        return (
            <div className="card">
            <div className="card-body">
                <h2 className="card-title p-0">Comments</h2>
                <div className="msg-wrap">
                    {notesSaved === true ? <div className="text-success"><p>{notesMessages.success}</p> </div> : ''}
                    {notesSaved === false ? <ErrorMsgBlockComponent errorObject={notesError} /> : ''}
                </div>
                <TextEditor
                    maxCharCount="2500"
                    height={height}
                    notesUpdatedBy={caseData && caseData.notesUpdatedBy ? caseData.notesUpdatedBy : ''}
                    notesUpdatedDate={caseData && caseData.notesUpdatedDate ? caseData.notesUpdatedDate : ''}
                    data={caseData && caseData.notes ? caseData.notes : ''}
                    saved={isReadOnlyNotes}
                    handleNotesInputChange={handleNotesInputChange}
                    submit={handleNoteSubmit}
                    isEditDisabled={getUserRoleType.includes('rar') ? true : false}
                />
                <div className="history-link">
                    <button onClick={this.toggleHistoryModal} className="btn btn-link px-0 fs-8-rem text-dark">View History</button>
                </div>
                <div className="comments-history-modal-wrap">
                    {/* Modal to add new license */}
                    <CustomModal
                        id="comments-history-modal"
                        dialogClassName="comments-history-dialog"
                        show={this.state.showHistoryModal}
                        isComponent={true}
                        title="Comments History"
                        closeAction={() => this.toggleHistoryModal()}>
                        <CommentsHistoryContainer caseId={caseId}/>
                    </CustomModal>
                </div>
            </div>
            </div>
        )
    }
}
