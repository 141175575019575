import React, { Component } from 'react';
import AddProms from './AddProms';
import ListProms from './ListProms';
import DisplayProms from './DisplayProms';
class PromScores extends Component {

	render() {
		const { addPromScore, handleInputChange, formErrors, promScoreSave, promsErrorMsg, isLoading, getPROMGraphError, operativeProcedure,
			caseId, outComeScoreData, handleOutcomeScoreType, refreshDataList, refreshOutcomeScore, surgeonUserName, getUserRoleType, onEnterPress, 
			disableReset, resetForm, isCustomScore, scoreType, scoreFields, subScores, scoreValue } = this.props;
		return (<div className="prom-score-wrap">
			<div className="row">
				<div className="col-md-6 pe-2">
					<AddProms
						operativeProcedure={operativeProcedure}
						handleInputChange={handleInputChange}
						addPromScore={addPromScore}
						promScoreSave={promScoreSave}
						promsErrorMsg={promsErrorMsg}
						formErrors={formErrors}
						isCustomScore={isCustomScore}
						scoreType={scoreType}
						scoreFields={scoreFields}
						subScores={subScores}
						scoreValue={scoreValue}
						getUserRoleType={getUserRoleType}
						onEnterPress={onEnterPress}
						disableReset={disableReset}
						resetForm={resetForm} />
				</div>
				<div className="col-md-6 ps-2">
					<DisplayProms 
						operativeProcedure={operativeProcedure} 
						refreshDataList={refreshDataList} 
						isLoading={isLoading} 
						outComeScoreData={outComeScoreData} 
						getPROMGraphError={getPROMGraphError} 
						handleOutcomeScoreType={handleOutcomeScoreType}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<ListProms
						operativeProcedure={operativeProcedure} 
						refreshDataList={refreshDataList} 
						caseId={caseId} 
						refreshOutcomeScore={refreshOutcomeScore} 
						surgeonUserName={surgeonUserName} 
					/>
				</div>
			</div>

		</div>);
	}
}
export default PromScores;
