import React, { Component } from 'react';
import { getSessionStorage, setSessionStorage } from '../../services/storage/storage-service';

export default class MultiSelectComponent extends Component {
    constructor(props) {
        super(props);

        const { row } = this.props;

        this.state = {
            selected: this.isRowSelected(row),
        };
    }

    componentDidMount() {
        window.addEventListener('storage', this.handleStorageChange);
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);
    }

    /**
     * @description Handle storage change event
     * @memberof MultiSelectComponent
     */

    handleStorageChange = () => {
        const { row } = this.props;
            this.setState({ selected: this.isRowSelected(row) });
    };

    /**
     * @description Handle checkbox change event
     * @param {Object} row - The row object
     * @memberof MultiSelectComponent
     */

    handleCheckboxChange = (row) => {
        const selectedRow = JSON.parse(getSessionStorage('selectedRow')) || [];
        const isSelected = this.isRowSelected(row);

        const updatedItems = isSelected ? this.removeRowFromSelection(row, selectedRow) : this.addRowToSelection(row, selectedRow);

        setSessionStorage('selectedRow', JSON.stringify(updatedItems));

        const storageChangeEvent = new Event('storage');
        window.dispatchEvent(storageChangeEvent);
    };

    /**
     * @description check if row is selected
     * @param {Object} row - The row object
     * @memberof MultiSelectComponent
     */
    isRowSelected = (row) => {
        const selectedRow = JSON.parse(getSessionStorage('selectedRow')) || [];
        const value = row.VisionaierId;
        return selectedRow.some((item) => item.VisionaierId === value);
    };

    /**
     * @description Handle row from remove selection
     * @param {Object} row - The row object
     * @memberof MultiSelectComponent
     */
    removeRowFromSelection = (row, selectedRow) => {
        const value = row.VisionaierId;
        return selectedRow.filter((item) => item.VisionaierId !== value);
    };

    /**
     * @description Handle add row to selected
     * @param {Object} row - The row object
     * @memberof MultiSelectComponent
     */
    addRowToSelection = (row, selectedRow) => {
        return [...selectedRow, row];
    };

    render() {
        const { row } = this.props;
        const { selected } = this.state;

        return (
            <span>
                <input
                    type='checkbox' className='form-check-input zipCheckbox' checked={selected} onChange={() => this.handleCheckboxChange(row)} />
            </span>
        );
    }
}
