import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddLicense from '../../components/CustomerDetails/AddLicense';
import { licenseMessages, loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { validateLicenseFields, validateAlphanumeric } from './ValidateCustomer';
import { saveLicenseDetails } from '../../services/java/java-services';
import { logger } from '../../services/logger/logger-service';
import { licenseTypes } from '../../helpers/constants';
let startDate;
class AddLicenseContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            durationObj: [
                { key: '1 year', value: '1 year' },
                { key: '2 years', value: '2 years' },
                { key: '3 years', value: '3 years' },
                { key: '4 years', value: '4 years' },
                { key: '5 years', value: '5 years' },
                { key: '6 years', value: '6 years' },
                { key: '7 years', value: '7 years' },
                { key: '8 years', value: '8 years' },
                { key: '9 years', value: '9 years' },
                { key: '10 years', value: '10 years' },
            ],
            licenseTypeObj: licenseTypes,
            licenseStartDate: '',
            duration: '',
            poNumber: '',
            showErrorMsg: '',
            showLicenseErr: '',
            isLoading: false,
            licenseType: '',
            errors: {
                licenseStartDate: '',
                duration: '',
                poNumber: '',
                licenseType: '',
            }
        }
    }
    componentDidMount() {
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof AddLicenseContainer
     */
    applicationLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.addNewLicense,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }


    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field name
     * @memberof AddLicenseContainer
     */
    handleInputChange = (field, value) => {
        this.setState({ showErrorMsg: '' });
        var errorMessage = value ? '' : licenseMessages[field]

        if (field === 'poNumber' && value) {
            const poNumberCheck = validateAlphanumeric(value);
            errorMessage = poNumberCheck.error;
        }
        if (field === 'poNumber' && value === '') {
            errorMessage = '';
        }

        this.setState(prevState => {
            return {
                [field]: value,
                errors: {
                    ...prevState.errors,
                    [field]: errorMessage
                }
            }
        });
    }

    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof AddLicenseContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveLicense();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof AddLicenseContainer
     */
    validateFields = () => {
        let { errorFlag, errors } = validateLicenseFields(this.state);
        if (!errorFlag && this.state.poNumber) {
            let poNumber = validateAlphanumeric(this.state.poNumber);
            errorFlag = poNumber.errorFlag;
        }
        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }
        return errorFlag;
    }

    /**
     * @description function to validate and save the license details
     * @param {*}
     * @memberof AddLicenseContainer
     */
    saveLicense = () => {
        this.setState({ showErrorMsg: '' });
        const formHasErrors = this.validateFields();
        if (!formHasErrors) {
            this.setState({ isLoading: true });
            const getLicenseDetails = {
                "startDate": this.state.licenseStartDate,
                "licenseDuration": this.state.duration,
                "po": this.state.poNumber,
                "customerId": this.props.customerId,
                "licenseType": this.state.licenseType,
            }
            saveLicenseDetails(getLicenseDetails, (err, result) => {
                /* istanbul ignore next  */
                if (err) {
                    this.setState({ showErrorMsg: err.message, isLoading: false, showLicenseErr: err });
                } else {
                    this.resetInputs();
                    this.props.refreshLicenseDataList(true);
                    this.props.cancelAction();
                    this.setState({ isLoading: false, showLicenseErr: null });

                }
            })
        }
    }
    /** @description function to clear inputs
     *  @param  {}
     *  @memberof AddLicenseContainer
     */
    resetInputs = () => {
        this.setState({ licenseStartDate: '', duration: '', poNumber: '' });
    }


    /** @description function to cancel license modal popup
     *  @param  {}
     *  @memberof AddLicenseContainer
     */
    cancelAction = () => {
        this.props.cancelAction();
    }

    render() {
        return (
            <AddLicense
                formErrors={this.state.errors}
                durationObj={this.state.durationObj}
                handleInputChange={this.handleInputChange}
                onEnterPress={this.onEnterPress}
                saveLicense={this.saveLicense}
                cancelAction={this.cancelAction}
                showErrorMsg={this.state.showErrorMsg}
                isLoading={this.state.isLoading}
                showLicenseErr={this.state.showLicenseErr}
                licenseTypeObj={this.state.licenseTypeObj} />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(AddLicenseContainer);