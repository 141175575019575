import React, { Component } from 'react';
import { connect } from 'react-redux';
import FilesListComponent from '../../components/FilesList/FilesList';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import {logger} from '../../services/logger/logger-service';
let startDate;
class FilesListContainer extends Component {
  constructor (){
    super()
    this.state = {
      loggerObj : {
        "EventOutcome": loggerEventOutcome.success,
        "EventType": loggerEventTypes.read,
        "EventName": loggerEventName.filesList,
          "StartDate": new Date()
      }
    }
  }
  componentDidMount() {
    // initialize the start date on page load
    startDate = new Date();
    window.addEventListener('beforeunload', this.applicationLogger);
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.applicationLogger);
    this.applicationLogger();
  }
  /**
   * @description function to handle application logs
   * @param {*}
   * @memberof FilesListContainer
   */
  applicationLogger(key) {
   // calculate the time since we loaded this page
   const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
   const loggerObj = {
        "EventOutcome": loggerEventOutcome.success,
        "EventType": loggerEventTypes.read,
        "EventName": loggerEventName.filesList,
        "Content":{
            "TimeSpent": timeSinceLoad
        }
    } 
  logger(loggerObj);
  }
  render() {
    return (
      <div >
        <FilesListComponent loggerObj={this.state.loggerObj}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state,
  };
}


export default connect(mapStateToProps)(FilesListContainer);
