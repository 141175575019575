import React, { Component } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import CustomerListTable from '../CustomerList/CustomerListTable';
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'
import SurgeonListTable from '../SurgeonList/SurgeonListTable';

export default class RARLookupComponent extends Component {
    render() {
        const { activeTab, handleTabSelect, handleCustomerSearchSubmit, handleCustomerReset, refreshCustomerListTable, customerSearchString, customerSearchSubmit, surgeonSearchSubmit, surgeonSearchString, refreshSurgeonListTable, handleSurgeonSearchSubmit, handleSurgeonReset } = this.props;
        return (
            <div>
                <Header />
                <div className="bgGray">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-1">
                                <SideMenu />
                            </div>
                            <div className="col-md-11">
                                <div className="surgeon-list data-list-tabs table-wrap px-3">
                                    <Tabs defaultActiveKey={activeTab} onSelect={handleTabSelect}>
                                        <Tab eventKey="surgeons" title="Surgeons">
                                            {activeTab === 'surgeons' ?
                                                <SurgeonListTable
                                                    handleSearchSubmit={handleSurgeonSearchSubmit}
                                                    handleReset={handleSurgeonReset}
                                                    refreshSurgeonListTable={refreshSurgeonListTable}
                                                    searchString={surgeonSearchString}
                                                    searchSubmit={surgeonSearchSubmit}
                                                /> : null}
                                        </Tab>
                                        <Tab eventKey="customers" title="Customers">
                                            {activeTab === 'customers' ?
                                                <CustomerListTable
                                                    handleSearchSubmit={handleCustomerSearchSubmit}
                                                    handleReset={handleCustomerReset}
                                                    refreshCustomerListTable={refreshCustomerListTable}
                                                    searchString={customerSearchString}
                                                    searchSubmit={customerSearchSubmit}
                                                /> : null}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
