import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Sidemenu from '../SideMenu/SideMenu';

class HomeComponent extends Component {
  render() {
    return (<div>
      <Header />
      <div className="bgGray">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-1">
                      <Sidemenu/>
                    </div>
                    <div className="col-md-11" >
                        <h1 className="sub-heading">Welcome to RI.Insights</h1>   
                    </div>
                  </div>    
                </div>
            </div>
      </div>);
  }
}
const mapStateToProps = (state) => (
  {
      state
  }
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
