import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddUserComponent from '../../components/UserDetails/AddUser'
import { addUserToGroupCognito, sendEmail, userSignUpCognito } from '../../services/aws/aws-services';
import { loggerEventName, loggerEventOutcome, loggerEventTypes, resetPasswordMessages, surgeonMessages, userMessages } from '../../helpers/messages';
import { validateAlphanumeric, validateEmail, validateUserFields, validateUsername } from './ValidateUser';
import { charset, getUserCreatedTemplate, sourceEmail, subjectLines } from '../../helpers/Email';
import { matchExpression, passwordRegexMatch, passwordStrength } from '../../helpers/PasswordValidate';
import { validateSurgeonID } from '../SurgeonDetails/ValidateSurgeon';
import { checkUniqueSurgeonIdService, getCountryList, saveUserService, getGenerateSurgeonIDs } from '../../services/java/java-services';
import { logger } from '../../services/logger/logger-service';
import { cognitoConstants, CustomErrorCodes, Roles } from '../../helpers/constants';
import { validateWhiteSpace } from '../../helpers/GlobalFunctions';
import { validateSalesRepEmail } from '../SalesRepDetails/ValidateSalesRep';

let startDate;
class AddUserContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showSuccessMsg: '',
            showEmailSuccessMsg: '',
            showErrorMsg: '',
            successMessage: '',
            pwdStrengthLabel: '',
            pwdStrengthProgress: '',
            pwdStrengthVarient: '',
            expMatch: {},
            cognitoUser: '',
            userSaveError: null,
            countries: null,
            countryError: null,
            isSurgeonIDDisabled: false,
            isMultipleRole: false,
            secondRoleArray: [],
            generateSurgeonIDError: null,
            userObj: {
                firstName: '',
                lastName: '',
                userName: '',
                email: '',
                role: '',
                secondRole: '',
                surgeonID: '',
                password: '',
                confirmPassword: '',
                country: '',
            },
            errors: {
                firstName: '',
                lastName: '',
                userName: '',
                email: '',
                role: '',
                secondRole: '',
                surgeonID: '',
                password: '',
                confirmPassword: '',
                country: '',
            }
        }
    }

    componentDidMount() {
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.timeSpentLogger);
        this.getCountryList();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
    }

    /**
     * @description function to handle application logs for time spent on the page
     * @param {*}
     * @memberof AddCustomerContainer
     */
    timeSpentLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.userCreate,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description Function to get the country list
     * @memberof AddUserContainer
     */
    getCountryList = async () => {
        getCountryList((err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ countryError: err });
            } else {
                const countries = result.data && result.data.countryList && result.data.countryList.length ? result.data.countryList : [];
                this.setState({
                    countries: countries,
                    countryError: null
                });
            }
        })
    }

    /**
     * @description Function to get the unique surgeon id
     * @memberof AddUserContainer
     */
    generateSurgeonID = async () => {
        this.setState({ loading: true });
        getGenerateSurgeonIDs((err, result) => {
            if (err) {
                this.setState({ loading: false, generateSurgeonIDError: err });
            } else {
                const uniqueSurgeonID = result.data && !isNaN(parseInt(result.data.globalSurgeonId)) ? result.data.globalSurgeonId : null
                this.setState(prevState => {
                    return {
                        loading: false,
                        surgeonID: uniqueSurgeonID,
                        isSurgeonIDDisabled: true,
                        generateSurgeonIDError: null,
                        userObj: {
                            ...prevState.userObj,
                            surgeonID: uniqueSurgeonID
                        },
                        errors: {
                            ...prevState.errors,
                            surgeonID: ''
                        }
                    }
                });
            }
        });
    }

    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field name
     * @memberof AddUserContainer
     */
    handleInputChange = (field, value) => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '', userSaveError: null });
        var { errors, userObj, isMultipleRole, secondRoleArray } = this.state;
        var errorMessage = !value ? userMessages[field] : '';
        if ((field === 'firstName' || field === 'lastName') && value) {
            const surgeonNameCheck = validateAlphanumeric(value);
            errorMessage = surgeonNameCheck.error;
        }
        if (field === 'email' && value) {
            // validate sales rep email
            if ((userObj.role && userObj.role === 'salesrep') || (userObj.secondRole && userObj.secondRole === 'salesrep')) {
                let validateSEmail = validateSalesRepEmail(value);
                errorMessage = validateSEmail.error;
            } else {
                const emailCheck = validateEmail(value);
                errorMessage = emailCheck.error;
            }
        }
        if (field === 'role' && value) {
            if (value === 'salesrep' && userObj.email) {
                let validateSalesRep = validateSalesRepEmail(userObj.email);
                errors.email = validateSalesRep.error;
            } else if (userObj.email) {
                const emailCheck = validateEmail(userObj.email);
                errors.email = emailCheck.error;
            }
        }

        if (field === 'secondRole' && value) {
            if (userObj.email && value === 'salesrep') {
                let validateSalesRep = validateSalesRepEmail(userObj.email);
                errors.email = validateSalesRep.error;
            } else if (userObj.email) {
                const emailCheck = validateEmail(userObj.email);
                errors.email = emailCheck.error;
            }
        } else if (field === 'secondRole' && value === 'undefined' && userObj.email) {//to handle validation, onClick of '-' sign of secondRole
            const emailCheck = validateEmail(userObj.email);
            errors.email = emailCheck.error;
        }

        if (field === 'userName' && value) {
            const userNameCheck = validateUsername(value);
            errorMessage = userNameCheck.error;
        }
        if (field === 'surgeonID' && value) {
            const surgeonIDCheck = validateSurgeonID(value);
            errorMessage = surgeonIDCheck.error;
        }

        if (field === 'role') {
            userObj.secondRole = '';
            errors.secondRole = '';
            isMultipleRole = false;
            secondRoleArray = [];
        }
        if (field === 'password') {
            if (validateWhiteSpace(value)) {
                errorMessage = userMessages.whiteSpace;
            }
            var strengthObj = passwordStrength(value, userObj.userName);
            this.setState({ ...strengthObj, expMatch: {} });

            if (!passwordRegexMatch(value, userObj.userName)) {
                errorMessage = '';
                errors.password = '';
                errors.confirmPassword = '';
                errors.passwordMatch = true;
            } else {
                errors.passwordMatch = '';

            }

            if (value === '') {
                this.setState({ pwdStrengthLabel: '' });
            }
        }

        if (field === 'confirmPassword') {
            errorMessage = '';
            errors.confirmPassword = '';
        }

        this.setState({
            isMultipleRole,
            secondRoleArray,
            [field]: value,
            userObj: {
                ...userObj,
                [field]: value
            },
            errors: {
                ...errors,
                [field]: errorMessage
            }
        });
    }

    /**
    * @description Function to handle blur event in reset password form
    * @memberof AddUserContainer
    */
    handleBlur = (field, value) => {
        this.handleInputChange(field, value);
        setTimeout(() => {
            if (field === 'password') {
                var matchResults = matchExpression(value, this.state.expMatch, this.state.userObj.userName);
                this.setState({ expMatch: matchResults });
            }
        }, 0);
    }

    /**
    * @description Function to handle multiple role selection enable/disable
    * @memberof AddUserContainer
    */
    handleMultipleRoleSelect = (value) => {
        //to handle validation onClick of '-' sign of secondRole
        this.handleInputChange('secondRole', 'undefined')
        const { userObj } = this.state;
        if (!value) {
            this.setState({
                isMultipleRole: false,
                secondRoleArray: [],
                userObj: {
                    ...userObj,
                    secondRole: ''
                }
            });
            return;
        } else if (value && this.state.isMultipleRole) {
            return;
        }
        var secondRoleArray = Roles.filter(x => x.secondRoleOption === userObj.role);
        this.setState({
            isMultipleRole: true,
            secondRoleArray,
            userObj: {
                ...userObj,
                secondRole: ''
            }
        });
    }

    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof AddUserContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveUser();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof AddUserContainer
     */
    validateFields = () => {
        const { userObj, isMultipleRole } = this.state;
        const userFields = ['firstName', 'lastName', 'email', 'userName', 'password', 'confirmPassword', 'role', 'country'];
        if (userObj.role && isMultipleRole) {
            userFields.push('secondRole');
        }

        let { errorFlag, errors } = validateUserFields({ editObj: userObj, errors: this.state.errors }, userFields);

        if (userObj.email && userObj.role && userObj.role === 'salesrep') {
            let validateSalesEmail = validateSalesRepEmail(userObj.email);
            if (validateSalesEmail.errorFlag) {
                errorFlag = true;
                errors['email'] = validateSalesEmail.error;
            }
        }

        if (userObj.email && userObj.secondRole && userObj.secondRole === 'salesrep') {
            let validateSalesEmail = validateSalesRepEmail(userObj.email);
            if (validateSalesEmail.errorFlag) {
                errorFlag = true;
                errors['email'] = validateSalesEmail.error;
            }
        }

        if (!errorFlag && userObj.firstName) {
            let firstName = validateAlphanumeric(userObj.firstName);
            errorFlag = firstName.errorFlag;
            errors['firstName'] = firstName.error;
        }
        if (!errorFlag && userObj.lastName) {
            let lastName = validateAlphanumeric(userObj.lastName);
            errorFlag = lastName.errorFlag;
            errors['lastName'] = lastName.error;
        }
        if (!errorFlag && userObj.email) {
            let email = validateEmail(userObj.email);
            errorFlag = email.errorFlag;
            errors['email'] = email.error;
        }
        if (!errorFlag && userObj.userName) {
            let userName = validateUsername(userObj.userName);
            errorFlag = userName.errorFlag;
            errors['userName'] = userName.error;
        }
        if (!errorFlag && userObj.password && userObj.confirmPassword) {
            this.handleBlur(userObj.password, 'password');
            if (!passwordRegexMatch(userObj.password, userObj.userName)) {
                errorFlag = true;
                errors["passwordMatch"] = true;
            }
            if (userObj.password !== userObj.confirmPassword) {
                errorFlag = true;
                errors["confirmPassword"] = resetPasswordMessages.error.notMatch;
            }
        }
        if (!errorFlag && userObj.role && isMultipleRole && !userObj.secondRole) {
            errorFlag = true;
            errors['secondRole'] = userMessages.secondRole;
        }

        if (!errorFlag && (userObj.role === 'Surgeon' || userObj.secondRole === 'Surgeon')) {
            if (!userObj.surgeonID) {
                errorFlag = true;
                errors['surgeonID'] = userMessages.surgeonID;
            } else {
                if (userObj.surgeonID.length !== 10) {
                    errorFlag = true;
                    errors['surgeonID'] = surgeonMessages.surgeonIDRegex;
                } else {
                    errorFlag = false;
                }
            }
        } else {
            errors['surgeonID'] = '';
        }

        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }

        return errorFlag;
    }

    /**
     * @description function to get cognito user attribute array
     * @param {*}
     * @memberof AddUserContainer
     */
    getCognitoUserAttributes = () => {
        const { userObj } = this.state;
        return [
            { Name: 'given_name', Value: userObj.firstName },
            { Name: 'family_name', Value: userObj.lastName },
            { Name: 'email', Value: userObj.email },
            { Name: 'email_verified', Value: "true" },
            { Name: 'custom:country', Value: userObj.country }
        ];
    }

    /**
     * @description function to validate and save user details
     * @param {*}
     * @memberof AddUserContainer
     */
    saveUser = () => {
        const { userObj } = this.state;
        this.setState({ showSuccessMsg: '', showErrorMsg: '' });
        const formHasErrors = this.validateFields();
        if (!formHasErrors) {
            this.setState({ loading: true });
            const userAttributes = this.getCognitoUserAttributes();
            if (userObj.role === 'Surgeon' || userObj.secondRole === 'Surgeon') {
                this.checkUniqueSurgeonId(userAttributes);
            } else {
                this.updateCognito(userAttributes);
            }
        }
    }

    /**
     * @description function to check if unique surgeon id is provided
     * @param {*}
     * @memberof AddUserContainer
     */
    checkUniqueSurgeonId = (userAttributes) => {
        let { userObj, errors } = this.state;
        checkUniqueSurgeonIdService(userObj.surgeonID, (err, res) => {
            if (err) {
                this.handleErrorWithLogs(err);
            } else {
                const isNotUniqueID = res?.data?.isSurgeonIdPresent;
                if (isNotUniqueID) {
                    errors['surgeonID'] = surgeonMessages.duplicateSurgeonID;
                    this.setState({ loading: false, errors });
                    this.handleErrorWithLogs({ errorCode: CustomErrorCodes.duplicateSurgeonID, message: errors['surgeonID'] });
                } else {
                    userAttributes.push({ Name: 'custom:surgeonId', Value: userObj.surgeonID });
                    this.updateCognito(userAttributes);
                }
            }
        });
    }

    /**
     * @description function to update cognito userpool
     * @param {*}
     * @memberof AddUserContainer
     */
    updateCognito = (updateAttributes) => {
        const { userObj } = this.state;
        userSignUpCognito(userObj.userName, userObj.password, updateAttributes)
            .then(res => {
                if (res && res.User) {
                    this.setState({ cognitoUser: res.User })
                    this.addUserToGroup();
                }
            })
            .catch(err => {
                let { errors } = this.state;
                const errObj = {
                    ...err,
                    errorCode: err.statusCode
                }
                if (err.code === cognitoConstants.exceptions.UsernameExistsException) {
                    errors['userName'] = userMessages.duplicateUserName;
                }
                this.setState({ errors });
                this.handleErrorWithLogs(errObj);
            });
    }

    /**
     * @description function to add user to group
     * @param {*}
     * @memberof AddUserContainer
     */
    addUserToGroup = () => {
        const { userObj, isMultipleRole } = this.state;
        var roles = [userObj.role];
        var allPromises = [];
        if (isMultipleRole && userObj.secondRole) {
            roles.push(userObj.secondRole);
        }
        roles.forEach(role => {
            allPromises.push(addUserToGroupCognito(userObj.userName, role));
        });

        Promise.all(allPromises).then(res => {
            if (res) {
                this.addUserInDB();
            }
        }).catch(err => {
            let { errors } = this.state;
            const errObj = {
                ...err,
                errorCode: err.statusCode
            }
            if (err.code === cognitoConstants.exceptions.ResourceNotFoundException) {
                errors['role'] = userMessages.roleNotFound;
            }
            this.setState({ errors });
            this.handleErrorWithLogs(errObj);
        });
    }

    /**
     * @description function to add user in db
     * @param {*}
     * @memberof AddUserContainer
     */
    addUserInDB = () => {
        const { userObj, cognitoUser } = this.state;
        var params = {
            username: cognitoUser.Username,
            firstname: userObj.firstName,
            lastname: userObj.lastName,
            email: userObj.email,
            userstatus: cognitoUser.UserStatus,
            usercreateddate: cognitoUser.UserCreateDate.toISOString(),
            group: userObj.role && userObj.secondRole ? [userObj.role, userObj.secondRole] : [userObj.role],
            country: userObj.country,
            enabled: true
        }

        if (userObj.role === 'Surgeon' || userObj.secondRole === 'Surgeon') {
            params['surgeonid'] = userObj.surgeonID
        }

        saveUserService(params, (err, res) => {
            if (err) {
                this.handleErrorWithLogs(err);
            } else {
                const loggerParams = {
                    outcome: loggerEventOutcome.success,
                    message: userMessages.userCreated
                }
                this.applicationLogger(loggerParams);
                this.sendEmailToUser();
            }
        });
    }

    /**
     * @description function to notify user of an email update
     * @param {*}
     * @memberof AddUserContainer
     */
    sendEmailToUser = () => {
        const { userObj } = this.state;
        const params = {
            Destination: {
                ToAddresses: [userObj.email]
            },
            Message: {
                Body: {
                    Html: {
                        Charset: charset,
                        Data: getUserCreatedTemplate(userObj)
                    }
                },
                Subject: {
                    Charset: charset,
                    Data: subjectLines.welcome
                }
            },
            Source: sourceEmail
        };
        sendEmail(params, (res) => {
            this.setState({
                loading: false,
                showSuccessMsg: userMessages.userCreated,
                showErrorMsg: '',
                showEmailSuccessMsg: userMessages.emailSent,
                userSaveError: null
            });
            this.props.userAdded(userObj.userName);
        })
    }

    /**
     * @description function to handle errors and log them
     * @memberof AddUserContainer
     */
    handleErrorWithLogs = (err) => {
        const errMsg = err && err.message ? err.message : userMessages.saveError;
        const params = {
            outcome: loggerEventOutcome.failure,
            message: errMsg
        }
        this.applicationLogger(params);
        this.setState({ loading: false, showErrorMsg: errMsg, userSaveError: err });
    }

    /**
     * @description function to log user creation event
     * @memberof AddUserContainer
     */
    /* istanbul ignore next  */
    applicationLogger = (params) => {
        const loggerObj = {
            "EventOutcome": params.outcome,
            "EventType": loggerEventTypes.create,
            "EventName": loggerEventName.userCreate,
            "Content": {
                "Data": params.message
            }
        }
        logger(loggerObj);
    }

    render() {
        return (
            <AddUserComponent
                loading={this.state.loading}
                userObj={this.state.userObj}
                countries={this.state.countries}
                userSaveError={this.state.userSaveError}
                pwdStrengthLabel={this.state.pwdStrengthLabel}
                pwdStrengthProgress={this.state.pwdStrengthProgress}
                pwdStrengthVarient={this.state.pwdStrengthVarient}
                pwdMatchObj={this.state.expMatch}
                resetPwdErrMsg={resetPasswordMessages.error.passwordErrors}
                handleInputChange={this.handleInputChange}
                handleBlur={this.handleBlur}
                onEnterPress={this.onEnterPress}
                showSuccessMsg={this.state.showSuccessMsg}
                showEmailSuccessMsg={this.state.showEmailSuccessMsg}
                showErrorMsg={this.state.showErrorMsg}
                formErrors={this.state.errors}
                isSurgeonIDDisabled={this.state.isSurgeonIDDisabled}
                generateSurgeonID={this.generateSurgeonID}
                isMultipleRole={this.state.isMultipleRole}
                secondRoleArray={this.state.secondRoleArray}
                handleMultipleRoleSelect={this.handleMultipleRoleSelect}
                saveUser={this.saveUser}
                handleCancel={this.props.handleCancel}
                generateSurgeonIDError={this.state.generateSurgeonIDError}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(AddUserContainer);