import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { formatDateTime,formatDate, userHasPermission } from '../../helpers/GlobalFunctions';
import { withRouter } from "react-router-dom";
import { graphContextMenuItems, operativeProcedureTypes } from '../../helpers/constants';
import { exportAddOns } from '../../helpers/ChartExports';

class AnalyticsBarChart extends Component {
   constructor() {
      super()
      this.state = {
         barChartWithHC: ''
      }
   }

   componentDidMount() {
      /* istanbul ignore next  */
      this.createChartWithHC();
   }

   /* istanbul ignore next  */
   componentWillUnmount() {
      this.setState({
         barChartWithHC: ''
      })
   }

   /**
    * @description function to get value from the obj based on key passed
    * @param {*}
    * @memberof AnalyticsBarChart
    */
   /* istanbul ignore next  */
   getKeyValue(obj, key) {
      let returnValue;
      obj.forEach(value => {
         if (key === value['name']) {
            returnValue = value['data'];
         }
      })
      return returnValue;
   }
   /* istanbul ignore next  */
   createChartWithHC() {
      const getTimingData = this.props.timingData ? this.props.timingData : [];
      var setTimingData = [];
      let miscValues = [];
      let setUpValues = [];
      var xAxis = Array.from({ length: getTimingData.caseInfo && getTimingData.caseInfo.length ? getTimingData.caseInfo.length : null }, (_, i) => i + 1);
      /* istanbul ignore next  */
      if (getTimingData.series && getTimingData.series.length) {
         getTimingData.series.forEach(element => {
            var dataArry = [];
   
            element.data.forEach(value => {
               // get misc values
               if (element.title === 'misc') {
                  miscValues.push(parseFloat(value) / 60);
               }
               // get setup values
               if (element.title === 'setup') {
                  setUpValues.push(parseFloat(value) / 60);
               }
               dataArry.push(parseFloat((parseFloat(value) / 60).toFixed(1))) // round up values with 1 decimal point
            });
            var keyMapping;
            switch (this.props.selectedProcedure) {
               case  operativeProcedureTypes.TKA.raw:
                  keyMapping = {
                     "cutting": "Cutting",
                     "planning": "Planning",
                     "postOp": "PostOp",
                     "registration": "Registration",
                     "setup": "Setup",
                     "misc": "Misc"
                  };
                  break;
               case  operativeProcedureTypes.HIP.raw:
                  keyMapping = {
                     "registration": "Registration",
                     "navigation": "Navigation"
                  };
                  break;
            
               default:
                  keyMapping = {
                     "cutting": "Cutting",
                     "planning": "Planning",
                     "postOp": "PostOp",
                     "registration": "Registration",
                     "setup": "Setup",
                     "misc": "Misc"
                  };
                  break;
            }
              
            let dataObj = {
               name: keyMapping[element.title],
               data: dataArry
            }
            if (element.title !== 'misc' && element.title !== 'setup') {
               setTimingData.push(dataObj);
            }
         });
      }
      // as per the requirement, Misc should be rolled into Setup
      let mergeSetupMiscValues = [];
      for (var j = 0; j < setUpValues.length; j++) {
         mergeSetupMiscValues.push(parseFloat((setUpValues[j] + miscValues[j]).toFixed(1)));
      }      
      // add setup key to setTimingData obj
      if (mergeSetupMiscValues.filter(x => !isNaN(x)).length) {
         let setUpObj = {
            name: 'Setup',
            data: mergeSetupMiscValues
         }
         setTimingData.push(setUpObj);
      }

      // rearrange timing data by surgery order
      let timingDataBySurgeryOrder = [];
      let surgeryOrderObj;
      let colors;
      switch (this.props.selectedProcedure) {
         case operativeProcedureTypes.TKA.raw:
            surgeryOrderObj = this.props.chartDetails.TKALegends;
            colors = this.props.chartDetails.TKAColors;
            break;
         case operativeProcedureTypes.HIP.raw:
            surgeryOrderObj = this.props.chartDetails.THALegends;
            colors = this.props.chartDetails.THAColors;
            break;
      
         default:
            surgeryOrderObj = this.props.chartDetails.TKALegends;
            colors = this.props.chartDetails.TKAColors;
            break;
      }

      /* istanbul ignore next  */
      surgeryOrderObj.forEach((key, k) => {
         if (setTimingData.find(x => x.name === key)) {
            let obj = {
               name: key,
               color: colors && colors[k] ? colors[k] : '#000',
               data: this.getKeyValue(setTimingData, key)
            }
            timingDataBySurgeryOrder.push(obj);
         }
      });

      // HIGHCHARTS VALUE INTERCEPTION FOR CUSTOM DATA PLOT
      // If the value is less than or equal to the minPointVal defined in the config, 
      // return the minPointVal to be plotted in the graph
      Highcharts.seriesTypes.column.prototype.modifyValue = function (val) {
         var minPointVal = this.options.minPointVal;
         if (minPointVal) {
            if (val >= 0 && val <= minPointVal) {
               return minPointVal;
            }
         }
         return val;
      };

      // Modify the values used in stacks to show a minimum representation of low value points. 
      Highcharts.wrap(Highcharts.Series.prototype, 'setStackedPoints', function (proceed) {
         var yData = this.processedYData,
            i = this.processedYData.length;
         while (i--) {
            if (this.modifyValue) {
               yData[i] = this.modifyValue(yData[i]);
            }
         }
         proceed.call(this);
      });


      /* istanbul ignore next  */
      var config = {
         exporting: {
            sourceWidth: 900,
            sourceHeight: 350,
            buttons: {
               contextButton: {
                  menuItems: graphContextMenuItems
               },
            },
            chartOptions: {
               chart: {
                  marginTop: 100,
                  marginBottom: 80,
                  events: {
                     load: function () {
                        if (this.options.chart.forExport) {
                           this.update({ chart: { spacingRight: 20 } })

                           if (this.xAxis && this.xAxis.length) {
                              this.xAxis[0].update({
                                 labels: {
                                    formatter: (event) => {
                                       return `<span>${event.value}</span>`;
                                    },
                                 }
                              });
                           }
                        }
                        exportAddOns(this);
                     }
                  }
               }
            }
         },
         chart: {
            type: 'column',
            spacingRight: 0,
            events: {
               load: function () {
                  this.reflow();
               },
               render: (event) => {
                  var chart = event.target;
                  var axis = chart.xAxis[0];
                  var ticks = axis.ticks;
                  var tickPositions = Object.keys(ticks);

                  if (ticks && tickPositions.length) {
                     tickPositions.forEach((pos, i) => {
                        if (ticks[i] && ticks[i].label) {
                           var label = ticks[i].label.element;
                           const caseInfo = getTimingData.caseInfo[i];
                           label.onclick = () => {
                              setTimeout(() => {
                                 this.individualCaseSummary(caseInfo);
                              }, 0);
                           }
                        }
                     });
                  }
               }
            }
         },
         title: {
            text: this.props.chartDetails ? this.props.chartDetails.graphTitle : ''
         },
         xAxis: {
            categories: xAxis,
            title: {
               text: this.props.chartDetails ? this.props.chartDetails.labelXTitle : ''
            },
            labels: {
               formatter: (event) => {
                  const caseInfo = getTimingData.caseInfo[event.value - 1];
                  var caseIDText = '';
                  switch (this.props.selectedProcedure) {
                     case operativeProcedureTypes.TKA.raw:
                        caseIDText = '<span class="tooltiptext">Case ID: ' + caseInfo.caseId;
                        break;
                     case operativeProcedureTypes.HIP.raw:
                        caseIDText = '<span class="tooltiptext">Patient ID: ' + caseInfo.patientId;
                        break;
                  
                     default:
                        caseIDText = '<span class="tooltiptext">Case ID: ' + caseInfo.caseId;
                        break;
                  }
                  return '<div class="graphs-label-tooltip label-link graphs-label-point' + event.value + '" > ' + event.value + caseIDText +
                     (userHasPermission('patientData') ? ('<br/>Patient Name: ' + (caseInfo.patientname ? caseInfo.patientname : 'N/A')) : '') +
                     '<br/>Surgery Date: ' + (this.props.selectedProcedure === operativeProcedureTypes.HIP.raw ? (caseInfo.dateOfSurgery ? formatDate(caseInfo.dateOfSurgery) : 'N/A') : caseInfo.dateOfSurgery ? formatDateTime(caseInfo.dateOfSurgery) : 'N/A') + '</span></div>';
               },
               useHTML: true,
            }
         },
         yAxis: {
            reversedStacks: false,
            min: 0,
            tickInterval: 25,
            title: {
               text: this.props.chartDetails ? this.props.chartDetails.labelYTitle : '',
               margin: 3
            },
            stackLabels: {
               enabled: true,
               formatter: function () {
                  const chart = this.axis.chart;
                  var sum = 0;
                  if (chart && chart.series && chart.series.length) {
                     const series = chart.series
                     series.forEach(serie => {
                        if (serie.visible && serie.points && serie.points.length) {
                           var point = serie.points[this.x];
                           sum += (point && point.y ? point.y : 0);
                        }
                     });
                  }
                  return parseFloat(sum.toFixed(1));
               }
            }
         },
         tooltip: {
            formatter: function () {
               return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: <b>' + this.y + '</b><br/>';
            }
         },
         legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            floating: false,
            backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
            borderWidth: 1,
            margin: 32
         },
         plotOptions: {
            column: {
               stacking: 'normal',
               cursor: 'pointer',
               minPointLength: 3,
               minPointVal: 3,
               borderWidth: 0.5,
               events: {
                  mouseOver: function () {
                     this.update({ opacity: 0.2 })
                  },
                  mouseOut: function () {
                     this.update({ opacity: 1 })
                  }
               },
               states: {
                  hover: {
                     animation: {
                        duration: 500
                     },
                     opacity: 1
                  },
                  inactive: {
                     animation: {
                        duration: 500
                     }
                  }
               },
               point: {
                  events: {
                     click: (event) => {
                        const caseInfo = getTimingData.caseInfo[event.point.category - 1];
                        this.individualCaseSummary(caseInfo);
                     }
                  }
               }
            }
         },
         series: timingDataBySurgeryOrder,
         responsive: {
            rules: [{
               condition: {
                  minWidth: 900,
                  maxWidth: 1200
               },
               chartOptions: {
                  yAxis: {
                     stackLabels: {
                        style: {
                           fontSize: '7px'
                        }
                     }
                  }
               }
            }]
         }
      };
      
      this.setState({ barChartWithHC: config })
   }

   /**
    * @description Function to redirect to case details page on data point and label click
    * @param caseInfo Details of the case clicked
    * @memberof AnalyticsBarChart
    */
   /* istanbul ignore next  */
   individualCaseSummary = (caseInfo) => {
      this.props.history.push({
         pathname: `/casedetails/${caseInfo.caseId}`,
         state: {
            id: caseInfo.caseId,
            surgeonName: caseInfo.surgeonname,
            activeTab: 'Trends'
         }
      })
   }

   render() {
      return (<div className="chart-blocks">
         {this.state.barChartWithHC ? <HighchartsReact
            highcharts={Highcharts}
            options={this.state.barChartWithHC}
            containerProps={{ style: { height: '400px' } }}
         /> : ''}
      </div>);
   }
}
export default withRouter(AnalyticsBarChart);
