import React, { Component } from 'react'
import AddDeviceComponent from '../../components/DeviceDetails/AddDevice'
import { deviceMessages } from '../../helpers/messages';
import { saveDeviceInfo, searchCustomersService } from '../../services/java/java-services';
import { validateAlphanumeric, validateDeviceFields } from './ValidateDevice';

export default class AddDeviceContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            deviceSaveError: null,
            customers: [],
            customerError: null,
            deviceObj: {
                slNo: '',
                customerID: props.customerid ? props.customerid:''
            },
            errors: {
                slNo: '',
                customerID: ''
            }
        }
    }

    componentDidMount() {
        this.getCustomerList();
    }

    /**
     * @description Function to get the customer list
     * @memberof AddDeviceContainer
     */
    getCustomerList = async () => {
        searchCustomersService({searchString: ''}, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ customerError: err });
            } else {
                const customers = result.data && result.data.customerList && result.data.customerList.length ? result.data.customerList : [];
                this.setState({
                    customers: customers,
                    customerError: null
                });
            }
        })
    }

    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field name
     * @memberof AddDeviceContainer
     */
    handleInputChange = (field, value) => {
        this.setState({ deviceSaveError: null });
        var { errors } = this.state;
        var errorMessage = !value ? deviceMessages[field] : '';
        if (field === 'slNo' && value) {
            if (value.length > 12) {
                errorMessage = deviceMessages.slNoLength;
            } else {
                const slNoCheck = validateAlphanumeric(value);
                errorMessage = slNoCheck.error;
            }
        }
        if (field === 'customerID' && !value) {
            errorMessage = deviceMessages.customerEmpty;
        }

        this.setState(prevState => {
            return {
                [field]: value,
                deviceObj: {
                    ...prevState.deviceObj,
                    [field]: value
                },
                errors: {
                    ...errors,
                    [field]: errorMessage
                }
            }
        });
    }

    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof AddDeviceContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveDevice();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof AddDeviceContainer
     */
    validateFields = () => {
        const { deviceObj } = this.state;
        const deviceFields = ['slNo', 'customerID'];
        let { errorFlag, errors } = validateDeviceFields({ editObj: deviceObj, errors: this.state.errors }, deviceFields);
        if (!errorFlag && deviceObj.slNo) {
            let slNo = validateAlphanumeric(deviceObj.slNo);
            errorFlag = slNo.errorFlag;
            errors['slNo'] = slNo.error;
        }

        if (!errorFlag && deviceObj.customerID) {
            errors['customerID'] = '';
        }


        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }

        return errorFlag;
    }

    /**
     * @description function to validate and save device details
     * @memberof AddDeviceContainer
     */
    saveDevice = () => {
        const formHasErrors = this.validateFields();
        if (!formHasErrors) {
            const { deviceObj } = this.state
            this.setState({ loading: true });
            const params = {
                serialno: deviceObj.slNo,
                customerid: deviceObj.customerID,
                status: "ACTIVE",
                addition: true
            }
            saveDeviceInfo(params, (err, result) => {
                this.setState({ loading: false });
                if (err) {
                    this.setState({ loading: false, deviceSaveError: err });
                } else {
                    this.props.deviceAdded(true);
                }
            })
        }
    }

    render() {
        const { loading, customers, deviceObj, errors, deviceSaveError} = this.state;
        const { handleCancel,isCustomerDisable } = this.props;
        return (
            <AddDeviceComponent
                loading={loading}
                customers={customers}
                deviceObj={deviceObj}
                handleInputChange={this.handleInputChange}
                onEnterPress={this.onEnterPress}
                formErrors={errors}
                saveDevice={this.saveDevice}
                deviceSaveError={deviceSaveError}
                handleCancel={handleCancel}
                isCustomerDisable={isCustomerDisable}
            />
        )
    }
}
