import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Sidemenu from '../../components/SideMenu/SideMenu';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import PreOpTableComponent from '../../components/ReviewPlanList/PreOpTableComponent';
import DownloadZip from '../../components/DownloadPreOpZip/DownloadZip';
import PreOpFilterContainer from '../PreOpCaseListFilter/PreOpFilterContainer';

class PreOpTableContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            refreshDataList: false,
            allStatus: false,
            searchSubmit: false,
            showFilterBlock: false,
            filterObj: {
                status: 1,
                customer: 'all',
                procedureType: 'all',
                searchString: '',
               
            },
        };
    }



    /**
     * @description function to handle the refresh the review plan list
     * @memberof PreOpTableContainer
     */
    refreshTable = () => {
        this.setState({ refreshDataList: true });
    };

    /**
     * @description function to handle filter data to show in table by adding filter in obj
     * @param obj:Obj object contains info for configured filter
     * @memberof PreOpTableContainer
     */
    getPrePFilterObj = (obj) => {
        this.setState({ filterObj: obj, refreshDataList: true });
    };



    /**
     * @description function to handle show all status pre op list
     * @param status : Boolean
     * @memberof PreOpTableContainer
     */
    handleShowAll = (status) => {
        this.setState({ allStatus: status });
    };


    /**
     * @description function to show / hide filter block
     * @param {*}
     * @memberof PreOpTableContainer
     */
    showFilter = () => {
        this.setState(prevState => ({
            showFilterBlock: !prevState.showFilterBlock,
            allStatus: false
        }));
    };


    render() {
        const { isPending } = this.props;

        return (
            <div>
                <Header />
                <div className='bgGray'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-1 sm-side-width'>
                                <Sidemenu loggerObj={this.props.loggerObj} />
                            </div>
                            <div className='col-md-11 sm-col-width'>
                                <div className='data-list-tabs caselist-tabs '>
                                    <ErrorBoundary>
                                        {!isPending && <PreOpFilterContainer id='pre-list-filter' setPreFilterObj={this.getPrePFilterObj} filterObj={this.state.filterObj} showFilterBlock={this.state.showFilterBlock} showFilter={this.showFilter} status={1} />}
                                    </ErrorBoundary>
                                    <div className='file-list p-3'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h1 className='file-list-heading ms-0 my-2 mx-0'>Pre-op Plans</h1>
                                                {!isPending && <div className='hide-column-on-mobile'><DownloadZip /></div>}
                                            </div>
                                        <ErrorBoundary>
                                            <PreOpTableComponent
                                                isUpdated={this.refreshTable}
                                                listType='review-plan-list'
                                                showPagination={true}
                                                filterObj={this.state.filterObj ? this.state.filterObj : ''}
                                                status={this.state?.allStatus}
                                                isPending={isPending}
                                                showFilterBlock={this.state.showFilterBlock}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default PreOpTableContainer;
