import React, { Component } from 'react';
import { isValidDate } from '../../helpers/GlobalFunctions';
import { salesRepMessages } from '../../helpers/messages';

export default class ExpirationTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
        };
    }

    /**
     * @description function to handle when hover on the date
     * @memberof `ExpirationTooltip`
     **/
    handleMouseEnter = () => {
        this.setState({ showTooltip: true });
    };

    /**
     * @description function to handle the close the popup when not hovering
     * @memberof `ExpirationTooltip`
     **/
    handleMouseLeave = () => {
        this.setState({ showTooltip: false });
    };

    render() {
        const { licenseExpiringInDays, licenseExpireDate } = this.props.row;
        const { showTooltip } = this.state;
        // Check if expired or not
        const isExpired = parseInt(licenseExpiringInDays) < 0;
        var expiryDateText = isValidDate(licenseExpireDate) ? licenseExpireDate : 'N/A';

        return (
            <div className='tooltip-container'>
                <span className={`tooltip-custom ${isExpired ? 'text-danger' : ''}`} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    {this.props?.hideToolTip ? (<span className='formatted-date'>{expiryDateText}</span>) : (<><span className='formatted-date'>{expiryDateText}</span> {showTooltip && isExpired && <span className='tooltip-text'>{salesRepMessages.dateofExpiration}</span>}</>)}
                </span>
            </div>
        );
    }
}
