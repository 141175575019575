import React, { Component } from 'react';
import { actions } from '../../helpers/messages';
import { isPositiveNumber } from '../../helpers/GlobalFunctions';
import { ProgressBar } from 'react-bootstrap';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import { PasswordCriteria, PasswordHints } from '../../shared/PasswordCriteria';
import { multipleRolesAllowed, Roles } from '../../helpers/constants';

export default class AddUserComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }

    /**
     * @description Function to disable edit mode while restting the form
     * @memberof UserDetailsComponent
     */
    /* istanbul ignore next  */
    cancelEdit = () => {
        this.myFormRef.reset();
        this.props.handleCancel();
    }

    /**
     * @description Function to make sure only numbers are input
     * @memberof UserDetailsComponent
     */
    /* istanbul ignore next  */
    checkNumeric = e => {
        const isValid = isPositiveNumber(e);
        if (!isValid) {
            e.preventDefault();
            return false;
        }

        if (e.keyCode === 13) {
            this.props.onEnterPress(e);
        }
    }

    render() {
        const { loading, userObj, handleInputChange, handleBlur, onEnterPress, showSuccessMsg, formErrors, countries,
            pwdStrengthLabel, pwdStrengthProgress, pwdStrengthVarient, pwdMatchObj, resetPwdErrMsg, saveUser, userSaveError,
            isSurgeonIDDisabled, generateSurgeonID, generateSurgeonIDError, isMultipleRole, secondRoleArray, handleMultipleRoleSelect } = this.props;

        return (
            <>
                {loading ? <div className="loading-overlay-upload add-surgeon-overlay"><span>{actions.load}</span></div> : ''}
                <div className="customer-fields surgeon-details"  data-testid="addUser">
                    <div className="add-user-container">
                        <div className="card-body p-0">
                            <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                                <div className="row">
                                    {showSuccessMsg ?
                                        <div className="col-sm-12">
                                            <div className="customer-save-success-msg">
                                                <p>{showSuccessMsg}</p>
                                            </div>
                                        </div>
                                        : ''}
                                    {userSaveError ?
                                        <div className="col-sm-12">
                                            <div className="customer-save-error-msg">
                                                <ErrorMsgBlockComponent className="pb-4 m-0" errorObject={userSaveError} />
                                            </div>
                                        </div>
                                        : ''}
                                    {generateSurgeonIDError ?
                                        <div className="col-sm-12">
                                            <div className="customer-save-error-msg">
                                                <ErrorMsgBlockComponent className="pb-4 m-0" errorObject={generateSurgeonIDError} />
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                                <fieldset className="customer-fields reset-password p-0">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="firstName" className="col-sm-4 col-form-label">First Name<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        id="firstName"
                                                        data-testid="firstName"
                                                        autoComplete="off"
                                                        className={`form-control ${formErrors?.firstName ? 'has-error' : ''}`}
                                                        value={userObj?.firstName || ''}
                                                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                                                    />
                                                    {formErrors?.firstName ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.firstName}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="lastName" className="col-sm-4 col-form-label shrink-label">Last Name<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        id="lastName"
                                                        data-testid="lastName"
                                                        autoComplete="off"
                                                        className={`form-control ${formErrors?.lastName ? 'has-error' : ''}`}
                                                        value={userObj?.lastName || ''}
                                                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                                                    />
                                                    {formErrors?.lastName ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.lastName}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="email" className="col-sm-4 col-form-label">Email<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        id="email"
                                                        data-testid="email"
                                                        autoComplete="off"
                                                        className={`form-control ${formErrors?.email ? 'has-error' : ''}`}
                                                        value={userObj?.email || ''}
                                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                                    />
                                                    {formErrors?.email ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.email}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="userName" className="col-sm-4 col-form-label shrink-label">Username<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="userName"
                                                        id="userName"
                                                        data-testid="userName"
                                                        autoComplete="off"
                                                        className={`form-control ${formErrors?.userName ? 'has-error' : ''}`}
                                                        value={userObj?.userName || ''}
                                                        onChange={(e) => handleInputChange('userName', e.target.value)}
                                                    />
                                                    {formErrors?.userName ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.userName}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="password" className="col-sm-4 col-form-label">Password<span className="red">*</span></label>
                                                <div className="col-sm-8 add-user-password-input">
                                                    <div className="reset-password-input-wrap new-password">
                                                        <input
                                                            type="password"
                                                            className={`form-control ${formErrors?.password ? 'has-error' : ''}`}
                                                            name="newPassword"
                                                            autoComplete="new-password"
                                                            value={userObj?.password || ''}
                                                            onChange={(e) => handleInputChange('password', e.target.value)}
                                                            onBlur={(e) => handleBlur('password', e.target.value)}
                                                            id="password" 
                                                            data-testid="password"/>
                                                        {/* Password Hint */}
                                                        <PasswordHints containerClass="add-user-password-hint" />
                                                        {formErrors?.password ?
                                                            <div className="customer-error-msg add-user-password-error-msg mb-2">
                                                                <p className="m-0">{formErrors.password}</p>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    {pwdStrengthLabel ?
                                                        <div className="new-password-errors mt-1">
                                                            <ProgressBar variant={pwdStrengthVarient} now={pwdStrengthProgress} label={pwdStrengthLabel} />
                                                        </div>
                                                        : null}
                                                    {formErrors?.passwordMatch ?
                                                        <div className="add-user-password-error-msg">
                                                            <PasswordCriteria pwdMatchObj={pwdMatchObj} errorMessages={resetPwdErrMsg} />
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="confirmPassword"className="col-sm-4 col-form-label shrink-label">Confirm Password<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <div className="reset-password-input-wrap confirm-password">
                                                        <input
                                                            type="password"
                                                            className={`form-control ${formErrors?.confirmPassword ? 'has-error' : ''}`}
                                                            name="confirmPassword"
                                                            autoComplete="confirm-password"
                                                            value={userObj?.confirmPassword || ''}
                                                            disabled={loading}
                                                            onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                                                            onKeyPress={onEnterPress}
                                                            id="confirmPassword"
                                                            data-testid="confirmPassword" />
                                                        {formErrors?.confirmPassword ?
                                                            <div className="customer-error-msg add-user-password-error-msg">
                                                                <p className="m-0">{formErrors.confirmPassword}</p>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="country" className="col-sm-4 col-form-label">Country<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <select
                                                        id="country"
                                                        data-testid="country"
                                                        defaultValue={userObj?.country || ''}
                                                        className={`form-select ${formErrors?.country ? 'has-error' : ''}`}
                                                        onChange={(e) => handleInputChange('country', e.target.value)}>
                                                        <option value="">Select a country</option>
                                                        {countries ? countries.map((c, i) => (
                                                            <option key={`country-${i}`} value={c.countryname}>{c.countryname}</option>
                                                        )) : ''}
                                                    </select>
                                                    {formErrors?.country ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.country}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="role" className="col-sm-4 col-form-label shrink-label">Role<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="role-select-wrap d-flex align-items-center cursor-pointer">
                                                                <select
                                                                    id="role"
                                                                    data-testid="role"
                                                                    defaultValue={userObj?.role || ''}
                                                                    className={`form-select ${formErrors?.role ? 'has-error' : ''}`}
                                                                    onChange={(e) => handleInputChange('role', e.target.value)}>
                                                                    <option value="">Select a role</option>
                                                                    {Roles.map(role => <option key={`option-${role.userRole}`} value={role.cognitoGroupName}>{role.label}</option>)}
                                                                </select>
                                                                {userObj?.role && multipleRolesAllowed.includes(userObj.role) ?
                                                                    <span data-testid="addNewRole" id="addNewRole" className={`add-role-icon ms-2 mb-1 ${isMultipleRole ? 'disabled-icon' : ''}`} title="Add Role" onClick={e => { e.preventDefault(); handleMultipleRoleSelect(true); }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                                        </svg>
                                                                    </span>
                                                                    : ''}
                                                            </div>
                                                            {formErrors?.role ?
                                                                <div className="customer-error-msg">
                                                                    <p>{formErrors.role}</p>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Multiple Role */}
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {userObj?.role === 'Surgeon' || userObj?.secondRole === 'Surgeon' ?
                                                <div className="form-group row">
                                                    <label htmlFor="surgeonID" className="col-sm-4 col-form-label">Surgeon ID<span className="red">*</span></label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="surgeonID"
                                                            id="surgeonID"
                                                            data-testid="surgeonID"
                                                            autoComplete="off"
                                                            maxLength="10"
                                                            disabled={isSurgeonIDDisabled}
                                                            className={`form-control ${formErrors && formErrors.surgeonID ? 'has-error' : ''}`}
                                                            value={userObj && userObj.surgeonID ? userObj.surgeonID : ''}
                                                            onKeyDown={e => this.checkNumeric(e)}
                                                            onChange={(e) => handleInputChange('surgeonID', e.target.value)}
                                                        />
                                                        <button type="button" className="btn btn-link btn-surgeon-unique-id" onClick={generateSurgeonID}>Generate Unique ID</button>
                                                        {formErrors && formErrors.surgeonID ?
                                                            <div className="customer-error-msg">
                                                                <p>{formErrors.surgeonID}</p>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                        {isMultipleRole ?
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <div className="col-sm-4"></div>
                                                    <div className="col-sm-8">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="role-select-wrap d-flex align-items-center cursor-pointer">
                                                                    <select
                                                                        id="secondRole"
                                                                        data-testid="secondRole"
                                                                        defaultValue={userObj?.secondRole || ''}
                                                                        className={`form-select ${formErrors && formErrors.secondRole ? 'has-error' : ''}`}
                                                                        onChange={(e) => handleInputChange('secondRole', e.target.value)}>
                                                                        <option value="">Select a role</option>
                                                                        {secondRoleArray.map(role => <option key={`option-${role.userRole}`} value={role.cognitoGroupName}>{role.label}</option>)}
                                                                    </select>
                                                                    <span data-testid="removeNewRole" id="removeNewRole" className="remove-role-icon ms-2 mb-1" title="Remove Role" onClick={e => { e.preventDefault(); handleMultipleRoleSelect(false); }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                                {formErrors && formErrors.secondRole ?
                                                                    <div className="customer-error-msg">
                                                                        <p>{formErrors.secondRole}</p>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 text-end">
                                        <button type="button" id="save-btn" className="btn btn-primary case-action-btn" onClick={saveUser}>Save</button>
                                        <button type="button" id="cancel-btn" className="btn btn-secondary me-0" onClick={this.cancelEdit}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
