import { getUserRoleType } from "../services/aws/aws-services"
import { capitalizeFirstLetter } from "./GlobalFunctions"

export const errorMessages = {
    // java
    1001: 'Authorization header not provided in the request.',
    1002: 'Invalid Authorization header value',
    1003: 'User not allowed to perform the action',
    2000: 'This request could not be processed at the server. Please contact administrator.',
    2001: 'Some mandatory data doesnot exists in DB',
    2002: 'Data could not be deleted due to server internal issue',
    2003: 'Data could not be saved due to server internal issue',
    2004: 'Data could not be Edited due to server internal issue',
    2005: 'Data could not be fetched due to server internal issue',
    2006: 'File upload got failed, please try later.',//'Consent not checked',
    2007: 'File upload got failed, please try later.', //'Invalid Field Report number',
    2008: 'File upload got failed, please try later.',// 'Field Report not checked',
    2009: 'A score has already been recorded for the specified time frame.',
    2010: 'Selected surgeon has already been added.',
    400: 'Bad request. Invalid syntax used for HTTP call.',
    401: 'Unauthorized. Authentication required to proceed.',
    403: 'Forbidden access. Permission denied.',
    404: 'Not found. Could not locate the requested resource.',
    405: 'Method not allowed. Request method not supported.',
    500: 'Internal server error. Please contact administrator.',
    502: 'Bad gateway. Please contact administrator.',
    503: 'Service unavailable. Please contact administrator.',
    504: 'Gateway timeout. Unable to get response in time.',
    505: 'The HTTP version used in the request is not supported by the server.',


    // cognito
    InternalErrorException: 'Internal error',
    NotAuthorizedException: 'Incorrect username or password.',
    PasswordAttemptsExceeded: 'Password attempts exceeded',
    PasswordAttemptsExceededMsg: 'Account is locked out. Please try again in a while.',
    TooManyRequestsException: 'Too many requests',
    UserNotFoundException: 'Invalid username or password',
    ResetPwdInvalidUserName: 'No user found with this username.',
    UserNotConfirmedException: 'User not confirmed',
    ResetPwdOtpExpired: 'Authentication code expired.',
    InvalidParameterException: 'User has not verified their email yet.',

    // S3
    AccessDenied: 'Access Denied',
    AccountProblem: 'Account Problem',
    InvalidBucketName: 'Invalid bucket name',
    NoSuchBucket: 'No such bucket',
    NoSuchBucketPolicy: 'No such bucket policy',
    NoSuchKey: 'No such key',
    DisabledUser: 'User is disabled.',
    DisabledUserCustom: 'The account is locked. Please contact the administrator for assistance.',

    // amplify
    missingConfig: 'Missing configuration',
    invalidParameter: 'Invalid parameter',

    //login page validation
    login: {
        username: 'Please enter Username',
        password: 'Please enter Password',
        bothEmpty: 'Please enter Username and Password',
        invalidCredentials: 'Invalid Username or Password',
        forgotPassward: 'Forgot password? ',
        logIn: 'Logging In...'
    },

    //dashboard
    dashboard: {
        unauthorizedUser: 'Unauthorized user, please contact your administrator.',
        caseTypeError: 'Unable to fetch case procedures. TKA is selected by default on dashboard.'
    },

    //file upload validation
    fileUpload: {
        note: 'Note: If you can’t find your facility you can select or type other',
        check1: 'By checking this box, I confirm that the files I am uploading originate from a clinical account under my responsibility, and that the conditions of their contract(s) with ',
        check2: 'Smith & Nephew allow for data to be shared with Smith & Nephew. I understand that I will be held accountable for any wrongful data upload that breaches the terms of the account’s contract.',
        fileName: 'Please select a zip file to upload',
        selectZip: 'Please select a zip file only',
        maxSizeAllowed: 'Upload not allowed for this file, which exceeds the file size limit of 5GB',
        duplicateFileName: 'A file with the same name has already been uploaded. Please upload with a different filename.',
        fieldReportTextBox: 'Please enter the Field Report Number',
        fieldReportStartWith: 'Field report number should start with C-',
        fieldReportMaxValue: 'Field Report Numbers should be all numbers and 7 characters long',
        disclaimerCheckBox: 'Please select the above checkbox to confirm file upload',
        facilitySelectBox: 'Please select the Facility',
        permissionDenied: 'Upload not allowed for this user role. Only RAR and Care Team users can upload a case file.'
    },
    sessionTimeOut: 'Your session is expired, please re-login again',
    noPermission: 'You do not have permission to access this page.',
    pageNotFound: 'The page you\'re looking for does not exist.',
    generic: 'Something went wrong. Please try again.',

    salesrep:{
       noResult:'No search results available for the selected filters.' 
    },
    customerSupport:{
        noPlanHistory:'No plan history found.'
    }
}

export const actions = {
    load: 'Loading...',
    request: 'Requesting...',
    download: 'Downloading...',
    upload: 'Uploading...',
    wait: 'Please wait...'
}
export const noDataFound = {
    noData: 'No data found'
}
export const outcomeScoreMessage = {
    noData: 'No PROMs Data Found'
}
export const successMessages = {
    fileUploadSuccess: 'File uploaded successfully.',
    fileUploadCanceled: 'File upload canceled.',
    customerSupportHistoryDetails:"Displayed Customer Support Plan History Details Successfully  ",
    custSupportCountDetails:"Displayed Customer Support Count Successfully"
}
export const promScoreMessages = {
    saveData: 'PROM score saved successfully',
    updateData: 'PROM score updated successfully',
    deleteData: 'PROM score deleted successfully',
    deleteAlert: 'Are you sure you want to delete this data?',
    scoreHelpText: 'Enter an interval score.',
    error: {
        scoreType: 'Please select a score type.',
        subScoreType: 'Please select a sub-score type.',
        timeFrame: 'Please select a time frame.',
        scoreValue: 'Please enter a valid score value.',
        scoreLimit: (limit) => `Please enter a score value between 0-${limit}.`,
        decimalValue: 'Please enter a valid score without decimal values.',
        negativeValue: 'Please enter a valid score without negative values.',
        EQ5DRegex: 'Please enter a score between 1-5',
        manualScoreOneRequired: 'Please enter at least one score value.',
        decimalValuePlaces: (places) => `Please enter a valid score with up to ${places} decimal places.`
    }
}

export const notesMessages = {
    success: 'Comments saved successfully',
    notesUpdate: 'Comments updated successfully',
    notesDelete: 'Comments deleted successfully'
}

export const caseFilterErrorMessages = {
    toLessThanFrom: 'Surgery To Date should be equal to or greater than Surgery From Date',
    fromGreaterThanCurrentYear: 'Surgery From Date should not be greater than Current Year',
    toGreaterThanCurrentYear: 'Surgery To Date should not be greater than Current Year',
    selectBoth: 'Please select both Surgery To Date and Surgery From Date',
}

export const downTimeSettingsMessages = {
    settingsHeader: 'Set the start and end time for the anticipated downtime for the application',
    toLessThanFrom: 'End time cannot be earlier than or equal to start time.',
    bothEarlierThanToday: 'Both start and end dates cannot be earlier than present time.',
    requireField: 'Please enter required fields.',
    success: 'Downtime information saved successfully.',
    dashboardBanner: (data) => `RI.INSIGHTS will be unavailable from ${data.starttime} to ${data.endtime} for maintenance.`
}
export const downtimeDashboardSuccessMessage = {
    successMessage: 'Date and time set successfully.',   
}

export const logFilterErrorMessages = {
    userName: 'Please enter only alphanumeric characters in username',
    eventName: 'Please enter only alphanumeric characters in eventname',
    caseId: 'Please enter only alphanumeric characters in caseid',
    userNameAndEventName: 'Please enter only alphanumeric characters in username and event name',
    userNameAndCaseId: 'Please enter only alphanumeric characters in username and case Id',
    caseIdAndEventName: 'Please enter only alphanumeric characters in case Id and event name',
    all3Fields: 'Please enter only alphanumeric characters in username, case Id and event name',
    toLessThanFrom: 'Event To Date should be equal to or greater than Event From Date',
    fromGreaterThanCurrentYear: 'Event From Date should not be greater than Current Year',
    toGreaterThanCurrentYear: 'Event To Date should not be greater than Current Year',
    selectBoth: 'Please select both Event To Date and Event From Date',
}

export const planningToolErrorMessages = {
    noFilterSelected: 'Implant and Patient Sex are mandatory fields.',
    implantMandatory: 'Implant is a mandatory field.',
    patientSexMandatory: 'Patient Sex is a mandatory field.',
    noResult: 'No results found',
    getPlanningToolResult: 'User done the activity on planning tool.'
}
export const salesRepMapping = {
    mappingSuccess: 'Customer association is created.',
    emptySearchBox: "Please enter customer's name or customer ID to search",
    removeCustomer: 'Customer removed successfully',
    removeAlert: 'Are you sure you want to remove this customer association?',
    approveSuccess: 'Request has been approved successfully.',
    revokeSuccess: 'Request has been revoked successfully.'
}
export const surgeonMapping = {
    mappingSuccess: 'Request has been sent to surgeon successfully.',
    emptySearchBox: "Please enter surgeon's name or surgeon ID to search",
    removeSurgeon: 'Surgeon removed successfully',
    removeAlert: 'Are you sure you want to remove this surgeon association?',
    approveSuccess: 'Request has been approved successfully.',
    revokeSuccess: 'Request has been revoked successfully.'
}
export const careteamMapping = {
    emptySearchBox: "Please enter care team's member name",
    removeCareteamMember: 'Care team member removed successfully',
    removeAlert: 'Are you sure you want to remove this care team member association?',
    approveSuccess: 'Request has been approved successfully.',
    revokeSuccess: 'Request has been revoked successfully.'
}
export const customerMapping = {
    mappingSuccess: 'Surgeon associated with customer successfully.',
    emptySearchBox: "Please enter customer's name or customer ID to search",
    removeSurgeon: 'Customer association removed successfully',
    removeAlert: 'Are you sure you want to remove this customer association?',
    mappingAlert: 'Are you sure you want to associate this surgeon with selected customer?',
    noMappedCustomers: 'No customer(s) associated.'
}

export const notificationMessages = {
    singleDismissed: 'Notification dismissed successfully.',
    allDismissed: 'All notifications dismissed successfully.',
    noResult: 'No notifications found'
}

export const trendsAlert = {
    info: 'Only the 30 oldest cases from the applied filter are being displayed. Please modify the filter parameters to display cases of interest.',
    noProcedureSelected: 'Please select a procedure type.'
}
export const exportToCsv = {
    success: 'Data exported successfully.'
}

export const resetPasswordMessages = {
    updatePassword: 'Password has been updated successfully.',
    resetPassword: 'Password has been reset successfully.',
    resetFailed: 'Unable to reset password. Please try again.',
    resetMsgWithUsername: (username) => `Password reset by admin for ${username}`,
    resetButAuthFailed: 'Password has been reset but unable to authenticate. Please log in again.',
    passwordHint: 'Password Rules',
    error: {
        username: 'Please enter your Username.',
        authCode: 'Please enter the authentication code.',
        oldPassword: 'Please enter your old password.',
        newPassword: 'Please enter your new password.',
        confirmPassword: 'Please confirm new password.',
        notMatch: "New password and confirm password don’t match.",
        getErrorMsg: (error) => `Password must have ${error.toLowerCase()}`,
        passwordErrors: {
            stringLength: 'Between 8 and 16 characters',
            containsLowerCase: 'At least one lowercase letter',
            containsUpperCase: 'At least one uppercase letter',
            containsNumber: 'At least one numeric digit',
            containsSpecialChr: 'At least one special character',
            numberRepeat: 'No more than two repeating numeric digits',
            numberSequence: 'No sequential numeric digits',
            numberSequenceReverse: 'No reverse sequential numeric digits',
            forbiddenWords: 'No common strings such as "password" etc.',
            containsUsername: 'No username contained in it'
        },
        inValidPass: "Please provide the valid current / old password."
    }
}

export const reviewCaseDetailsMessages = {
    noCustomer: 'Customer details is a mandatory field.',
    noSurgeon: 'Surgeon details is a mandatory field.',
    noSurgeonCustomer: 'Customer and Surgeon details are mandatory fields.',
    confirmSave: 'Are you sure you want to save this information?',
    detailsSaved: 'Review details saved successfully.',
    caseDiff: (diff, namesList) => {
        const caseText = (diff === 1) ? 'case was' : 'cases were';
        return `${diff} ${caseText} associated with ${namesList.join(' and ')}.`;
    },
    confirmInactiveAssociation: (params) => {
        let device = params && params.serialNo ? params.serialNo : 'Device';
        let customer = params && params.customerName ? params.customerName : 'the selected customer';
        return `${device} is currently inactive for ${customer}. <br /><br /> Are you sure you want to proceed?`
    }
}

export const fileUpload = {
    cancelConfirmationText: 'Are you sure you want to cancel the upload?',
}

export const exportTexts = {
    disclaimer: 'Disclaimer: This is a placeholder disclaimer.'
}

export const auditLogs = {
    refreshing: 'Refreshing audit logs...'
}

export const loggerEventTypes = {
    read: "READ",
    create: "CREATE",
    update: "UPDATE",
    delete: "DELETE",
    login: "LOGIN",
    logout: "LOGOUT",
    download:"Case Zip Download"
}
export const loggerEventOutcome = {
    success: "Success",
    failure: "Failure",
}
export const loggerEventName = {
    login: "LOGIN",
    planningTool: "Planning Tool",
    planningToolResults: "Planning Tool - Results",
    helpPage: "Help Page",
    userSettings: "User Settings",
    logout: "LOGOUT",
    caseSummeryTiming: "Case Summary Timing",
    caseList: "Case List",
    exportCsv: "Case List Csv Export",
    rarDashboard: "RAR Dashboard",
    surgeondashboard: "Surgeon dashboard",
    adminDashboard: "Admin dashboard",
    careteamDashboard: "Careteam Dashboard",
    salesrepDashboard: "Sales Rep Dashboard",
    rarsalesrepDashboard: "RAR and Sales Rep Dashboard",
    customersupportDashboard: "Customer Support Dashboard",
    filesList: "Files List",
    fileUpload: "File Upload",
    fileDetails: "File Details",
    notification: "Notification",
    reviewCaseList: "Review Case List",
    reviewCasedetails: "Review Case details",
    trendsPage: "Trends Page",
    caseSummaryReport: "Case Summary Report",
    planOwnerUpdate: "Plan Owner Update",
    addNotes: "Add Notes",
    addProms: "Add PROM Score Entry",
    updateProms: "Update PROM Score Entry",
    deleteProms: "DELETE PROM Score Entry",
    passwordReset: "Password Reset",
    surgeonList: "Surgeon List",
    removeSurgeon: "Remove surgeon from Rar's surgeon list",
    removeSurgeonFromCustomer: "Remove surgeon from customer's surgeon list",
    removeCareteamMemberFromCustomer: "Remove care team member from customer's careteam list",
    removeCustomerFromSurgeon: "Remove customer from surgeon's customer list",
    addSurgeon: "Rar association request to surgeon",
    dataSharing: "Data Sharing Requests",
    dataSharingAction: "Data Sharing Requests - Approve / Revoke",
    dismissNotification: "Dismiss Notification",
    customerCreate: "Customer Create",
    customerUpdate: "Customer Update",
    customerListExport: "Export Customer List",
    customerList: "Customer List",
    customerDetails: "View Customer Details",
    surgeonToCustomerMap: "Surgeon To Customer Map",
    careTeamToCustomerMap: "CareTeam To Customer Map",
    addNewLicense: "Add License",
    surgeonListExport: "Export Surgeon List",
    associateSurgeonToCustomer: "Customer List for Surgeon",
    updatePasswordAdmin: "Password Reset By Admin",
    userCreate: "User Create",
    userUpdate: "User Update",
    userDetails: "User Details",
    surgeonUpdate: "Surgeon Update",
    surgeonDetails: "Surgeon Details",
    userStatus: "User Status",
    commentsHistory: "Comments History Details",
    licenseexpirationlist: () => `${getUserRoleType()}'s License Expiration List`, 
    reviewPlanList: "Review Pre-Op Plan List",
    updateReviewPlanList: "Update Review Pre-Op Plan List",
    removeSalesrepCustomerAssociation: "Remove customer",
    searchForCustomer: "Search for customer",
    customerMaptoSalesrep: 'Customer Assigned',
    pendingPreOpList: "Pending Pre-Op Case List",
    preOpList: "Pre-Op Case List",
    downloadZip: "Download",
    planDetailView: "Plan Detail",
    customerSupportHistoryList:"View customer support history plan list",
    custSupportCount:"View customer support count",
    updateCommentinCustomerSupport:"Update customer support comment",
    custSupportPlanDetailView: (status) => `Customer support ${status} plan details`,
    customerPreOpLastSyncAction: "Pre-op last sync",
    customerSupportHistoryDetails:'Displayed customer support history details',
    triggerSync:'Triggered pre-op sync',
}
export const loggerEventMessage = {
    logout: "Logout successful.",
    login: "Login successful.",
    dashboardView: () =>`Displayed ${getUserRoleType()}'s dashboard successfully.`,
    surgeonList: "Displayed surgeon list successfully.",
    licenseexpirationlist: () => `Displayed ${getUserRoleType()}'s license expiration list successfully.`, 
    customerSupportHistoryDetails: "Displayed history details successfully.",
    userUpdated: (username) => `Details updated for ${username}.`,
    userStatusUpdated: (user) => `Status updated to ${user.enabled ? 'active' : 'inactive'} for ${user.userName}.`,
    reviewPlanListUpdated: (value,type) => `${type === 'hospitalId' ? 'Hospital association':'Plan Status'} ${type === 'hospitalId' ? 'updated to':'changed to'} ${capitalizeFirstLetter(value)}.`, 
    removeCustomer:(custId)=> `Removed customer ${custId} from sales rep customer list.`,
    searchForCustomer:(searchKey)=> `User searched for ${searchKey} keyword for the customers.`,
    customerMaptoSalesrep:(customerId,salesRepName)=> `Customer ${customerId} mapped to the sales rep(${salesRepName}).`,
    pendingPreOpList: "Displayed pending pre-Op Case list successfully.",
    preOpList: "Displayed Pre-Op Case list successfully.",
    preOpListAdmin: "Displayed pre-op case list successfully in admin dashboard.",
    downloadZip:(custId) => `Downloaded ${custId}.zip.`,
    planDetailView:()=> `Displayed additional plan details including PHI to user.`,
    custSupportPlanDetailView:(status)=> `Displayed additional plan details including PHI to user.`,
    customerSupportHistoryDetail: "Customer support history details.",
    commentUpdated:"Customer support comment updated successfully.",
    customerPreopLastSyncDetails: "Customer support pre-op last sync details displayed successfully.",
    triggerSync: "Sync triggered for pre-op list. ",
    unassignMessage:(planId)=>`Pre-op Plan (${planId}) Hospital Association is removed.`,
    planOwnerUpdate:(username)=>`New owner ${username} assigned.`
}

export const customerMessages = {
    customername: 'Please enter the Customer Name.',
    addressline1: 'Please enter the Address Line 1',
    addressline2: 'Please enter the Address Line 2',
    postalcode: 'Please enter the Postal Code',
    country: 'Please select the Country',
    state: 'Please select the State',
    countrycode: 'Please select the Country Code',
    phonenumber: 'Please enter the Phone No',
    phoneRegex: 'Please enter a valid phone number',
    countrydialcode: 'Please select the Country Dial Code',
    duplicateCustomerName: 'Given Customer Name already exists.',
    duplicateAddress: 'Given Address already exists.',
    duplicatePhoneNumber: 'Given Phone Number already exists.',
    duplicateProfile: 'A profile already exists for this customer. This profile is not saved.',
    saveCustomerMsg: 'Customer details saved successfully.',
    alphaNumericRegex: 'Please enter letters and numbers only',
    whiteSpace: 'Input cannot start with white space',
    surgeonMapSuccess: 'Customer mapped with surgeon successfully.',
    confirmMapping: 'Are you sure you want to proceed?',
    requestedBy:'Please Select Requestor',
    comment:'Please write a comment'
}
export const careTeamMessages = {
    careTeamMapSuccess: 'Customer mapped with careteam successfully.',

}

export const surgeonMessages = {
    firstName: 'Please enter a first name',
    lastName: 'Please enter a last name',
    email: 'Please enter an email',
    surgeonID: 'Please enter a surgeon ID',
    alphaNumericRegex: 'Please enter letters, numbers and hyphen only',
    emailRegex: 'Please enter a valid email',
    surgeonIDRegex: 'Please enter a 10 digit numeric value',
    duplicateEmail: 'Email provided already exists in the system.',
    duplicateSurgeonID: 'Surgeon ID provided already exists in the system.',
    saveSurgeonMsg: 'Surgeon details saved successfully.',
    saveError: 'Surgeon details could not be saved.'
}
export const TMVMessage = {
    savedinfo: 'Preference saved successfully',
   
}
export const userMessages = {
    firstName: 'Please enter a first name',
    lastName: 'Please enter a last name',
    email: 'Please enter an email',
    role: 'Please enter a role',
    secondRole: 'Please enter a role',
    userName: 'Please enter a username',
    country: 'Please select a country',
    alphaNumericRegex: 'Please enter letters, numbers and hyphen only',
    whiteSpace: 'Input cannot start with white space',
    userNameRegex: 'Please enter letters and numbers only, without spaces',
    emailRegex: 'Please enter a valid email',
    duplicateEmail: 'Email provided already exists in the system.',
    saveUserMsg: 'User details saved successfully.',
    password: 'Please create a password',
    confirmPassword: 'Please confirm your password',
    surgeonID: 'Please enter a surgeon ID',
    emailSent: 'An email has been sent to the user\'s Email ID.',
    userCreated: 'User created successfully.',
    saveError: 'User details could not be saved.',
    duplicateUserName: 'Username provided already exists in the system.',
    roleNotFound: 'User role provided does not exist in the system.',
    statusChanged: (status) => `The current status of the user is changed to ${status ? 'active and password is reset' : 'inactive'}.`,
    statusConfirmation: (status) => {
        if (status) {
            return 'Are you sure you want to reactivate this user?'
        } else {
            return `Deactivating the user will prevent this user from gaining access to their account. <br /><br />Are you sure you want to deactivate the user?`
        }
    },
    showEmailSuccessMsg: 'Email sent successfully.',
    invalidSalesRepEmailDomain: 'Enter valid Email ID. Email should be example@smith-nephew.com'
}

export const deviceMessages = {
    whiteSpace: 'Serial number cannot start with spaces.',
    slNo: 'Please enter a serial number.',
    slNoAlphaNumeric: 'Please enter only alphanumeric characters.',
    slNoLength: 'Serial number must be between 3 and 12 characters long.',
    customerID: 'Please select a customer.',
    deviceSaved: 'New device saved successfully.',
    saveError: 'Device details could not be saved.',
    statusConfirmation: (status) => {
        if (status && status.toString().toLowerCase() === 'active') {
            return `Deactivating the device will mark new incoming cases from this device for manual review. <br /><br />Are you sure you want to deactivate the device?`
        } else {
            return 'Are you sure you want to reactivate this device?'
        }
    },
    statusChanged: 'Device status changed successfully.'
}

export const licenseMessages = {
    licenseStartDate: 'Please select Start Date.',
    duration: 'Please select a Duration',
    poNumber: 'Please enter PO number',
    alphaNumericRegex: 'Please enter letters and numbers only',
    saveLicenseMsg: 'License details has been saved successfully.',
    licenseType: 'Please select License Type'
}

export const contactForm = {
    subject: 'Please enter subject.',
    editorData: 'Please enter your concern or comments.',
    feedbackSend: 'Feedback sent successfully.',
    wordsExceeded: (maxWordCount) => {
        return `You have exceeded the maximum limit of ${maxWordCount} words.`
    }
}

export const gapMessages = {
    noSelection: 'Please select at least one option to view post-op gap details graph'
}


export const salesRepMessages ={
    downloadErrorEmptyCust : 'Plan must be assigned to the customer to be able to download the zip.',
    downloadErrorOne: `Downloading multiple files is only supported for files assigned to the same customer.`,
    downloadErrorTwo: `Please confirm 'Hospital Association' field is correct for the selected files or deselect files associated with a different customer prior to download.`,    
    noPlans: 'There is no plan selected for download',
    searchError: 'Please enter at least 3 characters',
    passwordError: 'Please enter 6 digits',
    dateofExpiration:'License is expired. New plans cannot be assigned.',
    searchText:'Patient Name, Plan ID or Surgeon Name',
    hospitalUpdateMessage: (id, text) => `(${id}) Hospital Association updated to ${capitalizeFirstLetter(text)}.`,
    statusUpdateMessage: (id, text) => `(${id}) Status changed to ${text}.`,
    unAssignMessage: (id) => `(${id}) Hospital Association is removed.`,
    salesRepUpdateMessage: (id, text) => `(${id}) New owner ${text} assigned`,

}
