import React, { Component } from 'react';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes} from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { updateReviewPlanCustomerService, updateSalesRepCustomerService } from '../../services/java/java-services';
import UpdateAssociation from '../../components/CustomerSupportPreopList/UpdateAssociation';
import { eventSource } from '../../helpers/constants';

class UpdateCSContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    /**
     * @description Function to update review table's data in backend DB for customer assigned to hospital and status
     * @memberof UpdateCSContainer
     * @param {Object} params - Object containing parameters for the update operation
     * @param {string} params.value - The value to update
     * @param {string} params.id - The ID of the review to update
     * @param {string} params.type - The type of update to perform ("currentStatus" or "customerId")
     * @param {string} params.selectedOptionText - The text of the selected option in the update dropdown
     */
    updateReviewDataInDB = ({ requestedBy, value, id, type, selectedOptionText, salesrepName, status,oldSalesrepUsername }) => {
        // Create an object with the update parameters for logging and passing to other functions
        const reviewUpdateObj = {
            type,
            value,
            id: `${id}`,
            selectedOptionText,
            salesrepName
        };
        const ApiParams = {
            caseNumbers: [reviewUpdateObj.id], 
            customerId: value,
            salesrepName
        }
        // Show the loader while the update is in progress
        this.props.updateLoader(true);
            // Api for updating customer association for customer support
        if(type === 'hospitalId') {
            this.updateReviewPlan(ApiParams,reviewUpdateObj,selectedOptionText,type)
        } else if (type  === 'salesRep') {
            const payload ={
                "preopPlanId": id,
                "status": status,
                "newSalesrepUsername":value,
                "oldSalesrepUsername":oldSalesrepUsername,
                requestedBy: requestedBy
            }
            this.updateSalesRep(payload,selectedOptionText,reviewUpdateObj)
        }
          
    };


    updateSalesRep = (ApiParams,selectedOptionText,reviewUpdateObj) => {
        updateSalesRepCustomerService(ApiParams, (err, res) => {
            if (err) {
                // Update the error message if there was an error
                this.props.updateError(err);
                // Hide the loader once the update is complete
                this.props.updateLoader(false);
            } else {
                if (res.status === 200) {
                    this.props.reviewDataUpdated(reviewUpdateObj);
                    this.applicationLogger(ApiParams, selectedOptionText)
                    this.props.updateLoader(false);
                }
            }
        });
    };

    applicationLogger(apiParams, username) {
        const loggerObj = {
            "EventSource": eventSource.kpopPlans.value,
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.update,
            "EventName": loggerEventName.planOwnerUpdate,
            "Content": {
                "CaseId": apiParams.preopPlanId,
                "Data": loggerEventMessage.planOwnerUpdate(username)
            }
        }
        logger(loggerObj);
    }


    updateReviewPlan = (ApiParams,reviewUpdateObj,selectedOptionText,type) => {
        updateReviewPlanCustomerService(ApiParams, (err, res) => {
            if (err) {
                // Update the error message if there was an error
                this.props.updateError(err);
                // Hide the loader once the update is complete
                this.props.updateLoader(false);
            } else {
                if (res.status === 200) {
          
                    // Update the state and log the update if the update was successful
                    this.props.isUpdated(true);
                    let logMessage = loggerEventMessage.reviewPlanListUpdated(selectedOptionText, type);
                    if(selectedOptionText === 'Select Any Customer'){
                        logMessage = loggerEventMessage.unassignMessage(reviewUpdateObj.id)
                    }
                    setTimeout(() => {
                        this.props.reviewDataUpdated(reviewUpdateObj);
                        this.reviewDataUpdateLogger({
                            outcome: loggerEventOutcome.success,
                            message: logMessage
                        });
                        // Hide the loader once the update is complete
                        this.props.updateLoader(false);
                    }, 600);
                }
            }
        });
    }

    /**
     * @description Function to handle errors
     * @param error Error object with error code and message
     * @memberof UpdateCSContainer
     */
    handleError = (error) => {
        this.setState({ error: error });
        this.reviewDataUpdateLogger({ outcome: loggerEventOutcome.failure, message: error.message });
        this.props.updateError(error);
    };

    /**
     * @description function to log for review table dropdown update event(Hospital association)
     * @param params Params with outcome and message
     * @memberof UpdateCSContainer
     */
    reviewDataUpdateLogger = (params) => {
            const loggerObj = {
            EventSource: eventSource.kpopPlans.value,
            EventOutcome: params.outcome,
            EventType: loggerEventTypes.update,
            EventName: loggerEventName.updateReviewPlanList,
            Content: {
                Data: params.message,
                CaseId:this.props.row.VisionaierId.toString()
            },
        };
        logger(loggerObj);
    };

    render() {
        const { row, type, salesRepList } = this.props;
        return (
            <div>
                <UpdateAssociation salesRepList={salesRepList} row={row} updateReviewDataInDB={this.updateReviewDataInDB} type={type} />
            </div>
        );
    }
}

export default UpdateCSContainer