import parse from 'html-react-parser';
import React from 'react';
import { getUserRoleType } from '../services/aws/aws-services';
import { checkLocalStorage } from '../services/storage/storage-service';
import { licenseTypes, RolePermission } from './constants';

/**
 * @description Function to check if a number fall in a range
 * @param num Number to be checked
 * @param min Min value in the range
 * @param max Max value in the range
 * @returns Boolean. True if num lies between min and max
 */
export const between = (num, min, max) => {
	return num >= min && num < max;
}

/**
 * @description Function to check if a number fall in a hard range 
 * @description (at most, equal to both the extremes)
 * @param num Number to be checked
 * @param min Min value in the range
 * @param max Max value in the range
 * @returns Boolean. True if num lies between min and max
 */
export const hardBetween = (num, min, max) => {
	return num >= min && num <= max;
}

/**
 * @description Function to format exponential value to decimal
 * @param number Floating point value in exponential format
 */
/* istanbul ignore next  */
export const convertExponentialToDecimal = (number) => {
	const exponentialNumber = parseFloat(number);
	const str = exponentialNumber.toString();
	if (str.indexOf('e') !== -1) {
		const result = parseFloat((Math.round((exponentialNumber)).toFixed(30))).toFixed(2);
		return Math.abs(Math.round(result));
	} else {
		return exponentialNumber;
	}
}

/**
 * @description Function to format and breakdown date objects
 * @param date JS Date object
 * @returns Object with date time attributes broken down
 */
/* istanbul ignore next  */
export const breakDownDateFormat = (date) => {
	let newDate = date ? new Date(date) : new Date();
	let d = newDate.getDate();
	let m = newDate.getMonth() + 1;
	let yyyy = newDate.getFullYear();
	let dd = d > 9 ? d.toString() : '0' + d.toString();
	let mm = m > 9 ? m.toString() : '0' + m.toString();
	let hr = newDate.getHours() > 9 ? newDate.getHours().toString() : '0' + newDate.getHours().toString();
	let min = newDate.getMinutes() > 9 ? newDate.getMinutes().toString() : '0' + newDate.getMinutes().toString();
	let sec = newDate.getSeconds() > 9 ? newDate.getSeconds().toString() : '0' + newDate.getSeconds().toString();

	return {
		date: dd,
		month: mm,
		fullYear: yyyy,
		hour: hr,
		min: min,
		sec: sec
	}
}
/**
 * @description Function to format date time
 * @param date Date to be formatted
 */
/* istanbul ignore next  */
export const formatDateTime = (date) => {
	let correctInput = date;
	let newDate = new Date(correctInput);
	let convDateTime = '';
	if (!isNaN(newDate.getTime())) {
		let d = newDate.getUTCDate();
		let m = newDate.getUTCMonth() + 1;
		let yyyy = newDate.getUTCFullYear();
		let dd = d > 9 ? d.toString() : '0' + d.toString();
		let mm = m > 9 ? m.toString() : '0' + m.toString();
		let hr = newDate.getUTCHours() > 9 ? newDate.getUTCHours().toString() : '0' + newDate.getUTCHours().toString();
		let min = newDate.getUTCMinutes() > 9 ? newDate.getUTCMinutes().toString() : '0' + newDate.getUTCMinutes().toString();
		let sec = newDate.getUTCSeconds() > 9 ? newDate.getUTCSeconds().toString() : '0' + newDate.getUTCSeconds().toString();
		convDateTime = yyyy + '-' + mm + '-' + dd + ' ' + hr + ':' + min + ':' + sec;
	}
	return convDateTime;
}

/**
 * @description Function to format date time for CSV filename
 */
/* istanbul ignore next  */
export const formatDateTimeForCSV = () => {
	let formattedDateObj = breakDownDateFormat();
	const { date, month, fullYear, hour, min, sec } = formattedDateObj;
	return `${month}${date}${fullYear}_${hour}${min}${sec}`;
}

/**
 * @description Function to format date
 * @param dateObj Date to be formatted
 */
/* istanbul ignore next  */
export const formatDate = (dateObj) => {
	let formattedDateObj = breakDownDateFormat(dateObj);
	const { date, month, fullYear } = formattedDateObj;

	return `${fullYear}-${month}-${date}`;
}

/**
 * @description Function to format date to string
 * @param date Date to be formatted
 */
/* istanbul ignore next  */
export const formatDateString = (date) => {
	const dateString = new Date(date).toDateString();
	const dateArray = dateString.split(' ');
	var stringValue = '';
	if (dateArray.length) {
		stringValue += `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`
	}

	return stringValue;
}

/**
 * @description Function to convert UTC to Local time 
 * @param dateObj Date to be formatted
 */
/* istanbul ignore next  */
export const convertUTCToLocalDateTime = (dateObj) => {
	let formattedDateObj = breakDownDateFormat(dateObj);
	const { date, month, fullYear, hour, min, sec } = formattedDateObj;

	return `${fullYear}-${month}-${date} ${hour}:${min}:${sec}`;
}

/**
 * @description Function to convert date to date-time input format (upto minute)
 * @param dateObj Date to be formatted
 */
/* istanbul ignore next  */
export const getTodaysDateTime = (dateObj) => {
	let formattedDateObj = breakDownDateFormat(dateObj);
	const { date, month, fullYear, hour, min } = formattedDateObj;

	return `${fullYear}-${month}-${date} ${hour}:${min}`;
}

/**
 * @description Function to round up decimal values as per custom rules
 * @param num Float value to be rounded
 * @returns Floating point value rounded off
 */
/* istanbul ignore next  */
export const customRoundOff = (num) => {
	if (isNaN(parseFloat(num))) {
		return 'N/A';
	}

	const integerPart = Math.trunc(num);
	const floatPart = Math.abs(num - integerPart);

	if (between(floatPart, 0.00, 0.25)) {
		return Math.abs(integerPart);
	}

	if (between(floatPart, 0.25, 0.75)) {
		return Math.abs(integerPart) + 0.5;
	}

	if (between(floatPart, 0.75, 1)) {
		return Math.abs(integerPart) + 1;
	}
}

/**
 * @description Function to round up decimal values THA orientation
 * @param num Float value to be rounded
 * @returns Floating point value rounded off
 */
/* istanbul ignore next  */
export const roundOffTHAOrientation = (num) => {
	if (isNaN(parseFloat(num))) {
		return 'N/A';
	}

	return Math.abs(Math.round(num));
}

/**
 * @description Function to round up decimal values as whole numbers
 * @param num Float value to be rounded
 * @returns Whole number value rounded off
 */
export const wholeNumberRoundOff = (num, isGraph = false) => {
	if (isNaN(parseFloat(num))) {
		return isGraph ? null : 'N/A';
	}

	return Math.round(num);
}

/**
 * @description Function to round up KOOS JR. or HOOS JR. values as per custom rules
 * @param num Float value to be rounded
 * @returns Floating point value rounded off
 */
export const customKOOSJrRoundOff = (num) => {
	const floatNum = parseFloat(num);
	if (isNaN(floatNum)) {
		return 0;
	}

	const integerPart = Math.trunc(num);
	const floatPart = floatNum - integerPart;
	const uptoThreePlaces = floatPart * 1000;
	const floatFourth = Math.abs(uptoThreePlaces - Math.trunc(uptoThreePlaces));
	const roundedToThreePlaces = parseFloat(`${integerPart}.${Math.trunc(uptoThreePlaces)}`)

	if (parseFloat(floatFourth.toFixed(3)) === 0.5) {
		return parseFloat((roundedToThreePlaces + 0.001).toFixed(3));
	} else {
		return floatPart ? parseFloat(floatNum.toFixed(3)) : floatNum;
	}
}
/**
 * @description Function to round up PROMIS-GLOBAL values as per custom rules
 * @param num Float value to be rounded
 * @returns Floating point value rounded off
 */
export const customPROMISScoreRoundOff = (num) => {
	const floatNum = parseFloat(num);
	if (isNaN(floatNum)) {
		return 0;
	}

	return parseFloat(floatNum.toFixed(2))

}

/**
 * @description Function to round up up to certain decimal places
 * @param num Float value to be rounded
 * @returns Floating point value rounded off
 */
export const customToFixed = (num, decimalPlcaes) => {
	const floatNum = parseFloat(num);
	if (isNaN(floatNum)) {
		return 0;
	}

	return parseFloat(floatNum.toFixed(decimalPlcaes));
}

/**
 * @description Function to check if a number is negative or not
 * @param num Float value to be checked
 * @returns Boolean (true if negative, false if not)
 */
export const isNegative = (num) => {
	if (isNaN(parseFloat(num))) {
		return false;
	}

	let sign = Math.sign(num);
	switch (sign) {
		case -1:
			return true;
		case 0:
		case -0:
			return 1 / sign === -Infinity;
		default:
			return false;
	}
}

/**
 * @description Function to round up decimal values as per custom rules with sign
 * @param num Float value to be rounded
 * @returns Rounded off value with sign
 */
export const signedRoundOff = (num) => {
	if (isNaN(parseFloat(num))) {
		return null;
	}
	const roundedOff = customRoundOff(num);
	return isNegative(num) ? `-${roundedOff}` : roundedOff;
}

/**
 * @description Function to get units to append to values based on the number being positive or negative
 * @param number Number in question
 * @param type Based on this, various units will be returned
 * @returns String value to be displayed
 */
export const getSignedUnit = (number, type) => {
	if (isNaN(parseFloat(number))) {
		return '';
	}

	switch (type) {
		case 'alignment':
			return isNegative(number) ? 'Valgus' : 'Varus';
		case 'rotation':
			return isNegative(number) ? 'External' : 'Internal';
		case 'flexion':
			return isNegative(number) ? 'Extension' : 'Flexion';
		case 'slope':
			return isNegative(number) ? 'Posterior' : 'Anterior';
		case 'orientation':
			return isNegative(number) ? 'Anterior' : 'Posterior';

		default:
			return '';
	}
}

/**
 * @description Function to parse HTML string to DOM elements
 * @param str String in question
 * @returns String value to be displayed
 */
export const parseHTML = (htmlString) => {
	return parse(htmlString && typeof htmlString === 'string' ? htmlString : '');
}

/**
 * @description Function to convert HTML to text string
 * @param html HTML in question
 * @returns String value to be displayed
 */
export const convertToPlainText = (html) => {
	// Create a new div element
	var tempDivElement = document.createElement("div");
	// Set the HTML content with the given value
	tempDivElement.innerHTML = html;
	// Retrieve the text property of the element 
	return tempDivElement.textContent || tempDivElement.innerText || "";
}

/**
 * @description Function to ensure positive number inputs
 * @param e Keydown event
 * @returns Boolean
 */
export const isPositiveNumber = (e) => {
	// 48-57 => number row numbers, 96-105 => numpad numbers
	// 8 => backspace, 110 => numpad decimal point, 37-40 => arrow keys
	// 190 => decimal point, 46 => delete, 13 => enter
	// 86 => v, 88 => x, 67 => c
	if (!((e.keyCode > 95 && e.keyCode < 106)
		|| (e.keyCode > 47 && e.keyCode < 58)
		|| (e.keyCode > 36 && e.keyCode < 41)
		|| e.keyCode === 8 || e.keyCode === 110
		|| e.keyCode === 190 || e.keyCode === 46
		|| (e.ctrlKey && e.keyCode === 67)
		|| (e.ctrlKey && e.keyCode === 86)
		|| (e.ctrlKey && e.keyCode === 88)
		|| e.keyCode === 13)) {
		return false;
	}

	return true;
}

/**
 * @description Function to convert string to title case
 * @param str String to be converted
 * @returns Converted string
 */
export const toTitleCase = (str) => {
	if (!str || !str.toString().trim()) {
		return 'N/A';
	}
	return str.replace(
		/\w\S*/g,
		(txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
}

/**
 * @description Function to get concatenated name if availabled
 * @param firstName First name
 * @param lastName Last name
 * @param midName Middle name
 * @returns Converted string
 */
export const getConcatenatedName = (firstName, lastName, midName = '') => {
	if (!firstName && !lastName && !midName) {
		return 'N/A';
	}

	return `${firstName ? firstName : ''} ${midName ? midName : ''} ${lastName ? lastName : ''}`;
}

/**
 * @description Function to get rounded off PROM score value
 * @param score Score value
 * @param scoreType Score type
 * @returns Rounded off value for respective score type
 */
export const roundedOffPROMScore = (score, scoreType) => {
	if (isNaN(parseFloat(score))) {
		return null;
	}

	switch (scoreType) {
		case 'KOOS JR.':
		case 'HOOS JR.':
			return customKOOSJrRoundOff(score);
		case 'PROMIS-Global':
			return customPROMISScoreRoundOff(score);
		case 'KOOS':
		case 'HOOS':
			return customToFixed(score, 1);
		case 'KSS':
		case 'Oxford Knee Score':
		case 'Forgotten Joint Score':
		case 'EQ5D':
			return Math.round(score);

		default:
			return parseFloat(score);
	}
}

/**
 * @description Function to get rounded off Force changed value
 * @param number force change type
 * @returns Rounded off value for respective force change type
 */
export const tensionerForceRange = (number) => {
	if (hardBetween(number, 40, 60)) {
		return '40 N - 60 N (Low)';
	}

	if (hardBetween(number, 90, 110)) {
		return '90 N - 110 N (Medium)';
	}

	if (hardBetween(number, 140, 160)) {
		return '140 N - 160 N (High)';
	}

	return 'N/A';
}

/**
 * @description Function to get N/A help text
 * @returns JSX template with said help text
 */
export const NAHelpText = () => {
	return (
		<div className="my-2 na-help-text">
			<p className="m-0">*N/A - Not Available</p>
		</div>
	);
}

/**
 * @description Function to get disclaimer text for timing data
 * @returns JSX template with said disclaimer text
 */
export const getTimingDisclaimer = () => {
	return (
		<div className="na-help-text text-italic">
			<p className="m-0"><strong>Disclaimer: </strong>Data presented is reflective of user activity logged by system during case.</p>
		</div>
	);
}

/**
 * @description Function to get disclaimer text for learning curve
 * @returns JSX template with said disclaimer text
 */
export const getLearningCurveDisclaimer = () => {
	return (
		<div className="na-help-text text-italic">
			<p className="m-0"><strong>Note: </strong>Timing data excludes setup time.</p>
		</div>
	);
}

/**
 * @description Function to get if the user is logged in
 * @returns Boolean true if yes, false if not
 */
export const isAuthenticated = () => {
	return checkLocalStorage('cognito_data');
}

/**
 * @description Function to get if the user has permission
 * @returns Boolean true if yes, false if not
 */
export const userHasPermission = (permissionFor) => {
	var userRoleArray = getUserRoleType();
	var permissionFlag = false;
	for (let i = 0; i < userRoleArray.length; i++) {
		const role = userRoleArray[i];
		if (RolePermission[permissionFor].includes(role)) {
			permissionFlag = true;
			break;
		}
	}
	return permissionFlag;
}

/**
 * @description Function to get if this is the user's first login
 * @returns Boolean true if yes, false if not
 */
export const isFirstLogin = () => {
	return checkLocalStorage('isFirstLogin');
}

/**
 * @description Function to return the image src as base64 encoded string
 * @returns String
 */
export const getImgSrc = (img) => {
	var defaultExport = require(`../assets/images/${img}`);
	return defaultExport.default ? defaultExport.default : defaultExport;
}

/**
 * @description Function to define template for loading animation
 * @returns Template for loading animation
 */
export const getLoadingTemplate = () => {
	return <div className="loading-img mb-3"><img className="" src={getImgSrc('loading.gif')} alt="loading" /></div>
}

/**
 * @description Function to check for whitespace at the start
 * @param value Input value
 * @returns Regex test result (Boolean)
 */
export const validateWhiteSpace = (value) => {
	return new RegExp(/^\s/g).test(value);
}

/**
 * @description Function to check if date is valid or not
 * @param value Input value
 * @returns Boolean indicating whether the input is a valid date or not
 */
export const isValidDate = (value) => {
	return !isNaN(Date.parse(value));
}

/**
 * @description Function to check if a given date is in the past or not
 * @param {string} dateString - The date string in 'YYYY-MM-DD' format
 * @returns {boolean} - Returns true if the date is in the past, false otherwise
 */
export const isDatePast = (dateString) => {
	const today = new Date();
	const givenDate = new Date(dateString);
	return givenDate < today;
}


/**
 * @description Function to make the first letter capital
 * @param {string} - String to get in capital letter
 * @returns {string} - String with first letter changed to capital
 */
export const capitalizeFirstLetter = (text) => {
	return text && `${text[0].toUpperCase()}${text.slice(1)}`
}


/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export const arrayIncludesKey = (haystack, arr) => {
	return arr.some(v => haystack.includes(v));
};

/**
* @description Formats the given date into a human-readable local date-time format if the date is valid.
* If the date is not provided or invalid, appropriate placeholders are returned.
* @param {string|Date} date - The date to be formatted.
* @returns {string} The formatted local date-time, 'N/A' if the date is not provided, or 'Invalid Date' if the date is invalid.
*/
export const formatDateTimeLocal = (date) => {
	if (!date) {
		return 'N/A';
	} else if (isValidDate(date)) {
		return convertUTCToLocalDateTime(date);
	} else {
		return 'Invalid Date';
	}
}

/**
 * @description Function to return desired customer license type name
 * @param {string} licenseType - License type name coming from the API response
 * @returns {string} - String with desired modified name
 */
export const getLicenseTypeName = (licenseType) => {
	if (!licenseType) {
		return 'N/A';
	}
	
	return licenseTypes.find(x => x.key?.toLowerCase() === licenseType.toLowerCase())?.value || licenseType;
}

