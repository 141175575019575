import React, { Component } from 'react';
import { actions } from '../../helpers/messages';
import { convertUTCToLocalDateTime } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import { Roles, multipleRolesAllowed } from '../../helpers/constants';

export default class UserDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }

    /**
     * @description Function to disable edit mode while restting the form
     * @memberof UserDetailsComponent
     */
    /* istanbul ignore next  */
    cancelEdit = () => {
        this.myFormRef.reset();
        this.props.toggleEditMode();
    }

    /**
   * @description Function tocheck user allow for multiple role
   * @param userGroups Roles of  each user
    */
    /* istanbul ignore next  */
    checkMultiRole(userGroups) {
        if (userGroups && userGroups.length === 1) {
            return multipleRolesAllowed.some(x => userGroups.includes(x))
        }
        return false
    }
    /**
     * @description Function to get user role
     * @param user Row element for each user
      */
    /* istanbul ignore next  */
    getUserRole(userGroups) {
        if (userGroups && userGroups.length) {
            let roleNames = userGroups.map(x => {
                const userRole = Roles.find(userGroup => userGroup.cognitoGroupName === x);
                return userRole ? userRole.label : x;
            })
            return roleNames
        }

        return [];
    }

    render() {
        const { user, loading, userError, editMode, toggleEditMode, editObj, handleInputChange, onEnterPress, showSuccessMsg, isMultipleRole, handleMultipleRoleSelect, secondRoleArray, showErrorMsg, formErrors, saveUser, handleCancel } = this.props;
        const userRoles = user?.groups?.length ? this.getUserRole(user.groups) : this.getUserRole(user.group ? [user.group] : []);
        return (
            <>
                {loading ? <div className="loading-overlay-upload add-surgeon-overlay"><span>{actions.load}</span></div> :
                    user ?
                    <div className='container'>
                        <div className="customer-fields surgeon-details">
                            <div className="case-details-container" data-testid="userDetails">
                                <div className="card-body py-0">
                                    <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                                        <div className="row">
                                            {showSuccessMsg ?
                                                <div className="col-sm-12">
                                                    <div className="customer-save-success-msg">
                                                        <p>{showSuccessMsg}</p>
                                                    </div>
                                                </div>
                                                : ''}
                                            {showErrorMsg ?
                                                <div className="col-sm-12">
                                                    <div className="customer-save-error-msg">
                                                        <p>{showErrorMsg}</p>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                        <fieldset className="customer-fields" disabled={!editMode}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="firstName" className="col-sm-4 col-form-label shrink-label">First Name{editMode ? <span className="red">*</span> : ''}</label>
                                                        <div className="col-sm-8">
                                                            <input
                                                                type="text"
                                                                id="firstName"
                                                                data-testid="firstName"
                                                                autoComplete="off"
                                                                className={`form-control ${formErrors && formErrors.firstName ? 'has-error' : ''}`}
                                                                value={editObj && editObj.firstName ? editObj.firstName : ''}
                                                                onChange={(e) => handleInputChange('firstName', e.target.value)}
                                                            />
                                                            {formErrors && formErrors.firstName ?
                                                                <div className="customer-error-msg">
                                                                    <p>{formErrors.firstName}</p>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="lastName" className="col-sm-4 col-form-label">Last Name{editMode ? <span className="red">*</span> : ''}</label>
                                                        <div className="col-sm-8">
                                                            <input
                                                                type="text"
                                                                id="lastName"
                                                                data-testid="lastName"
                                                                autoComplete="off"
                                                                className={`form-control ${formErrors && formErrors.lastName ? 'has-error' : ''}`}
                                                                value={editObj && editObj.lastName ? editObj.lastName : ''}
                                                                onChange={(e) => handleInputChange('lastName', e.target.value)}
                                                            />
                                                            {formErrors && formErrors.lastName ?
                                                                <div className="customer-error-msg">
                                                                    <p>{formErrors.lastName}</p>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="email" className="col-sm-4 col-form-label shrink-label">Email{editMode ? <span className="red">*</span> : ''}</label>
                                                        <div className="col-sm-8">
                                                            <input
                                                                disabled={userRoles.includes('Sales Representative')}
                                                                type="email"
                                                                id="email"
                                                                data-testid="email"
                                                                autoComplete="off"
                                                                className={`form-control ${formErrors && formErrors.email ? 'has-error' : ''}`}
                                                                value={editObj && editObj.email ? editObj.email : ''}
                                                                onChange={(e) => handleInputChange('email', e.target.value)}
                                                            />
                                                            {formErrors && formErrors.email ?
                                                                <div className="customer-error-msg">
                                                                    <p>{formErrors.email}</p>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Role</label>
                                                        <div className="col-sm-8">
                                                            <div className="role-select-wrap d-flex align-items-center cursor-pointer">
                                                                <span className="form-control disabled">{userRoles.length ?
                                                                    userRoles.sort().toString().split(',').join(',  ')
                                                                    : 'N/A'}
                                                                </span>
                                                                {editMode && (user.groups.length ? this.checkMultiRole(user.groups) : this.checkMultiRole(user.group ? [user.group] : [])) ?
                                                                    <span id="addNewRole" data-testid="addNewRole" className={`add-role-icon ms-2 mb-1 ${isMultipleRole ? 'disabled-icon' : ''}`} title="Add Role" onClick={e => { e.preventDefault(); handleMultipleRoleSelect(true); }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                                        </svg>
                                                                    </span>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>

                                        {/* Multiple Role */}
                                        {
                                            isMultipleRole ?
                                                <div className="row">

                                                    <div className='col-sm-6'>

                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group row">
                                                            <div className="col-sm-4"></div>
                                                            <div className="col-sm-8">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="role-select-wrap d-flex align-items-center cursor-pointer">
                                                                            <select
                                                                                id="secondRole"
                                                                                data-testid="secondRole"
                                                                                defaultValue={editObj?.secondRole || ''}
                                                                                className={`form-select ${editMode ? 'edit-form-select' : ''}  ${formErrors && formErrors.secondRole ? 'has-error' : ''}`}
                                                                                onChange={(e) => handleInputChange('secondRole', e.target.value)}>
                                                                                <option value="">Select a role</option>
                                                                                {secondRoleArray.map(role => <option key={`option-${role.userRole}`} value={role.cognitoGroupName}>{role.label}</option>)}
                                                                            </select>
                                                                            <span id="removeNewRole" data-testid ="removeNewRole" className="remove-role-icon ms-2 mb-1" title="Remove Role" onClick={e => { e.preventDefault(); handleMultipleRoleSelect(false); }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                        {formErrors && formErrors.secondRole ?
                                                                            <div className="customer-error-msg">
                                                                                <p>{formErrors.secondRole}</p>
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> : <></>
                                        }

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label shrink-label">Username</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.userName ? user.userName : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label">Country</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.country ? user.country : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label shrink-label">Date Created</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.userCreatedDate ? convertUTCToLocalDateTime(user.userCreatedDate) : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label">Last Seen</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.lastLogin ? convertUTCToLocalDateTime(user.lastLogin) : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label shrink-label">Last Password Reset</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.lastPasswordModified ? convertUTCToLocalDateTime(user.lastPasswordModified) : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 text-end">
                                                {!editMode ?
                                                    <>
                                                        <button type="button" id="edit-btn" className="btn btn-primary case-action-btn me-0" onClick={toggleEditMode}>Edit</button>
                                                        <button type="button" className="btn btn-secondary ms-2" id="cancel" onClick={handleCancel}>Close</button>
                                                    </>
                                                    : ''}
                                                {editMode ?
                                                    <>
                                                        <button type="button" id="save-btn" className="btn btn-primary case-action-btn" onClick={saveUser}>Save</button>
                                                        <button type="button" id="cancel-btn" className="btn btn-secondary me-0" onClick={this.cancelEdit}>Cancel</button>
                                                    </>
                                                    : ''}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                        :
                        <div className="customer-fields">
                            <div className="case-details-container">
                                <div className="card-body">
                                    {userError ? <ErrorMsgBlockComponent errorObject={userError} /> : <ErrorMsgBlockComponent noData={true} />}
                                </div>
                            </div>
                        </div>
                }
            </>
        );
    }
}
