import environment from '../config/environment';
export const charset = "UTF-8";
export const sourceEmail = environment.sourceEmail;
export const supportEmail = environment.supportEmail;
const  privacyUrl = 'https://www.smith-nephew.com/en/privacy-notice';
export const subjectLines = {
    welcome: "Smith+Nephew Welcomes You to RI.INSIGHTS!",
    passwordReset: "Smith+Nephew Password Reset.",
    emailChange: "RI.Insights Email Change"
}

/**
 * @description Function to create anchor tag to redirect to current env home page
 * @returns HTML anchor tag
 */
/* istanbul ignore next  */
const getLinkBackToSite = (text) => {
    return `<a href=${environment.baseUrl}>${text ? text : 'RI.Insights'}</a>`
}

/**
 * @description Function to create anchor tag to redirect to current env home page
 * @returns HTML anchor tag
 */
/* istanbul ignore next  */
const getSupportLink = (text) => {
    return `<a href=mailto:${supportEmail}>${text}</a>`
}

/**
 * @description Function to create header of the email body
 * @returns HTML
 */
/* istanbul ignore next  */
const getHeader = () => {
    const headerImg = `${environment.baseUrl}/logo.png`;
    return (`
        <html>
            <body style="text-align: center; font-size: 18px;">
                <header>
                    <img src="${headerImg}" height="300px">
                    <h2 style="font-size: 38px; font-weight: 600; color: #FF9E18;">Your Real Intelligence journey begins with analytics!</h2>
                </header>
    `);
}

/**
 * @description Function to create footer of the email body
 * @returns HTML
 */
/* istanbul ignore next  */
const getFooter = () => {
    return (`
                <footer>
                    <div>
                        <p>Click here to login-> ${getLinkBackToSite('RI.Insights Home')}</p>
                        <p style="margin-top: 15px;">Please email questions to ${getSupportLink(supportEmail)}</p>
                    </div>
                </footer>
            </body>
        </html>
    `)
}

/**
 * @description Function to create template for password reset email
 * @param user User who will receive the email
 * @returns Template for password reset
 */
export const getPasswordResetEmailTemplate = (user) => {
    return (`
        ${getHeader()}
        <h3 style="margin-top: 15px; font-size: 1.7rem;">Hello!</h3>
        <div style="margin: 15px 0;">
            <p>We received a request to reset the password for your RI.Insights account.</p>
            <p style="margin: 15px 0 0 0;">Your new password is:</p> 
            <p style="margin: 0 0 15px 0;"><strong>New Password:</strong> ${user.password}</p>
            <p>Note: The temporary password is set to expire in 30 days.</p> 
        </div>
        ${getFooter()}
    `)
}

/**
 * @description Function to create template for reactivating a user
 * @param user User who will receive the email
 * @returns Template for password reset
 */
export const getUserReactivatedEmailTemplate = (user) => {
    return (`
        ${getHeader()}
        <h3 style="margin-top: 15px; font-size: 1.7rem;">Hello!</h3>
        <div style="margin: 15px 0;">
            <p>We received a request to reactivate your RI.Insights account.</p>
            <p style="margin: 15px 0 15px 0;">Your new password is:</p> 
            <p style="margin: 0;"><strong>Username:</strong> ${user.username}</p>
            <p style="margin: 0 0 15px 0;"><strong>New Password:</strong> ${user.password}</p>
        </div>
        ${getFooter()}
    `)
}

/**
 * @description Function to create template for email when admin updates details
 * @param user User who will receive the email
 * @returns Template for email change
 */
export const getUserEmailChangedTemplate = (user) => {
    return (`
        ${getHeader()}
        <h3 style="margin-top: 15px; font-size: 1.7rem;">Hello!</h3>
        <div style="margin: 15px 0;">
            <p>We received a request to reset the email for your RI.Insights account.</p>
            <p>Your new email on the account is <i>"${user.email}"</i>. </p>
            <p>If this request was made in error please contact the RI.Insights ${getSupportLink('support')} team.</p>
        </div>
        ${getFooter()}
    `)
}

/**
 * @description Function to create template for email when admin creates new user
 * @param user User who will receive the email
 * @returns Template for email change
 */
export const getUserCreatedTemplate = (user) => {
    return (`
        ${getHeader()}
        <h3 style="margin-top: 15px; font-size: 1.7rem;">Welcome,${user.role && user.role === 'Surgeon' ? ' Dr. ' : ' '}${user.firstName} ${user.lastName}!</h3>
        <div style="margin: 15px 0;">
            <p>Smith+Nephew has received your contact information from your Hospital Team Administrator. You are invited to create an account to explore and gain insights in the S+N RI.INSIGHTS Portal.</p>
            <p>By creating an account, you agree to the use of your personal information for this purpose and in accordance with our
            <a href=${privacyUrl}>privacy notice.</a>
            </p>
            <p>Please sign-in with the information below to get started:</p>
            <p style="margin: 15px 0 0 0;"><strong>Username:</strong> ${user.userName}. </p>
            <p style="margin: 0 0 15px 0;"><strong>Temporary password:</strong> ${user.password} </p>
            <p>Note: The temporary password is set to expire in 30 days.</p>
        </div>
        ${getFooter()}
    `)
}

/**
 * @description Function to create template for  feedback 
 * @param user User who will receive the email
 */
export const getCustomerFeedbackTemplate = (user, body) => {
    return (`
        <html>
            <body>
                ${body}
                <footer style="margin-top: 15px;">
                    <p style="margin: 0 0 5px 0;">-------------</p>
                    <p style="margin: 0 0 3px 0;">Sent By:</p>
                    <p style="margin: 0 0 2px 0;">Name: ${user.given_name} ${user.family_name}</p>
                    <p style="margin: 0">Email: <a href="mailto:${user.email}">${user.email}</a></p>
                </footer>
            </body>
        </html>
    `)
}
