import React, { Component } from 'react'
import { DataTable } from '../DataTable/DataTable'
import { convertUTCToLocalDateTime, isValidDate } from '../../helpers/GlobalFunctions';
import CustomerSupportHistoryModal from './CustomerSupportHistoryModal';
import CustomModal from '../../shared/CustomModal';
import { setLocalStorage } from '../../services/storage/storage-service';
import ExpirationTooltip from '../ExpirationTooltip/ExpirationTooltip';
import { getAllSalesRepListService } from "../../services/java/java-services";
import { getUserName } from "../../services/aws/aws-services";

export default class CustomerSupportPreopPlanTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCustomerHistoryModal: false,
            VisionaierId: '',
            salesRepList: [],

        }
        this.getSalesRepList = this.getSalesRepList.bind(this)
    }

    /**
     * @description get the list of the sales reps users for admin
     * @memberof CustomerSupportPreopPlanTable
     */
    getSalesRepList() {
        /* istanbul ignore next  */
    getAllSalesRepListService(getUserName(), (err, result) => {
        
        if (err) {
        this.setState({
            errorMessage: typeof err === "string" ? err : JSON.stringify(err),
        });
        } else {
        this.setState({
            salesRepList: result.data.SalesRepList,
        });
        }
    });
    }

  componentDidMount() {
    this.getSalesRepList();
  }

    shouldComponentUpdate(nextProps) {
        if (this.props.showFilterBlock !== nextProps.showFilterBlock) {
            return false;
        }
        return true; // Allow re-rendering for other prop changes
    }

    /**
    * @description Function to show modal when an viewHistory point is clicked
    * @param VisionaierId
    * @memberof CustomerSupportPreopPlanTable
    */

    openModal = (VisionaierId) => {
        setLocalStorage('PlanHistoryCaseId', VisionaierId);
        this.setState({ showCustomerHistoryModal: true, VisionaierId });
    }

    /**
  * @description Function to close modal when clicked on close button
  * @param VisionaierId
  * @memberof CustomerSupportPreopPlanTable
  */

    closeModal = () => {
        this.setState({ showCustomerHistoryModal: false });
    }

    /**
* @description Function to Get the Status Label 
* @param VisionaierId
* @memberof CustomerSupportPreopPlanTable
*/

    getStatusLabel = (list, status) => {
        if (!status) return 'N/A';

        if (status === '9') {
            return 'Operation Complete'
        }

        return list.find(x => x.planStatus === Number(status))?.status

    }

    /**
    * @description function show date in correct format or NA
    * @param {updatedDate} date to show 
    * @memberof CustomerSupportPreopPlanTable
    */

    renderDate(updatedDate) {
        if (!updatedDate) {
            return 'N/A';
        }

        if (isValidDate(updatedDate)) {
            return convertUTCToLocalDateTime(updatedDate);
        }

        return 'Invalid Date';
    }

    /**
    * @description render eye icon
    * @param {row} current row 
    * @memberof CustomerSupportPreopPlanTable
    */
    /* istanbul ignore next  */
    renderEyeIcon(row) {
        return row.isExpanded ? (
            <svg xmlns='http://www.w3.org/2000/svg' width='2.5em' height='2.5em' fill='#73BC51' className='bi bi-eye-fill' viewBox='0 0 16 16'>
                <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
            </svg>
        ) : (
            <svg width='2em' height='2em' viewBox='0 0 16 16' className='bi bi-eye-slash' xmlns='http://www.w3.org/2000/svg'>
                <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z' />
                <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z' />
                <path d='M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z' />
                <path fillRule='evenodd' d='M13.646 14.354l-12-12 .708-.708 12 12-.708.708z' />
            </svg>
        );
    }


    render() {
        /* istanbul ignore next  */
        const columns = [
            {
                // Make an expander cell
                Header: () => 'Plan Details',
                id: 'expander', // It needs an ID
                Cell: ({ row }) => (
                    <span {...row.getToggleRowExpandedProps()}>
                        {this.renderEyeIcon(row)}
                    </span>
                ),
                // We can override the cell renderer with a SubCell to be used with an expanded row
                SubCell: () => null, // No expander on an expanded row
            },
            {
                Header: 'Creation Date',
                accessor: 'plan_creation_date',
                Cell: ({ row }) => <div>{this.renderDate(row.original.creationdate)}</div>,
                Filter: '',
            },
            {
                Header: 'Pre-op Plan ID',
                accessor: 'case_number',
                Cell: ({ row }) => {
                    const value = row.original.VisionaierId?.trim() || 'N/A';
                    return <span>{value}</span>;
                },
                Filter: '',
            },
            {
                Header: 'Surgeon Name',
                accessor: 'surgeon_name',
                Cell: ({ row }) => {
                    const value = row.original.SurgeonName?.trim() || 'N/A';
                    return <span className='text-capitalize'>{value}</span>;
                },
                Filter: '',
            },
            {
                Header: 'Sales Rep',
                accessor: 'salesRepFullName',
                // Cell: ({ row }) => {
                //     const value = row.original?.salesRepFullName?.trim() || 'N/A';
                //     return <span className='text-capitalize'>{value}</span>;
                // },
                // Filter: '',
                changeAssociationDropDown: true,
            },
            {
                Header: 'Hospital Association',
                changeAssociationDropDown: true,
                accessor: 'hospitalAssociation',
            },
            {
                Header: 'Implant Type',
                accessor: 'operative_implant',
                Cell: ({ row }) => {
                    const value = row.original.implantType?.trim() || 'N/A';
                    return <span className='text-capitalize'>{value}</span>;
                },
                Filter: '',
            },
            {
                Header: 'Status',
                accessor: 'procedureStatus',
                Cell: ({ row }) => <span>{this.getStatusLabel(row.original.procedureStatus, row.original.currentStatus)}</span>,
                Filter: '',
            },
            {
                Header: 'Procedure Type',
                accessor: 'procedure_type',
                Cell: ({ row }) => <span>{row.original.patientDetails?.procedureType?.trim() || 'N/A' }</span>,
                Filter: '',
            },
            {
                Header: <div>Date Of Expiration</div>,
                Filter: '',
                accessor: 'expiration_date',
                Cell: ({ row }) => {
                    const expirationDate = row.original.expirationDate;

                    const onlyDate = expirationDate.substring(0, 10)

                    return <ExpirationTooltip hideToolTip={true} row={{ licenseExpiringInDays: row.original.expiringInDays, licenseExpireDate: onlyDate }} />
                },
            },
            {
                Header: "View History",
                Cell: ({ row }) => (
                    // code to open view history modal
                    <div className="view-history" id="openCustomerHistoryModal" user={row.original} onClick={() => this.openModal(row.original.VisionaierId)}><u> View History</u></div>
                )
            }
        ];
        const { isUpdated, filterObj, isActiveListOnly, showFilterBlock, listType, searchString } = this.props;

        return (
            <>
                <DataTable
                    isUpdated={isUpdated}
                    listType={listType}
                    showPagination={true}
                    columns={columns}
                    filterObj={filterObj ? filterObj : ''}
                    searchString={searchString ? searchString : ''}
                    isActiveListOnly={isActiveListOnly}
                    showFilterBlock={showFilterBlock}
                    salesRepUsersList={this.state.salesRepList}
                />
                <div >
                    {/* Modal to add customer support plan history  mapping list */}
                    <CustomModal
                        id="customerHistoryModal"
                        dialogClassName="add-surgeon-dialog"
                        show={this.state.showCustomerHistoryModal}
                        isComponent={true}
                        isAlert={true}
                        alertText="Close"
                        title="Plan History"
                        closeAction={this.closeModal}
                    >
                        <CustomerSupportHistoryModal
                            customerHistoryData={this.state.VisionaierId}
                            action='customerHistoryMap'
                        />
                    </CustomModal>
                </div>
            </>
        )
    }
}
