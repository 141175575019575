import React, { Component } from 'react'
import PlanningReviewTool from '../../components/PlanningReviewTool/PlanningReviewTool';
import { getAllImplantsService, getAllRegions, getPlanningResultService } from '../../services/java/java-services';
import { planningToolErrorMessages, actions, loggerEventTypes, loggerEventOutcome, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
let startDate;

const DEFAULTS = {
	preopDeformity: { min: -8, max: 8, minVal: 0, maxVal: 3 },
	femurSize: { min: 1, max: 10 },
	patientAge: { min: 0, max: 101, minVal: 0, maxVal: 0, maxLabel: "> 100" },
}

export default class PlanningReviewToolContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			caseData: null,
			implants: [],
			regions: [],
			selectedRegion: '',
			loading: false,
			femurSizeRange: {
				min: 1,
				max: 10
			},
			preopDeformity: { min: DEFAULTS.preopDeformity.minVal, max: DEFAULTS.preopDeformity.maxVal },
			femurSize: { min: '', max: '' },
			patientAge: { min: DEFAULTS.patientAge.min, max: DEFAULTS.patientAge.max },
			patientSex: '',
			tensionerForce: ['high', 'medium', 'low'],
			implant: '',
			gapsCollectedPlanningTool: '',
			casePull: 'myCases',
			showResults: false,
			disableReset: true,
			disableFilter: false,
			invalidFilters: true,
			error: '',
			planningToolError: null,
			implantError: null,
			regionError: null,
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.planningTool,
				"StartDate": new Date()
			}
		};
	}

	componentDidMount() {
		this.getAllImplants();
		this.getAllRegions();
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}

	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof PlanningReviewToolContainer
	 */
	applicationLogger(key) {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.planningTool,
			"Content": {
				"TimeSpent": timeSinceLoad
			}
		}
		logger(loggerObj);
	}

	/**
	 * @description function to get the all implant types
	 * @memberof PlanningReviewToolContainer
	 */
	getAllImplants = async () => {
		this.setState({ loading: true });
		getAllImplantsService((err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ loading: false, implantError: err });
			} else {
				this.setState({
					loading: false,
					implants: result.data.case,
					implantError: null
				})
			}
		})
	}

	/**
	 * @description function to get the all market regions
	 * @memberof PlanningReviewToolContainer
	 */
	getAllRegions = async () => {
		this.setState({ loading: true });
		getAllRegions((err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ loading: false, regionError: err });
			} else {
				const res = result.data && result.data.case && result.data.case.length ? result.data.case[0] : null;
				this.setState({
					regions: res && res.regions && res.regions.length ? res.regions : [],
					selectedRegion: res && res.defaultregion ? res.defaultregion : '',
					regionError: null
				});
			}
		})
	}

	/**
	* @description Function to handle changes in the range input
	* @param values The min and max value selected in array format
	* @param field The field for which the range is being selected
	* @memberof PlanningReviewToolContainer
	*/
	handleRangeChange = (values, field) => {
		let obj = {};
		if (values.length) {
			obj[field] = {
				min: values[0],
				max: values[1]
			}
			this.setState(obj);
		} else {
			obj[field] = { min: '', max: '' }
			this.setState(obj);
		}

		setTimeout(() => {
			this.validateReviewFilters();
		}, 0);
	}

	/**
	* @description Function to handle changes in the other form inputs
	* @param values The value being input in the field
	* @param field The field for which the value is being input
	* @memberof PlanningReviewToolContainer
	*/
	handleInputChange = (value, field) => {
		if (field === 'implant') {
			this.setFemurSizeRange(value);
		}

		if (field === 'casePull' && value === this.state.casePull) {
			return;
		}

		this.setState({ [field]: value });

		// Allowing time for the state to be set before triggering the next action
		setTimeout(() => {
			if (['casePull', 'selectedRegion'].indexOf(field) > -1) {
				this.handleSubmit();
			} else {
				this.validateReviewFilters();
			}
		}, 0);
	}

	/**
	* @description Function to handle changes in region aggregation select
	* @param region Selected region
	* @memberof PlanningReviewToolContainer
	*/
	handleRegionChange = (region) => {
		this.setState({
			selectedRegion: region,
		});
	}

	/**
	 * @description Function to set femur size range limits based on implant selection
	 * @param implant The value of selected implant
	 * @memberof PlanningReviewToolContainer
	 */
	setFemurSizeRange = (implant) => {
		const selectedImplant = this.state.implants.find(x => x.name === implant);
		const obj = {
			min: selectedImplant ? Math.min(...selectedImplant.femurSize) : 1,
			max: selectedImplant ? Math.max(...selectedImplant.femurSize) : 10
		}
		this.setState({ femurSizeRange: obj, femurSize: { min: '', max: '' } });
	}

	/**
	* @description Function to handle validation from the filters, at least one input should be there
	* @memberof PlanningReviewToolContainer
	*/
	validateReviewFilters() {
		const { preopDeformity, femurSize, patientAge, patientSex, implant, casePull, gapsCollectedPlanningTool, tensionerForce } = this.state;
		const otherInputValidate = femurSize.min === '' && femurSize.max === '' && patientAge.min === '' && patientAge.max === '' &&
			!patientSex.length && !tensionerForce.length && implant === '' && gapsCollectedPlanningTool === '' && casePull === 'myCases';
		const isEmptyFilters = preopDeformity.min === '' && preopDeformity.max === '' && otherInputValidate;
		const invalidFilters = implant === '';
		const disableReset = preopDeformity.min === DEFAULTS.preopDeformity.minVal && preopDeformity.max === DEFAULTS.preopDeformity.maxVal && otherInputValidate;

		this.setState((prevState) => {
			return {
				disableReset: disableReset,
				disableFilter: isEmptyFilters,
				invalidFilters: invalidFilters,
				error: invalidFilters ? prevState.error : ''
			}
		});
	}

	/**
	* @description Function to submit the filters
	* @memberof PlanningReviewToolContainer
	*/
	handleSubmit = () => {
		this.validateReviewFilters();
		setTimeout(() => {
			const { invalidFilters } = this.state;
			if (invalidFilters) {
				this.setState({
					showResults: invalidFilters === false,
					error: invalidFilters === true ? planningToolErrorMessages.implantMandatory : ''
				});
			} else {
				this.fetchPlanningResults();
			}
		}, 0);
	}

	/**
	 * @description Function to return a comma separated list of all femursize selections
	 * @memberof PlanningReviewToolContainer
	 */
	getFemurSizeForFilter() {
		const min = this.state.femurSize.min !== '' ? this.state.femurSize.min : this.state.femurSizeRange.min;
		const max = this.state.femurSize.max !== '' ? this.state.femurSize.max : this.state.femurSizeRange.max;
		var femurSizeArray = [];
		for (let i = min; i <= max; i++) {
			femurSizeArray.push(i);
		}

		return femurSizeArray.join(',');
	}

	/**
	* @description Function to fetch results bsed on filters
	* @memberof PlanningReviewToolContainer
	*/
	fetchPlanningResults() {
		const { preopDeformity, patientAge, patientSex, implant, casePull, selectedRegion, gapsCollectedPlanningTool, tensionerForce } = this.state;
		var filterObj = {
			deformityfrom: preopDeformity.min !== '' ? preopDeformity.min : DEFAULTS.preopDeformity.min,
			deformityto: preopDeformity.max !== '' ? preopDeformity.max : DEFAULTS.preopDeformity.max,
			implant: implant,
			femursize: this.getFemurSizeForFilter(),
			type: casePull.toLowerCase()
		}
		
		if (parseInt(patientAge.min) >= 0 && parseInt(patientAge.max) > 0) {
			filterObj['agemin'] = patientAge.min ? patientAge.min : DEFAULTS.patientAge.minVal;
			filterObj['agemax'] = patientAge.max ? patientAge.max : DEFAULTS.patientAge.maxVal;
		}

		if (patientSex && patientSex.length) {
			filterObj['patientsex'] = patientSex.join(',');
		}
		if (gapsCollectedPlanningTool && gapsCollectedPlanningTool.length) {
			filterObj['gapsCollected'] = gapsCollectedPlanningTool;

		}
		if (gapsCollectedPlanningTool && gapsCollectedPlanningTool.toLowerCase() === 'tensioner' && tensionerForce && tensionerForce.length) {
			filterObj['tensionerforce'] = tensionerForce.join(',');
		}
		if (casePull.toLowerCase() === 'region') {
			filterObj['region'] = selectedRegion;
		}

		this.setState({ loading: true })
		getPlanningResultService(filterObj, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ loading: false, planningToolError: err, caseData: null, showResults: false })
			} else {
				const caseData = result && result.data && result.data.case && result.data.case.length ? result.data.case[0] : null;
				this.setState({
					loading: false,
					caseData: caseData,
					showResults: true,
					planningToolError: null

				})
			}

			// Scroll to the bottom so that the results are focused.
			window.scrollTo(0, document.body.scrollHeight);
		})
	}

	/**
	* @description Function to reset the filters
	* @param {values, field}
	* @memberof PlanningReviewToolContainer
	*/
	handleReset = () => {
		this.setState({
			femurSizeRange: { min: 1, max: 10 },
			preopDeformity: { min: DEFAULTS.preopDeformity.minVal, max: DEFAULTS.preopDeformity.maxVal },
			femurSize: { min: '', max: '' },
			patientAge: { min: DEFAULTS.patientAge.min, max: DEFAULTS.patientAge.max },
			patientSex: '',
			tensionerForce: ['high', 'medium', 'low'],
			implant: '',
			gapsCollectedPlanningTool: '',
			casePull: 'myCases',
			showResults: false,
			disableReset: true,
			disableFilter: false,
			error: '',
			planningToolError: ''
		});
	}

	render() {
		const { femurSizeRange, implants, regions, selectedRegion, caseData, showResults, error, loading, disableReset, disableFilter } = this.state;
		return (
			<>
				{loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : null}
				<PlanningReviewTool
					caseData={caseData}
					isLoading={loading}
					femurSizeRange={femurSizeRange}
					implants={implants}
					regions={regions}
					selectedRegion={selectedRegion}
					showResults={showResults}
					defaults={DEFAULTS}
					disableFilter={disableFilter}
					disableReset={disableReset}
					error={error}
					handleRangeChange={this.handleRangeChange}
					handleInputChange={this.handleInputChange}
					handleSubmit={this.handleSubmit}
					handleReset={this.handleReset}
					loggerObj={this.state.loggerObj}
					planningToolError={this.state.planningToolError}
					gapsCollectedPlanningTool={this.state.gapsCollectedPlanningTool}
				/>
			</>
		)
	}
}
