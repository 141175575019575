export const graphContextMenuItems = ["downloadPNG", "downloadJPEG", "downloadCSV"];
export const forbiddenPasswordStrings = ['password', 'qwerty'];
export const RegularExpressions = {
    alphanumeric: new RegExp(/^[a-zA-Z0-9- ]+$/),
    name: new RegExp(/^\b[a-zA-Z0-9-\s']*[a-zA-Z0-9]\b$/),
    alphanumericNoSpace: new RegExp(/^[a-zA-Z0-9-]+$/),
    email: new RegExp(/^[A-Za-z0-9_]+(['.-]?[A-Za-z0-9_]+)*@[A-Za-z0-9_]+([.-]?[A-Za-z0-9_]+)*(\.[A-Za-z_]{2,4})+$/),
    surgeonID: new RegExp(/^[0-9]{0,10}$/),
    passwords: {
        format: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/),
        strength: {
            strong: new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$"),
            medium: new RegExp("^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$")
        },
        matchCriteria: {
            containsNumber: new RegExp(/[0-9]/),
            containsLowerCase: new RegExp(/[a-z]/),
            containsUpperCase: new RegExp(/[A-Z]/),
            numberRepeat: new RegExp(/(\d)(\1{2,})+/),
            numberSequence: new RegExp(/((01)|(12)|(23)|(34)|(45)|(56)|(67)|(78)|(89)|(90))/),
            numberSequenceReverse: new RegExp(/((21)|(32)|(43)|(54)|(65)|(76)|(87)|(98)|(09))/),
            stringLength: new RegExp(/^.{8,16}$/),
            containsSpecialChr: new RegExp(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/),
            containsString: (string) => new RegExp(string)
        }
    }
}
export const cognitoConstants = {
    userStatus: {
        CONFIRMED: 'CONFIRMED'
    },
    messageActions: {
        SUPPRESS: 'SUPPRESS'
    },
    exceptions: {
        UsernameExistsException: 'UsernameExistsException',
        ResourceNotFoundException: 'ResourceNotFoundException'
    }
}

export const GapDataFileNames = {
    manual15: {
        preOpLateral: [
            'PreOpLateral.json',
            'PreOperativeLateralGaps.json'
        ],
        preOpMedial: [
            'PreOpMedial.json',
            'PreOperativeMedialGaps.json'
        ],
        postOpLateral: [
            'PostOpLateral.json',
            'PostOperativeLateralGaps.json'
        ],
        postOpMedial: [
            'PostOpMedial.json',
            'PostOperativeMedialGaps.json'
        ]
    },
    manual17: {
        postOp: ['NTKA_AcceptedManualPostOpGaps.json'],
        preOp: ['NTKA_AcceptedManualPreOpGaps.json']
    },
    tensioner: {
        postOp: ['NTKA_AcceptedTensionerPostOpGaps.json'],
        preOp: ['NTKA_AcceptedTensionerPreOpGaps.json']
    }
}

export const CustomErrorCodes = {
    duplicateSurgeonID: 2028
}

export const operativeProcedureTypes = {
    TKA: {
        inLowerCase: 'naviotka',
        raw: 'navioTKA',
        text: 'TKA',
        longText: 'TKA Procedure'
    },
    HIP: {
        inLowerCase: 'tha',
        raw: 'THA',
        text: 'THA',
        longText: 'THA Procedure'
    },
    UKR: {
        inLowerCase: 'ukr',
        raw: 'UKR',
        text: 'UKR',
        longText: 'UKR Procedure'
    },
}

export const caseTypes = {
    imageFree: {
        inLowerCase: ['image free', 'image-free'],
        text: 'Image Free'
    },
    imageBased: {
        inLowerCase: ['image based', 'image-based'],
        text: 'Image Based'
    }
}

export const SurgeonPreferences = {
    THAMeasurement: {
        ALL: ['APP', 'FPP', 'BOTH'],
        APP: 'APP',
        FPP: 'FPP',
        BOTH: 'BOTH'
    }
}

export const Roles = [
    { userRole: 'admin', cognitoGroupName: 'admin', label: 'Admin', specialLabel: 'Admin', secondRoleOption: 'customersupport' },
    { userRole: 'rar', cognitoGroupName: 'RAR', label: 'RAR', specialLabel: 'SN Support Staff', secondRoleOption: 'salesrep' },
    { userRole: 'surgeon', cognitoGroupName: 'Surgeon', label: 'Surgeon', specialLabel: 'Surgeon', secondRoleOption: null },
    { userRole: 'careteam', cognitoGroupName: 'careteam', label: 'Care Team', specialLabel: 'Care Team', secondRoleOption: null },
    { userRole: 'salesrep', cognitoGroupName: 'salesrep', label: 'Sales Representative', specialLabel: 'Sales Representative', secondRoleOption: 'RAR' },
    { userRole: 'customersupport', cognitoGroupName: 'customersupport', label: 'Customer Support', specialLabel: 'Customer Support', secondRoleOption: 'admin' }
]

export const multipleRolesAllowed = ['RAR', 'salesrep', 'admin', 'customersupport']

export const RolePermission = {
    fileUpload: ['rar', 'careteam'],
    screenshots: ['surgeon', 'careteam'],
    promsUpdate: ['surgeon', 'careteam'],
    patientData: ['surgeon', 'careteam']
}

export const opCaseStatus = [
    { label: 'All', value: 'all' },
    { label: 'Plan Available', value: 1 },
    { label: 'Plan Exported', value: 2 },
    { label: 'Operation Complete', value: 9 }
]

export const eventSource = {
    kpopPlans: { value: 'kpop plans', label: 'Pre-Op Plans' },
    riInsights: { value: 'RI.Insights', label: 'RI.INSIGHTS' },
    visionaireKpop: { value: "visionaire kpop", label: 'Visionaire Service' }
}

export const syncStatus = {
    started: 'Sync has Started.',
    inProgress: 'Sync In-Progress.',
    completed: 'Sync has Completed.',
    failed: 'Sync Failed.'
}

export const licenseTypes = [
    { key: 'RI.Insights', value: 'RI.Insights' },
    { key: 'Pre-op planning', value: 'CORIOGRAPH' }
]