import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { graphContextMenuItems } from '../../helpers/constants';
import { exportAddOns } from '../../helpers/ChartExports';
import { manualPromScoreFields, PROMScoreTypes, scoreTypesForManualScore } from '../../containers/CaseDetails/constants';
import { getImgSrc, roundedOffPROMScore } from '../../helpers/GlobalFunctions';
import { operativeProcedureTypes } from '../../helpers/constants';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
class DisplayProms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scoreType: props.operativeProcedure.toLowerCase() === operativeProcedureTypes.TKA.inLowerCase ? 'KOOS' : props.operativeProcedure.toLowerCase() === operativeProcedureTypes.HIP.inLowerCase ? 'HOOS' : '',
            min: 0,
            max: 100,
            yAxisTickMarks: [0, 20, 40, 60, 80, 100]
        }
    }

    /**
      * @description function to handle the change in the outcome score type dropdown
      * @param scoreType the selected value
      * @memberof DisplayProms
      */
    handleScoreTypeChange = (scoreType) => {
        this.setState({ scoreType: scoreType });
        this.props.handleOutcomeScoreType(scoreType);

        switch (scoreType) {
            case 'Oxford Knee Score':
                this.setState({
                    min: 0,
                    max: 48,
                    yAxisTickMarks: [0, 12, 24, 36, 48]
                });
                break;
            case 'PROMIS-Global':
                this.setState({
                    min: 0,
                    max: 20,
                    yAxisTickMarks: [0, 5, 10, 15, 20]
                });
                break;
            case 'EQ5D':
                this.setState({
                    min: 1,
                    max: 5,
                    yAxisTickMarks: [1, 3, 5]
                });
                break;

            default:
                this.setState({
                    min: 0,
                    max: 100,
                    yAxisTickMarks: [0, 20, 40, 60, 80, 100]
                });
                break;
        }
    }

    render() {
        const { outComeScoreData, isLoading, getPROMGraphError, operativeProcedure, hideDropdown, graphHeight } = this.props;
        const { scoreType, min, max, yAxisTickMarks } = this.state;
        const scoreTypeDropdown = PROMScoreTypes[operativeProcedure.toLowerCase()];
        let outComeScoreArry = [];

        let timeFrameLable = ["6weeks", "12weeks", "6months", "12months", "2years"];
        let reOrderTimeFrame = [];
        let series = [];
        let xAxisTickMarks = [];

        /* istanbul ignore next  */
        if (outComeScoreData && outComeScoreData.length) {
            if (scoreTypesForManualScore.indexOf(scoreType) > -1) {
                // Getting the individual score field names and creating a serie for each of them
                let serieScoreFields = manualPromScoreFields[scoreType] ? manualPromScoreFields[scoreType] : [];
                if (serieScoreFields.length) {
                    serieScoreFields.forEach(scoreField => {
                        series.push({ name: scoreField.label, color: scoreField.color, custom: { scoreType: scoreField.type }, data: [] });
                    });
                }

                // Getting the time interval values present and reordering to show as labels along the X-axis
                timeFrameLable.forEach(timeFrame => {
                    outComeScoreData.forEach(outComeScore => {
                        let timeInterval = outComeScore.timeInterval[0];
                        if (timeInterval && timeFrame === timeInterval.replace(/ /g, '') && reOrderTimeFrame.indexOf(timeInterval) === -1) {
                            reOrderTimeFrame.push(timeInterval);
                        }
                    });
                })

                // Mapping the score field, score data and time interval and adding it to the corresponding serie
                outComeScoreData.forEach(outComeScore => {
                    let timeInterval = outComeScore.timeInterval[0];
                    if (outComeScore.subScoreType && outComeScore.subScoreType.length) {
                        outComeScore.subScoreType.forEach((subScoreType, index) => {
                            series.forEach(serie => {
                                if (subScoreType === serie.custom.scoreType) {
                                    serie.data.push({
                                        x: reOrderTimeFrame.indexOf(timeInterval),
                                        y: roundedOffPROMScore(outComeScore.score[index], this.state.scoreType)
                                    })
                                }
                            });
                        });
                    }
                });
            } else {
                let dataset = outComeScoreData[0];
                let outComeScoreLength = dataset && dataset.timeInterval ? dataset.timeInterval.length : 0;
                for (var j = 0; j < timeFrameLable.length; j++) {
                    for (var i = 0; i < outComeScoreLength; i++) {
                        let data = {
                            name: timeFrameLable[dataset.timeInterval[i].replace(/ /g, '')],
                            y: roundedOffPROMScore(dataset.score[i], this.state.scoreType),
                            color: '#59BE78'
                        }
                        if (timeFrameLable[j] === dataset.timeInterval[i].replace(/ /g, '')) {
                            reOrderTimeFrame.push(dataset.timeInterval[i])
                            outComeScoreArry.push(data);
                        }
                    }
                }
                series.push({ name: scoreType, data: outComeScoreArry });
            }

            // Array of indices from the timeframe (x axis) array. 
            // This array will determine where tickmarks and labels are shown in the x axis
            if (reOrderTimeFrame.length) {
                xAxisTickMarks = Array.from(reOrderTimeFrame, (x, i) => i);
            }
        }

        /* istanbul ignore next  */
        var config = {
            exporting: {
                sourceWidth: 900,
                sourceHeight: 350,
                buttons: {
                    contextButton: {
                        menuItems: graphContextMenuItems
                    },
                },
                csv: {
                    columnHeaderFormatter: (item, key, keyLength) => {
                        return keyLength ? `Outcome Scores for ${scoreType}` : 'Timeframe';
                    }
                },
                chartOptions: {
                    chart: {
                        marginTop: 100,
                        marginBottom: 80,
                        events: {
                            load: function () {
                                exportAddOns(this);
                            }
                        }
                    },
                    subtitle: {
                        text: scoreType
                    },
                    title: {
                        text: 'Outcome Scores',
                        style: {
                            fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
                            color: '#333333',
                            fontSize: 18,
                            textAlign: 'center'
                        }
                    }
                }
            },
            chart: {
                type: 'column',
                marginTop: 25,
                events: {
                    load: function () {
                        this.reflow();
                    },
                    redraw: function () {
                        if (this.fullscreen && this.fullscreen.isOpen) {
                            this.update({ chart: { marginTop: 70 }, title: { text: 'Outcome Scores' } }, false)
                        } else {
                            if (!this.options.chart.forExport) {
                                this.update({ chart: { marginTop: 25 }, title: { text: '' } }, false);
                            }
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: reOrderTimeFrame,
                minRange: 1,
                tickPositions: xAxisTickMarks,
                title: {
                    text: ''
                },
                labels: {
                    rotation: 0,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: min,
                max: max,
                tickPositions: yAxisTickMarks,
                title: ''
            },
            plotOptions: {
                series: {
                    centerInCategory: true,
                    shadow: false,
                    borderWidth: 0,
                    minPointLength: 3
                }
            },
            legend: {
                enabled: scoreTypesForManualScore.indexOf(scoreType) > -1
            },
            tooltip: {
                pointFormatter: function () {
                    let text = '';
                    if (this.y !== null && typeof this.y !== 'undefined') {
                        if (scoreTypesForManualScore.indexOf(scoreType) > -1) {
                            text = `${this.series.name}: ${this.y}`
                        } else {
                            text = this.y;
                        }
                    } else {
                        text = 'N/A';
                    }
                    return `<b>${text}</b>`;
                }
            },
            series: series
        };

        console.log('config: ', config);
        return (<div className="proms-blocks">
            <div className="proms-blocks-body">
                {!hideDropdown ?
                    <div className="row">
                        <div className="col-md-6 dashboard-block-title">Outcome Scores</div>
                        <div className="col-md-6">
                            <select id="scoresType" ref={this.myRef} className="form-select" onChange={(e) => this.handleScoreTypeChange(e.target.value)}>
                                {scoreTypeDropdown && scoreTypeDropdown.length && scoreTypeDropdown.map((score, index) => <option key={`score-type-${index}`} value={score.value}>{score.label}</option>)}
                            </select>
                        </div>
                    </div>
                    : ''}

                {isLoading ?
                    <div className="prom-loader-wrap">
                        <div className="loading-img mb-3">
                            <img className="" src={getImgSrc('loading.gif')} alt="loading" />
                        </div>
                    </div>
                    : outComeScoreData && outComeScoreData.length > 0 ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={config}
                            containerProps={{ style: { height: graphHeight || '235px' } }}
                        />
                        : <div className="d-flex align-items-center justify-content-center h-100">
                            {getPROMGraphError ?
                                <ErrorMsgBlockComponent errorObject={getPROMGraphError} />
                                : <ErrorMsgBlockComponent noData={true} />}
                        </div>
                }
            </div>
        </div>);
    }
}
export default DisplayProms;
