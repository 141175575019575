import React, { Component } from 'react';
import { connect } from 'react-redux';
import HomeComponent from '../../components/Home/Home';

class HomeContainer extends Component {
  render() {
    return (
      <div >
        <HomeComponent />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state,
  };
}

export default connect(mapStateToProps)(HomeContainer);
