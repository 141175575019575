import { promScoreMessages } from "../../helpers/messages";
import { scoreTypesForManualScore } from "./constants";

export const customValidationScoreTypes = ['Oxford Knee Score', 'EQ5D'];

/**
 * @description Function for validate each input field
 * @param field Field name
 * @param value Field value
 * @param errors Errors state object
 * @returns Object with error messages
 * 
 */
export const validatePromFields = (field, value, errors) => {
    var errorFlag = false;

    switch (field) {
        case 'scoreType':
            if (value === '') {
                errors.scoreType = promScoreMessages.error.scoreType;
                errorFlag = true;
            } else {
                errors.scoreType = '';
            }
            break;
        case 'subScoreType':
            if (value === '') {
                errors.subScoreType = promScoreMessages.error.subScoreType;
                errorFlag = true;
            } else {
                errors.subScoreType = '';
            }
            break;
        case 'timeFrame':
            if (value === '') {
                errors.timeFrame = promScoreMessages.error.timeFrame;
                errorFlag = true;
            } else {
                errors.timeFrame = '';
            }
            break;

        default:
            break;
    }

    return { errorFlag, errors }
}

/**
 * @description Function for validate prom score
 * @param scoreType PROM score type
 * @param value PROM score value
 * @param errors Errors state object
 * @returns Object with error messages
 * 
 */
export const validatePromScore = (scoreType, value, errors, formSubmit = false, manualScoresObj = null) => {
    if (scoreTypesForManualScore.indexOf(scoreType) > -1 && manualScoresObj) {
        return validateManualScores(scoreType, manualScoresObj, errors, formSubmit);
    }
    var errorFlag = false;
    var scoreLimit = 100;
    const decimalRegex = new RegExp(/^\d+\.?\d{0,3}$/);
    const decimalRegexPromisGlobal =  new RegExp(/^\d+\.?\d{0,2}$/);
    if (value === '') {
        errors.scoreValue = promScoreMessages.error.scoreValue;
        errorFlag = true;
    } else if ((parseFloat(value) < 0) || (1 / Math.sign(value)) === -Infinity) {
        errors.scoreValue = promScoreMessages.error.negativeValue;
        errorFlag = true;
    } else if (['KOOS JR.', 'HOOS JR.','PROMIS-Global'].indexOf(scoreType) === -1 && (value.toString().indexOf(".") !== -1 || !Number.isInteger(parseFloat(value)))) {
        errors.scoreValue = promScoreMessages.error.decimalValue;
        errorFlag = true;
    } else {
        switch (scoreType) {
            case 'Oxford Knee Score':
                scoreLimit = 48;
                break;
            case 'PROMIS-Global':
                scoreLimit = 20;
                if (!decimalRegexPromisGlobal.test(value)) {
                    errorFlag = true;
                    errors.scoreValue = promScoreMessages.error.decimalValuePlaces(2);
                    return { errorFlag, errors };
                }
                break;
                case 'KOOS JR.':
                case 'HOOS JR.':
                if (!decimalRegex.test(value)) {
                    errorFlag = true;
                    errors.scoreValue = promScoreMessages.error.decimalValuePlaces(3);
                    return { errorFlag, errors };
                }
                break;
            default:
                break;
        }
        if (parseFloat(value) > scoreLimit) {
            errors.scoreValue = promScoreMessages.error.scoreLimit(scoreLimit);
            errorFlag = true;
        } else {
            errors.scoreValue = '';
        } 
    } 

    return { errorFlag, errors };
}

/**
 * @description Function for validate sub scores
 * @param scoreType PROM score type
 * @param manualScoresObj PROM sub score object
 * @param errors Errors state object
 * @param formSubmit Flag for form submission
 * @returns Object with error messages
 * 
 */
export const validateManualScores = (scoreType, manualScoresObj, errors, formSubmit) => {
    var fields = Object.keys(manualScoresObj);
    var errorFlag = false;
    var hasAtLeastOneValue = false;
    
    if (fields.length) {
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const value = manualScoresObj[field];
            if (value !== '') {
                errors.manualScoreOneRequired = '';
                hasAtLeastOneValue = true;
            }
            
            switch (scoreType) {
                case 'KOOS':
                case 'HOOS':
                    const validateField = validateKOOSScore(field, value, errors);
                    if (validateField.errorFlag) {
                        return validateField;
                    }
                    break;
                case 'EQ5D':
                    const validateEQ5D = validateEQ5DScore(field, value, errors);
                    if (validateEQ5D.errorFlag) {
                        return validateEQ5D;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    if (formSubmit && !hasAtLeastOneValue) {
        errors.manualScoreOneRequired = promScoreMessages.error.manualScoreOneRequired;
        errorFlag = true;
    }

    return { errorFlag, errors };
}

/**
 * @description Function for validate KOOS/HOOS subscores
 * @param field KOOS/HOOS subscore type
 * @param value Score value
 * @param errors Errors state object
 * @returns Object with error messages
 * 
 */
export const validateKOOSScore = (field, value, errors) => {
    var errorFlag = false;
    if (!value) {
        errors[field] = '';
    } else {
        var scoreLimit = 100;
        const decimalRegex = new RegExp(/^\d+\.?\d{0,2}$/);
        errors.manualScoreOneRequired = '';

        if ((parseFloat(value) < 0) || (1 / Math.sign(value)) === -Infinity) {
            errors[field] = promScoreMessages.error.negativeValue;
            errorFlag = true;
        } else if (!decimalRegex.test(value)) {
            errors[field] = promScoreMessages.error.decimalValuePlaces(2);
            errorFlag = true;
        } else if (parseFloat(value) > scoreLimit) {
            errors[field] = promScoreMessages.error.scoreLimit(scoreLimit);
            errorFlag = true;
        } else {
            errors[field] = '';
        }
    }

    return { errorFlag, errors };
}

/**
 * @description Function for validate EQ5D subscores
 * @param field EQ5D subscore type
 * @param value Score value
 * @param errors Errors state object
 * @returns Object with error messages
 * 
 */
export const validateEQ5DScore = (field, value, errors) => {
    var errorFlag = false;
    if (!value) {
        errors[field] = '';
    } else {
        const Regex = new RegExp(/^[1-5]{1}$/);
        if ((parseInt(value) < 0) || (1 / Math.sign(value)) === -Infinity) {
            errors[field] = promScoreMessages.error.negativeValue;
            errorFlag = true;
        } else if (value.toString().indexOf(".") !== -1 || !Number.isInteger(parseFloat(value))) {
            errors[field] = promScoreMessages.error.decimalValue;
            errorFlag = true;
        }else if (!Regex.test(value)) {
            errors[field] = promScoreMessages.error.EQ5DRegex;
            errorFlag = true;
        } else {
            errors[field] = '';
        }
    }

    return { errorFlag, errors };
}