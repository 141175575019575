import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerListComponent from '../../components/CustomerList/CustomerList';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
let startDate;
class CustomerListContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fromDashboard: (props.location && props.location.state && props.location.state.fromDashboard === true),
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.customerList,
				"StartDate": new Date()
			},
			searchString: '',
			searchSubmit: '',
			refreshCustomerListTable: ''
		}
	}
	componentDidMount() {
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}

	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof CustomerListContainer
	 */
	applicationLogger(key) {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.customerList,
			"Content": {
				"TimeSpent": timeSinceLoad
			}
		}
		logger(loggerObj);
	}
	/** 
	 * 	@description Function to handle search submit
	 *  @param searchString The value in the input field
	 *  @memberof CustomerListContainer
	 */
	handleSearchSubmit = (searchString) => {
		this.setState({ searchString: searchString, searchSubmit: true, refreshCustomerListTable: '' });
	}
	/** 
	 *  @description Function to handle search reset
	 *  @memberof CustomerListContainer
	 */
	handleReset = () => {
		this.setState({ searchString: '', searchSubmit: '', refreshCustomerListTable: '' });
	}


	render() {
		return (
			<CustomerListComponent
				loggerObj={this.state.loggerObj}
				fromDashboard={this.state.fromDashboard}
				handleSearchSubmit={this.handleSearchSubmit}
				handleReset={this.handleReset}
				refreshCustomerListTable={this.state.refreshCustomerListTable}
				searchString={this.state.searchString}
				searchSubmit={this.state.searchSubmit}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(CustomerListContainer);