import React from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { mapCustomerSurgeonsService, deleteSurgeonService } from "../../services/java/java-services";
import { actions, customerMapping, loggerEventName } from '../../helpers/messages';
import { Link } from "react-router-dom";
import CustomModal from "../../shared/CustomModal";
import ErrorMsgBlockComponent from "../Error/ErrorMessageBlock";

// Define a default UI for filtering
/* istanbul ignore next  */
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows && preFilteredRows.length ? preFilteredRows.length : 0;
    return (
        <input
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}
/** @description function to create UI for Data list
 *  @param  {}
 */
function Table({
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    totalCount,
    listType,
    disableColmSort,
    showSuccessMsg,
    showErrorMsg,
    isApiRequest,
    showSurgeonAssociationConfirmModal,
    surgeonValues,
    showHideConfirmCustomerSurgeonModal,
    deleteSurgeon,
    action,
    showConfirmMappingModal,
    showHideConfirmSurgeonModal,
    confirmMappingSurgeon
}) {
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, sortBy: [] },
            manualPagination: true,
            autoResetPage: false,
            manualSortBy: false,
            disableFilters: false,
            disableSortBy: disableColmSort ? disableColmSort : false,
            pageCount: controlledPageCount,
            totalCount: totalCount,
        },
        useFilters,
        useSortBy,
        usePagination
    );
    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Render the UI for your table
    /* istanbul ignore next  */
    return (
        <>
            {(data && data.length) || loading ?
                <>
                    {/* Code for displaying messages based on actions goes here */}
                    {showSuccessMsg === 'Success' ? (
                        <div className="proms-success-msg">
                            <p>{action === 'adminSurgeonCustomerMap' ? customerMapping.mappingSuccess : showSuccessMsg}</p>
                        </div>
                    ) : null}
                    {showErrorMsg ? (
                        <div className="data-list-error-msg">
                            <ErrorMsgBlockComponent className="pt-2 m-0" errorObject={showErrorMsg} />
                        </div>
                    ) : null}
                    {isApiRequest ? (
                        <div className="loading-overlay-upload add-surgeon-overlay">
                            <span>{actions.request}</span>
                        </div>
                    ) : null}
                    {showSuccessMsg === 'removeCustomer' ? (
                        <div className="proms-success-msg">
                            <p>{customerMapping.removeSurgeon}</p>
                        </div>
                    ) : null}


                    <div>
                        <table
                            {...getTableProps()}
                            id={listType}
                            className={
                                listType
                                    ? listType + " table-bordered table-hover "
                                    : "table-bordered table-hover"
                            }
                        >
                            <thead className="rt-thead">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                title=""
                                            >
                                                {column.render("Header")}
                                                {/* Add a sort direction indicator */}
                                                <span>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <svg
                                                                className="bi bi-chevron-down"
                                                                width="1.2em"
                                                                height="1.2em"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="bi bi-chevron-up"
                                                                width="1.2em"
                                                                height="1.2em"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                                <div>
                                                    {column.canFilter ? column.render("Filter") : null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            {loading ? (
                                <tbody className="rt-tbody tr-refresh">
                                    {[...Array(10)].map((e, i) => (
                                        <tr key={i}>
                                            {[...Array(headerGroups && headerGroups.length ? headerGroups[0].headers.length : 1)].map((f, j) => (
                                                <td className="loading-cell" key={j}>
                                                    <span></span>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="10000">{actions.load}</td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody {...getTableBodyProps()} className="rt-tbody">
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                    <tr className="result-count">
                                        <td colSpan="10000">
                                            Showing {page && page.length ? page.length : 0} of {totalCount} results
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>

                        {/* Modal to confirm surgeon mapping with customer */}
                        <CustomModal
                            id="confirm-surgeon-modal"
                            show={showConfirmMappingModal}
                            isConfirm={true}
                            title="Alert"
                            body={customerMapping.mappingAlert}
                            closeAction={() => showHideConfirmSurgeonModal()}
                            rejectAction={() => showHideConfirmSurgeonModal()}
                            confirmAction={() => confirmMappingSurgeon()}
                        />

                        {/* Modal to delete surgeon from customer profile */}
                        <CustomModal
                            id="delete-surgeon-modal"
                            show={showSurgeonAssociationConfirmModal}
                            isConfirm={true}
                            title="Alert"
                            body={customerMapping.removeAlert}
                            closeAction={() => showHideConfirmCustomerSurgeonModal(surgeonValues)}
                            rejectAction={() => showHideConfirmCustomerSurgeonModal(surgeonValues)}
                            confirmAction={() => deleteSurgeon()}
                        />
                    </div>
                </>
                : <ErrorMsgBlockComponent noData={true} />}
        </>
    );
}

function AssociatedCustomerList(props) {
    let getColumnsFromProps = props.columns ? props.columns : ""; // get columns and datalist type as props
    // eslint-disable-next-line
	let columnsList = [];
    // add the button based on the columns input
    getColumnsFromProps &&
        getColumnsFromProps.forEach((element) => {
            /* istanbul ignore next  */

            if (element.SearchAction) {
                let searchActions = {
                    Header: element.Header,
                    Cell: ({ row }) => (
                        <div className="case-actions">
                            <svg id="openSurgeonModal" onClick={() => mapCustomer(row.original)} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus-circle cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                        </div>
                    ),
                };
                columnsList.push(searchActions);
            } else if (element.AssociatedCustomerAction) {
                let AssociatedCustomerAction = {
                    Header: element.Header,
                    Cell: ({ row }) => (
                        <div >
                            <Link to={`/customer/${row.original.customerid}`}>
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
                                    <path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            </Link>
                            <svg onClick={() => showHideConfirmCustomerSurgeonModal(row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash delete-icon cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </div>
                    ),
                };
                columnsList.push(AssociatedCustomerAction);
            } else if (element.Filter) {
                // add column filter
                let filter = {
                    Header: element.Header,
                    accessor: element.accessor,
                    Filter: DefaultColumnFilter,
                };
                columnsList.push(filter);
            } else {
                columnsList.push(element);
            }
        });

    const columns = React.useMemo(() => columnsList, [columnsList]);
    // We'll start our table without any data
    const [surgeonList, setSurgeonList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [isApiRequest, setIsApiRequest] = React.useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = React.useState('');
    const [mappingRemoveObj, setMappingRemoveObj] = React.useState([]);
    const surgeonValues = React.useState(false);
    const [showErrorMsg, setShowErrorMsg] = React.useState(null);
    const [showSurgeonAssociationConfirmModal, setShowSurgeonAssociationConfirmModal] = React.useState(false);
    const [showConfirmMappingModal, setShowConfirmMappingModal] = React.useState(false);
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedCustomerDetails, setSelectedCustomerDetails] = React.useState('');
    const fetchIdRef = React.useRef(0);
    const [errorObj, setErrorObj] = React.useState(null);

    /**
      * @description Function to confirm mapping surgeon with customer
     * @param  {}
     */
    /* istanbul ignore next  */
    const showHideConfirmSurgeonModal = React.useCallback(() => {
        if (showConfirmMappingModal) {
            setSelectedCustomerDetails('');
            setShowErrorMsg(null);
            setShowSuccessMsg('');
            setIsApiRequest(false);
            setShowConfirmMappingModal(false);
        } else {
            setShowConfirmMappingModal(true);
        }
    }, [showConfirmMappingModal]);

    /**
    * @description Function to delete surgeon from customer profile
    * @param  {}
    */
    /* istanbul ignore next  */
    const deleteSurgeonAPI = async (params) => {
        setLoading(true);
        const loggerObj = {
            eventName: loggerEventName.removeCustomerFromSurgeon,
            eventMessage: customerMapping.removeSurgeon
        }
        deleteSurgeonService(params, loggerObj, (err) => {
            if (err) {
                setShowErrorMsg(err);
                setLoading(false);
                setTimeout(() => {
                    setShowErrorMsg(null);
                }, 5000);
            } else {
                setShowSuccessMsg('removeCustomer');
                setTimeout(() => {
                    props.refreshCustomer(true);
                }, 1000);
                setLoading(false);
            }
        })
    }

    /**
      * @description Function to confirm delete surgeon from customer profile
     * @param  {}
     */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const showHideConfirmCustomerSurgeonModal = React.useCallback(({ name }) => {
        setShowSuccessMsg('');
        let params = {
            customerName: name,
            surgeonName: props.surgeonUsername,
        }
        setMappingRemoveObj(params)
        showSurgeonAssociationConfirmModal ? setShowSurgeonAssociationConfirmModal(false) : setShowSurgeonAssociationConfirmModal(true);
    });

    /**
    * @description Function to delete surgeon from customer profile
    * @param  {}
    */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const deleteSurgeon = React.useCallback(() => {
        setShowSurgeonAssociationConfirmModal(false);
        deleteSurgeonAPI(mappingRemoveObj);
    });

    /**
   * @description Function to map surgeons
   * @param  {}
   */
    /* istanbul ignore next  */
    const reponseHandling = (err) => {
        if (err) {
            setShowErrorMsg(err);
            setIsApiRequest(false);
            setTimeout(() => {
                setShowErrorMsg('');
            }, 5000);
        } else {
            setShowSuccessMsg('Success');
            props.refreshDataList(true);
            setIsApiRequest(false);
            setTimeout(() => {
                setShowSuccessMsg('');
            }, 5000);
        }
    }

    /**
     * @description Function to map surgeons
     * @param  {}
     */
    /* istanbul ignore next  */
    const confirmMappingSurgeon = async () => {
        setShowErrorMsg(null);
        setShowSuccessMsg('');
        setIsApiRequest(true);
        setShowConfirmMappingModal(false);
        const customerSurgeonObj = {
            "customerName": selectedCustomerDetails.name,
            "surgeonName": props.surgeonUsername
        }
        props.refreshDataList(false);
        mapCustomerSurgeonsService(customerSurgeonObj, (err) => {
            /* istanbul ignore next  */
            reponseHandling(err);
        });
    }

    /**
     * @description Function to map surgeons
     * @param  {}
     */
    /* istanbul ignore next  */
    const mapCustomer = async (customer) => {
        if (props.action === 'adminSurgeonCustomerMap' && props.surgeonUsername) {
            setSelectedCustomerDetails(customer);
            setShowConfirmMappingModal(true);
        }
    }

    const fetchData = React.useCallback(
        () => {
            // Give this fetch an ID
            const fetchId = ++fetchIdRef.current;
            /* istanbul ignore next  */
            if (fetchId === fetchIdRef.current) {
                switch (props.listType) {
                    case "customer-search":
                    case "surgeon-customers":
                        if (props.error) {
                            setErrorObj(props.error);
                        } else {
                            setErrorObj(null);
                            setTotalCount(props.searchData ? props.searchData.length : 0)
                            setSurgeonList(props.searchData || []);
                        }
                        break;
                    default:
                        break;
                }
                setShowSuccessMsg('');
            }
        },
        [props.listType, props.searchData, props.error]
    );

    return (
        <>
            {errorObj ? <ErrorMsgBlockComponent errorObject={errorObj} /> :
                <Table
                    columns={columns}
                    data={surgeonList}
                    fetchData={fetchData}
                    loading={loading}
                    totalCount={totalCount}
                    showPagination={props.showPagination}
                    disableColmSort={props.disableColmSort}
                    showSurgeonAssociationConfirmModal={showSurgeonAssociationConfirmModal}
                    showConfirmMappingModal={showConfirmMappingModal}
                    showHideConfirmSurgeonModal={showHideConfirmSurgeonModal}
                    showHideConfirmCustomerSurgeonModal={showHideConfirmCustomerSurgeonModal}
                    confirmMappingSurgeon={confirmMappingSurgeon}
                    deleteSurgeon={deleteSurgeon}
                    showSuccessMsg={showSuccessMsg}
                    showErrorMsg={showErrorMsg}
                    isApiRequest={isApiRequest}
                    searchData={props.searchData}
                    surgeonValues={surgeonValues}
                    mappingRemoveObj={mappingRemoveObj}
                    action={props.action}
                />
            }
        </>
    );
}
export { AssociatedCustomerList, Table };
