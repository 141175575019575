import React, { Component } from 'react';
import { downTimeSettingsMessages } from '../../helpers/messages';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

export default class DownTimeComponent extends Component {
    /* istanbul ignore next  */
    constructor(props) {
        super(props);
        this.filterRef = React.createRef();
    }

    render() {
        const { todayDate, downtimeFromDate, downtimeTodate, errorMessage, onInputChange, status, successMessage, handleSubmit, loading, error } = this.props;
        return (
            <form ref={(el) => this.filterRef = el}>
                <div className="reset-password">
                    <h6>{downTimeSettingsMessages.settingsHeader}</h6>
                    {errorMessage ? <div className="text-danger">{errorMessage}</div> : null}
                    {successMessage ? <div className="reset-pwd-success-msg"><p>{successMessage}</p></div> : ''}
                    {error ? <ErrorMsgBlockComponent className="downtime-error" errorObject={error} /> : ''}

                    <div className="d-flex align-items-center justify-content-left">
                        <div className="reset-form w-40">
                            <div className="start-time-wrap">
                                <label className="col-sm-4">Start Time<span className="mandatory">*</span></label>
                                <div className="reset-password-input-wrap old-password">
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        placeholder="Select start of downtime"
                                        name="downtimeFromDate"
                                        id="downtimeFromDate"
                                        autoComplete="off"
                                        value={downtimeFromDate}
                                        min={todayDate}
                                        step="60"
                                        disabled={loading}
                                        onChange={(e) => onInputChange(e, 'downtimeFromDate')}
                                    />
                                </div>
                            </div>

                            <div className="end-time-wrap">
                                <label className="col-sm-3">End Time<span className="mandatory">*</span></label>
                                <div className="reset-password-input-wrap new-password">
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        name="downtimeTodate"
                                        id="downtimeTodate"
                                        autoComplete="off"
                                        placeholder="Select end of downtime"
                                        min={downtimeFromDate || todayDate}
                                        value={downtimeTodate}
                                        step="60"
                                        disabled={loading}
                                        onChange={(e) => onInputChange(e, 'downtimeTodate')}
                                    />
                                </div>
                            </div>

                            <div className="status-wrap">
                                <div className="reset-password-input-wrap">
                                        <label className="status-checkbox d-flex align-items-center">
                                            <input type="checkbox"
                                                onChange={(e) => onInputChange(e, 'status')}
                                                id="downtime-status"
                                                checked={status === true}
                                                disabled={loading}
                                            />
                                            <span className="ps-2">Show this message on dashboard</span>
                                        </label>
                                </div>
                            </div>
                            <button className="btn btn-primary proms-btn-color float-end" disabled={loading} id="saveSettings" onClick={handleSubmit}>
                                {loading ? 'Saving...' : 'Save Changes'}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}
