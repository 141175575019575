import React, { Component } from "react";
import { getAllSalesRepListService } from "../../services/java/java-services";
import { getUserName } from "../../services/aws/aws-services";

export default class CommentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      salesRepList: this.props?.salesRepList || [],
    };
    this.getSalesRepList = this.getSalesRepList.bind(this)
  }

/**
 * @description get the list of the sales reps users for admin
 * @memberof CommentModal
 */
  getSalesRepList() {
     /* istanbul ignore next  */
    getAllSalesRepListService(getUserName(), (err, result) => {
     
      if (err) {
        this.setState({
          errorMessage: typeof err === "string" ? err : JSON.stringify(err),
        });
      } else {

        this.setState({
          salesRepList: result.data.SalesRepList,
        });
      }
    });
  }

  componentDidMount() {
    this.getSalesRepList();
  }

  render() {
    const { salesRepList } = this.state;
    const { onOptionChangeHandler, commentHandler, errors } = this.props;
    return (
      <div className="comment-modal">
        <form>
          <div className="form-group">
            <label htmlFor="users" className="label">
              Requestor:
            </label>
            <select
                className={`form-select form-select-sm w-100 ${errors && errors.requestedBy ? "is-invalid" : ""
                }`}
              onChange={(event) => onOptionChangeHandler(event)}
              id="users"
            >
              <option value='Select User'>Select User</option>
              {salesRepList?.map((option, index) => (
                option?.username && <option key={option?.salesrepId + index} value={option?.username}>
                  {option?.salesRepName}
                </option>

              ))}
            </select>
            {errors && errors.requestedBy ? (
              <div className="invalid-feedback">
                <p>{errors.requestedBy}</p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <label htmlFor="comment" className="label">
              Comment:
            </label>
            <div>
              <div className="flex">
                <textarea
                  id="Usercomment"
                  rows={6}
                  cols={43}
                  maxLength={250}
                  placeholder="write a comment..."
                  required
                  className={`form-control ${errors && errors.Comment ? "is-invalid" : ""
                    }`}
                  onChange={commentHandler}
                />
                <p
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: "0px",
                  }}
                >
                  <small>250 characters</small>
                </p>
                {errors && errors.Comment ? (
                  <div className="invalid-feedback">
                    <p>{errors.Comment}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
