import React, { Component } from 'react';
import { connect } from 'react-redux';
import SurgeonListComponent from '../../components/SurgeonList/SurgeonList';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
let startDate;
class SurgeonListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDashboard: (props.location && props.location.state && props.location.state.fromDashboard === true),
      loggerObj: {
        "EventOutcome": loggerEventOutcome.success,
        "EventType": loggerEventTypes.read,
        "EventName": loggerEventName.surgeonList,
        "StartDate": new Date()
      },
      searchString: '',
			searchSubmit: '',
			refreshSurgeonListTable: ''
    }
  }
  componentDidMount() {
    // initialize the start date on page load
    startDate = new Date();
    window.addEventListener('beforeunload', this.applicationLogger);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.applicationLogger);
    this.applicationLogger();
  }

  /**
   * @description function to handle application logs
   * @param {*}
   * @memberof SurgeonListContainer
   */
  applicationLogger(key) {
    // calculate the time since we loaded this page
    const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
    const loggerObj = {
      "EventOutcome": loggerEventOutcome.success,
      "EventType": loggerEventTypes.read,
      "EventName": loggerEventName.surgeonList,
      "Content": {
        "TimeSpent": timeSinceLoad
      }
    }
    logger(loggerObj);
  }
	/** 
	 * 	@description Function to handle search submit
	 *  @param searchString The value in the input field
	 *  @memberof SurgeonListContainer
	 */
   handleSearchSubmit = (searchString) => {
		this.setState({ searchString: searchString, searchSubmit: true, refreshSurgeonListTable: '' });
	}
	/** 
	 *  @description Function to handle search reset
	 *  @memberof SurgeonListContainer
	 */
	handleReset = () => {
		this.setState({ searchString: '', searchSubmit: '', refreshSurgeonListTable: '' });
	}

  render() {
    return (
      <SurgeonListComponent loggerObj={this.state.loggerObj} 
      fromDashboard={this.state.fromDashboard}
      handleSearchSubmit={this.handleSearchSubmit}
      handleReset={this.handleReset}
      refreshSurgeonListTable={this.state.refreshSurgeonListTable}
      searchString={this.state.searchString}
      searchSubmit={this.state.searchSubmit} />
    );
  }
}

function mapStateToProps(state) {
  return {
    state,
  };
}

export default connect(mapStateToProps)(SurgeonListContainer);