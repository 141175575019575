import React, { Component } from 'react';
import CustomModal from '../../shared/CustomModal';
import { salesRepMessages } from '../../helpers/messages';

export default class DownloadError extends Component {
    render() {
        const { isVisible, closeModal } = this.props;
        return (
            <div>
                <CustomModal show={isVisible} dialogClassName='overlapping-points-modal border rounded' isComponent={true} title='Check Hospital Association' closeAction={closeModal}>
                    <div className='py-3'>
                        <p className='mb-3'>{salesRepMessages.downloadErrorOne}</p>
                        <p>{salesRepMessages.downloadErrorTwo}</p>
                    </div>
                    <button type='button' className='btn btn-primary' onClick={closeModal}>
                        Close
                    </button>
                </CustomModal>
            </div>
        );
    }
}
