import React, { Component } from 'react'
import Select from 'react-dropdown-select';
import { actions } from '../../helpers/messages';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

export default class AddDeviceComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
        this.state = {
            selectedCustomer: []
        }
    }
    /**
     * @description Function to select the customer list
     * @memberof AddDeviceComponent
     */
    customerChanged = (values) => {
        if (values && values.length) {
            let customer = values[0];
            this.setState({ selectedCustomer: values });
            this.props.handleInputChange('customerID', customer.customerid);
        } 
        else {
            this.setState({ selectedCustomer: [] });
            this.props.handleInputChange('customerID', null);
            }
    }

    render() {
        const { customers, loading, deviceObj, handleInputChange, onEnterPress, formErrors, saveDevice, deviceSaveError, handleCancel,isCustomerDisable } = this.props;
        const selectedCustomer = deviceObj && deviceObj.customerID ? customers.filter(x => x.customerid === deviceObj.customerID) : [];
        return (
            <>
                {loading ? <div className="loading-overlay-upload add-surgeon-overlay"><span>{actions.load}</span></div> : ''}
                <div className="surgeon-details">
                    <div className="add-device-container">
                        <div className="card-body p-0">
                            <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                                <div className="row">
                                    {deviceSaveError ?
                                        <div className="col-sm-12">
                                            <div className="customer-save-error-msg">
                                                <ErrorMsgBlockComponent className="pb-4 m-0" errorObject={deviceSaveError} />
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                                <fieldset className="customer-fields device-fields">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Serial Number<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        id="serial-no"
                                                        autoComplete="off"
                                                        minLength={3}
                                                        maxLength={12}
                                                        className={`form-control ${formErrors && formErrors.slNo ? 'has-error' : ''}`}
                                                        value={deviceObj && deviceObj.slNo ? deviceObj.slNo : ''}
                                                        onChange={(e) => handleInputChange('slNo', e.target.value)}
                                                    />
                                                    {formErrors && formErrors.slNo ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.slNo}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Customer<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <Select
                                                        id="select-customer-device"
                                                        className={`form-control ${!loading && formErrors && formErrors.customerID ? 'has-error' : ''}`}
                                                        placeholder="Search Customers"
                                                        values={selectedCustomer ? selectedCustomer : []}
                                                        options={customers}
                                                        disabled={isCustomerDisable}
                                                        clearable={false}
                                                        searchBy="name"
                                                        labelField="name"
                                                        valueField="customerid"
                                                        onChange={(values) => this.customerChanged(values)}
                                                    />
                                                    {formErrors && formErrors.customerID ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.customerID}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 text-end">
                                        <button type="button" id="save-btn" className="btn btn-primary case-action-btn" onClick={saveDevice}>Save</button>
                                        <button type="button" id="cancel-btn" className="btn btn-secondary me-0" onClick={handleCancel}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
