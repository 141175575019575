import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { formatDateTime, signedRoundOff, userHasPermission } from '../../helpers/GlobalFunctions';
import { withRouter } from "react-router-dom";
import { graphContextMenuItems } from '../../helpers/constants';
import { exportAddOns } from '../../helpers/ChartExports';

class AnalyticsScatterChart extends Component {
    constructor() {
        super();
        this.state = {
            scatterChartOptions: ''
        }
    }
    componentDidMount() {
        /* istanbul ignore next  */
        this.createChartWithHC();
    }
    /**
    * @description function to Create chart with Highcharts
    * @param {*}
    * @memberof AnalyticsScatterChart
    */
    /* istanbul ignore next  */
    createChartWithHC() {
        const { showUnit, rangeUnit, lowUnit, highUnit, unit, analyticsData, chartDetails, spacingRight, legendMargin, showTextInToolTip } = this.props;
        var getAnalyticsData = analyticsData ? analyticsData : {};
        var setAnalyticsData = [];
        var xAxis = getAnalyticsData.caseInfo && getAnalyticsData.caseInfo.length ? Array.from({ length: getAnalyticsData.caseInfo.length }, (_, j) => j + 1): [];
        var i = 0;
        if (getAnalyticsData.series && getAnalyticsData.series.length) {
            getAnalyticsData.series.forEach(element => {
                var dataArry = [];
                element.data.forEach(value => {
                    // Pushing the rounded off signed values for plotting. Tooltip will show rounded off absolute values
                    dataArry.push(parseFloat(signedRoundOff(value)));
                });
                let dataObj = {
                    name: chartDetails.legends[i],
                    color: chartDetails.colors[i],
                    data: dataArry
                }
                setAnalyticsData.push(dataObj);
                i++;
            });
        }

        // allow for spline like curves
        // Highcharts.seriesTypes.scatter.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline;
        var scatterChartOptions = {
            exporting: {
                sourceWidth: 900,
                sourceHeight: 350,
                buttons: {
                    contextButton: {
                        menuItems: graphContextMenuItems
                    },
                    toggleLineOn: {
                        enabled: true,
                        text: '<span style="font-weight: normal;">Toggle Lines</span>',
                        theme: {
                            fill: '#fff',
                            stroke: '#888',
                            paddingLeft: 2,
                            states: {
                                hover: {
                                    fill: '#e5e5e5',
                                    stroke: '#888'
                                },
                                select: {
                                    fill: '#e5e5e5',
                                    stroke: '#888'
                                },
                            }
                        },
                        onclick: function () {
                            let series = this.series;
                            var self = this.exportSVGElements[2];
                            if (series && series.length) {
                                series.forEach(serie => {
                                    if (serie.options.lineWidth === 0) {
                                        self.setState(2);
                                        serie.update({
                                            lineWidth: 1.5
                                        })
                                    } else {
                                        self.setState(0);
                                        serie.update({
                                            lineWidth: 0,
                                        })
                                        if (serie.graph) {
                                            serie.graph = serie.graph.destroy();
                                        }
                                    }
                                });
                            }
                        }
                    },
                },
                chartOptions: {
                    chart: {
                        marginTop: 100,
                        marginBottom: 80,
                        events: {
                            load: function () {
                                if (this.options.chart.forExport) {
                                    this.update({ chart: { spacingRight: spacingRight + 20 }})

                                    if (this.xAxis && this.xAxis.length) {
                                        this.xAxis[0].update({
                                            labels: {
                                                formatter: (event) => {
                                                    return `<span>${event.value}</span>`;
                                                },
                                            }
                                        });
                                    }
                                }
                                exportAddOns(this);
                            }
                        }
                    }
                }
            },
            chart: {
                type: 'scatter',
                zoomType: 'xy',
                spacingRight: spacingRight,
                events: {
                    load: function () {
                        if (this.yAxis && this.yAxis.length) {
                            var yAxisTitleMargin = 10;
                            const maxLabelLength = this.yAxis[0].paddedTicks && this.yAxis[0].paddedTicks.length ? Math.max(...(this.yAxis[0].paddedTicks.map(tick => tick.toString().length))) : 4;
                            if (maxLabelLength >= 4) {
                                yAxisTitleMargin = 0;
                            }
                            if (maxLabelLength === 3) {
                                yAxisTitleMargin = 6;
                            }
                            if (maxLabelLength < 2) {
                                yAxisTitleMargin = 17;
                            }
                            this.yAxis[0].update({
                                title: {
                                    margin: yAxisTitleMargin
                                }
                            });
                        }

                        this.reflow();
                    },
                    render: (event) => {
                        var chart = event.target;
                        var axis = chart.xAxis[0];
                        var ticks = axis.ticks;
                        var tickPositions = Object.keys(ticks);
                        
                        if (ticks && tickPositions.length) {
                           tickPositions.forEach((pos, index) => {
                              if (ticks[index] && ticks[index].label) {
                                 var label = ticks[index].label.element;
                                 const caseInfo = getAnalyticsData.caseInfo[index];
                                 label.onclick = () => {
                                    setTimeout(() => {
                                       this.individualCaseSummary(caseInfo);
                                    }, 0);
                                 }
                              }
                           });
                        }
                     }
                }
            },
            title: {
                text: chartDetails ? chartDetails.graphTitle : ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    enabled: true,
                    text: chartDetails ? chartDetails.labelXTitle : ''
                },
                labels: {
                    formatter: (event) => {
                        const caseInfo = getAnalyticsData.caseInfo[event.value - 1];

                        return '<div class="graphs-label-tooltip label-link graphs-label-point' + event.value + '" > ' + event.value + '<span class="tooltiptext">Case ID: ' + caseInfo.caseId +
                            (userHasPermission('patientData') ? ('<br/>Patient Name: ' + (caseInfo.patientname ? caseInfo.patientname : 'N/A')) : '') +
                            '<br/>Surgery Date: ' + (caseInfo.dateOfSurgery ? formatDateTime(caseInfo.dateOfSurgery) : 'N/A') + '</span></div>';
                    },
                    useHTML: true
                },
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true
            },
            yAxis: {
                title: {
                    text: chartDetails ? chartDetails.labelYTitle : ''
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                floating: false,
                backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                borderWidth: 1,
                margin: legendMargin
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: (event) => {
                                const caseInfo = getAnalyticsData.caseInfo[event.point.category - 1];
                                this.individualCaseSummary(caseInfo);
                            }
                        }
                    }
                },
                scatter: {
                    lineWidth: 0,
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                }
            },
            tooltip: {
                positioner: function (labelWidth, labelHeight, point) {
                    const adjustXBy = labelWidth > 95 ? 0 : 30;
                    const adjustYBy = labelHeight < 40 ? 0 : 15;

                    return {
                        x: point.plotX + adjustXBy,
                        y: point.plotY - adjustYBy
                    };
                },
                formatter: function () {
                    function getTooltip(yValue, serie) {
                        const unitToShow = rangeUnit ? ((Math.sign(yValue) === -1) || ((1 / Math.sign(yValue)) === -Infinity) ? lowUnit : highUnit) : (unit ? unit : '');
                        const val = Math.abs(yValue); // Taking absolute value from the plotted point
                        return showTextInToolTip ? `<strong>${serie.name}: ${val}${showUnit ? unitToShow : ''} </strong><br/>` : `<strong> ${val}${showUnit ? unitToShow : ''} </strong>`;
                    }
                    var tooltip = getTooltip(this.y, this.series)
                    const series = this.series.chart.series;
                    for (let index = 0; index < series.length; index++) {
                        const serie = series[index];
                        const yValue = serie.processedYData[this.point.index];
                        if (yValue === this.y && this.series.name !== serie.name && serie.visible) {
                            tooltip += `<br/>${getTooltip(yValue, serie)}`;
                        }
                    }

                    return tooltip;
                }
            },
            series: setAnalyticsData
        };
        this.setState({ scatterChartOptions: scatterChartOptions })
    }

    /**
    * @description Function to redirect to case details page on data point and label click
    * @param caseInfo Details of the case clicked
    * @memberof AnalyticsScatterChart
    */
       /* istanbul ignore next  */
    individualCaseSummary = (caseInfo) => {
        /* istanbul ignore next  */
        this.props.history.push({
            pathname: `/casedetails/${caseInfo.caseId}`,
            state: {
                id: caseInfo.caseId,
                surgeonName: caseInfo.surgeonname,
                activeTab: 'Trends'
            }
        })
    }

    render() {
        const { analyticsData } = this.props;
        return (<div className="chart-blocks">
            {analyticsData && this.state.scatterChartOptions ? <div className="gaps-container-highchart" >
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.scatterChartOptions}
                    containerProps = {{ style: {height: '400px'} }}
                />
            </div> : null}
        </div>);
    }
}
export default withRouter(AnalyticsScatterChart);
