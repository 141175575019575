import React, { Component } from 'react';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { withRouter } from 'react-router';
import PreOpTableContainer from '../PreOpPlan/PreOpTableContainer';

export class SaleRepTableContainer extends Component {

    render() {
        return (
            <ErrorBoundary>
                <PreOpTableContainer isPending={this.props.location.search === '?ispending=true'} />
            </ErrorBoundary>
        );
    }
}

export default withRouter(SaleRepTableContainer);
