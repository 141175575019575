import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Sidemenu from '../SideMenu/SideMenu';
import TimingDetails from './TimingDetails';
import PromScores from './PromScores';
import { Link } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { actions } from '../../helpers/messages';
import { NAHelpText } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import TKASummary from './TKASummary';
import HIPSummary from './HIPSummary';
import { operativeProcedureTypes } from '../../helpers/constants';
import ScreenshotsContainer from '../../containers/CaseDetails/Screenshots';
import THATimingDetails from './THATimingDetails';

class CaseDetailsComponent extends Component {
	/**
	 * @description Function to display case summary based on procedure
	 * @param operativeProcedure The procedure implemented
	 * @memberof CaseDetailsComponent
	 */
	getCaseSummaryTemplate = (operativeProcedure) => {
		let procedure = operativeProcedure.toString().toLowerCase();
		const { caseData, isLoading, gapLoading, gapData, gapError, handleNoteSubmit, notesError, notesSaved, isReadOnlyNotes, handleNotesInputChange, getUserRoleType,caseId } = this.props
		switch (procedure) {
			case operativeProcedureTypes.TKA.inLowerCase:
				return <TKASummary
					caseData={caseData}
					isLoading={isLoading}
					gapLoading={gapLoading}
					gapData={gapData}
					gapError={gapError}
					handleNoteSubmit={handleNoteSubmit}
					notesError={notesError}
					notesSaved={notesSaved}
					isReadOnlyNotes={isReadOnlyNotes}
					handleNotesInputChange={handleNotesInputChange}
					getUserRoleType={getUserRoleType}
					caseId={caseId}

				/>
			case operativeProcedureTypes.HIP.inLowerCase:
				return <HIPSummary
					caseData={caseData}
					isLoading={isLoading}
					handleNoteSubmit={handleNoteSubmit}
					notesError={notesError}
					notesSaved={notesSaved}
					isReadOnlyNotes={isReadOnlyNotes}
					handleNotesInputChange={handleNotesInputChange}
					getUserRoleType={getUserRoleType}
					caseId={caseId}

				/>
			default:
				return null;
		}
	}

	/**
	 * @description Function to display case timing based on procedure
	 * @param operativeProcedure The procedure implemented
	 * @memberof CaseDetailsComponent
	 */
	getCaseTimingTemplate = (operativeProcedure) => {
		let procedure = operativeProcedure.toString().toLowerCase();
		const { caseId, timingData, caseTimingData } = this.props;
		switch (procedure) {
			case operativeProcedureTypes.TKA.inLowerCase:
				return timingData && caseTimingData ? <TimingDetails caseId={caseId} timingData={timingData} caseTimingData={caseTimingData} /> : this.handleCaseTimingError();
			case operativeProcedureTypes.HIP.inLowerCase:
				return timingData ? <THATimingDetails caseId={caseId} timingData={timingData} /> : this.handleCaseTimingError();
			default:
				return null;
		}
	}

	/**
	 * @description Function to handle error and no data scenario for case timing
	 * @memberof CaseDetailsComponent
	 */
    /* istanbul ignore next  */
    // eslint-disable-next-line
	handleCaseTimingError = () => {
		const { timingError } = this.props;
		return timingError ? <ErrorMsgBlockComponent errorObject={timingError} /> : <ErrorMsgBlockComponent noData={true} />
	}

	render() {
		const { caseData, caseId, operativeProcedure, isLoading, detailsLoading, handleTabSelect, detailsError, timingDataLoading, caseTimingDataLoading, getPROMGraphError,
			handleInputChange, addPromScore, formErrors, promScoreSave, promsErrorMsg, outComeScoreData, handleOutcomeScoreType, refreshDataList, selectedTab, refreshOutcomeScore,
			surgeonUserName, getUserRoleType, onEnterPress, disableReset, resetForm, loggerObj, isCustomScore, scoreType, scoreFields, subScores, scoreValue, pageFrom } = this.props;
		const isRarUser = getUserRoleType.includes('rar');
		return (
			<div>
				<Header />
				<div className="bgGray">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-1">
								<Sidemenu loggerObj={loggerObj} />
							</div>
							<div className="col-md-11">
								<div className="datalist-style case-details-wrap p-0">
									<div className="case-details-top-block">
										<h1 className="case-details-heading">Case Summary Report</h1>
										<div className="case-details-back-btn text-end">
											<Link className="btn btn-primary case-action-btn" id="view-case" to={{ pathname: '/caselist', state: { activeTab: pageFrom } }}>
												Back to {pageFrom === 'Trends' ? 'Trends' : 'Case List'}
											</Link>
										</div>
									</div>
									<div className="case-details-tabs">
										<div className="case-details-na">{NAHelpText()}</div>
										<Tabs defaultActiveKey="overview" id="uncontrolled-tab-example" onSelect={handleTabSelect}>
											<Tab eventKey="overview" title="Overview">
												{detailsLoading ? ''
													: caseData ? this.getCaseSummaryTemplate(operativeProcedure)
														: detailsError ? <ErrorMsgBlockComponent errorObject={detailsError} />
															: <ErrorMsgBlockComponent noData={true} />}
											</Tab>
											<Tab eventKey="Timing" title="Case Time">
												{caseData ? selectedTab === 'Timing'
													? timingDataLoading || caseTimingDataLoading ? ''
														: this.getCaseTimingTemplate(operativeProcedure)
													: ''
													: <ErrorMsgBlockComponent noData={true} />
												}
											</Tab>
											<Tab eventKey="proms" title="PROM Scores">
												{caseData ? selectedTab === 'proms' ?
													<PromScores
														selectedTab={selectedTab}
														caseId={caseId}
														operativeProcedure={operativeProcedure}
														formErrors={formErrors}
														addPromScore={addPromScore}
														promScoreSave={promScoreSave}
														promsErrorMsg={promsErrorMsg}
														isLoading={isLoading}
														outComeScoreData={outComeScoreData}
														getPROMGraphError={getPROMGraphError}
														refreshDataList={refreshDataList}
														refreshOutcomeScore={refreshOutcomeScore}
														handleOutcomeScoreType={handleOutcomeScoreType}
														handleInputChange={handleInputChange}
														isCustomScore={isCustomScore}
														scoreType={scoreType}
														scoreFields={scoreFields}
														subScores={subScores}
														scoreValue={scoreValue}
														surgeonUserName={surgeonUserName}
														getUserRoleType={getUserRoleType}
														onEnterPress={onEnterPress}
														disableReset={disableReset}
														resetForm={resetForm} />
													: ''
													: <ErrorMsgBlockComponent noData={true} />}
											</Tab>
											<Tab eventKey="screenshots" title="Screenshots" disabled={operativeProcedure === operativeProcedureTypes.HIP.raw || isRarUser}>
												<ScreenshotsContainer selectedTab={selectedTab} caseID={caseId} getUserRoleType={getUserRoleType} />
											</Tab>
										</Tabs>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{isLoading ? <div className="loading-overlay"><span>{actions.load}</span></div> : null}
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(CaseDetailsComponent);
