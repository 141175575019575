import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReviewCasesComponent from '../../components/ReviewCase/ReviewCase';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
let startDate;
class ReviewCasesContainer extends Component {
	constructor() {
		super()
		this.state = {
			showFilterBlock: false, updatedProps: true,
			surgeryFromDate: '', surgeryTodate: '',
			errorMessage: '', activeTab: 'caseList',
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.reviewCaseList,
				"StartDate": new Date()
			}
		}
	}

	componentDidMount() {
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
	}
	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}
	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof ReviewCasesContainer
	 */
	applicationLogger(key) {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.reviewCaseList,
			"Content": {
				"TimeSpent": timeSinceLoad
			}
		}
		logger(loggerObj);
	}

	render() {
		return <ReviewCasesComponent loggerObj={this.state.loggerObj} />
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}


export default connect(mapStateToProps)(ReviewCasesContainer);
