import React, { Component } from 'react';
import SurgeonDashboard from './Surgeon';
import SideMenu from '../../components/SideMenu/SideMenu';
import { getSurgeonListCareTeamService, getSurgeonListService } from '../../services/java/java-services';
import { getUserRoleType } from '../../services/aws/aws-services';

export default class RarViewOfSurgeon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            surgeonId: props.location?.state?.surgeonID,
            surgeons: [],
            surgeonError: null
        }
    }

    componentDidMount() {
        const userRole = getUserRoleType();
        if (userRole.includes('careteam')) {
            this.fetchSurgeonsforCareTeam();
        } else {
            this.fetchSurgeonsforRAR();
        }
    }

    /**
     * @description Function to toggle loader
     * @param status Show/hide status of the loader
     * @memberof RarViewOfSurgeon
     */
    toggleLoading = (status) => {
        this.setState({
            loading: status
        })
    }

    /**
     * @description function to fetch surgeons who have approved data sharing with logged in RAR
     * @param {*}
     * @memberof RarViewOfSurgeon
     */
    fetchSurgeonsforRAR() {
        var urlParams = { type: 'Approved' };
        getSurgeonListService(urlParams, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ surgeonError: err });
            } else {
                const surgeons = result.data.user ? result.data.user[0].surgeonList : [];
                this.setState({ surgeons, surgeonError: null });
            }
        })
    }

    /**
     * @description function to fetch surgeons who have approved data sharing with logged in care team user
     * @param {*}
     * @memberof RarViewOfSurgeon
     */
     fetchSurgeonsforCareTeam() {
        var urlParams = { type: 'Approved' };
        getSurgeonListCareTeamService(urlParams, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ surgeonError: err });
            } else {
                const surgeons = result.data.user ? result.data.user[0].surgeonList : [];
                this.setState({ surgeons, surgeonError: null });
            }
        })
    }

    render() {
        const { surgeons, surgeonId } = this.state;
        return (
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1">
                            <SideMenu />
                        </div>

                        <div className="col-md-11" >
                            {surgeons && surgeons.length ? <SurgeonDashboard toggleLoading={this.toggleLoading} isRarView={true} surgeons={surgeons} surgeonId={surgeonId} /> : ''}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
