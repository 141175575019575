import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardComponent from '../../components/Dashboard/Dashboard';
import { getUserRoleType } from '../../services/aws/aws-services';
import { loggerEventOutcome, loggerEventTypes, loggerEventName, loggerEventMessage } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { getDowntimeSettings } from '../../services/java/java-services';
import { eventSource } from '../../helpers/constants';
let startDate;
class DashboardContainer extends Component {
	constructor() {
		super()
		const loggedInUserType = getUserRoleType();
		let eventName = "Dashboard";
		if (loggedInUserType.includes('surgeon')) {
			eventName = loggerEventName.surgeondashboard;
		} else if (loggedInUserType.includes('careteam')) {
			eventName = loggerEventName.careteamDashboard;
		} else if (loggedInUserType?.length === 1 && loggedInUserType.includes('rar')) {
			eventName = loggerEventName.rarDashboard;
		} else if (loggedInUserType?.length === 1 && loggedInUserType.includes('salesrep')) {
			eventName = loggerEventName.salesrepDashboard;
		} else if (loggedInUserType?.length > 1 && loggedInUserType.includes('rar') && loggedInUserType.includes('salesrep')) {
			eventName = loggerEventName.rarsalesrepDashboard;
		} else if (loggedInUserType.includes('admin')) {
			eventName = loggerEventName.adminDashboard;
		} else if (loggedInUserType?.length === 1 && loggedInUserType.includes('customersupport')) {
			eventName = loggerEventName.customersupportDashboard;
		} 
		this.state = {
			eventName: eventName,
			loading: false,
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": eventName,
				"EventMessage":loggerEventMessage.login,
				"StartDate": new Date()
			}
		}
	}

	componentDidMount() {
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
		this.fetchDowntimeData();
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}

	fetchDowntimeData = () => {
		this.setState({ loading: true })
		getDowntimeSettings((err, res) => {
			if (err) {
				this.setState({ downTimeData: null, loading: false });
			} else {
				var data = res.data ? res.data : null
				this.setState({ 
					downTimeData: {
						starttime: data.starttime ? new Date(data.starttime).toLocaleString() : '',
						endtime: data.starttime ? new Date(data.endtime).toLocaleString() : '',
						status: data.status
					}, 
					loading: false
				});
			}
		})
	}

	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof CaseListContainer
	 */
	applicationLogger = (key) => {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		let loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": this.state.eventName,
			"Content": {
				"TimeSpent": timeSinceLoad,
				"Data":loggerEventMessage.dashboardView()
			}
		}
		const role = getUserRoleType()
		if (role.includes('salesrep') || role.includes('customersupport')) {
			loggerObj = { ...loggerObj, EventSource: eventSource.kpopPlans.value }
		}
		logger(loggerObj);
	}
	
	render() {
		return (
			<DashboardComponent 
				loading={this.state.loading} 
				downTimeData={this.state.downTimeData} 
				loggerObj={this.state.loggerObj} 
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(DashboardContainer);
