import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import ChartDetails from './ChartDetails.json';
import { getLoadingTemplate } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import AnalyticsScatterChartTHA from './AnalyticsScatterChartTHA';

export default class THAScatterChartsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            APPView: null,
            FPPView: null
        }
    }

    componentDidMount() {
        const { chartList, allAnalyticsData } = this.props;
        if (allAnalyticsData && allAnalyticsData.seriesdata && allAnalyticsData.seriesdata.length) {
            const stateObj = {};
            chartList.forEach(chart => {
                let seriesData = allAnalyticsData.seriesdata.find(x => x.name === chart) || {};
                let chartData = {
                    caseInfo: seriesData.series && seriesData.series.length ? allAnalyticsData.caseInfo : [],
                    name: seriesData.name,
                    series: seriesData.series && seriesData.series.length ? seriesData.series : []
                };
                stateObj[chart] = chartData;
            });
            this.setState({ ...stateObj });
        }
    }

    /**
     * @description Function to define template for no data scenario
     * @returns Template showing no data text
     * @memberof CaseAnalyticsComponent
     */
    noDataTemplate(chartName) {
        return (
            <div className="chart-blocks">
                <h3 className="text-center">{chartName}</h3>
                <ErrorMsgBlockComponent noData={true} />
            </div>
        )
    }

    /**
     * @description Function to define template for error scenario
     * @returns Template showing error message
     * @memberof CaseAnalyticsComponent
     */
    errorTemplate(chartName, errorObj) {
        return (
            <div className="chart-blocks">
                <h3 className="text-center">{chartName}</h3>
                <ErrorMsgBlockComponent errorObject={errorObj} />
            </div>
        )
    }

    render() {
        const { analyticsError } = this.props;
        const { APPView, FPPView } = this.state;

        return (
            <>
                <LazyLoad height={200}>
                    {/* APP View */}
                    {APPView ?
                        (APPView.caseInfo && APPView.caseInfo.length ?
                            <AnalyticsScatterChartTHA
                                spacingRight={0}
                                legendMargin={49}
                                analyticsData={APPView}
                                showTextInToolTip={true}
                                chartDetails={ChartDetails.appView}
                                showUnit={true}
                                xAxis="Anteversion"
                                yAxis="Inclination"
                                unit="°"
                            />
                            : this.noDataTemplate(ChartDetails.appView.graphTitle))
                        : analyticsError
                            ? this.errorTemplate(ChartDetails.appView.graphTitle, analyticsError)
                            : getLoadingTemplate()}
                </LazyLoad>

                <LazyLoad height={200} once offset={100}>
                    {/* FPP View */}
                    {FPPView ?
                        (FPPView.caseInfo && FPPView.caseInfo.length ?
                            <AnalyticsScatterChartTHA
                                spacingRight={0}
                                legendMargin={49}
                                analyticsData={FPPView}
                                showTextInToolTip={true}
                                chartDetails={ChartDetails.fppView}
                                xAxis="Anteversion"
                                yAxis="Inclination"
                                showUnit={true}
                                unit="°"
                            />
                            : this.noDataTemplate(ChartDetails.fppView.graphTitle))
                        : analyticsError
                            ? this.errorTemplate(ChartDetails.fppView.graphTitle, analyticsError)
                            : getLoadingTemplate()}
                </LazyLoad>
            </>
        );
    }
}
