import React, { Component } from 'react';
import { errorMessages, noDataFound } from '../../helpers/messages';

export default class ErrorMsgBlockComponent extends Component {
    render() {
        const { errorObject, noData, className } = this.props;
        const classList = `text-danger text-center bg-white rounded ${className ? className : 'py-4 m-0'}`

        return (
            <p className={classList}>
                {noData ?
                    noDataFound.noData
                    : errorObject && errorObject.message ?
                        errorObject.message
                        : errorMessages.generic
                }
            </p>
        )
    }
}