import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; // Import the editor build you want to use
import { convertUTCToLocalDateTime, isValidDate } from '../../helpers/GlobalFunctions';
import { commentsEditorConfig } from '../../containers/CaseDetails/constants';
import { loggerEventMessage, loggerEventTypes, loggerEventName, loggerEventOutcome } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { eventSource } from '../../helpers/constants';
import { getLocalStorage, removeLocalStorage } from '../../services/storage/storage-service';
import { DataTable } from '../DataTable/DataTable';
let startDate;
export class CustomerSupportHistoryDetail extends Component {
    componentDidMount() {
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
        removeLocalStorage('PlanHistoryCaseId');
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof CustomerSupportHistoryDetail
     */
    /* istanbul ignore next  */
    applicationLogger = (key) => {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            EventOutcome: loggerEventOutcome.success,
            EventType: loggerEventTypes.read,
            EventSource: eventSource.kpopPlans.value,
            EventName: loggerEventName.customerSupportHistoryDetails,
            Content: {
                TimeSpent: timeSinceLoad,
                Data: loggerEventMessage.customerSupportHistoryDetails,
                CaseId: getLocalStorage('PlanHistoryCaseId'),
            },
        };
        logger(loggerObj);
    };

    /**
     * @description Renders the updated date for a given row. If the date is valid, it converts the UTC date to local date-time.
     * If the date is invalid, it returns 'Invalid Date'. If there's no date, it returns 'N/A'.
     * @param {Object} row - The row object containing the original updated date.
     * @returns {string} The converted date string or 'Invalid Date' or 'N/A'.
     * @memberof CustomerSupportHistoryDetail
     */
    renderUpdatedDate(row) {
        if (row.original.updateddate) {
            if (isValidDate(row.original.updateddate)) {
                return convertUTCToLocalDateTime(row.original.updateddate);
            } else {
                return 'Invalid Date';
            }
        } else {
            return 'N/A';
        }
    }

    render() {
        const { showEventDetailModal, viewHistoryData } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Event',
                accessor: 'event',
                Cell: ({ row }) => <div>{row.original.event ? row.original.event : 'N/A'}</div>,
                Filter: '',
                disableSortBy: true
            },
            {
                Header: 'Updated By',
                accessor: 'updatedby',
                Filter: '',
                Cell: ({ row }) => <div className='text-capitalize'>{row.original.updatedUserFullName ? row.original.updatedUserFullName : 'N/A'}</div>,
                disableSortBy: true
            },
            {
                Header: 'Requested By',
                accessor: 'requestedByName',
                Cell: ({ row }) => <div className='text-capitalize'>{row.original.requestorFullName ? row.original.requestorFullName : 'N/A'}</div>,
                Filter: '',
                disableSortBy: true
            },
            {
                Header: 'Updated On',
                accessor: 'updateddate',
                Cell: ({ row }) => <div>{this.renderUpdatedDate(row)}</div>,
                Filter: '',
                disableSortBy: true
            },
        ];

        return (
            <div>
                <div>
                    <span>{'<'}</span>
                    <button
                        style={{
                            background: 'none',
                            color: 'inherit',
                            border: 'none',
                            padding: 0,
                            font: 'inherit',
                            cursor: 'pointer',
                            outline: 'inherit',
                        }}
                        onClick={() => showEventDetailModal(false)}
                    >
                        <u>Back to History List</u>
                    </button>
                </div>
                <DataTable listType='customer-support-history-details' columns={columns} viewHistoryData={[viewHistoryData]} showPagination={false} />
                <CKEditor
                    editor={ClassicEditor} // Use the editor build
                    config={commentsEditorConfig()} // Configuration object for the editor
                    data={viewHistoryData?.notesComment || ''} // Initial data for the editor
                    disabled={true} // Set the editor to read-only mode
                />
            </div>
        );
    }
}

export default CustomerSupportHistoryDetail;
