import environment from '../../config/environment';
import { getCognitoIdTokenFromStorage, logoutService, getUserRoleType, getUserName } from '../aws/aws-services';
import axios from 'axios';
import {
	errorMessages, promScoreMessages, notesMessages, exportToCsv, successMessages,
	planningToolErrorMessages, surgeonMapping, notificationMessages, reviewCaseDetailsMessages,
	loggerEventOutcome, loggerEventTypes, loggerEventName, loggerEventMessage, customerMessages, licenseMessages,customerMapping
} from '../../helpers/messages';
import { logger } from '../logger/logger-service';
import { eventSource } from '../../helpers/constants';

/**
 * @description function to call API to update procedure metadata
 * @param  {} data
 * @param  {} callback
 */
const updateMetadata = async (data, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/metadata',
		method: 'POST',
		EventType: loggerEventTypes.create,
		EventName: loggerEventName.fileUpload,
		AddLogs: true,
		EventMessage: successMessages.fileUploadSuccess,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: data
	};
	axiosApiCall(options, callback)
}

/**
	 * @description function to call API to get all facility list
	 * @param  {} data
	 * @param  {} callback
	 */
const facilityListService = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'facility-service/facility',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	axiosApiCall(options, callback)
}

/**
	 * @description function to call API to get all procedure metadata list
	 * @param  {} data
	 * @param  {} callback
	 */
const procedureMetadataListService = async (pageNo, sortBy, sortOrder, limit, callback) => {
	/* istanbul ignore next  */
	const fileListOptions = {
		url: environment.apiBaseUrl + 'procedure-metadata/metadata?pageno='
			+ pageNo + '&sortorder=' + sortOrder + '&column=' + sortBy + '&limit=' + limit,
		method: 'GET',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.filesList,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(fileListOptions, callback)
}

/**
	 * @description function to call API to get file details
	 * @param  {} fileName
	 * @param  {} callback
	 */
export const getFileDetailsService = async (fileName, callback) => {
	/* istanbul ignore next  */
	const fileListOptions = {
		url: environment.apiBaseUrl + 'procedure-metadata/metadata/' + fileName,
		method: 'GET',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.fileDetails,
		AddLogs: true,
		EventMessage: fileName + " details displayed successfully.",
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(fileListOptions, callback)
}

/**
	 * @description function to call API to get all case list
	 * @param urlParams Pagination and filter parameters to be passed as query params
	 * @param callback Callback function to handle the API response
	 */
const caseListService = async (urlParams, callback) => {
	/* istanbul ignore next  */
	const caseListOptions = {
		url: environment.apiBaseUrl + 'case-service/case',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.caseList,
		method: 'GET',
		params: urlParams,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(caseListOptions, callback)
}

/**
	 * @description function to call API to get all case data
	 * @param urlParams filter parameters to be passed as query params
	 * @param callback Callback function to handle the API response
	 */
const exportCaseDataService = async (urlParams, callback) => {
	/* istanbul ignore next  */
	const caseDataOptions = {
		url: environment.apiBaseUrl + 'case-service/cases/export',
		method: 'GET',
		params: urlParams,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.exportCsv,
		AddLogs: true,
		EventMessage: exportToCsv.success,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(caseDataOptions, callback)
}

/**
 * @description function to call API to get all logs
 * @param urlParams filter parameters to be passed as query params
 * @param callback Callback function to handle the API response
 */
const exportLogsDataService = async (urlParams, callback) => {
	/* istanbul ignore next  */
	const LogsDataOptions = {
		url: environment.apiBaseUrl + 'logger-service/export/logger',
		method: 'GET',
		params: urlParams,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(LogsDataOptions, callback)
}

/**
	 * @description function to call API to get single case details
	 * @param  {} data
	 * @param  {} callback
	 */
const caseDetailsService = async (caseId, params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId,
		method: 'GET',
		params: params,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.caseSummaryReport,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to update case details
 * @param  {} data
 * @param  {} callback
 */
const caseDetailsUpdateService = async (caseId, data, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId,
		method: 'PUT',
		EventType: loggerEventTypes.update,
		EventName: loggerEventName.addNotes,
		CaseId: caseId,
		AddLogs: true,
		EventMessage: data && data.notes ? notesMessages.notesUpdate : notesMessages.notesDelete,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: data
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to get case gap data
 * @param  {} data
 * @param  {} callback
 */
const caseGapDataService = async (caseId, params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId + '/gapsdata',
		method: 'GET',
		params: getUserRoleType()?.includes('rar') ? params : '',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to get case timing data
 * @param  {} data
 * @param  {} callback
 */
const caseTimingDataService = async (caseId, params, type, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId + '/timing?type=' + type,
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description Function to call API to get case analytics data
* @param params Filter params to fetch data
* @param callback Callback function to handle API response
*/
const caseAnalyticsDataService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/analytics',
		method: 'GET',
		params: params,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.trendsPage,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description Function to call API to get THA case analytics data
* @param params Filter params to fetch data
* @param callback Callback function to handle API response
*/
const caseAnalyticsDataTHAService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/tha/analytics',
		method: 'GET',
		params: params,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.trendsPage,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get case analytics timing data
* @param  {} data
* @param  {} callback
*/
const caseAnalyticsTimingDataService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/timing',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to get total and global Case based on the timeframe input
 * @param  {} data
 * @param  {} callback
 */
const totalGlobalCasesService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to get total device count
 * @param  {} callback
 */
 const totalDeviceCount = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/activedevicecount',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}


/**
 * @description function to call API to get total and global Case based in the Outcome Scores
 * @param  {} data
 * @param  {} callback
 */
const totalOutcomeScoreService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/prom',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	axiosApiCall(options, callback)
}
/* istanbul ignore next  */

/**
* @description function to call API to get case alignment details  based on the timeframe input
* @param  {} data
* @param  {} callback
*/
const getCasesAlighmentsService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/alignments',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to add prom score inputs
* @param  {} data
* @param  {} callback
*/
const addPromScoreService = async (caseId, promsDetails, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId + '/prom',
		method: 'POST',
		EventType: loggerEventTypes.create,
		EventName: loggerEventName.addProms,
		CaseId: caseId,
		AddLogs: true,
		EventMessage: promScoreMessages.saveData,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: promsDetails
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to update prom score inputs
* @param  {} data
* @param  {} callback
*/
const updatePromScoreService = async (caseId, promsDetails, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId + '/prom',
		method: 'PUT',
		EventType: loggerEventTypes.update,
		EventName: loggerEventName.updateProms,
		CaseId: caseId,
		AddLogs: true,
		EventMessage: promScoreMessages.updateData,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: promsDetails
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
 * @description function to call API to delete prom score inputs
 * @param  {} data
 * @param  {} callback
 */
const deletePromScoreService = async (caseId, promsDetails, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId + '/prom?scoreId=' + promsDetails.scoreId,
		method: 'DELETE',
		EventType: loggerEventTypes.delete,
		EventName: loggerEventName.deleteProms,
		CaseId: caseId,
		AddLogs: true,
		EventMessage: promScoreMessages.deleteData,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: promsDetails
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
 * @description function to call API to delete SalesRep Customer
 * @param  {} data
 * @param  {} callback
 */
export const deleteSalesRepCustomerService = async (params, callback) => {
	const {customerId, salesRepName} = params;
	const options = {
		url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/customer/delete?customerId=${customerId}&salesrepName=${salesRepName}`,
		method: 'DELETE',
		EventType: loggerEventTypes.delete,
		EventName: loggerEventName.removeSalesrepCustomerAssociation,
		EventMessage: loggerEventMessage.removeCustomer(customerId),
		EventSource: eventSource.kpopPlans.value,
		AddLogs: true,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
 * @description function to call API to delete prom score inputs
 * @param  {} data
 * @param  {} callback
 */
const saveDeviceInfo = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/deviceinfo',
		method: 'PUT',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};

	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
 * @description function to call API to get case alignment details  based on the timeframe input
 * @param  {} data
 * @param  {} callback
 */
const getPromScoreHistoryService = async (caseId, params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId + '/prom',
		method: 'GET',
		params: getUserRoleType()?.includes('rar') ? params : '',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get proms outcome score
* @param  {} data
* @param  {} callback
*/
const promsOutcomeScoreService = async (caseId, params, scoreType, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId + '/prom?scoreType=' + scoreType,
		method: 'GET',
		params: getUserRoleType()?.includes('rar')  ? params : '',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all implants
* @param  {} callback
*/
const getAllImplantsService = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/implant',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/* APIs for RAR users */
/**
* @description function to call API to get all implants
* @param  {} callback
*/
const getLastCaseUploadService = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/metadata/lastupload',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
	 * @description Function to call API to get planning tool results
	 * @param urlParams Filter parameters to be passed as query params
	 * @param callback Callback function to handle the API response
	 */
const getPlanningResultService = async (urlParams, callback) => {
	/* istanbul ignore next  */
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/component',
		method: 'GET',
		params: urlParams,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.planningToolResults,
		AddLogs: true,
		EventMessage: planningToolErrorMessages.getPlanningToolResult,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all surgeon list
* @param  {} callback
*/
const getSurgeonListService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/rar/surgeon/mapping',
		method: 'GET',
		params: params,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.surgeonList,
		AddLogs: true,
		EventMessage: loggerEventMessage.surgeonList,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get surgeon preference
* @param  {} callback
*/
const getSurgeonPreference = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/user/preference',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call PUT to save the surgeon preference
* @param  {} callback
*/
const putSurgeonPreference = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/user/preference',
		method: 'PUT',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: params,
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
	 * @description Function to call API to get Pelvic tilt  results
	 * @param urlParams Filter parameters to be passed as query params
	 * @param callback Callback function to handle the API response
	 */
 const getPelvictiltResultService = async (urlParams, callback) => {
	/* istanbul ignore next  */
	const options = {
		url: environment.apiBaseUrl + 'case-service/pelvictiltsummary',
		method: 'GET',
		params: urlParams,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.Pelvictilt,
		AddLogs: true,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to get all customer License expiration list
* @param  {} callback
*/
const getExpiredLicenseList = async (params, callback) => {
	const logInUser = getUserRoleType();
	const options = {
		url: environment.apiBaseUrl + 'users-service/licenceexpertion',
		method: 'GET',
		params: params,
		EventSource: logInUser.includes("admin") ? eventSource.riInsights.value : eventSource.kpopPlans.value,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.licenseexpirationlist(),
		AddLogs: true,
		EventMessage: loggerEventMessage.licenseexpirationlist(),
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)

}
/**
* @description function to call API to get SurgeonId's
* @param  {} callback
*/
const getGenerateSurgeonIDs = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/globalsurgeonid',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to search surgeons from master table
* @param  {} callback
*/
const searchSurgeonsService = async (searchKey, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/surgeon/list?searchString=' + searchKey,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to search careteam from master table
* @param  {} callback
*/
const searchCareteamService = async (searchKey, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/user/careteam?searchString=' + searchKey,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to search sales Rep customer
* @param  {} callback
*/
const searchSalesRepCustomerService = async (searchKey, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'kpop-nsync-service/salesrep/customer/list?searchString=' + searchKey,
		method: 'GET',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.searchForCustomer,
		EventMessage: loggerEventMessage.searchForCustomer(searchKey),
        EventSource: eventSource.kpopPlans.value,
		AddLogs: true,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to remove surgeon
 * @param  {} data
 * @param  {} callback
 */
const removeSurgeonService = async (surgeonDetails, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/rar/surgeon/mapping',
		method: 'DELETE',
		EventType: loggerEventTypes.delete,
		EventName: loggerEventName.removeSurgeon,
		AddLogs: true,
		EventMessage: surgeonMapping.removeSurgeon,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: surgeonDetails
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to delete surgeon in customer profile
 * @param  {} data
 * @param  {} callback
 */
export const deleteSurgeonService = async (surgeonDetails, loggerObj, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/surgeon/customer/mapping',
		method: 'DELETE',
		EventType: loggerEventTypes.delete,
		EventName: loggerObj && loggerObj.eventName ? loggerObj.eventName : loggerEventName.removeSurgeon,
		AddLogs: true,
		EventMessage: loggerObj && loggerObj.eventMessage ? loggerObj.eventMessage : surgeonMapping.removeSurgeon,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: surgeonDetails
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
 * @description function to call API to delete careteam in customer profile
 * @param  {} data
 * @param  {} callback
 */
 export const deleteCareteamService = async (careTeamDetails, loggerObj, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/careteam/customer/mapping',
		method: 'DELETE',
		EventType: loggerEventTypes.delete,
		EventName: loggerObj && loggerObj.eventName ? loggerObj.eventName : loggerEventName.removeCareteamMemberFromCustomer,
		AddLogs: true,
		EventMessage: loggerObj && loggerObj.eventMessage ? loggerObj.eventMessage : customerMapping.removeCareteam,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: careTeamDetails
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
 * @description function to call API to remove surgeon from careteam
 * @param  {} data
 * @param  {} callback
 */
 export const removeCareteamService = async (careteamDetails, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/careteam/surgeon/mapping',
		method: 'DELETE',
		EventType: loggerEventTypes.delete,
		EventName: loggerEventName.removeSurgeon,
		AddLogs: true,
		EventMessage: surgeonMapping.removeSurgeon,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: careteamDetails
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to map surgeon with RAR
* @param  {} callback
*/
const mapSurgeonsService = async (mapObj, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/rar/surgeon/mapping',
		method: 'POST',
		EventType: loggerEventTypes.create,
		EventName: loggerEventName.addSurgeon,
		AddLogs: true,
		EventMessage: surgeonMapping.mappingSuccess,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: mapObj
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to map customer to sales Rep 
* @param  {} callback
*/
export const mapSalesRepCustomerService = async (params, callback) => {
	const { customerId, salesRepName } = params;

	const payload = { updatedBy: getUserName(), customerId: customerId, salesrepName: salesRepName };
	
	const options = {
		url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/customer`,
		method: 'POST',
		data: payload,
		EventType: loggerEventTypes.update,
		AddLogs: true,
		EventName: loggerEventName.customerMaptoSalesrep,
		EventSource: eventSource.kpopPlans.value,
		EventMessage: loggerEventMessage.customerMaptoSalesrep(customerId, salesRepName),
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to approve or revoke RAR association
* @param  {} callback
*/
const dataAssociationReqListService = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/surgeon/data/mapping',
		method: 'GET',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.dataSharing,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to approve or revoke RAR association
* @param  {} callback
*/
const dataAssociationReqService = async (requestObj, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/surgeon/data/mapping',
		method: 'PUT',
		EventType: loggerEventTypes.update,
		EventName: loggerEventName.dataSharingAction,
		AddLogs: true,
		EventMessage: requestObj.requeststatus === 'Approved' ? surgeonMapping.approveSuccess : surgeonMapping.revokeSuccess,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: requestObj
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to set notifications for surgeon from RAR
* @param params POST params to be sent
* @param callback Callback function to handle the response
*/
const setNotifications = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'notification-service/notification',
		method: 'POST',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get notification count for surgeon
* @param callback Callback function to handle the response
*/
const getNotificationCount = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'notification-service/notification/count',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all notifications for surgeon
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
const getNotifications = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'notification-service/notification',
		method: 'GET',
		params: params,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.notification,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to dismiss notifications for surgeon
* @param params POST params to be sent
* @param callback Callback function to handle the response
*/
const dismissNotification = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'notification-service/notification/dismiss',
		method: 'PUT',
		data: params,
		EventType: loggerEventTypes.delete,
		EventName: loggerEventName.dismissNotification,
		AddLogs: true,
		EventMessage: notificationMessages.singleDismissed,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all review cases for Rar
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
const getReviewCases = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/cases/error',
		method: 'GET',
		params: params,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.reviewCaseList,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get the count of pending cases for Sales rep
* @param userName username sales rep user 
* @param callback Callback function to handle the response
*/
const getPendingCasesCount = async (userName, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'kpop-nsync-service/salesrep/count/' + userName,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get teh count of review cases for Rar
* @param callback Callback function to handle the response
*/
const getReviewCasesCount = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/cases/error/count',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to get all History of comments 
* @param caseId GET caseId to be sent in URL
* @param callback Callback function to handle the response
*/
const getCommentsHistoryDetails = async (caseId, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'case-service/case/' + caseId + '/commenthistory',
		method: 'GET',
		caseId:caseId,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)

}

/**
* @description function to call API to get all review case details for Rar
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
const getReviewCaseDetails = async (caseId, params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}case-service/case/${caseId}/info`,
		method: 'GET',
		params: params,
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.reviewCasedetails,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to get all review case details for Rar
* @param data PUT data to be sent
* @param callback Callback function to handle the response
*/
const saveReviewCaseDetails = async (caseId, data, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}case-service/case/${caseId}/info`,
		method: 'PUT',
		EventType: loggerEventTypes.update,
		EventName: loggerEventName.reviewCasedetails,
		AddLogs: true,
		EventMessage: reviewCaseDetailsMessages.detailsSaved,
		data: data,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all customer profiles
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
const customerListService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/user/customerslist',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all surgeon list for admin
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const surgeonListService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/surgeonlist',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}


/**
* @description function to call API to get all surgeons for Rar
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
const getAllSurgeons = async (params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/user/surgeons`,
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to get all customers for Rar
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
const getAllCustomers = async (params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/user/customers`,
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get customer details
* @param customerID ID of the customer whose details are to be fetched
* @param callback Callback function to handle the response
*/
const getCustomerDetailsService = async (customerID, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/user/customer/${customerID}`,
		method: 'GET',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.customerDetails,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to track the user activities
* @param params POST params to be sent
* @param callback Callback function to handle the response
*/
export const loggerPostAPI = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'logger-service/logger',
		method: 'POST',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get the user activities
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const loggerGetAPI = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'logger-service/logger',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get FAQ list
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const getFAQList = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'faq-service/faq',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get Country list
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const getCountryList = async (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/country/list',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to save CustomerDetails
* @param params POST params to be sent
* @param callback Callback function to handle the response
*/
export const saveCustomerDetails = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/customer',
		method: 'POST',
		data: params,
		EventType: loggerEventTypes.create,
		EventName: loggerEventName.customerCreate,
		AddLogs: true,
		EventMessage: customerMessages.saveCustomerMsg,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to update CustomerDetails
* @param customerId ID of customer to be edited
* @param params PUT params to be sent
* @param callback Callback function to handle the response
*/
export const updateCustomerDetails = async (customerId, params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/customer/' + customerId,
		method: 'PUT',
		data: params,
		EventType: loggerEventTypes.update,
		EventName: loggerEventName.customerUpdate,
		AddLogs: true,
		EventMessage: customerMessages.saveCustomerMsg,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to map customers to surgeons
* @param mapObj POST params to be sent
* @param callback Callback function to handle the response
*/
export const mapCustomerSurgeonsService = async (mapObj, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/surgeon/customer',
		method: 'POST',
		EventType: loggerEventTypes.create,
		EventName: loggerEventName.surgeonToCustomerMap,
		AddLogs: true,
		EventMessage: customerMessages.surgeonMapSuccess,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: mapObj
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to map customers to careteam
* @param mapObj POST params to be sent
* @param callback Callback function to handle the response
*/
export const mapCustomerCareteamService = async (mapObj, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/user/careteam',
		method: 'POST',
		EventType: loggerEventTypes.create,
		EventName: loggerEventName.careTeamToCustomerMap,
		AddLogs: true,
		EventMessage: customerMessages.careTeamMapSuccess,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: mapObj
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
	 * @description function to call API to get all customer data
	 * @param 
	 * @param callback Callback function to handle the API response
	 */
export const exportCustomerListService = async (callback) => {
	/* istanbul ignore next  */
	const customerDataOptions = {
		url: environment.apiBaseUrl + 'users-service/user/customer/export',
		method: 'GET',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.customerListExport,
		AddLogs: true,
		EventMessage: exportToCsv.success,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(customerDataOptions, callback)
}

/**
 * @description function to call API to get all surgeon data to export
 * @param
 * @param callback Callback function to handle the API response
 */
export const exportSurgeonListService = async (callback) => {
	/* istanbul ignore next  */
	const surgeonDataOptions = {
		url: environment.apiBaseUrl + 'users-service/surgeon/list',
		method: 'GET',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.surgeonListExport,
		AddLogs: true,
		EventMessage: exportToCsv.success,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(surgeonDataOptions, callback)
}

/**
* @description function to call API to save saveLicenseDetails
* @param params POST params to be sent
* @param callback Callback function to handle the response
*/
export const saveLicenseDetails = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/license/customer',
		method: 'POST',
		data: params,
		EventType: loggerEventTypes.create,
		EventName: loggerEventName.addNewLicense,
		AddLogs: true,
		EventMessage: licenseMessages.saveLicenseMsg,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to get all license
 * @param 
 * @param callback Callback function to handle the API response
 */
export const licenseListService = async (customerId, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/license/' + customerId,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to get customer associated sales rep
 * @param 
 * @param callback Callback function to handle the API response
 */
export const customerAssociatedSaleRepService = async (customerId, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'kpop-nsync-service/salesrep/customermapping/users?page=1&limit=10&customerid=' + customerId,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}


/**
* @description function to call API to get surgeon details
* @param userName Username of surgeon to be fetched
* @param callback Callback function to handle the response
*/
export const getSurgeonDetails = async (userName, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/surgeon/${userName}/details`,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to update user details
* @param userName Username of user to be edited
* @param params PUT params to be sent
* @param callback Callback function to handle the response
*/
export const updateUserDetails = async (userName, params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/user/' + userName,
		method: 'PUT',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get customers associated to the surgeon
* @param userName Username of surgeon to be edited
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const getSurgeonCustomersService = async (userName, params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/customerlist/' + userName,
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get customers associated to the sales rep
* @param userName Username of sales rep user
* @param callback Callback function to handle the response
*/
/* istanbul ignore next  */
export const getPreopCustomersService = async (userName,callback) => {
	const options = {
		url: environment.apiBaseUrl + 'kpop-nsync-service/salesrep/' + userName,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get customers to associate with surgeon
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const searchCustomersService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/customer/list',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all users
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const getAllUsersList = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/userslist',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to get all users
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
const getAllDevicesList = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/devicelist',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to get user details
* @param userName Username of the user to be fetched
* @param callback Callback function to handle the response
*/
export const getUserDetails = async (userName, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/user/${userName}/details`,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to update user activity
* @param params PUT params to be sent
* @param callback Callback function to handle the response
*/
export const updateUserActivity = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/user/useraction',
		method: 'PUT',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to check if surgeon id is unique
* @param surgeonID Surgeon id to be checked
* @param callback Callback function to handle the response
*/
export const checkUniqueSurgeonIdService = async (surgeonID, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/user/${surgeonID}/exists`,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/* @description function to call API to check if surgeon id is unique
* @param surgeonID Surgeon id to be checked
* @param callback Callback function to handle the response
*/
export const getCareteamListResults = async (customerId, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/user/${customerId}/careteam`,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to save user in db
* @param params User attributes to be saved
* @param callback Callback function to handle the response
*/
export const saveUserService = async (params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/user`,
		method: 'POST',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to save downtime in db
* @param params User attributes to be saved
* @param callback Callback function to handle the response
*/
export const setDowntimeSettings = async (params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/system/downtime`,
		method: params.id ? 'PUT' : 'POST',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get downtime info
* @param callback Callback function to handle the response
*/
export const getDowntimeSettings = async (callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/system/downtime`,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}


/**
* @description function to call API to get all market regions
* @param callback Callback function to handle the response
*/
export const getAllRegions = async (callback) => {
	const options = {
		url: `${environment.apiBaseUrl}case-service/marketregion`,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get screenshots for a case
* @param callback Callback function to handle the response
*/
export const getAllScreenshots = async (params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}case-service/case/screenshot`,
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get case types for surgeon
* @param callback Callback function to handle the response
*/
export const getSurgeonCaseTypes = async (params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}case-service/case/casetype`,
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get case types for surgeon
* @param callback Callback function to handle the response
*/
export const checkInactiveAssociation = async (caseId, params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}case-service/case/${caseId}/checkinactiveassociation`,
		method: 'PUT',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	
	axiosApiCall(options, callback)
}

/**
* @description function to call API to send contact us email
* @param callback Callback function to handle the response
*/
export const sendContactUsEmail = async (params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/sendemail`,
		method: 'POST',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all surgeon list
* @param  {} callback
*/
export const getSurgeonListCareTeamService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/careteam/surgeon/mapping',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to map surgeon with RAR
* @param  {} callback
*/
export const mapSurgeonsCareTeamService = async (mapObj, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/careteam/surgeon/mapping',
		method: 'POST',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: mapObj
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to search surgeons for care team
* @param  {} callback
*/
export const searchSurgeonsCareTeamService = async (searchKey, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/careteam/surgeon/list?searchString=' + searchKey,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}


/**
 * @description function to call API to get filtered pre plans of salesrep
 * @param  params params for API if needed
 * @param  callback Callback function to handle the response
 */
export const getFilteredPreOpPlansService = async (params, callback) => {
	/* istanbul ignore next  */
  const salesrepName = getUserName()
  const getReviewPreOptions = {
	  url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/filter/preop`,
	  method: 'GET',
	  params: {...params,salesrepName},
	  EventType: loggerEventTypes.read,
	  EventName: loggerEventName.preOpList,
	  EventSource: eventSource.kpopPlans.value,
	  AddLogs: true,
	  EventMessage: loggerEventMessage.preOpList,
	  headers: {
		  Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
		  'Content-Type': 'application/json;charset=UTF-8',
	  },
  };
  /* istanbul ignore next  */
  axiosApiCall(getReviewPreOptions, callback);
};

/**
 * @description function to call API to get filtered only pending pre plans of salesrep
 * @param  params params for API if needed
 * @param  callback Callback function to handle the response
 */
export const getPendingCustomerAssociationService = async (params, callback) => {
	/* istanbul ignore next  */
  const salesrepName = getUserName()
  const getReviewPreOptions = {
	  url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/preop/review/list`,
	  method: 'GET',
	  params: {...params,salesrepName},
	  AddLogs: true,
	  EventType: loggerEventTypes.read,
	  EventName: loggerEventName.pendingPreOpList,
	  EventMessage: loggerEventMessage.pendingPreOpList,
	  EventSource: eventSource.kpopPlans.value,
	  headers: {
		  Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
		  'Content-Type': 'application/json;charset=UTF-8',
	  },
  };
  /* istanbul ignore next  */
  axiosApiCall(getReviewPreOptions, callback);
};



/**
 * @description function to update status in sales rep panel in review pre plans table
 * @param  params params for API if needed
 * @param callback Callback function to handle the response
 */
export const updateReviewPlanStatusService = async (params, callback) => {
	/* istanbul ignore next  */

	const { caseNumbers, status } = params;

	const payload = {
		"caseNumbers": caseNumbers,
		"procedureStatus": status,
		"updatedBy": getUserName()
	};

	const options = {
		url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/preop/status`,
		method: 'POST',
		data: payload,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
};


/**
 * @description function to update customer in Customer support and Admin panel in review pre plans table
 * @param  params params for API if needed
 * @param callback Callback function to handle the response
 */
export const updateReviewPlanCustomerService = async (params, callback) => {
	/* istanbul ignore next  */
	const payload = {
		"caseNumbers": params?.caseNumbers,
		"customerId": params?.customerId,
		"salesrepName":params?.salesrepName,
		"updatedBy":getUserName()
	};
	const options = {
		url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/preop/admin/customer`,
		method: 'POST',
		data: payload,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
};


/**
 * @description function to update customer sales rep in Customer support and Admin panel in review pre plans table
 * @param  params params for API if needed
 * @param callback Callback function to handle the response
 */
export const updateSalesRepCustomerService = async (params, callback) => {
	/* istanbul ignore next  */
	const payload = {
		"preopPlanId": params?.preopPlanId,
		"status": params?.status,
		"newSalesrepUsername":params?.newSalesrepUsername,
		"oldSalesrepUsername":params?.oldSalesrepUsername,
		"updatedBy":getUserName(),
		"updatedDate":new Date().getTime(),
		"requestedBy": params?.requestedBy
	};
	const options = {
		url: environment.apiBaseUrl + `kpop-nsync-service/update/salesrepowner`,
		method: 'PUT',
		data: payload,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
};
/**
 * @description function to update customer in sales rep panel in review pre plans table
 * @param  params params for API if needed
 * @param callback Callback function to handle the response
 */
export const updateReviewPlanCustomerForSalesRepService = async (params, callback) => {
	/* istanbul ignore next  */
	const payload = {
		"caseNumbers": params?.caseNumbers,
		"customerId": params?.customerId,
		"updatedBy": getUserName()
	};
	const options = {
		url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/preop/customer`,
		method: 'POST',
		data: payload,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
};

/**
 * @description function to call API to get sales rep userList for admin
 * @param  params params for API if needed
 * @param  callback Callback function to handle the response
 */
export const getAllSalesRepListService = async (params, callback) => {
	/* istanbul ignore next  */
  const getSalesRepListOptions = {
	  url: environment.apiBaseUrl + `kpop-nsync-service/admin/salesrep/list`,
	  method: 'GET',
	  params: {userName: params},
	  headers: {
		  Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
		  'Content-Type': 'application/json;charset=UTF-8',
	  },
  };
  /* istanbul ignore next  */
  axiosApiCall(getSalesRepListOptions, callback);
};


/**
* @description function to call API to get all customers for admin
* @param params params for API if needed
* @param callback Callback function to handle the response
*/
/* istanbul ignore next  */
export const getAllPreOpCustomersService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'kpop-nsync-service/salesrep/preop/customer/list',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to get all the pre op list for admin
 * @param  params params for API if needed
 * @param  callback Callback function to handle the response
 */
export const getAllPreOpListService = async (params, callback) => {
	const getReviewPreOptions = {
		url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/preop/list`,
		method: 'GET',
		params: params,
		headers: {
			Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8',
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(getReviewPreOptions, callback);
};

/**
 * @description function to call API to get all the pre op list for customer support
 * @param  params params for API if needed
 * @param  callback Callback function to handle the response
 */
export const getCustomerSupportPreOpListService = async (params, callback) => {
	const getReviewPreOptions = {
		url: environment.apiBaseUrl + `kpop-nsync-service/customer/service/preop/list`,
		method: 'GET',
		params: params,
		headers: {
			Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8',
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(getReviewPreOptions, callback);
};

/**
 * @description function to call API to get all Active  the pre op list for customer support
 * @param  params params for API if needed
 * @param  callback Callback function to handle the response
 */
export const getCustomerSupportPreOpActiveListService = async (params, callback) => {
	const getReviewPreOptions = {
		url: environment.apiBaseUrl + `kpop-nsync-service/customer/service/view/preop/list`,
		method: 'GET',
		params: params,
		headers: {
			Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8',
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(getReviewPreOptions, callback);
};

/**
* @description function to call API to update comments in DB for change in hospital association in customer support 
* @param callback Callback function to handle the response
*/
export const updateCommentForAssociationService = async (visionaireID, params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}kpop-nsync-service/customer/support/update/${visionaireID}`,
		method: 'PUT',
		data: {...params,updatedBy:getUserName()},
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */	
	axiosApiCall(options, callback)
}

/*
 * @description function to call API to get total active plans of customer support
 * @param  {} callback
 */
const totalActivePlans =  (userName , callback) => {
	const options = {
		url: environment.apiBaseUrl + 'kpop-nsync-service/customer/service/count/'+userName,
		method: 'GET',
		EventSource: eventSource.kpopPlans.value,
	    EventType: loggerEventTypes.read,
		EventName: loggerEventName.custSupportCount,
		AddLogs: true,
		EventMessage: successMessages.custSupportCountDetails,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to download master zip from backend by plan id
 * @param  data plans ids array
 * @param  callback Callback function to handle the response
 */
export const getMasterZip = async (params, callback) => {
	const getReviewPreOptions = {
		url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/download/file`,
		method: 'GET',
		params: params,
		maxBodyLength: Infinity,
		responseType: 'blob',
		headers: {
			Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
		},

	};
	/* istanbul ignore next  */
	axiosApiCall(getReviewPreOptions, callback);
};

/**
* @description function to call API to get  customer support plan history
* @param  {} callback
*/
export const getCustSupportPlanHistoryService = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + `kpop-nsync-service/customer/support/planhistory?preopPlanId=${params.preopPlanId}&columnName=${params.columnName}&sortOrder=${params.sortOrder}`,
		method: 'GET',
		EventSource: eventSource.kpopPlans.value,
	    EventType: loggerEventTypes.read,
		EventName: loggerEventName.customerSupportHistoryList,
		CaseId: params.preopPlanId,
		AddLogs: true,
		EventMessage: successMessages.customerSupportHistoryDetails,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}



export const getCustomerLastSyncData = async (callback) => {
	/* istanbul ignore next  */
  const getSalesRepListOptions = {
	  url: environment.apiBaseUrl + `kpop-nsync-service/customer/support/sync`,
	  method: 'GET',
	  headers: {
		  Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
		  'Content-Type': 'application/json;charset=UTF-8',
	  },
  };
  /* istanbul ignore next  */
  axiosApiCall(getSalesRepListOptions, callback);
};

/*
 * @description function to trigger API call for the sync
 * @param  {} callback
 */
export const triggerSyncService =  (callback) => {
	const options = {
		url: environment.apiBaseUrl + 'kpop-nsync-service/visionaire/casereco',
		method: 'GET',
		timeout: 1200000,
		EventSource: eventSource.kpopPlans.value,
	    EventType: loggerEventTypes.read,
		EventName: loggerEventName.triggerSync,
		AddLogs: true,
		EventMessage: loggerEventMessage.triggerSync,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}


/**
 * @description function to call API to get all customers for the filter dropdown
 * @param  params params for API if needed
 * @param  callback Callback function to handle the response
 */
export const getCustomerFilterService = async (params, callback) => {
	const Options = {
		url: environment.apiBaseUrl + `kpop-nsync-service/salesrep/customerfilter/${params.userName}`,
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8',
		},
	};
	/* istanbul ignore next  */
	axiosApiCall(Options, callback);
};



/**
 * @description function to make AJAX call
 * @param  {} data
 * @param  {} callback
 */
const axiosApiCall = async (options, callback) => {
	/* istanbul ignore next  */
	let getContent = {
		"Data": options && options.EventMessage ? options.EventMessage : ''
	}
	if (options && options.CaseId) {
		getContent.CaseId = options.CaseId
	}

	if (options && options.PlanId) {
		getContent.PlanId = options.PlanId
	}

	let loggerObj = {
		"EventOutcome": loggerEventOutcome.success,
		"EventType": options && options.EventType ? options.EventType : '',
		"EventName": options && options.EventName ? options.EventName : '',
		"Content": getContent
	}

	if (options.EventSource) {
		loggerObj = { ...loggerObj, EventSource: options.EventSource }
	}


	axios(options)
		.then(response => {
			if (options && options.AddLogs) {
				setTimeout(() => {
					logger(loggerObj);
				}, 0);
			}
			return callback(null, response);
		})
		.catch(error => {
			if (error.response && error.response.status) {
				const fallBackErrorMsg = errorMessages[error.response.status] ? errorMessages[error.response.status] : errorMessages.generic;
				// If API call is unauthorized, force log out
				if (error.response.status === 401) {
					logoutService((err, res) => { });
				}
				if (
					error?.response?.data?.error?.errorCode === 5000 || error?.response?.data?.error?.errorType === 'com.nsync.handler.exception.EntityNotFoundException') {
					error = {
						errorCode: error?.response?.data?.error?.errorCode,
						message: 'No records found'
					};
				}else if (
					error?.response?.data?.error?.errorCode === 5027 || error?.response?.data?.error?.errorType === 'com.nsync.handler.exception.DownloadFailedException') {
					error = {
						errorCode: error?.response?.data?.error?.errorCode,
						message: 'Download failed. Please try again later or contact the admin.'
					};
				}
				else if (!error.response.data) {
					// Checking if response has a body
					error = { errorCode: error.response.status, message: fallBackErrorMsg };
				} else {
					let errRes = error.response.data;
					let isErrorObj = errRes.error && typeof errRes.error === 'object';
					// Create error object
					// If error object is sent from API, put that in, else create custom one
					error = isErrorObj ?
						{
							...errRes.error,
							message: errRes.error.errorMessage ? errRes.error.errorMessage : fallBackErrorMsg
						} :
						{
							errorCode: error.response.status,
							message: errRes.message ? errRes.message : fallBackErrorMsg
						};
				}
			} else {
				error = { errorCode: 'Unknown', message: errorMessages.generic };
			}

			if (options && options.EventType) {
				let errorLoggerObj = {
					"EventOutcome": loggerEventOutcome.failure,
					"EventType": options && options.EventType ? options.EventType : '',
					"EventName": options && options.EventName ? options.EventName : '',
					"Content": {
						"Data": error.message,
					}
				}
				if (options.EventSource) {
					errorLoggerObj = { ...loggerObj, EventSource: options.EventSource }
				}
				setTimeout(() => {
					logger(errorLoggerObj);
				}, 0);
			}

			return callback(error, null);
		});
}
export {
	updateMetadata, facilityListService, procedureMetadataListService, caseListService, caseDetailsService,
	caseDetailsUpdateService, caseGapDataService, caseTimingDataService, caseAnalyticsDataService, caseAnalyticsDataTHAService, caseAnalyticsTimingDataService,
	totalGlobalCasesService,totalDeviceCount, getCasesAlighmentsService, addPromScoreService,
	getPromScoreHistoryService, promsOutcomeScoreService, updatePromScoreService, deletePromScoreService,
	getAllImplantsService, getLastCaseUploadService, getSurgeonListService, removeSurgeonService, getPlanningResultService, totalOutcomeScoreService,
	searchSurgeonsService, mapSurgeonsService, dataAssociationReqService, dataAssociationReqListService, exportCaseDataService, setNotifications, getNotificationCount,
	getNotifications, dismissNotification, getReviewCases, getReviewCasesCount, getReviewCaseDetails, saveReviewCaseDetails, getAllSurgeons, getAllCustomers, customerListService,
	getCustomerDetailsService, exportLogsDataService, getAllDevicesList, saveDeviceInfo, getExpiredLicenseList, getPelvictiltResultService, getSurgeonPreference, putSurgeonPreference,getGenerateSurgeonIDs,searchCareteamService,getCommentsHistoryDetails,
	getPendingCasesCount, searchSalesRepCustomerService, totalActivePlans
};
