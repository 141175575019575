import React, { Component } from 'react';
import { connect } from 'react-redux';
import RarAssociationListComponent from '../../components/RarAssociationList/RarAssociationList';
class RarAssociationContainer extends Component {
  render() {
    return (
      <div >
        <RarAssociationListComponent />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state,
  };
}


export default connect(mapStateToProps)(RarAssociationContainer);
