import React, { Component } from 'react'
import SurgeonPreferenceComponent from '../../components/Preferences/SurgeonPreferencesSettings'
import { getSurgeonPreference, putSurgeonPreference } from '../../services/java/java-services';
import { TMVMessage } from '../../helpers/messages';
import { getUserRoleType } from "../../services/aws/aws-services";
import { SurgeonPreferences } from '../../helpers/constants';

var successMessageTimeout;
export default class SurgeonPreferenceContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preferenceLoad: false,
            preferenceSaveLoading: false,
            preferenceErr: null,
            errorMessage: null,
            successMessage: '',
            preferences: [],
            surgeonPreferenceValue: null,
            thacasePreference: null,
            successMessageTimeout: null,
            setuptimePreference:false,
            setuptimePreferenceChanged: false,
            setuptimePreferenceLoad:false

        }
    }

    componentDidMount() {
        const loggedInUserType = getUserRoleType();
        if (loggedInUserType.includes('surgeon')) {
            this.getvalueSurgeons();
        }
    }

    /**
    * @description function to handle get call for showing preferences
    * @memberof SurgeonPreferenceContainer
    */
    getvalueSurgeons() {
        this.setState({ preferenceLoad: true, setuptimePreferenceLoad:true, preferenceErr: null });
        getSurgeonPreference((err, result) => {
            if (err) {
                this.setState({ preferenceLoad: false, setuptimePreferenceLoad: false, preferenceErr: err });
            } else {
                const dataSet = result.data.user && result.data.user.length ? result.data.user[0] : null;
                const preferences = dataSet ? dataSet.preference : '';
                const thacasePreference = dataSet ? dataSet.thacasePreference : '';
                const setuptimePreference = dataSet ? dataSet.setuptimePreference: false;
                this.setState({ preferences, preferenceErr: null, thacasePreference: thacasePreference ? thacasePreference : SurgeonPreferences.THAMeasurement.BOTH, preferenceLoad: false,setuptimePreference:setuptimePreference, setuptimePreferenceLoad:false });
            }
        })
    }

    /**
    * @description function to handle put call for saving preference
    * @Bodyparam {preference}
    * @memberof SurgeonPreferenceContainer
    */
    putvalueSurgeons = (preference) => {
        const {setuptimePreference,setuptimePreferenceChanged } = this.state;
        clearTimeout(successMessageTimeout);
        this.setState({ preferenceSaveLoading: true, errorMessage: null, successMessage: '' });
      
        const params = {};
        if (preference) {
            params['preference'] = preference;
        }
        if (setuptimePreferenceChanged && typeof setuptimePreference === 'boolean') {
            params['setuptimePreference'] = setuptimePreference;
        }
    putSurgeonPreference(params, (err, result) => {
          if (err) {
            this.setState({ preferenceSaveLoading: false, errorMessage: err });
          } else {
            this.setState({
              preferenceSaveLoading: false,
              successMessage: TMVMessage.savedinfo,
              errorMessage: null,
            });
            successMessageTimeout = setTimeout(() => {
              this.setState({ successMessage: "" });
            }, 5000);
          }
        });
      }
    /**
     * @description function to handle radio button check and uncheck
     * @memberof SurgeonPreferenceContainer
     */
    handleOptionChange = (event) => {
        let value = event.currentTarget.value;
        this.setState({ surgeonPreferenceValue: value,setuptimePreferenceChanged: false }, () => {
            // Allowing time for the state to be set before API call
            setTimeout(() => {
                this.putvalueSurgeons(value);
            }, 0);
        });
    }
    /**
     * @description function to handle checkbox button check and uncheck
     * @memberof SurgeonPreferenceContainer
     */
    handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        this.setState(
            { setuptimePreference: checked, setuptimePreferenceChanged: true,surgeonPreferenceValue:false },
            () => {
                // Call the API after updating the state
                this.putvalueSurgeons(this.state.surgeonPreferenceValue);
            }
        );
    }

    render() {
        const { preferenceErr, successMessage, errorMessage, preferenceLoad,setuptimePreferenceLoad, preferences, preferenceSaveLoading, thacasePreference, surgeonPreferenceValue,setuptimePreference } = this.state;
        return (
            <SurgeonPreferenceComponent
                preferenceErr={preferenceErr}
                successMessage={successMessage}
                errorMessage={errorMessage}
                handleOptionChange={this.handleOptionChange}
                preferences={preferences}
                surgeonPreferenceValue={surgeonPreferenceValue}
                thacasePreference={thacasePreference}
                setuptimePreference={setuptimePreference}
                preferenceLoad={preferenceLoad}
                preferenceSaveLoading={preferenceSaveLoading}
                handleCheckboxChange={this.handleCheckboxChange}
                setuptimePreferenceLoad={setuptimePreferenceLoad}
            />
        )
    }
}
