import React, { Component } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Header from '../Header/Header';
import TKAPlanningReviewTool from './TKAPlanningReviewTool';

export default class PlanningReviewTool extends Component {
    render() {
        const { caseData, implants, regions, selectedRegion, femurSizeRange, showResults, handleRangeChange, handleInputChange, handleSubmit, handleReset, 
            handleRegionChange, error, disableFilter, disableReset, defaults, loggerObj,planningToolError,gapsCollectedPlanningTool } = this.props;

        return (<div>
            <Header />
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1">
                            <SideMenu loggerObj={loggerObj} />
                        </div>
                        <div className="col-md-11" >
                            <div className="data-list-tabs planning-tool-wrap">
                                <Tabs defaultActiveKey="TKA">
                                    <Tab eventKey="TKA" title="TKA">
                                        <TKAPlanningReviewTool
                                            caseData={caseData}
                                            femurSizeRange={femurSizeRange}
                                            implants={implants}
                                            regions={regions}
					                        selectedRegion={selectedRegion}
                                            defaults={defaults}
                                            showResults={showResults}
                                            disableFilter={disableFilter}
                                            disableReset={disableReset}
                                            error={error}
                                            handleRangeChange={handleRangeChange}
                                            handleInputChange={handleInputChange}
                                            handleRegionChange={handleRegionChange}
                                            handleSubmit={handleSubmit}
                                            handleReset={handleReset}
                                            planningToolError={planningToolError}
                                            gapsCollectedPlanningTool={gapsCollectedPlanningTool}
                                        />
                                    </Tab>
                                    {/* <Tab eventKey="UKR" title="UKR">
                                        UKR
                                    </Tab> */}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
