import React, { Component } from "react";
import DeviceSearch from './DeviceSearch'
import AddDeviceModalComponent from "./AddDeviceModal";
import { deviceMessages } from '../../helpers/messages';

export default class DeviceMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSuccessMsg: ''
        }
    }
    /**
     * @description Funtion to handle situation when device added
     * @memberof DeviceComponent
     */
    /* istanbul ignore next  */
    deviceAdded = () => {
        this.setState({ showSuccessMsg: deviceMessages.deviceSaved })
        this.props.deviceAdded(true);
        setTimeout(() => {
            this.setState({ showSuccessMsg: '' });
        }, 5000);
    }

    render() {
        const { fromDashboard, handleSearchSubmit, handleReset } = this.props;
        const { showSuccessMsg } = this.state;
        return (
            <div className="row">
                <div className="col-md-6">
                    <DeviceSearch handleSearchSubmit={handleSearchSubmit} handleReset={handleReset} />
                </div>
                <div className="col-md-6 text-end">
                    <div className="pt-3">
                        <AddDeviceModalComponent deviceAdded={this.deviceAdded} fromDashboard={fromDashboard} />
                    </div>
                </div>
                <div className="col-md-12">
                    {showSuccessMsg ? <div className="proms-success-msg my-3">{showSuccessMsg}</div> : ''}
                </div>
            </div>

        );
    }
}
