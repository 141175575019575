import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CloseButton } from 'react-bootstrap';

export default class CustomModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { show, dialogClassName, title, body, hideClose, isConfirm, isAlert, isComponent, children, confirmText, rejectText, alertText, confirmAction, rejectAction, closeAction , saveDisable = false } = this.props;
        const dataTestId = this.props['data-testid']; 

        return (
            <Modal data-testid="common-modal" id="common-modal" show={show} onHide={closeAction} backdrop="static" keyboard={!hideClose} dialogClassName={dialogClassName} >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    {hideClose === true ? '' : <CloseButton data-testid={dataTestId} onClick={closeAction} />}
                </Modal.Header>
                <Modal.Body>
                    {!isComponent ?
                        <div className="row">
                            <div className="col-sm-12">{body}</div>
                        </div>
                    : children}
                </Modal.Body>
                
                {isConfirm ?
                    <Modal.Footer>
                        <Button variant="primary" id="confirmBtn" disabled={saveDisable} onClick={confirmAction}>{confirmText || 'Yes'}</Button>
                        <Button variant="secondary" id="rejectBtn" onClick={rejectAction}>{rejectText || 'No'}</Button>
                    </Modal.Footer>
                : ''}

                {isAlert ?
                    <Modal.Footer>
                        <Button variant="primary" onClick={closeAction}>{alertText || 'Okay'}</Button>
                    </Modal.Footer>
                : ''}
            </Modal>
        )
    }
}
