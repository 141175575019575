import * as types from '../constants/actionTypes/upload';
const initialState = {
  updateMetaData: ''
};

export default function uploadReducer(state = initialState, action) {
  let data;
  switch (action && action.type) {
    case types.UPDATE_METADATA_SUCCESS:
      data = action.res;
      return Object.assign({}, state, {
        res: {
          updateMetaData: data,
          update: 'success'
         }
      });
    case types.UPDATE_METADATA_ERROR:
      data = action.err;
      return Object.assign({}, state, {
        res: {
         text: data,
         error: true,
         update: 'fail'
        }
      });
    default:
      return state;
  }
}
