import React, { Component } from 'react';
import ReviewCaseDetails from '../../components/ReviewCaseDetails/ReviewCaseDetails';
import { actions, reviewCaseDetailsMessages, loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { checkInactiveAssociation, getAllCustomers, getAllSurgeons, getReviewCaseDetails, saveReviewCaseDetails } from '../../services/java/java-services';
import { setLocalStorage } from '../../services/storage/storage-service';
import { logger } from '../../services/logger/logger-service';
let startDate;
export default class ReviewCaseDetailsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			caseId: props.match.params.id ? props.match.params.id : '',
			caseDetails: '',
			noCaseDetails: false,
			surgeons: [],
			customers: [],
			selectedSurgeon: '',
			selectedCustomer: '',
			loading: false,
			error: false,
			errorMsg: '',
			saved: false,
			successMsg: '',
			saveError: false,
			saveErrorMsg: null,
			disableSurgeons: false,
			disableCustomers: false,
			caseDetailsErr: null,
			showModal: false,
			showInactiveConfirmModal: false,
			inactiveDeviceError: null,
			inactiveDeviceParams: {
				serialNo: '',
				customerName: ''
			},
			saveParams: {
				patientFirstName: '',
				patientLastName: '',
				dateOfBirth: '',
				corisurgeonId: '',
				surgeonName: '',
				customerId: '',
				serialNo: ''
			},
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.reviewCasedetails,
				"StartDate": new Date()
			}
		};
	}

	componentDidMount() {
		this.getCaseDetails(this.props.match.params.id);
		this.setState({ loading: true })
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}

	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof ReviewCaseDetailsContainer
	 */
	applicationLogger(key) {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.reviewCasedetails,
			"Content": {
				"TimeSpent": timeSinceLoad,
				"CaseId": this.state.caseId,
			}
		}
		logger(loggerObj);
	}

	/**
	 * @description function to get the review case details
	 * @param {*}
	 * @memberof ReviewCaseDetailsContainer
	 */
	getCaseDetails = async (caseId) => {
		getReviewCaseDetails(caseId, {}, (err, result) => {
			if (err) {
				this.setState({ loading: false, noCaseDetails: true, caseDetailsErr: err })
			} else {
				const caseDetails = result.data && result.data.case && result.data.case.length ? result.data.case[0] : '';
				this.setState({
					loading: false,
					saved: false,
					noCaseDetails: caseDetails ? false : true,
					successMsg: '',
					caseDetails: caseDetails,
					caseDetailsErr: null,
					saveParams: {
						patientFirstName: caseDetails.patientFirstName,
						patientLastName: caseDetails.patientLastName,
						dateOfBirth: caseDetails.dateOfBirth,
						corisurgeonId: caseDetails.surgeonId,
						surgeonName: caseDetails.surgeonName,
						customerId: caseDetails.customerId,
						serialNo: caseDetails.serialNo,
					}
				});
				this.getSurgeons();
				this.getCustomers();
			}
		})
	}

	/**
	 * @description function to fetch a list of all surgeons
	 * @param {*}
	 * @memberof ReviewCaseDetailsContainer
	*/
	getSurgeons() {
		getAllSurgeons({}, (err, result) => {
			if (err) {
				this.setState({ loading: false })
			} else {
				const surgeons = result.data.user ? result.data.user : [];
				const selectedSurgeon = surgeons.find(x => x.username === this.state.caseDetails.surgeonName);
				this.setState({
					surgeons: surgeons.map(x => { x.customName = `${x.surgeonname} - (ID: ${x.surgeonid || 'N/A'})`; return x; }),
					loading: false,
					selectedSurgeon: selectedSurgeon ? [selectedSurgeon] : '',
					disableSurgeons: selectedSurgeon ? true : false
				});
			}
		})
	}

	/**
	 * @description function to fetch a list of all customers
	 * @param {*}
	 * @memberof ReviewCaseDetailsContainer
	*/
	getCustomers() {
		getAllCustomers({}, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ loading: false })
			} else {
				const customers = result.data.user ? result.data.user : [];
				const selectedCustomer = this.state.caseDetails.customerId ? customers.find(x => x.customerid.toString() === this.state.caseDetails.customerId.toString()) : '';
				this.setState({
					customers: customers.map(x => { x.customName = `${x.customername} - (ID: ${x.customerid})`; return x; }),
					loading: false,
					selectedCustomer: selectedCustomer ? [selectedCustomer] : '',
					disableCustomers: selectedCustomer ? true : false
				});
			}
		})
	}

	/**
	 * @description Function to handle change in surgeon selection
	 * @param surgeon Selected surgeon
	 * @memberof ReviewCaseDetailsContainer
	*/
	surgeonChanged = (surgeon) => {
		this.setState(prevState => {
			return {
				selectedSurgeon: surgeon && surgeon.length ? surgeon : '',
				saveParams: {
					...prevState.saveParams,
					surgeonName: surgeon && surgeon.length ? surgeon[0].username : ''
				}
			}
		});

		// Allowing time for the state to be set before accessing the same while validating.
		setTimeout(() => {
			this.validateEditedDetails();
		}, 0);
	}

	/**
	 * @description Function to handle change in customer selection
	 * @param customer Selected customer
	 * @memberof ReviewCaseDetailsContainer
	*/
	customerChanged = (customer) => {
		this.setState({ loading: true });
		if (customer && customer.length) {
			const { saveParams, caseId } = this.state;
			let params = {
				serialNo: saveParams.serialNo,
				corisurgeonId: saveParams.corisurgeonId,
				customerId: customer[0].customerid
			};
			checkInactiveAssociation(caseId, params, (err, res) => {
				if (err) {
					this.handleCustomerUnselect(err);
				} else {
					if (res && res.data && res.data.warning) {
						this.setState({
							loading: false,
							selectedCustomer: customer,
							inactiveDeviceParams: {
								serialNo: saveParams.serialNo,
								customerName: customer[0].customName
							},
							showInactiveConfirmModal: true
						});
					} else {
						this.setState({ 
							selectedCustomer: customer,
							saveParams: {
								...saveParams,
								customerId: customer && customer.length ? customer[0].customerid : ''
							},
							loading: false 
						});
						setTimeout(() => {
							this.validateEditedDetails();
						}, 0);
					}
				}
			})
		} else {
			this.handleCustomerUnselect();
		}
	}

	handleCustomerUnselect = (err) => {
		this.setState(prevState => {
			return {
				selectedCustomer: '',
				inactiveDeviceParams: {
					...prevState.inactiveDeviceParams,
					customerName: ''
				},
				showInactiveConfirmModal: false,
				loading: false,
				inactiveDeviceError: err ? err : null
			}
		});
	}

	/**
	 * @description Function to validate edited mandatory details
	 * @memberof ReviewCaseDetailsContainer
	 * @returns Boolean value
	 */
	validateEditedDetails() {
		if (this.state.selectedSurgeon && this.state.selectedSurgeon.length && (!this.state.selectedCustomer || !this.state.selectedCustomer.length)) {
			this.setState({
				error: true,
				errorMsg: reviewCaseDetailsMessages.noCustomer
			})

			return false;
		}

		if (this.state.selectedCustomer && this.state.selectedCustomer.length && (!this.state.selectedSurgeon || !this.state.selectedSurgeon.length)) {
			this.setState({
				error: true,
				errorMsg: reviewCaseDetailsMessages.noSurgeon
			})

			return false;
		}

		if ((!this.state.selectedCustomer || !this.state.selectedCustomer.length) && (!this.state.selectedSurgeon || !this.state.selectedSurgeon.length)) {
			this.setState({
				error: true,
				errorMsg: reviewCaseDetailsMessages.noSurgeonCustomer
			})

			return false;
		}

		this.setState({ error: false, errorMsg: '' });
		return true;
	}

	/**
	 * @description Function to create save API parameters
	 * @memberof ReviewCaseDetailsContainer
	 * @returns Object to be sent to the API
	 */
	setSaveParams = () => {
		const { saveParams, caseDetails } = this.state;
		var params = {
			serialNo: saveParams.serialNo,
			corisurgeonId: saveParams.corisurgeonId
		};

		// Scenario: Surgeon details changed
		if (saveParams.surgeonName !== caseDetails.surgeonName) {
			params['surgeonName'] = saveParams.surgeonName;
		}

		// Scenario: Customer details changed
		if (saveParams.customerId !== caseDetails.customerId) {
			params['customerId'] = saveParams.customerId;
		}

		return params;
	}

	/**
	 * @description Function to save edited details
	 * @memberof ReviewCaseDetailsContainer
	 */
	saveDetails = () => {
		const data = this.setSaveParams();
		this.setState({ loading: true, showModal: false });
		saveReviewCaseDetails(this.state.caseId, data, (err, result) => {
			if (err) {
				this.setState({
					loading: false,
					saveError: true,
					saveErrorMsg: err
				})
			} else {
				this.setState({
					loading: false,
					saved: true,
					successMsg: reviewCaseDetailsMessages.detailsSaved,
					saveError: false,
					saveErrorMsg: null,
					disableSurgeons: true,
					disableCustomers: true
				});

				if (data.customerId) {
					const customer = this.state.customers.find(x => x.customerid.toString() === data.customerId.toString());
					if (customer) {
						setLocalStorage('ReviewCustomer', JSON.stringify(customer));
					}
				}

				if (data.surgeonName) {
					const surgeon = this.state.surgeons.find(x => x.username.toString() === data.surgeonName.toString());
					if (surgeon) {
						setLocalStorage('ReviewSurgeon', JSON.stringify(surgeon));
					}
				}
			}

			// The save success/error banner will disappear after 10 seconds
			setTimeout(() => {
				this.setState({ successMsg: '', saveErrorMsg: null });
			}, 10000);
		});
	}

	/**
	 * @description Function to handle modal for save confirmation
	 * @memberof ReviewCaseDetailsContainer
	 */
	confirmSave = () => {
		if (this.validateEditedDetails()) {
			this.setState({ showModal: true });
		}
	}

	/**
	 * @description Function to close confirmation modal
	 * @memberof ReviewCaseDetailsContainer
	 */
	closeModal = () => {
		this.setState({ showModal: false });
	}

	/**
	 * @description Function to close inactive device confirmation modal
	 * @memberof ReviewCaseDetailsContainer
	 */
	closeInactiveConfirmModal = () => {
		this.setState({ selectedCustomer: '', disableCustomers: false, showInactiveConfirmModal: false });
	}

	/**
	 * @description Function to handle inactive device associate confirmation
	 * @memberof ReviewCaseDetailsContainer
	 */
	inactiveDeviceConfirmed = () => {
		this.setState(prevState => {
			return {
				saveParams: {
					...prevState.saveParams,
					customerId: prevState.selectedCustomer && prevState.selectedCustomer.length ? prevState.selectedCustomer[0].customerid : ''
				},
				showInactiveConfirmModal: false
			}
		});

		// Allowing time for the state to be set before accessing the same while validating.
		setTimeout(() => {
			this.confirmSave();
		}, 0);
	}

	render() {
		return (
			<>
				{this.state.loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : ''}
				<ReviewCaseDetails
					loading={this.state.loading}
					caseDetails={this.state.caseDetails}
					noCaseDetails={this.state.noCaseDetails}
					selectedSurgeon={this.state.selectedSurgeon}
					selectedCustomer={this.state.selectedCustomer}
					surgeons={this.state.surgeons}
					customers={this.state.customers}
					disableSurgeons={this.state.disableSurgeons}
					disableCustomers={this.state.disableCustomers}
					surgeonChanged={this.surgeonChanged}
					customerChanged={this.customerChanged}
					saveDetails={this.saveDetails}
					error={this.state.error}
					errorMsg={this.state.errorMsg}
					saved={this.state.saved}
					successMsg={this.state.successMsg}
					saveError={this.state.saveError}
					saveErrorMsg={this.state.saveErrorMsg}
					confirmSave={this.confirmSave}
					closeModal={this.closeModal}
					showModal={this.state.showModal}
					loggerObj={this.state.loggerObj}
					caseDetailsErr={this.state.caseDetailsErr}
					showInactiveConfirmModal={this.state.showInactiveConfirmModal}
					inactiveDeviceParams={this.state.inactiveDeviceParams}
					inactiveDeviceError={this.state.inactiveDeviceError}
					closeInactiveConfirmModal={this.closeInactiveConfirmModal}
					inactiveDeviceConfirmed={this.inactiveDeviceConfirmed}
				/>
			</>
		)
	}
}
