import React, { Component } from "react";
import { connect } from "react-redux";
class Header extends Component {
  render() {
    return (
      <div className="">
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Header);
