import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sidemenu from '../SideMenu/SideMenu';
import SurgeonDashboard from '../../containers/Dashboard/Surgeon';
import RarDashboard from '../../containers/Dashboard/Rar';
import AdminDashboard from '../../containers/Dashboard/Admin';
import CareTeamDashboard from '../../containers/Dashboard/CareTeam';
import SalesRepDashboard from '../../containers/Dashboard/SalesRep';
import RarSalesRepDashboard from '../RarSalesRepdashDoard/RarSalesRepDashboard';
import CustomerSupportDashboard from '../../containers/Dashboard/CSDashContainer';
import { getUserRoleType } from '../../services/aws/aws-services';
import { actions, downTimeSettingsMessages, errorMessages } from '../../helpers/messages'

class DashboardComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: props.loading === true,
			loggedInUserType: getUserRoleType(),
			showBanner: true
		}
	}

	/**
	* @description Function to toggle loader
	* @param status Show/hide status of the loader
	* @memberof DashboardComponent
	*/
	toggleLoading = (status) => {
		this.setState({ loading: status })
	}

	closeBanner = (event) => {
		event.preventDefault()
		this.setState({ showBanner: false });
	}

	render() {
		const { downTimeData } = this.props;
		const { loggedInUserType } = this.state;
		return (<>
			{/* <Header /> */}
			{this.state.loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : null}
			<div className="bgGray">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-1 sm-side-width">
							<Sidemenu loggerObj={this.props.loggerObj} />
						</div>
						<div className="col-md-11 sm-col-width" >
							{/* Downtime info */}
							{downTimeData && downTimeData.status && this.state.showBanner ?
								<div className="alert alert-warning data-list-tabs downtime-banner">
									<div className="d-flex align-items-center justify-content-between">
										<p className="text-danger m-0">{downTimeSettingsMessages.dashboardBanner(downTimeData)}</p>
										<div data-testid="close" title="Close" className="cursor-pointer" onClick={e => this.closeBanner(e)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
												<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
											</svg>
										</div>
									</div>
								</div>
								: null}

							{/* Surgeon Dashboard */}
							{loggedInUserType.includes('surgeon') ? <SurgeonDashboard toggleLoading={this.toggleLoading} /> : null}

							{/* Careteam Dashboard */}
							{loggedInUserType.includes('careteam') ? <CareTeamDashboard /> : null}

							{/* RAR Dashboard */}
							{loggedInUserType?.length === 1 && loggedInUserType.includes('rar') ? <RarDashboard /> : null}
							{/* SalesRep Dashboard */}
							{loggedInUserType?.length === 1 && loggedInUserType.includes('salesrep') ? <SalesRepDashboard /> : null}
							{/* RAR and SalesRep Combined Dashboard */}
							{loggedInUserType?.length > 1 && loggedInUserType.includes('rar') && loggedInUserType.includes('salesrep') ? <RarSalesRepDashboard/> : null}

							{/* Admin Dashboard */}
							{loggedInUserType.includes('admin') ? <AdminDashboard loggedInUserType={loggedInUserType} /> : null}
							{/* CustomerSupport Dashboard */}
							{loggedInUserType?.length === 1 && loggedInUserType.includes('customersupport') ? <CustomerSupportDashboard /> : null}

							{/* Unauthorized user */}
							{!loggedInUserType?.length ? <div className="dashboard-heading red"> {errorMessages.dashboard.unauthorizedUser}</div> : null}
						</div>
					</div>
				</div>
			</div>
		</>);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
