import React, { Component } from 'react'
import { actions } from '../../helpers/messages';
import { DataTable } from '../DataTable/DataTable';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

export default class BillOfMaterials extends Component {
    render() {
        const { loading, materials, error } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Microservice',
                accessor: 'name',
                Filter: ''
            },
            {
                Header: 'Identifier',
                accessor: 'id',
                Filter: ''
            },
            {
                Header: 'Version',
                accessor: 'version',
                Cell: ({ row }) => row.original.version ? row.original.version : 'N/A',
                Filter: ''
            }
        ];
        return (
            <div className="bom-modal">
                {loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : ''}
                {error ? <ErrorMsgBlockComponent errorObject={error} /> : ''}
                {materials ? <DataTable listType="bill-of-materials" columns={columns} dataSet={materials} showPagination={false} /> : ''}
            </div>
        )
    }
}
