import React, { Component } from 'react';
import { DataTable } from '../DataTable/DataTable';
import { convertUTCToLocalDateTime, isValidDate } from '../../helpers/GlobalFunctions';
import CustomModal from '../../shared/CustomModal';
import CustomerSupportHistoryDetail from './CustomerSupportHistoryDetail';

class CustomerSupportHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHistoryDetailModal: false,
      viewHistoryData:{}
    };
  }

  /**
  * @description function set data for the comment modal
  * @param {rowData} data for row
  * @memberof CustomerSupportHistoryModal
  */
  showEventDetailModal = (rowData) => {
    rowData && this.setState({ viewHistoryData: rowData })
    this.state.showHistoryDetailModal
      ? this.setState({ showHistoryDetailModal: false })
      : this.setState({ showHistoryDetailModal: true });
  };

/**
* @description function show date in correct format or NA
* @param {updatedDate} date to show 
* @memberof CustomerSupportHistoryModal
*/
/* istanbul ignore next  */
  renderDate(updatedDate) {
    if (!updatedDate) {
      return 'N/A';
    }

    if (isValidDate(updatedDate)) {
      return convertUTCToLocalDateTime(updatedDate);
    }

    return 'Invalid Date';
  }

  render() {
    const { customerHistoryData } = this.props;
    const {viewHistoryData, showHistoryDetailModal} = this.state
    /* istanbul ignore next  */
    const columns = [
      {
        Header: 'Event',
        accessor: 'event',
        Cell: ({ row }) => <div>{row.original.event ? row.original.event : 'N/A'}</div>,
        Filter: ''
      },
      {
        Header: "Updated By",
        accessor: 'updatedUserFullName',
        Filter: '',
        Cell: ({ row }) => <div className='text-capitalize'>{row.original.updatedUserFullName ? row.original.updatedUserFullName : 'N/A'}</div>,
      },
      {
        Header: 'Updated On',
        accessor: 'updatedDate',
        Cell: ({ row }) =>  <div>{this.renderDate(row.original.updateddate)}</div>,
        Filter: ''
      },
      {
        Header: 'Requested By',
        accessor: 'requestorFullName',
        Cell: ({ row }) => <div className='text-capitalize'>{row.original.requestorFullName ? row.original.requestorFullName : 'N/A'}</div>,
        Filter: ''
      },
      {
        Header: 'Comment',
        accessor: 'notesComment',
        Cell: ({ row }) => <div className="d-inline-block text-truncate" style={{ maxWidth: '150px' }}>{row.original.notesComment ? row.original.notesComment : 'N/A'}</div>,
        Filter: ''
      },
      {
        Header: ' ',
        Cell: ({row}) => (
          <div role="button">
            <svg onClick={() => this.showEventDetailModal(row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
              <path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>
          </div>
        ),
      }
    ]
    return (
      <>
        <div className="customer-history-dialog">
          <DataTable
            listType="customer-support-historylist"
            customerHistoryData={customerHistoryData}
            columns={columns}
            showPagination={false}
          />
        </div>

        {/* Modal to history view event details for customer support */}
        <CustomModal
          id="customer-support-view-history-modal"
          show={showHistoryDetailModal}
          isComponent={true}
          title="Comments History"
          closeAction={this.showEventDetailModal}
          dialogClassName="support-comment-modal"
        >
          <CustomerSupportHistoryDetail
            showEventDetailModal={this.showEventDetailModal}
            customerHistoryData={customerHistoryData}
            viewHistoryData={viewHistoryData}
            dialogClassName="support-comment-modal"
          />
        </CustomModal>
      </>
    );
  }
}
export default CustomerSupportHistoryModal;

