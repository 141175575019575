import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";

class ExportDataComponent extends Component {
	constructor() {
		super()
		this.exportRef = React.createRef();
	}

	/**
		 * @description function to handle export action 
		 * @param {*}
		 * @memberof ExportDataComponent
		 */
	handleExportAction = () => {
		/* istanbul ignore next  */
		setTimeout(() => {
			this.exportRef.current.link.click();
		}, 1000);
	}

	render() {
		const { exportToCsv, exportLogsHeader, exportLogsData, isLoading, isExport, exportFileName, filterObj, errorMessage, disableExport, totalCount, refreshLogs } = this.props;
		if (isExport) {
			this.handleExportAction();
		}

		return (
			<>
				<div className="d-flex align-items-center justify-content-between mt-3">
					<h1 className="file-list-heading">Audit Logs</h1>
					<div className="buttons-wrap">
						<button type="button" id="refresh-logs" className="btn btn-primary me-2" onClick={refreshLogs}>Refresh Logs</button>
						{isLoading ?
							<button className="btn btn-primary admin-logs-export-csv-button disabled">
								<span className="spinner-border spinner-border-sm"></span>&nbsp;Exporting...
							</button>
							: <button type="button" id="exportToExcel" className="btn btn-primary admin-logs-export-csv-button"
								disabled={!filterObj || disableExport || totalCount === 0} onClick={(e) => exportToCsv(filterObj)}>
								Export to CSV
							</button>}

					</div>
				</div>
				{errorMessage ? <span className="error-block d-flex align-items-center justify-content-center">{errorMessage} &nbsp;</span> : ''}
				{exportLogsData ?
					<CSVLink
						headers={exportLogsHeader}
						data={exportLogsData}
						filename={exportFileName}
						ref={this.exportRef}
						target="_blank"
					/>
					: null}
			</>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(ExportDataComponent);
