import React, { Component } from 'react';
import { connect } from 'react-redux';
import { totalActivePlans, totalDeviceCount } from '../../services/java/java-services';
import AdminComponent from '../../components/Dashboard/Admin/Admin';
import { getUserName } from '../../services/aws/aws-services';

class AdminContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			planLoading: false,
			deviceLoading: true,
			totalDeviceCount: null,
			deviceCountErr: null,
			totalActivePlansCount: null,
			activePlansErr: null
		}
	}

	componentDidMount() {
		this.getTotalDeviceCount();
		if (this.props.loggedInUserType.includes('customersupport')) {
			this.getTotalActivePlansCount();
		}
	}

	/**
	* @description Function to fetch device count
	* @memberof AdminContainer
	*/
	getTotalDeviceCount = () => {
		this.setState({ deviceLoading: true, totalDeviceCount: null, deviceCountErr: null });
		totalDeviceCount((err, result) => {
			if (err) {
				this.setState({ deviceLoading: false, deviceCountErr: err });
			} else {
				this.setState({ deviceLoading: false, deviceCountErr: null, totalDeviceCount: result.data && !isNaN(parseInt(result.data.activedevicecount)) ? result.data.activedevicecount : null });
			}
		})
	}

	/**
	 * @description Function to fetch total active plans count
	 * @memberof CSDashContainer
	 */
	getTotalActivePlansCount = () => {
		this.setState({
			planLoading: true,
			totalActivePlansCount: null,
			activePlansErr: null,
		});
		let userName = getUserName();
		totalActivePlans(userName, (err, result) => {
			if (err) {
				this.setState({ planLoading: false, activePlansErr: err });
			} else {
				this.setState({
					planLoading: false,
					activePlansErr: null,
					totalActivePlansCount:
						result.data && !isNaN(parseInt(result.data.count))
							? result.data.count
							: null,
				});
			}
		});
	};

	render() {
		const { loggedInUserType } = this.props;
		const { planLoading, deviceLoading, deviceCountErr, totalDeviceCount, activePlansErr, totalActivePlansCount } = this.state;
		return (
			<AdminComponent
				planLoading={planLoading}
				deviceLoading={deviceLoading}
				loggedInUserType={loggedInUserType}
				deviceCountErr={deviceCountErr}
				totalDeviceCount={totalDeviceCount}
				activePlansErr={activePlansErr}
				totalActivePlansCount={totalActivePlansCount}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(AdminContainer);