import React, { Component } from 'react';
import { connect } from 'react-redux';
import SurgeonDetailsComponent from '../../components/SurgeonDetails/SurgeonDetails'
import { getSurgeonCustomersService, getSurgeonDetails, searchCustomersService, updateUserDetails } from '../../services/java/java-services';
import { updateUserDetailsCognito } from '../../services/aws/aws-services';
import { surgeonMessages, customerMapping, loggerEventTypes, loggerEventName, loggerEventOutcome, loggerEventMessage } from '../../helpers/messages';
import { validateAlphanumeric, validateSurgeonID, validateEmail, validateSurgeonFields } from './ValidateSurgeon';
import { logger } from '../../services/logger/logger-service';

let startDate;
class SurgeonDetailsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			searchListLoading:false,
			editMode: false,
			userName: props.match.params.id || '',
			surgeonDetails: '',
			searchResults:'',
			searchKey: '',
			customers: [],
			showAddCustomerModal: false,
			refreshCustomerList: false,
			surgeonError: null,
			surgeonCustomerError: null,
			searchCustomerError: null,
			editObj: {
				firstName: '',
				lastName: '',
				email: '',
				surgeonID: ''
			},
			errors: {
				firstName: '',
				lastName: '',
				email: '',
				surgeonID: ''
			}
		}
	}

	componentDidMount() {
		startDate = new Date();
        window.addEventListener('beforeunload', this.timeSpentLogger);
		this.getSurgeonDetails(this.state.userName);
		this.getSurgeonCustomers(this.state.userName);
	}

	
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
    }
    
    /**
     * @description function to handle application logs for time spent on the page
     * @param {*}
     * @memberof UserDetailsContainer
     */
     timeSpentLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.surgeonDetails,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

	/**
	 * @description Function to get the surgeon details
	 * @param userName Unique username of the surgeon to be fetched
	 * @memberof SurgeonDetailsContainer
	 */
	getSurgeonDetails = async (userName) => {
		this.setState({ loading: true })
		getSurgeonDetails(userName, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ loading: false, surgeonError: err })
			} else {
				const surgeon = result.data && result.data.user && result.data.user.length ? result.data.user[0] : '';
				this.setState({
					loading: false,
					surgeonError: null,
					surgeonDetails: surgeon,
					...this.getEditObject(surgeon)
				});
			}
		})
	}


	/**
	 * @description Function to get the customers associated with surgeon
	 * @param userName Unique username of the surgeon to be fetched
	 * @memberof SurgeonDetailsContainer
	 */
	getSurgeonCustomers = async (userName, params) => {
		var urlParams = {
			sortorder: params && params.sortOrder ? params && params.sortOrder : 'desc',
			column: params && params.columnSortBy ? params && params.columnSortBy : 'customername'
		}

		this.setState({ loading: true })
		getSurgeonCustomersService(userName, urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					this.setState({ loading: false, surgeonCustomerError: err })
				} else {
					const customers = result && result.data && result.data.customerList && result.data.customerList.length ? result.data.customerList : [];
					this.setState({
						loading: false,
						surgeonCustomerError: null,
						customers: customers
					});
				}
			}
		);
	}

	/**
	 * @description Function to handle state when edit is cancelled
	 * @param surgeon Surgeon Details
	 * @memberof SurgeonDetailsContainer
	 */
	getEditObject(surgeon) {
		const editObj = {
			firstName: surgeon && surgeon.firstName ? surgeon.firstName : '',
			lastName: surgeon && surgeon.lastName ? surgeon.lastName : '',
			email: surgeon && surgeon.email ? surgeon.email : '',
			surgeonID: surgeon && surgeon.surgeonId ? surgeon.surgeonId : '',
		}
		return {
			editObj: editObj,
			errors: {
				firstName: '',
				lastName: '',
				email: '',
				surgeonID: ''
			}
		}
	}

	/**
	 * @description Function to enable/disable edit mode
	 * @memberof SurgeonDetailsContainer
	 */
	toggleEditMode = () => {
		this.setState(prevState => {
			return {
				...this.getEditObject(prevState.surgeonDetails),
				editMode: !prevState.editMode,
				showErrorMsg:''
			};
		});
	}

	/**
	 * @description Function to handle input changes
	 * @param  value Value of the input
	 * @param  field Input field name
	 * @memberof SurgeonDetailsContainer
	 */
	handleInputChange = (field, value) => {
		var errorMessage = !value ? surgeonMessages[field] : '';
		if (field === 'surgeonID' && value) {
			const surgeonIDCheck = validateSurgeonID(value);
			errorMessage = surgeonIDCheck.error;
		}
		if ((field === 'firstName' || field === 'lastName') && value) {
			const surgeonNameCheck = validateAlphanumeric(value);
			errorMessage = surgeonNameCheck.error;
		}
		if (field === 'email' && value) {
			const emailCheck = validateEmail(value);
			errorMessage = emailCheck.error;
		}
		this.setState(prevState => {
			return {
				[field]: value,
				editObj: {
					...prevState.editObj,
					[field]: value
				},
				errors: {
					...prevState.errors,
					[field]: errorMessage
				}
			}
		});
	}

	/** 
	 * @description Function submit form by hitting enter key
	 *  @param e Keypress event
	 *  @memberof SurgeonDetailsContainer
	 */
	onEnterPress = (e) => {
		if (e.which === 13) {
			e.preventDefault();
			this.saveSurgeon();
		}
	}

	/**
	 * @description Function to validate fields at the time of submit
	 * @returns Boolean value if there are any errors
	 * @memberof SurgeonDetailsContainer
	 */
	validateFields = () => {
		const { editObj } = this.state;
		let { errorFlag, errors } = validateSurgeonFields({ editObj: editObj, errors: this.state.errors });
		if (!errorFlag && editObj.firstName) {
			let firstName = validateAlphanumeric(editObj.firstName);
			errorFlag = firstName.errorFlag;
			errors['firstName'] = firstName.error;
		}
		if (!errorFlag && editObj.lastName) {
			let lastName = validateAlphanumeric(editObj.lastName);
			errorFlag = lastName.errorFlag;
			errors['lastName'] = lastName.error;
		}
		if (!errorFlag && editObj.email) {
			let email = validateEmail(editObj.email);
			errorFlag = email.errorFlag;
			errors['email'] = email.error;
		}

		if (errorFlag) {
			this.setState(prevState => {
				return {
					errors: {
						...prevState.errors,
						...errors
					}
				}
			})
		}

		return errorFlag;
	}

	/**
	 * @description function to validate and save the case details
	 * @param {*}
	 * @memberof SurgeonDetailsContainer
	 */
	saveSurgeon = () => {
		this.setState({ showSuccessMsg: '', showErrorMsg: '' });
		const formHasErrors = this.validateFields();

		if (!formHasErrors) {
			const { editObj, surgeonDetails } = this.state;
			this.setState({ loading: true });
			const isUpdateCognito = editObj.firstName !== surgeonDetails.firstName || editObj.lastName !== surgeonDetails.lastName || editObj.email !== surgeonDetails.email;
			let updateAttributes = {};
			if (editObj.firstName !== surgeonDetails.firstName) {
				updateAttributes['firstname'] = editObj.firstName;
			}

			if (editObj.lastName !== surgeonDetails.lastName) {
				updateAttributes['lastname'] = editObj.lastName;
			}

			if (editObj.email !== surgeonDetails.email) {
				updateAttributes['email'] = editObj.email;
			}
			if (editObj.surgeonID !== surgeonDetails.surgeonId) {
				updateAttributes['surgeonid'] = editObj.surgeonID;
			}
			if (isUpdateCognito) {
				this.updateCognito(updateAttributes);
			} else {
				this.updateSurgeonAPI(updateAttributes);
			}
		}
	}

	/**
	 * @description function to update cognito userpool
	 * @param {*}
	 * @memberof SurgeonDetailsContainer
	 */
	updateCognito = (updateAttributes) => {
		this.setState({ showSuccessMsg: '', showErrorMsg: '' });
		const { userName } = this.state;
		let cognitoUserAttributes = [];

		if (updateAttributes.firstname) {
			cognitoUserAttributes.push({ Name: 'given_name', Value: updateAttributes.firstname });
		}

		if (updateAttributes.lastname) {
			cognitoUserAttributes.push({ Name: 'family_name', Value: updateAttributes.lastname });
		}

		if (updateAttributes.email) {
			cognitoUserAttributes.push({ Name: 'email', Value: updateAttributes.email });
		}

		updateUserDetailsCognito(userName, cognitoUserAttributes)
			.then(res => {
				if (res) {
					this.updateSurgeonAPI(updateAttributes);
				}
			})
			.catch(err => {
				this.setState({ loading: false, showErrorMsg: err && err.message ? err.message : surgeonMessages.saveError });
			});
	}

	/**
	 * @description function to update surgeon in db
	 * @param {*}
	 * @memberof SurgeonDetailsContainer
	 */
	updateSurgeonAPI = (updateAttributes) => {
		this.setState({ showSuccessMsg: '', showErrorMsg: '' });
		const { userName } = this.state;

		updateUserDetails(userName, updateAttributes, (err, res) => {
			if (err) {
				this.setState({ loading: false });
				let errMsg = '';
				if (err.errorCode === 2028) {
					errMsg = err.message ? err.message : surgeonMessages.duplicateSurgeonID;
					this.setState({ showErrorMsg: errMsg });
				}
				errMsg = errMsg ? errMsg : surgeonMessages.saveError;
				this.applicationLogger({ outcome: loggerEventOutcome.failure, message: `${errMsg} (For ${userName})` });
			} else {
				this.setState({ showSuccessMsg: surgeonMessages.saveSurgeonMsg, loading: false, editMode: false });
				this.applicationLogger({ outcome: loggerEventOutcome.success, message: loggerEventMessage.userUpdated(userName) });
				this.getSurgeonDetails(userName);
			}
		});
	}

    /**
     * @description function to log surgeon update event
     * @memberof SurgeonDetailsContainer
     */
    /* istanbul ignore next  */
    applicationLogger = (params) => {
        const loggerObj = {
            "EventOutcome": params.outcome,
            "EventType": loggerEventTypes.update,
            "EventName": loggerEventName.surgeonUpdate,
            "Content": {
                "Data": params.message
            }
        }
        logger(loggerObj);
    }

	/**
	 * @description function to handle input changes..
	 * @param  {} inputType
	 * @memberof SurgeonDetailsContainer
	 */
	handleSearchInputChange = async (inputValue) => {
		var errors = { ...this.state.errors }
		errors.searchKey = '';
		this.setState({ errors })
		this.setState({ searchKey: inputValue.trim() });
	}

	/** @description function to find customers
	 *  @param  {}
	 *  @memberof SurgeonDetailsContainer
	 */
	findCustomers = async () => {
		var errors = { ...this.state.errors }
		if (this.state.searchKey.trim() === '') {
			errors.searchKey = customerMapping.emptySearchBox;
			this.setState({ errors })
			return errors;
		} else {
			errors.searchKey = '';
			this.setState({ errors })
		}
		await this.getSearchResults(this.state.searchKey);
	}

	/** @description function to search customers by clicking on enter
	*  @param  {}
	*  @memberof SurgeonDetailsContainer
	*/
	onSearchEnterPress = (e) => {
		if (e.which === 13) {
			this.findCustomers();
		}
	}

	/**
	* @description function to get customers list based on search inputs 
	* @param {*}
	* @memberof SurgeonDetailsContainer
	*/
	getSearchResults = async (searchKey) => {
		this.setState({ searchListLoading: true, searchResults: '' })
		searchCustomersService({ searchString: searchKey }, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ searchListLoading: false, searchCustomerError: err })
			} else {
				this.setState({ searchListLoading: false, searchResults: result.data.customerList && result.data.customerList.length ? result.data.customerList : [], searchCustomerError: null })
			}
		})
	}

	/**
	 * @description function to open and close modal popup 
	 * @param {*}
	 * @memberof SurgeonDetailsContainer
	 */
	enableAddCustomerModal = (isShow) => {
		this.setState({ refreshCustomerList: false });
		this.state.showAddCustomerModal ? this.setState({ showAddCustomerModal: false })
			: this.setState({ showAddCustomerModal: true });
		this.clearSearch();
	}

	/**
	 * @description function to open clear search results 
	 * @param {*}
	 * @memberof SurgeonDetailsContainer
	 */
	clearSearch = () => {
		var errors = { ...this.state.errors }
		errors.searchKey = '';
		this.setState({ searchResults: '', searchKey: '', errors })
	}

	/**
	* @description function to refresh associated customer list
	* @param {*}
	* @memberof SurgeonDetailsContainer
	*/
	refreshDataList = (status) => {
		this.setState({ refreshCustomerList: status });
		if (status) {
			this.getSurgeonCustomers(this.state.userName);
		}
	}

	/**
	* @description function to refresh associated customer list
	* @param {*}
	* @memberof SurgeonDetailsContainer
	*/
	refreshCustomer = (status) => {
		if (status) {
			this.getSurgeonCustomers(this.state.userName);
		}
	}

	render() {
		return (
			<SurgeonDetailsComponent
				surgeon={this.state.surgeonDetails}
				loading={this.state.loading}
				editMode={this.state.editMode}
				toggleEditMode={this.toggleEditMode}
				editObj={this.state.editObj}
				handleInputChange={this.handleInputChange}
				onEnterPress={this.onEnterPress}
				showSuccessMsg={this.state.showSuccessMsg}
				showErrorMsg={this.state.showErrorMsg}
				surgeonError={this.state.surgeonError}
				surgeonCustomerError={this.state.surgeonCustomerError}
				formErrors={this.state.errors}
				saveSurgeon={this.saveSurgeon}
				customers={this.state.customers}
				searchResults={this.state.searchResults}
				searchCustomerError={this.state.searchCustomerError}
				enableAddCustomerModal={this.enableAddCustomerModal}
				showAddCustomerModal={this.state.showAddCustomerModal}
				handleSearchInputChange={this.handleSearchInputChange}
				findCustomers={this.findCustomers}
				refreshCustomerList={this.state.refreshCustomerList}
				refreshDataList={this.refreshDataList}
				onSearchEnterPress={this.onSearchEnterPress}
				refreshCustomer={this.refreshCustomer}
				searchListLoading={this.state.searchListLoading}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(SurgeonDetailsContainer);