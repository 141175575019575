import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { PasswordCriteria, PasswordHints } from '../../shared/PasswordCriteria';
import SideMenu from '../SideMenu/SideMenu';

export default class ResetTempPasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.resetFormRef = React.createRef();
    }
    /* istanbul ignore next  */
    resetForm = () => {
        this.resetFormRef.reset();
    }
    /* istanbul ignore next  */
    componentDidUpdate() {
        if (this.props.successMessage || this.props.formErrors.cognitoError) {
            this.resetForm();
        }
    }
    render() {
        const { handleResetPassword, handleChange, handleBlur, formErrors, loading, successMessage, onEnterPress, pwdStrengthLabel, pwdStrengthProgress,
            pwdStrengthVarient, pwdMatchObj, resetPwdErrMsg, password, confirmPassword } = this.props;
        return (
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1 sm-side-width reset-pass">
                            <SideMenu hideMenu={true} />
                        </div>
                        <div className="col-md-11 datalist-style mt-1 reset-temp-pass sm-col-width" data-testid="resetTempPassword">
                            <div className="">
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <h1 className="file-list-heading">Reset Temporary Password</h1>
                                        <p className="sub-heading-temp-pass mt-2">
                                            Please reset your temporary password before proceeding.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <form autoComplete="off" ref={(el) => this.resetFormRef = el}>
                                        <input type="hidden" value="autoCompleteOff" />
                                        <div className="reset-password">
                                            {successMessage ?
                                                <div className="reset-pwd-success-msg mt-0" data-testid="successMessage">
                                                    <p>{successMessage}</p>
                                                </div>
                                                : ''}
                                            {formErrors && formErrors.cognitoError ?
                                                <div className="reset-pwd-error-msg mt-0">
                                                    <p>{formErrors.cognitoError}</p>
                                                </div>
                                                : ''}
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    {/* New Password */}
                                                    <div className="row">
                                                        <label htmlFor="password" className="col-sm-4 col-form-label">New Password</label>
                                                        <div className="col-sm-8">
                                                            <div className="reset-password-input-wrap new-password">
                                                                <input placeholder="Enter new password"
                                                                    type="password"
                                                                    className="form-control"
                                                                    name="password"
                                                                    autoComplete="new-password"
                                                                    value={password}
                                                                    disabled={loading}
                                                                    onChange={(e) => handleChange(e.target.value, 'password')}
                                                                    onBlur={(e) => handleBlur(e.target.value, 'password')}
                                                                    onKeyPress={onEnterPress}
                                                                    id="password"
                                                                    data-testid="password" />
                                                                {/* Password Hint */}
                                                                <PasswordHints containerClass="force-reset-password-hint" />
                                                                {formErrors && formErrors.password ?
                                                                    <div className="error-block">
                                                                        <p className="m-0 new-password-errors">{formErrors.password}</p>
                                                                    </div>
                                                                    : ''}
                                                                <div className="new-password-errors"data-testid="passwordError">
                                                                    {pwdStrengthLabel ? <ProgressBar variant={pwdStrengthVarient} now={pwdStrengthProgress} label={pwdStrengthLabel} /> : null}
                                                                    {formErrors && formErrors.newPasswordMatch ?
                                                                        <PasswordCriteria pwdMatchObj={pwdMatchObj} errorMessages={resetPwdErrMsg} />
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Confirm password */}
                                                    <div className="row">
                                                        <label htmlFor="confirmPassword" className="col-sm-4 col-form-label pe-0">Confirm Password</label>
                                                        <div className="col-sm-8">
                                                            <div className="reset-password-input-wrap confirm-password">
                                                                <input placeholder="Confirm new password"
                                                                    type="password"
                                                                    className="form-control"
                                                                    name="confirmPassword"
                                                                    autoComplete="confirm-password"
                                                                    value={confirmPassword}
                                                                    disabled={loading}
                                                                    onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
                                                                    onKeyPress={onEnterPress}
                                                                    id="confirmPassword"
                                                                    data-testid="confirmPassword" />
                                                                {formErrors && formErrors.confirmPassword ?
                                                                    <div className="error-block">
                                                                        <p className="m-0 new-password-errors">{formErrors.confirmPassword}</p>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6 text-end" data-testid="updatingPassword">
                                                    {loading ? <button type="button" className="btn btn-primary disabled">
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                        &nbsp;Updating Password...
                                                    </button> : <button type="button" className="btn btn-primary proms-btn-color" id="saveChanges"
                                                        onClick={handleResetPassword} >Save and Update</button>}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
