import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { PasswordCriteria, PasswordHints } from '../../shared/PasswordCriteria';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.resetFormRef = React.createRef();
    }
    /* istanbul ignore next  */
    resetForm = () => {
        this.resetFormRef.reset();
    }
    /* istanbul ignore next  */
    componentDidUpdate() {
        if (this.props.successMessage || this.props.formErrors.cognitoError) {
            this.resetForm();
        }
    }
    render() {
        const { handleResetPassword, handleChange, handleBlur, formErrors, loading, successMessage, onEnterPress,
            pwdStrengthLabel, pwdStrengthProgress, pwdStrengthVarient, pwdMatchObj, resetPwdErrMsg,
            isAuthCodeReceived, requestAuthenticationCode, authCodeObj, authCodeDeliveryMsg,
            errorMessage, reSendAuthCode, closeAction, onEnterRequestCode } = this.props;
        return (
            <div>
                <form ref={(el) => this.resetFormRef = el}>
                    <div className="reset-password p-0">
                        {successMessage ?
                            <div className="forgot-pwd-success-msg">
                                <p>{successMessage}</p>
                            </div>
                            : ''}
                        {errorMessage ?
                            <div className="forgot-pwd-error-msg">
                                <p>{errorMessage.message}</p>
                            </div>
                            : ''}
                        <div className="row">
                            <div className="col-md-12">
                                {!isAuthCodeReceived ?
                                    <div className="reset-password-input-wrap username">
                                        <input placeholder="Enter Username"
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            autoComplete="off"
                                            value={this.props.username}
                                            onChange={(e) => handleChange(e.target.value, 'username')}
                                            onKeyPress={onEnterRequestCode}
                                            id="username" />
                                        {formErrors && formErrors.username ?
                                            <div className="error-block">
                                                <p>{formErrors.username}</p>
                                            </div>
                                            : ''}
                                    </div> : ''}

                                {isAuthCodeReceived ?
                                    <>
                                        {authCodeDeliveryMsg ?
                                            <div className="row">
                                                <div className="auth-code-success col-md-12">
                                                    Authentication code has been sent to the e-mail {authCodeObj && authCodeObj.CodeDeliveryDetails && authCodeObj.CodeDeliveryDetails.Destination ? authCodeObj.CodeDeliveryDetails.Destination : 'bla**@bla**.c**'}
                                                </div>
                                            </div> : ''}
                                        <div className="row mb-3">
                                            <div className="col-md-7">
                                                <input placeholder="Enter Authentication Code"
                                                    type="text"
                                                    className="form-control"
                                                    name="authcode"
                                                    autoComplete="off"
                                                    value={this.props.authcode}
                                                    onChange={(e) => handleChange(e.target.value, 'authcode')}
                                                    onKeyPress={onEnterPress}
                                                    id="authcode" />
                                            </div>
                                            <div className="col-md-5">
                                                {reSendAuthCode ? <button className="btn btn-primary float-end disabled resend-btn">
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                    &nbsp;Sending Code...</button> :
                                                    <button className="btn btn-primary proms-btn-color float-end resend-btn" id="resendCode"
                                                        onClick={(e) => requestAuthenticationCode(e, 'resend')} disabled={successMessage ? true : false}>Resend Code</button>}
                                            </div>
                                            <div className="col-md-12">{formErrors && formErrors.authcode ?
                                                <div className="error-block">
                                                    <p className="m-0 new-password-errors">{formErrors.authcode}</p>
                                                </div>
                                                : ''}
                                            </div>
                                        </div>
                                    </>
                                    : null}

                                {isAuthCodeReceived ?
                                    <div className="reset-password-input-wrap new-password">
                                        <input placeholder="Enter new password"
                                            type="password"
                                            className="form-control"
                                            name="newPassword"
                                            autoComplete="new-password"
                                            value={this.props.newPassword}
                                            onChange={(e) => handleChange(e.target.value, 'newPassword')}
                                            onBlur={(e) => handleBlur(e.target.value, 'newPassword')}
                                            onKeyPress={onEnterPress}
                                            id="newPassword" />
                                        {/* Password Hint */}
                                        <PasswordHints />
                                        {formErrors && formErrors.newPassword ?
                                            <div className="error-block">
                                                <p className="m-0 new-password-errors">{formErrors.newPassword}</p>
                                            </div>
                                            : ''}
                                        <div className="new-password-errors">
                                            {pwdStrengthLabel ? <ProgressBar variant={pwdStrengthVarient} now={pwdStrengthProgress} label={pwdStrengthLabel} /> : null}
                                            {formErrors && formErrors.newPasswordMatch ?
                                                <PasswordCriteria pwdMatchObj={pwdMatchObj} errorMessages={resetPwdErrMsg} />
                                                : ''}
                                        </div>
                                    </div>
                                    : null}

                                {isAuthCodeReceived ?
                                    <div className="reset-password-input-wrap confirm-password">
                                        <input placeholder="Confirm new password"
                                            type="password"
                                            className="form-control"
                                            name="confirmPassword"
                                            autoComplete="new-password"
                                            value={this.props.confirmPassword}
                                            onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
                                            onKeyPress={onEnterPress}
                                            id="confirmPassword" />
                                        {formErrors && formErrors.confirmPassword ?
                                            <div className="error-block">
                                                <p className="m-0 new-password-errors">{formErrors.confirmPassword}</p>
                                            </div>
                                            : ''}
                                    </div>
                                    : null}

                                {isAuthCodeReceived ?
                                    <div className="forgot-pwd-btns">
                                        {loading ? <button className="btn btn-primary disabled">
                                            <span className="spinner-border spinner-border-sm"></span>
                                            &nbsp;Reseting Password... </button> :
                                            <button className="btn btn-primary proms-btn-color" id="submitPassword"
                                                onClick={handleResetPassword} disabled={successMessage ? true : false} >Submit</button>}
                                        <button className="btn btn-secondary proms-btn-cancel" onClick={closeAction} id="cancelResetPwd">Cancel</button>
                                    </div>
                                    :
                                    <div className="forgot-pwd-btns">
                                        {loading && !reSendAuthCode ? <button className="btn btn-primary disabled">
                                            <span className="spinner-border spinner-border-sm"></span>
                                            &nbsp;Sending Authentication Code...</button> :
                                            <button className="btn btn-primary proms-btn-color" id="resetPassword"
                                                onClick={(e) => requestAuthenticationCode(e, 'reset')} >Reset Password</button>}
                                        <button className="btn btn-secondary proms-btn-cancel" onClick={closeAction} id="cancelResetPwd">Cancel</button>
                                    </div>}

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
