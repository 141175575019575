import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Header from '../Header/Header';
import Sidemenu from '../SideMenu/SideMenu';
import { actions, errorMessages, fileUpload } from '../../helpers/messages';
import CustomModal from '../../shared/CustomModal';

class FileUploadComponent extends Component {
	constructor(props) {
		super(props)
		this.inputOpenFileRef = React.createRef();
		this.state = {
			showConfirmModal: false
		}
	}

	/**
	* @description Function to open file dialog 
	* @memberof FileUploadComponent
	*/
	/* istanbul ignore next  */
	showOpenFileDlg = () => {
		this.inputOpenFileRef.current.click()
	}

	/**
	* @description Function to open modal for confirmation for file upload cancel
	* @memberof FileUploadComponent
	*/
	cancelUploadConfirmation = () => {
		this.setState({ showConfirmModal: true })
	}

	/**
	* @description Function to handle the action on upload confirmation modal
	* @memberof FileUploadComponent
	*/
	cancelUploadAction = (action) => {
		if (action) {
			this.props.onFileUploadCancel();
		}
		this.setState({ showConfirmModal: false });
	}

	render() {
		const { onSubmit, onInputChange, isLoading, resetInput, displayFileName, uploadError, fileExtentionError,
			uploadProgress, fileExistInS3, disclaimerCheckBoxValue, maxFileSizeError, metaDataUpdateStatus, metaDataUpdateError, disclaimerCheckBoxChange,
			formErrors, errorMsgs, successMsgs, enableUploadBtn, enableCancelBtn, showProgress, loggerObj, cancelFileUploadStatus } = this.props;
		return (
			<div>
				<Header />
				<div className="bgGray">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-1">
								<Sidemenu loggerObj={loggerObj} uploadProgress={uploadProgress} />
							</div>
							<div className="col-md-11">
								<div className="file-upload">
									<div className="body body-section">
										{metaDataUpdateStatus === 'success' && successMsgs && !cancelFileUploadStatus ?
											<div className="success-msg">
												<p>{successMsgs.fileUploadSuccess}</p>
											</div>
											: ''}
										{uploadError && cancelFileUploadStatus ?
											<div className="top-error-msg">
												<p>{uploadError}</p>
											</div>
											: ''}
										{metaDataUpdateStatus === 'fail' ?
											<div className="top-error-msg">
												<p>{metaDataUpdateError}</p>
											</div>
											: ''}
										<div className="form-container">
											<div className="form-lable"><label>Selected File<span className="red">*</span>: </label></div>
											<div className="fileinputs form-input">
												<input type="file" name="fileName" ref={this.inputOpenFileRef} id="inputFile" onChange={(e) => onInputChange(e.target.files)} onClick={resetInput} className="form-control file input-file" />
												<div className="sudofile" >
													<input placeholder="Select File" type="text" name="fileNames" disabled defaultValue={displayFileName} className="form-control input-file" size="60" />
													<button className="browse btn btn-secondary" onClick={this.showOpenFileDlg}>Browse</button>
												</div>
												{formErrors && formErrors.fileName ?
													<div className="error-msg">
														<p>{formErrors.fileName}</p>
													</div>
													: ''}
												{fileExtentionError ?
													<div className="error-msg">
														<p>{errorMsgs.fileUpload.selectZip}</p>
													</div>
													: ''}
												{maxFileSizeError ?
													<div className="error-msg">
														<p>{errorMsgs.fileUpload.maxSizeAllowed}</p>
													</div>
													: ''}
												{fileExistInS3 ?
													<div className="error-msg">
														<p>{errorMsgs.fileUpload.duplicateFileName} </p>
													</div>
													: ''}
											</div>
										</div>

										<div className="form-container">
											<div className="form-lable facility-label"></div>
											<div className="fileinputs form-input">
												<div className="mrgtop-20">
													<label className="chk-label"><input type="checkbox" id="disclaimerchk" className="chk-input" onChange={(e) => disclaimerCheckBoxChange(e)} checked={disclaimerCheckBoxValue === true} />
														&nbsp;{errorMessages.fileUpload.check1}<br />{errorMessages.fileUpload.check2}<span className="red">*</span>
													</label>
												</div>
												{formErrors && formErrors.disclaimerCheckBox ?
													<div className="disclaimer-error-msg">
														<p>{formErrors.disclaimerCheckBox}</p>
													</div>
													: ''}
												<div className="btns-style text-center">
													<button className="upload-selected btn btn-primary" id="fileUpload" onClick={onSubmit} disabled={enableUploadBtn ? false : true}>Upload</button>
												</div>
											</div>
										</div>
									</div>
									<div className="upload-status">
										{/* Modal to show upload progress */}
										{(!cancelFileUploadStatus) && <CustomModal
											id="upload-progress-modal"
											show={showProgress}
											title={actions.upload}
											isComponent={true}
											isAlert={uploadProgress > 0 && enableCancelBtn}
											alertText="Cancel Upload"
											closeAction={this.cancelUploadConfirmation}>
											{(uploadProgress > 0 && enableCancelBtn) || (isLoading && metaDataUpdateStatus === '') ?
												<ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
												: ''}
										</CustomModal>}

										{/* Modal to confirm upload cancel */}
										<CustomModal
											id="upload-cancel-modal"
											show={this.state.showConfirmModal}
											title="Alert"
											isConfirm={true}
											body={fileUpload.cancelConfirmationText}
											closeAction={() => this.cancelUploadAction(true)}
											rejectAction={() => this.cancelUploadAction(false)}
											confirmAction={() => this.cancelUploadAction(true)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(FileUploadComponent);
