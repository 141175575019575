import React, { useState, useEffect } from 'react';
import CaseAnalyticsComponent from '../../components/CaseAnalytics/CaseAnalytics';
import { caseAnalyticsDataService, caseAnalyticsDataTHAService, caseAnalyticsTimingDataService } from '../../services/java/java-services';
import AnalyticsChartList from './AnalyticsChartList.json';
import { loggerEventOutcome, loggerEventTypes, loggerEventName, trendsAlert } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { getLoadingTemplate } from '../../helpers/GlobalFunctions';
import { operativeProcedureTypes } from '../../helpers/constants';


export default function CaseAnalyticsContainer(props) {
	const [TimingData, setTimingData] = useState(null);;
	const [totalCount, setTotalCount] = React.useState(0);
	const [timingError, setTimingError] = useState(null);;
	const [analyticsDataLoading, setAnalyticsDataLoading] = useState(false);
	const [timingDataLoading, setTimingDataLoading] = useState(false);
	const [allAnalyticsData, setAllAnalyticsData] = useState(null);
	const [analyticsError, setAnalyticsError] = useState(null);
	const [noProcedureSelected, setNoProcedureSelected] = useState(true);
	const [selectedProcedure, setSelectedProcedure] = useState(null);
	let startDate = React.useMemo(() => new Date(), []);

	const getChartList = React.useMemo(() => {
		switch (props.filterObj.proceduretype) {
			case operativeProcedureTypes.TKA.raw:
				return AnalyticsChartList.TKAChartList;
			case operativeProcedureTypes.HIP.raw:
				return AnalyticsChartList.THAChartList;

			default:
				return [];
		}
	}, [props.filterObj.proceduretype])

	useEffect(
		() => {
			if (!props.filterObj || (props.filterObj && (!props.filterObj.proceduretype || props.filterObj.proceduretype === 'all'))) {
				setNoProcedureSelected(true);
				return;
			}
			const fetchData = async () => {
				//to display only 30 cases in trends graph from old to new cases, setted limit 30
				let params = { pageno: 1, limit: 30 };
				let chartList = getChartList;
				setTimingData(null);
				setTimingError(null);
				setAllAnalyticsData(null);
				setAnalyticsError(null);
				setNoProcedureSelected(false);
				setSelectedProcedure(props.filterObj.proceduretype);
				if (props.filterObj && Object.keys(props.filterObj)) {
					for (const key in props.filterObj) {
						if (props.filterObj.hasOwnProperty(key) && (props.filterObj[key] || props.filterObj[key] === 0)) {
							if (!(key === 'proceduretype' && props.filterObj[key] === 'all')) {
								params[key] = props.filterObj[key];
							}
						}
					}
				}
				await caseAnalyticsTimingData(params);
				if (chartList && chartList.length) {
					const analyticsParams = {
						...params,
						name: chartList.join(',')
					}

					switch (props.filterObj.proceduretype) {
						case operativeProcedureTypes.TKA.raw:
							await getAnalyticsData(analyticsParams);
							break;
						case operativeProcedureTypes.HIP.raw:
							await getAnalyticsDataTHA(analyticsParams);
							break
						default:
							break;
					}
				}

			}
			const applicationLogger = () => {
				// calculate the time since we loaded this page
				const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
				const loggerObj = {
					"EventOutcome": loggerEventOutcome.success,
					"EventType": loggerEventTypes.read,
					"EventName": loggerEventName.trendsPage,
					"Content": {
						"TimeSpent": timeSinceLoad,
					}
				}
				logger(loggerObj);
			}

			fetchData();
			return function cleanup() {
				applicationLogger()
			};
		},
		[props, getChartList, startDate]
	)

	/**
	 * @description function to get analytics data based on the chart name for all cases
	 * @param {*}
	 * @memberof CaseAnalyticsContainer
	 */
	const getAnalyticsData = async (params) => {
		setAnalyticsDataLoading(true);
		caseAnalyticsDataService(params, (err, result) => {
			/* istanbul ignore next  */
			setAnalyticsDataLoading(false);
			if (err) {
				setAnalyticsError(err);
			} else {
				setAnalyticsError(null);
				let dataset = result && result.data && result.data.case && result.data.case.length ? result.data.case[0] : null;
				if (dataset && dataset.seriesdata && dataset.seriesdata.length) {
					setAllAnalyticsData(dataset);
				}
			}
		});
	}

	/**
	 * @description function to get analytics data based on the chart name for all cases
	 * @param {*}
	 * @memberof CaseAnalyticsContainer
	 */
	const getAnalyticsDataTHA = async (params) => {
		setAnalyticsDataLoading(true);
		caseAnalyticsDataTHAService(params, (err, result) => {
			/* istanbul ignore next  */
			setAnalyticsDataLoading(false);
			if (err) {
				setAnalyticsError(err);
			} else {
				setAnalyticsError(null);
				let dataset = result && result.data && result.data.case && result.data.case.length ? result.data.case[0] : null;
				if (dataset && dataset.seriesdata && dataset.seriesdata.length) {
					setAllAnalyticsData(dataset);
				}
			}
		});
	}

	/**
	* @description function to get timing data for all cases
	* @param {*}
	* @memberof CaseAnalyticsContainer
	*/
	const caseAnalyticsTimingData = async (params) => {
		setTimingDataLoading(true);
		await caseAnalyticsTimingDataService(params, (err, result) => {
			setTimingDataLoading(false);
			/* istanbul ignore next  */
			if (err) {
				setTimingData(null);
				setTimingError(err);
			} else {
				const count = result.data && result.data.response_metadata && result.data.response_metadata.total ? result.data.response_metadata.total : 0;
				setTimingError(null);
				setTotalCount(count);
				setTimingData(result.data && result.data.case && result.data.case.length ? result.data.case[0] : []);
			}
		})
	}

	return (
		noProcedureSelected ?
			<div className="row m-0">
				<div className="col-sm-12 alert alert-danger text-center">{trendsAlert.noProcedureSelected}</div>
			</div>
			: TimingData || timingError || allAnalyticsData || analyticsError ?
				<CaseAnalyticsComponent
					selectedProcedure={selectedProcedure}
					chartList={getChartList}
					totalCount={totalCount}
					timingDataLoading={timingDataLoading}
					timingData={TimingData}
					timingError={timingError}
					analyticsDataLoading={analyticsDataLoading}
					allAnalyticsData={allAnalyticsData}
					analyticsError={analyticsError}
				/>
				: getLoadingTemplate()
	)
}
