import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { noDataFound } from '../../../helpers/messages'
import { graphContextMenuItems } from '../../../helpers/constants';
import { exportAddOns } from '../../../helpers/ChartExports';
import { NAHelpText, convertUTCToLocalDateTime } from '../../../helpers/GlobalFunctions';

class GlobalVsMyCaseDetails extends Component {
	render() {
		const { timeFrameValue, titleText } = this.props;

		const getTotalGlobalCases = this.props.totalGlobalCasesCount ? this.props.totalGlobalCasesCount : '';
		const myCases = getTotalGlobalCases.myCases;
		const globalCases = getTotalGlobalCases.globalCases;
		const averageCases = getTotalGlobalCases.averageCases;
		const lastCalculationTime = getTotalGlobalCases.lastCalculationTime
		const timeFrameText = timeFrameValue === "30D" ? 'last 30 Days' : (timeFrameValue === "12M" ? 'the last 12 Months' : 'all time')
		const caseText = myCases === 1 ? 'case' : 'cases'
		/* istanbul ignore next  */
		var Config = {
			exporting: {
				sourceWidth: 900,
				sourceHeight: 350,
				buttons: {
					contextButton: {
						menuItems: graphContextMenuItems
					},
				},
				chartOptions: {
					chart: {
						marginTop: 100,
						marginBottom: 80,
						events: {
							load: function () {
								exportAddOns(this);
							}
						}
					},
					title: {
						text: `<div><b>${titleText || 'TKA Cases'}:</b> ${myCases} ${caseText} in ${timeFrameText}</div>`,
						useHTML: true,
						style: {
							color: "#333",
							fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
							fontSize: "18"
						}
					},
				}
			},
			chart: {
				type: 'column',
				events: {
					redraw: function () {
						if (this.fullscreen && this.fullscreen.isOpen) {
							this.update({ title: { text: `<div class="dashboard-blocks-sub-heading">${titleText || 'TKA Cases'}: <b>${myCases} ${caseText}</b> in ${timeFrameText}</div>` } }, false)
						} else {
							if (!this.options.chart.forExport) {
								this.update({ title: { text: `<div class="dashboard-blocks-sub-heading"><b>${myCases} ${caseText}</b> in ${timeFrameText}</div>` } }, false);
							}
						}
					}
				}
			},
			xAxis: {
				categories: ['Global Avg', 'My Cases'],
				labels: {
					rotation: 0,
					style: {
						fontSize: '13px',
						fontFamily: 'Verdana, sans-serif',
					}
				}
			},
			yAxis: {
				min: 0,
				visible: false
			},
			tooltip: {
				enabled: true,
				formatter: function () {
					return '<b>' + this.series.name + '</b>: ' + this.point.y;
				}
			},
			title: {
				text: `<div class="dashboard-blocks-sub-heading"><b>${myCases} ${caseText}</b> in ${timeFrameText}</div>`,
				useHTML: true,
				style: {
					textAlign: 'center',
					color: '#7c7c7b',
					fontFamily: "Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
					fontSize: '16px'
				}
			},
			plotOptions: {
				series: {
					stacking: 'normal',
					dataLabels: {
						enabled: true,
						inside: false,
						color: 'black',
						crop: false,
						overflow: 'none',
					}
				},
			},
			legend: {
				layout: 'vertical',
				align: 'right',
				useHTML: true,
				verticalAlign: 'middle',
				floating: false,
				borderWidth: 1,
			},
			series: [{
				data: [{ x: 0, y: globalCases }],
				name: 'Global Avg',
				color: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, '#7ABA43'],
						[1, '#78CBC1']
					]
				}
			}, {
				data: [{ x: 1, y: myCases }],
				name: 'My Cases',
				color: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[0, '#C22326'],
						[1, '#F99D20']
					]
				}
			}],
		};

		return (<div className="globalVsMycaseDetails">
			<div className="dashboard-blocks">
				<div className="dashboard-blocks-body">
					<div className="dashboard-block-title">{titleText || 'TKA Cases'}</div>
					<div className="globalVsMycase-barChart">
						{(myCases !== 0 || globalCases !== 0) ?
							<HighchartsReact highcharts={Highcharts} options={Config} containerProps={{ style: { height: '250px' } }} /> :
							<div className="no-data-found"> {noDataFound.noData}</div>}
					</div>
					<div className="dashboard-blocks-sub-heading">{averageCases !== "You Averaged 0 cases per day" ? averageCases : null}</div>
					<div className="d-flex align-items-center justify-content-between mt-2">
						{NAHelpText()}
						<div className="last-updated-text">Last updated at : <span>{lastCalculationTime ? convertUTCToLocalDateTime(lastCalculationTime) : 'N/A'}</span></div>
						</div>
				</div>
			</div>
		</div>);
	}
}
export default GlobalVsMyCaseDetails;
