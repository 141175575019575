import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import fileUpload from '../reducers/uploadReducer';
const combineReducer = combineReducers({
  rootReducer,
  fileUpload
});
const store = createStore(
  combineReducer,
  applyMiddleware(thunk)
);
export default store;