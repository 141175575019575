import React, { Component } from 'react';
import UserStatusComponent from '../../components/UserList/UserStatus';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../helpers/messages';
import { activityUpdaterWithPromise } from '../../services/activity/activity-service';
import { changeUserStatusCognito, getCognitoUser } from '../../services/aws/aws-services';
import { logger } from '../../services/logger/logger-service';


export default class UserStatusContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			user: props.user ? props.user : null,
			error: null
		}
	}

	/**
	 * @description Function to change user's status
	 * @memberof UserStatusContainer
	 */
	changeUserStatus = (isEnable) => {
		const { user } = this.state;
		if (user) {
			let params = {
				userName: user.userName,
				enabled: isEnable
			}
			
			this.props.toggleLoader(true);
			changeUserStatusCognito(params).then(res => {
				getCognitoUser(user.userName,).then(res => {
					let updatedUser = {
						...user,
						status: res.UserStatus ? res.UserStatus : '',
						enabled: res.Enabled === true,
						isStatusChange: true
					}
					this.setState({ user: updatedUser });
					this.updateUserStatusInDB(updatedUser);
				}).catch(err => {
					this.handleError(err);
				});
			}).catch(err => {
				this.handleError(err);
			})
		}
	}

	/**
	 * @description Function to handle errors
	 * @param error Error object with error code and message
	 * @memberof UserStatusContainer
	 */
	handleError = (error) => {
		this.props.toggleLoader(false);
		this.setState({ error: error });
		this.userStatusUpdateLogger({ outcome: loggerEventOutcome.failure, message: error.message });
		this.props.updateError(error);
	}

	/**
	 * @description Function to update user's status in backend DB
	 * @memberof UserStatusContainer
	 */
	updateUserStatusInDB = (user) => {
		const activityObj = {
			enabled: user.enabled,
			userName: user.userName
		}

		activityUpdaterWithPromise(activityObj).then(res => {
			this.userStatusUpdateLogger({ outcome: loggerEventOutcome.success, message: loggerEventMessage.userStatusUpdated(user) });
			this.props.toggleLoader(false);
			setTimeout(() => {
				this.props.userStatusUpdated(user);
			}, 0);
		}).catch( err => {
			this.props.toggleLoader(false);
			this.handleError(err);
		});
	}

	/**
	 * @description function to log user status update event
	 * @param params Params with outcome and message
	 * @memberof UserStatusContainer
	 */
	userStatusUpdateLogger = (params) => {
		const loggerObj = {
			"EventOutcome": params.outcome,
			"EventType": loggerEventTypes.update,
			"EventName": loggerEventName.userStatus,
			"Content": {
				"Data": params.message
			}
		}
		logger(loggerObj);
	}

	render() {
		const { user } = this.state;
		return (
			<UserStatusComponent
				user={user}
				changeUserStatus={this.changeUserStatus}
			/>
		)
	}
}
