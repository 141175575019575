import * as types from '../constants/actionTypes/login';
import { errorMessages } from '../helpers/messages';
const initialState = {
	userAuth: false
};

export default function authReducer(state = initialState, action) {
	let data;
	switch (action && action.type) {
		case types.LOGIN_USER_SUCCESS:
		case types.NEW_PASSWORD_REQUIRED:
			data = action.res;
			return Object.assign({}, state, {
				userAuth: data
			});
		case types.LOGIN_USER_ERROR:
			var errMsg;
			if (action.err && action.err.message) {
				switch (action.err.message) {
					case errorMessages.PasswordAttemptsExceeded:
						errMsg = errorMessages.PasswordAttemptsExceededMsg;
						break;
					case errorMessages.DisabledUser:
						errMsg = errorMessages.DisabledUserCustom;
						break;
				
					default:
						errMsg = errorMessages.NotAuthorizedException;
						break;
				}
			} else {
				errMsg = errorMessages.NotAuthorizedException;
			}
			return Object.assign({}, state, {
				message: {
				 text: errMsg,
				 error: true
				}
			});
		default:
			return state;
	}
}
