import React, { Component } from 'react';
import CSVSurgeon from '../../components/SurgeonList/CSVSurgeon';
import { exportSurgeonListService } from '../../services/java/java-services';

export default class CSVSurgeonContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportSurgeonData: '',
            isLoading: false,
            isExport: false,
            errorMessage: '',
            getCsvFileName: '',
            exportCsvHeader: [
                { label: "Name", key: "name" },
                { label: "Username", key: "userName" },
                { label: "Email", key: "email" },
                { label: "Surgeon ID", key: "surgeonId" }
            ]
        }
    }

    /**
    * @description function to export data to csv file
    * @param {*}
    * @memberof CSVSurgeonContainer
    */
    exportToCsv = () => {
        this.setState({ isLoading: true, exportSurgeonData: '', isExport: false, errorMessage: '' });
        this.generateFileName()
        exportSurgeonListService((err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ isLoading: false, errorMessage: err });
            } else {
                this.setState({ exportSurgeonData: result.data && result.data.user ? result.data.user[0].surgeonList : '', isLoading: false, isExport: true });
            }
        })
    }
    /**
    * @description function to generate file name
    * @param {*}
    * @memberof CSVSurgeonContainer
    */
    generateFileName() {
        let d = new Date();
        let dformat = `${d.getDate()}${d.getMonth() + 1}${d.getFullYear()} ${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
        this.setState({ getCsvFileName: "SurgeonList_" + dformat + ".csv" })
    }

    /**
    * @description Function to end export
    * @memberof CSVSurgeonContainer
    */
    exportComplete = () => {
        this.setState({ isExport: false });
    }

    render() {
        const { handleSearchSubmit, handleReset } = this.props

        return (
            <CSVSurgeon
                refreshSurgeonList={this.state.refreshSurgeonList}
                exportCsvHeader={this.state.exportCsvHeader}
                exportSurgeonData={this.state.exportSurgeonData}
                exportToCsv={this.exportToCsv}
                getCsvFileName={this.state.getCsvFileName}
                errorMessage={this.state.errorMessage}
                isLoading={this.state.isLoading}
                isExport={this.state.isExport}
                exportComplete={this.exportComplete}
                handleReset={handleReset}
                handleSearchSubmit={handleSearchSubmit}
            />
        )
    }
}
