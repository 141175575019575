import React, { Component } from 'react';
import { connect } from 'react-redux';
import CaseListComponent from '../../components/CaseList/CaseList';
import { getUserRoleType } from '../../services/aws/aws-services';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
let startDate;
class CaseListContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFilterBlock: true, 
			updatedProps: true,
			surgeryFromDate: '', 
			surgeryTodate: '',
			errorMessage: '', 
			activeTab: props.location.state && props.location.state.activeTab ? props.location.state.activeTab : 'caseList', 
			hiddenColumnsList: this.getHiddenColumnList(),
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.caseList,
				"StartDate": new Date()
			}
		}
	}
	
	componentDidMount() {
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}

	getHiddenColumnList = ()  => {
		const loggedInUserType = getUserRoleType();
		if(loggedInUserType.includes('surgeon')) {
			return ['caseDetails.surgeonName'];
		} else if (loggedInUserType.includes('rar')) {
			return ['caseDetails.patientName'];
		}
		return []
	}

	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof CaseListContainer
	 */
	applicationLogger(key) {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.caseList,
			"Content": {
				"TimeSpent": timeSinceLoad
			}
		}
		logger(loggerObj);
	}

	/**
	 * @description function to handle tabs selection
	 * @param {*}
	 * @memberof CaseListContainer
	 */
	handleTabSelect = (key) => {
		this.setState({ activeTab: key });
	}

	render() {
		return (
			<div >
				<CaseListComponent
					handleTabSelect={this.handleTabSelect}
					activeTab={this.state.activeTab}
					hiddenColumnsList={this.state.hiddenColumnsList}
					loggerObj={this.state.loggerObj}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}


export default connect(mapStateToProps)(CaseListContainer);
