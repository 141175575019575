import React, { Component } from 'react';
import Notifications from '../../components/Notifications/Notifications';
import { actions, notificationMessages, loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { dismissNotification, getNotificationCount, getNotifications } from '../../services/java/java-services';
import { checkLocalStorage, getLocalStorage, setLocalStorage } from '../../services/storage/storage-service';
import { logger } from '../../services/logger/logger-service';
let startDate;
export default class NotificationsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notifications: null,
            notifDismissed: false,
            dismissMsg: '',
            notificationCount: checkLocalStorage('notif_count') ? parseInt(getLocalStorage('notif_count')) : null,
            loggerObj : {
                "EventOutcome": loggerEventOutcome.success,
                "EventType": loggerEventTypes.read,
                "EventName": loggerEventName.notification,
                  "StartDate": new Date()
            },
            notificatioError:null,
            notificationCountError:null,
            dismissNotificationError:null
        };
    }

    componentDidMount() {
        this.getAllNotifications();
        this.getAllNotificationsCount();
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }
    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof CaseListContainer
     */
    applicationLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.notification,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
    * @description Function to fetch all notifications
    * @memberof NotificationsContainer
    */
    getAllNotifications() {
        this.setState({ loading: true })
        getNotifications({}, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false ,notificatioError:err})
            } else {
                const notifications = result.data && result.data.notificationDeatails && result.data.notificationDeatails.length ? result.data.notificationDeatails : [];
                this.setState({
                    loading: false,
                    notifications: notifications,
                    notificatioError:null
                })
            }
        })
    }

    /**
    * @description Function to fetch all notification count
    * @memberof NotificationsContainer
    */
    getAllNotificationsCount() {
        this.setState({ loading: true })
        getNotificationCount((err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false ,notificationCountError:err})
            } else {
                const count = result.data.notificationDeatails[0].notificationcount ? result.data.notificationDeatails[0].notificationcount : 0;
                setLocalStorage('notif_count', count);
                this.setState({
                    loading: false,
                    notificationCount: count,
                    notificationCountError:null
                })
            }
        })
    }

    /**
    * @description Function to handle dismissal of one notification
    * @param notif Notification selected for dismissal
    * @memberof NotificationsContainer
    */
    onDismiss = (notification) => {
        this.setState({ loading: true })
        this.callDismissAPI({ notificationid: [notification.notificationid] }, 'single');
    }

    /**
    * @description Function to handle dismissal of all notifications
    * @memberof NotificationsContainer
    */
    onMassDismiss = () => {
        this.setState({ loading: true });
        const notifIDs = this.state.notifications.map(x => {
            if (x.notificationcategory !== "License Expired") {
                return x.notificationid;
            }

            return false;
        });
        
        this.callDismissAPI({ notificationid: notifIDs.filter(x => !!x) }, 'all');
    }

    /**
    * @description Function to handle dismissal API call
    * @memberof NotificationsContainer
    */
    callDismissAPI(params, type) {
        dismissNotification(params, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false,dismissNotificationError:err })
            } else {
                this.setState({
                    loading: false,
                    notifDismissed: true,
                    dismissMsg: type === 'single' ? notificationMessages.singleDismissed : notificationMessages.allDismissed,
                    dismissNotificationError:null
                });
                this.getAllNotifications();
                this.getAllNotificationsCount();
                setTimeout(() => {
                    this.setState({
                        notifDismissed: false,
                        dismissMsg: ''
                    });
                }, 5000);
            }
        })
    }

    render() {
        return (
            <>
                {this.state.loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : ''}
                <Notifications
                    loading={this.state.loading}
                    notifications={this.state.notifications}
                    onDismiss={this.onDismiss}
                    onMassDismiss={this.onMassDismiss}
                    notifDismissed={this.state.notifDismissed}
                    dismissMsg={this.state.dismissMsg}
                    notificationCount={this.state.notificationCount}
                    loggerObj={this.state.loggerObj}
                    notificatioError={this.state.notificatioError}
                    // notificationCountError={this.state.notificationCountError}
                    dismissNotificationError={this.state.dismissNotificationError}
                />
            </>
        )
    }
}
