import { forbiddenPasswordStrings, RegularExpressions } from '../helpers/constants';

/**
 * @description Function to validate password format
 * @param password Password input by the user
 * @returns Boolean, true if matches
 */
export const passwordRegexMatch = (password, username = null) => {
    const expMatches = matchExpression(password, {}, username);
    const matchCriteriaVals = Object.values(expMatches);
    return RegularExpressions.passwords.format.test(password) && matchCriteriaVals.indexOf(false) === -1;
}

/**
 * @description Function to validate password does not contain username
 * @param password Password input by the user
 * @returns Boolean, true if matches
 */
export const passwordUsernameMatch = (password, username) => {
    return RegularExpressions.passwords.matchCriteria.containsString(username.toLowerCase()).test(password.toLowerCase());
}

/**
 * @description Function to validate password does not contain forbidden strings
 * @param password Password input by the user
 * @returns Boolean, true if matches
 */
export const passwordForbiddenWordsMatch = (password) => {
    let matchFlag = false;
    for (let i = 0; i < forbiddenPasswordStrings.length; i++) {
        const word = forbiddenPasswordStrings[i];
        let matchWord = RegularExpressions.passwords.matchCriteria.containsString(word).test(password.toLowerCase());
        if (matchWord) {
            matchFlag = true;
            break;
        }
    }
    return matchFlag;
}

/**
 * @description Function to validate password strength
 * @param password Password input by the user
 * @returns Custom object with strength data to display
 */
export const passwordStrength = (password, username = null) => {
    const strongPwd = RegularExpressions.passwords.strength.strong.test(password);
    const expMatches = matchExpression(password, {}, username);
    const matchCriteriaVals = Object.values(expMatches);
    const medPwd = RegularExpressions.passwords.strength.medium.test(password);
    
    if (strongPwd && matchCriteriaVals.indexOf(false) === -1) {
        return { pwdStrengthLabel: 'Strong!', pwdStrengthProgress: 100, pwdStrengthVarient: 'success' };
    } else if (medPwd) {
        return { pwdStrengthLabel: 'Medium!', pwdStrengthProgress: 70, pwdStrengthVarient: 'warning' };
    } else {
        return { pwdStrengthLabel: 'Weak!', pwdStrengthProgress: 20, pwdStrengthVarient: 'danger' };
    }
}

/**
 * @description Function to match password compliance
 * @param password Password input by the user
 * @returns Custom object with strength data to display
 */
export const matchExpression = (password, defaultMatchResults, username = null) => {
    var expMatch = { ...defaultMatchResults };
    expMatch.stringLength = RegularExpressions.passwords.matchCriteria.stringLength.test(password);
    expMatch.containsLowerCase = RegularExpressions.passwords.matchCriteria.containsLowerCase.test(password);
    expMatch.containsUpperCase = RegularExpressions.passwords.matchCriteria.containsUpperCase.test(password);
    expMatch.containsNumber = RegularExpressions.passwords.matchCriteria.containsNumber.test(password);
    expMatch.containsSpecialChr = RegularExpressions.passwords.matchCriteria.containsSpecialChr.test(password);
    expMatch.containsUsername = username ? !passwordUsernameMatch(password, username) : false;

    return expMatch;
}