import React, { Component } from 'react';
import { DataTable } from '../DataTable/DataTable';
import { convertUTCToLocalDateTime, getImgSrc, roundedOffPROMScore } from '../../helpers/GlobalFunctions'
import { scoreTypesForManualScore } from '../../containers/CaseDetails/constants';
class ListProms extends Component {
	/* istanbul ignore next  */
	checkScoreTypeLabel(score) {
		/* istanbul ignore next  */
		if (scoreTypesForManualScore.indexOf(score.scoreType) > -1) {
			return score.subScoreType ? score.subScoreType : score.scoreType;
		} else {
			return score.scoreType;
		}
	}

	render() {
		const { caseId, refreshDataList, refreshOutcomeScore, operativeProcedure, surgeonUserName } = this.props;
		/* istanbul ignore next  */
		const columns = [
			{
				Header: 'Entry Date / Time',
				accessor: 'uploadedDate',
				Cell: ({ row }) => row.original.uploadedDate ? convertUTCToLocalDateTime(row.original.uploadedDate) : '',
				Filter: ''
			},
			{
				Header: 'Entry Type',
				accessor: 'source',
				Filter: ''
			},
			{
				Header: 'Score Type',
				accessor: 'scoreType',
				Cell: ({ row }) => row.original.scoreType ? this.checkScoreTypeLabel(row.original) : '',
				Filter: ''
			},
			{
				Header: 'Time of Collection ',
				accessor: 'timeIntervals',
				Filter: '',
			},
			{
				Header: 'Score Value',
				accessor: 'score',
				Cell: ({ row }) => !isNaN(parseFloat(row.original.score)) && row.original.scoreType ? roundedOffPROMScore(row.original.score, row.original.scoreType) : '',
				Filter: '',
			},
			{
				Header: ' ',
				PromsAction: true
			}
		]
		return (<div className="proms-blocks">
			<div className="proms-blocks-body">
				<div className="proms-block-title">PROM Score History</div>
				<div className="proms-table">
					{caseId ?
						<DataTable
							listType="proms-list" columns={columns}
							getId={caseId} showPagination={false}
							refreshDataList={refreshDataList}
							refreshOutcomeScore={refreshOutcomeScore}
							disableColmSort={true}
							operativeProcedure={operativeProcedure}
							surgeonUserName={surgeonUserName}
						/>
						: <div className="loading-img loading-img-center"><img className="" src={getImgSrc('loading.gif')} alt="loading" /> </div>}
				</div>
			</div>
		</div>);
	}
}
export default ListProms;
