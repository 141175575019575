import React, { Component } from 'react';
import { connect } from 'react-redux';
import AWS from 'aws-sdk';
import { newS3 } from '../../services/aws/aws-services';
import environment from '../../config/environment';
import FileUploadComponent from '../../components/FileUpload/FileUpload';
import { updateFileMetadata } from '../../actions/uploadAction';
import { logoutService, getUserName } from '../../services/aws/aws-services';
import { errorMessages, successMessages, loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { userHasPermission } from '../../helpers/GlobalFunctions';
let startDate;

class FileUploadContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uploadError: '',
			isLoading: false,
			uploadSuccess: false,
			showProgress: false,
			selectedFiles: '',
			fileExtentionError: false,
			maxFileSizeError: false,
			uploadProgress: 0,
			metaDataUpdateFailError: '',
			displayFileName: '',
			selectedFileExistInS3: false,
			disclaimerCheckBoxValue: false,
			cancelFileUploadStatus: false,
			enableUploadBtn: true,
			enableCancelBtn: false,
			fileUploadedId: '',
			errors: {
				fileName: ''
			},
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.fileUpload,
				"StartDate": new Date()
			}
		}
		this.resetStoreValue();
	}

	componentDidMount() {
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
	}
	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}
	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof FileUploadContainer
	 */
	applicationLogger(key) {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.fileUpload,
			"Content": {
				"TimeSpent": timeSinceLoad
			}
		}
		logger(loggerObj);
	}

	/**
	 * @description function for file upload form 
	 * @param {*}
	 * @memberof FileUploadContainer
	 */
	formValidation() {
		var errors = { ...this.state.errors }

		// File validation
		if (this.state.selectedFiles === '') {
			errors.fileName = errorMessages.fileUpload.fileName;
			this.setState({ errors })
			return errors;
		} else {
			errors.fileName = '';
			this.setState({ errors })
		}

		// File upload confirm checkbox validation
		if (this.state.disclaimerCheckBoxValue === false) {
			errors.disclaimerCheckBox = errorMessages.fileUpload.disclaimerCheckBox;
			this.setState({ errors })
			return errors;
		} else {
			errors.disclaimerCheckBox = '';
			this.setState({ errors })
		}
		return true;
	}

	/**
	 * @description function to verify file properties like size, name etc..
	 * @param  {} selectedFile
	 * @memberof FileUploadContainer
	 */
	handleChange = async (selectedFile) => {
		if (!selectedFile[0]) return;
		var errors = { ...this.state.errors }
		let getFileName = selectedFile[0]['name'];
		let getExtension = getFileName.split('.').pop();
		let getFileSize = selectedFile[0]['size'];
		let maxFileSize = 5368709120; // 5GB size (1GB = 1073741824)
		this.setState({
			displayFileName: getFileName, enableUploadBtn: true,
			uploadError: '', uploadProgress: 0
		});
		// to reset validation
		errors.fileName = '';
		this.setState({ errors })
		if (getExtension === 'zip' || getExtension === 'ZIP') {
			this.setState({
				selectedFiles: selectedFile, uploadSuccess: false,
				fileExtentionError: false, maxFileSizeError: false, selectedFileExistInS3: false
			});
		} else {
			this.setState({ fileExtentionError: true });
		}
		if (getFileSize > maxFileSize) {
			this.setState({ maxFileSizeError: true });
		}
		this.resetStoreValue();
	}

	/** @description function to handle field disclaimer box chnages
	 *  @param  {} event
	 *  @memberof FileUploadContainer
	 */
	disclaimerCheckBoxChange = (event) => {
		var errors = { ...this.state.errors }
		if (event.target.checked) {
			errors.disclaimerCheckBox = '';
			this.setState({ errors })
			this.setState({ disclaimerCheckBoxValue: true });
		} else {
			errors.disclaimerCheckBox = errorMessages.fileUpload.disclaimerCheckBox;
			this.setState({ errors })
			this.setState({ disclaimerCheckBoxValue: false });
		}
	}

	/** @description function to handle manage file upload
	 *  @param  {} event
	 *  @memberof FileUploadContainer
	 */
	createManagedUpload = async () => {
		const { dispatch } = this.props;
		let bucketName = environment.s3BucketName;
		let file = this.state.selectedFiles;
		let userName = getUserName();
		let filepath = file[0]['name'];
		let mediaKey = userName + '/' + filepath;

		var uploadParam = {
			partSize: 5 * 1024 * 1024, queueSize: 4,
			params: {
				Bucket: bucketName,
				Key: mediaKey,
				Body: file[0],
				Metadata: {
					userid: userName
				}
			}
		}
		var getCurrentState = this;

		var upload = new AWS.S3.ManagedUpload(uploadParam);
		upload.send(function (err, data) {
			if (err) {
				let errorMessage = 'User has cancelled the file upload, please try again.';
				getCurrentState.setState({ 'uploadError': errorMessage, isLoading: false, uploadProgress: 0, enableCancelBtn: false, showProgress: false });
				getCurrentState.deleteFileNameInS3();
			} else {
				if (!getCurrentState.state.cancelFileUploadStatus) {
					getCurrentState.setState({ cancelFileUploadStatus: false, uploadError: '' });
					var fileDetails = {
						"filename": filepath,
						"filepath": mediaKey,
						"isConsent": getCurrentState.state.disclaimerCheckBoxValue,
						"isFieldReport": false,
						"fieldReportNumber": '',
						"status": 'Uploaded',
						"source": 'RI.Insights'
					}
					// update metadata to DB
					dispatch(updateFileMetadata(fileDetails));
					getCurrentState.setState({ selectedFiles: '', displayFileName: '', disclaimerCheckBoxValue: false, isLoading: false, uploadProgress: 0, uploadError: '', showProgress: false });
				} else {
					getCurrentState.deleteFileNameInS3();
				}
			}
		})
		upload.on('httpUploadProgress', function (progress) {
			var uploaded = parseInt((progress.loaded * 100) / progress.total);
			getCurrentState.setState({ enableCancelBtn: true });
			if (!getCurrentState.state.cancelFileUploadStatus) {
				getCurrentState.setState({ uploadProgress: uploaded });
			} else {
				upload.abort(upload)
			}
		});
	}

	/**
	 * @description function to cancel file upload
	 * @param  {} selectedFile
	 * @memberof FileUploadContainer
	 */
	cancelFileUpload = async () => {
		this.setState({ cancelFileUploadStatus: true, uploadProgress: 0, enableCancelBtn: false, enableUploadBtn: true, uploadError: 'User has cancelled the file upload, please try again.' });
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.fileUpload,
			"Content": {
				"Data": successMessages.fileUploadCanceled
			}
		}
		logger(loggerObj);
	}

	/** @description function to verify selected file name exits in s3 bucket
	*  @param  {}
	*  @memberof FileUploadContainer
	*/
	verifyFileNameInS3 = async () => {
		this.setState({ isLoading: true, enableUploadBtn: false, showProgress: true });
		let bucketName = environment.s3BucketName;
		let s3 = await newS3(bucketName);
		let file = this.state.selectedFiles;
		let userName = getUserName();
		let filepath = file[0]['name'];
		let mediaKey = userName + '/' + filepath;
		let param = {
			Key: mediaKey
		};
		await s3.headObject(param, (err, res) => {
			if (err) {
				let errorMessage;
				if (err.message && err.message === 'Missing credentials in config') {
					errorMessage = errorMessages.sessionTimeOut;
					this.sessionTimeOut();
					this.setState({ 'uploadError': errorMessage, selectedFileExistInS3: false, enableUploadBtn: false });
				} else {
					this.createManagedUpload();
				}
			} else {
				this.setState({ selectedFileExistInS3: true, isLoading: false, enableUploadBtn: true, showProgress: false })
			}
		});
	}

	/** @description function to delete file in s3 bucket
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	deleteFileNameInS3 = async () => {
		let bucketName = environment.s3BucketName;
		let s3 = await newS3(bucketName);
		let file = this.state.selectedFiles;
		let userName = getUserName();
		let filepath = file[0]['name'];
		let mediaKey = userName + '/' + filepath;
		let param = {
			Key: mediaKey
		};
		await s3.deleteObject(param, (err, res) => { });
	}

	/** @description function to handle session time out  
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	sessionTimeOut = () => {
		setTimeout(
			function () {
				this.logout();
			}
				.bind(this),
			3000);
	}

	/** @description function to handle submit action 
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	uploadSubmit = async () => {
		if (!userHasPermission('fileUpload')) {
			this.setState({ cancelFileUploadStatus: true, uploadError: errorMessages.fileUpload.permissionDenied });
			return;
		}
		this.setState({ cancelFileUploadStatus: false, uploadError: '' });
		let verifyValidation = this.formValidation();
		this.resetStoreValue();
		if (verifyValidation === true) {
			await this.verifyFileNameInS3();
		}
	}

	/** @description function to handle reset Input value after form submit 
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	resetInput = (selectedFile) => {
		this.resetStoreValue();
		selectedFile.target.value = null;
		this.setState({ uploadSuccess: false, displayFileName: '', selectedFiles: '' })
	}

	/** @description function to handle reset store value after form submit 
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	resetStoreValue() {
		const { state } = this.props;
		if (state.rootReducer && state.rootReducer.uploadReducer.res) {
			state.rootReducer.uploadReducer.res.update = '';
			this.setState({ isLoading: false, showProgress: false })
		}
	}

	/**
	 * @description function to handle logout functionality
	 * @param {*}
	 * @memberof FileUploadContainer
	 */
	logout = async () => {
		logoutService(function (err, res) { });
	};

	render() {
		const { state } = this.props;
		return (
			<FileUploadComponent onSubmit={this.uploadSubmit}
				onInputChange={this.handleChange}
				onFileUploadCancel={this.cancelFileUpload}
				enableCancelBtn={this.state.enableCancelBtn}
				cancelFileUploadStatus={this.state.cancelFileUploadStatus}
				onFileUploadResume={this.resumeFileUpload}
				enableUploadBtn={this.state.enableUploadBtn}
				fileExistInS3={this.state.selectedFileExistInS3}
				disclaimerCheckBoxChange={this.disclaimerCheckBoxChange}
				disclaimerCheckBoxValue={this.state.disclaimerCheckBoxValue}
				errorMsgs={errorMessages}
				successMsgs={successMessages}
				isLoading={this.state.isLoading}
				showProgress={this.state.showProgress}
				uploadError={this.state.uploadError}
				formErrors={this.state.errors}
				displayFileName={this.state.displayFileName}
				metaDataUpdateStatus={state.rootReducer && state.rootReducer.uploadReducer.res ? state.rootReducer.uploadReducer.res.update : ''}
				metaDataUpdateError={state.rootReducer && state.rootReducer.uploadReducer.res ? state.rootReducer.uploadReducer.res.text : ''}
				fileExtentionError={this.state.fileExtentionError}
				maxFileSizeError={this.state.maxFileSizeError}
				uploadSuccess={this.state.uploadSuccess}
				uploadProgress={this.state.uploadProgress}
				downloadFile={this.downloadFile}
				resetInput={this.resetInput}
				onKeyDown={this.onKeyDown}
				loggerObj={this.state.loggerObj}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}


export default connect(mapStateToProps)(FileUploadContainer);
