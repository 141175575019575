import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExportDataComponent from '../../components/CaseList/ExportData';
import { operativeProcedureTypes } from '../../helpers/constants';
import { formatDateTimeForCSV } from '../../helpers/GlobalFunctions';
import { getUserRoleType } from '../../services/aws/aws-services';
import { exportCaseDataService } from '../../services/java/java-services';
import { CSVExportHeaders } from '../CaseDetails/constants';
import { convertUTCToLocalDateTime, convertToPlainText } from '../../helpers/GlobalFunctions';

class ExportDataContainer extends Component {
	constructor() {
		super()
		this.state = {
			showFilterBlock: true,
			updatedProps: true,
			surgeryFromDate: '',
			surgeryTodate: '',
			errorMessage: null,
			activeTab: 'caseList',
			hiddenColumnsList: [],
			isExport: false,
			isLoading: false, exportCaseData: '',
			exportCaseHeader: []
		}
	}

	componentDidUpdate() {
		if (this.state.isExport === true) {
			this.setState({ isExport: false });
		}
		if (this.state.errorMessage) {
			setTimeout(() => {
				this.setState({ errorMessage: null });
			}, 5000);
		}
	}

	/**
	 * @description function to export data to csv file
	 * @param {*}
	 * @memberof CaseListContainer
	 */
	exportToCsv = (filterObj) => {
		this.setState({ isLoading: true, exportCaseData: '', isExport: false, errorMessage: null });
		let urlParams = {}
		for (const key in filterObj) {
			if (filterObj.hasOwnProperty(key) && (filterObj[key] || filterObj[key] === 0)) {
				if (!(key === 'proceduretype' && filterObj[key] === 'all')) {
					urlParams[key] = filterObj[key];
				}
			}
		}
		exportCaseDataService(urlParams, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({
					isLoading: false,
					errorMessage: err
				});
			} else {
				var exportData = result.data.case.map(x => {
					let updatedDate = null;

					if (x.Comments || x.Comments==="") {
						updatedDate = convertUTCToLocalDateTime(x.CommentsUpdatedDate);
					}
					return {
						...x,
						...(x.Comments ? { Comments: convertToPlainText(x.Comments) } : {}),
						CommentsUpdatedDate: updatedDate
					};
				});
				setTimeout(() => {
					this.setState({
						exportCaseHeader: filterObj.proceduretype ? CSVExportHeaders[filterObj.proceduretype.toLowerCase()] : [],
						exportCaseData: exportData,
						isLoading: false,
						isExport: true,
						errorMessage: null
					});
				}, 0);
			}
		})
	}

	/**
	 * @description Function to prepend procedure type to file name
	 * @param procedureType
	 * @memberof CaseListContainer
	 */
	getProcedureTypePrepend = (procedureType) => {
		switch (procedureType) {
			case operativeProcedureTypes.TKA.raw:
				return `${operativeProcedureTypes.TKA.text}_`;
			case operativeProcedureTypes.HIP.raw:
				return `${operativeProcedureTypes.HIP.text}_`;

			default:
				return '';
		}
	}

	/**
	 * @description function to generate file name
	 * @param {*}
	 * @memberof CaseListContainer
	 */
	generateFileName() {
		return `${this.getProcedureTypePrepend(this.props.filterObj.proceduretype)}CaseDetails_${formatDateTimeForCSV()}.csv`;
	}

	render() {
		return (
			<div >
				<ExportDataComponent
					exportCaseHeader={this.state.exportCaseHeader}
					exportCaseData={this.state.exportCaseData}
					exportToCsv={this.exportToCsv}
					isLoading={this.state.isLoading}
					isExport={this.state.isExport}
					exportFileName={this.generateFileName()}
					loggedInUserType={getUserRoleType()}
					filterObj={this.props.filterObj}
					errorMessage={this.state.errorMessage}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(ExportDataContainer);
