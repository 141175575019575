import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExportDataComponent from '../../components/Logs/ExportData';
import { exportLogsDataService } from '../../services/java/java-services';

class ExportDataContainer extends Component {
	constructor() {
		super()
		this.state = {
			showFilterBlock: true, updatedProps: true,
			errorMessage: '',
			isExport: false,
			isLoading: false, exportLogsData: '',
			exportLogsHeader: [
				{ label: "Date", key: "id.date" },
				{ label: "Time Spent", key: "content.TimeSpent" },
				{ label: "User ID", key: "userID" },
				{ label: "Event Name", key: "eventName" },
				{ label: "Event Type", key: "eventType" },
				{ label: "Event Outcome", key: "eventOutcome" },
				{ label: "Message", key: "content.Data" },
				{ label: "Case Id", key: "content.CaseId" },
			]
		}
	}

	componentDidUpdate() {
		if (this.state.isExport === true) {
			this.setState({ isExport: false });
		}
		if (this.state.errorMessage) {
			setTimeout(() => {
				this.setState({ errorMessage: '' });
			}, 5000);
		}
	}

	/**
	 * @description function to export data to csv file
	 * @param {*}
	 * @memberof ExportDataContainer
	 */
	exportToCsv = (filterObj) => {
		this.setState({ isLoading: true, exportLogsData: '', isExport: false, errorMessage: '' });
		let urlParams = {}
		for (const key in filterObj) {
			if (filterObj.hasOwnProperty(key) && (filterObj[key] || filterObj[key] === 0)) {
				urlParams[key] = filterObj[key];
			}
		}
		exportLogsDataService(urlParams, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ isLoading: false, errorMessage: err });
			} else {
				this.setState({ exportLogsData: result.data.logger, isLoading: false, isExport: true });
			}
		})
	}
	/**
	 * @description function to generate file name
	 * @param {*}
	 * @memberof ExportDataContainer
	 */
	generateFileName() {
		let d = new Date();
		let dformat = `${d.getDate()}${d.getMonth() + 1}${d.getFullYear()} ${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
		return "Logs_" + dformat + ".csv";
	}

	/**
	 * @description function to disable export button
	 * @param {*}
	 * @memberof ExportDataContainer
	 */
	disableExport() {
		const filterObj = this.props.filterObj;
		const values = filterObj.EndEventDate === '' && filterObj.StartEventDate === '' && filterObj.eventName === '' && filterObj.caseId === '' && filterObj.userID === '';
		return values;
	}

	render() {
		return (
			<div >
				<ExportDataComponent
					exportLogsHeader={this.state.exportLogsHeader}
					exportLogsData={this.state.exportLogsData}
					exportToCsv={this.exportToCsv}
					isLoading={this.state.isLoading}
					isExport={this.state.isExport}
					exportFileName={this.generateFileName()}
					filterObj={this.props.filterObj}
					errorMessage={this.state.errorMessage}
					disableExport={this.disableExport()}
					totalCount={this.props.totalCount}
					refreshLogs={this.props.refreshLogs}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(ExportDataContainer);
