import React, { Component } from 'react';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { withRouter } from 'react-router';
import CustomerSupportPreopListComponent from '../../components/CustomerSupportPreopList/CsPlanListComponent'
import { logger } from '../../services/logger/logger-service';
import IdleTimer from 'react-idle-timer';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../helpers/messages';
import { eventSource } from '../../helpers/constants';

export class CustomerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remaining: this.timeout,
            lastActive: new Date(),
            elapsed: 0,
            logSent: false,
        };
        this.idleTimer = null;
        this.timeout = 1000 * 60 * 10; // 10 minute timeout

    }

    /* istanbul ignore next  */
    shouldComponentUpdate(nextProps, nextState) {
        const { remaining, elapsed } = this.state;
        const { remaining: nextRemaining, elapsed: nextElapsed } = nextState;

        if (
            remaining !== nextRemaining ||
            elapsed !== nextElapsed
        ) {
            return false;
        }

        return true; // Allow re-rendering for other prop changes
    }

    componentDidMount() {

        window.addEventListener('beforeunload', this.timeSpentLogger);

        this.setState({
            remaining: this.idleTimer.getRemainingTime(),
            elapsed: this.idleTimer.getElapsedTime(),
        });

        /* istanbul ignore next  */
        this.timer = setInterval(() => {
            this.setState({
                remaining: this.idleTimer.getRemainingTime(),
                elapsed: this.idleTimer.getElapsedTime(),
            });
        }, 1000);
    }

    /**
    * @description function to handle application logs for time spent on the page
    * @param {*}
    * @memberof CustomerContainer
   */
    timeSpentLogger=()=> {
        // calculate the time since we loaded this page
        if (!this.state.logSent) {
            const timeSinceLoad = (new Date().getTime() - this.state.lastActive.getTime()) / 1000;

            const loggerObj = {
                EventOutcome: loggerEventOutcome.success,
                EventType: loggerEventTypes.read,
                EventName: this.props.location.search === '?activeAll=true' ? loggerEventName.custSupportPlanDetailView('Active') : loggerEventName.custSupportPlanDetailView(''),
                EventSource: eventSource.kpopPlans.value,
                Content: {
                    Data: this.props.location.search === '?activeAll=true' ? loggerEventMessage.custSupportPlanDetailView('Active') : loggerEventMessage.custSupportPlanDetailView(''),
                    TimeSpent: timeSinceLoad,
                },
            };
            this.setState({ logSent: true });
            logger(loggerObj);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
        clearInterval(this.timer);
    }

    render() {
        return (
            <ErrorBoundary>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    timeout={this.timeout}
                    onIdle={this.timeSpentLogger}
                />
                <CustomerSupportPreopListComponent isActiveAll={this.props.location.search === '?activeAll=true'} />
            </ErrorBoundary>
        );
    }
}

export default withRouter(CustomerContainer);
