import React, { Component } from 'react';
import AlertMessage from '../AlertMessage/AlertMessage';
import { opCaseStatus, operativeProcedureTypes } from '../../helpers/constants';
import { salesRepMessages } from '../../helpers/messages';

export default class PreOpFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.filterRef = React.createRef();
        this.onEnterPress = this.onEnterPress.bind(this);
    }

    /**
     * @description function to reset filter form
     * @param {*}
     * @memberof PreOpFilterComponent
     */
    resetForm = () => {
        this.filterRef.reset();
        this.props.resetLogsFilter();
    };

    /**
     *  @description Function to handle search submit by hitting enter key
     *  @param e Keypress event
     *  @memberof PreOpFilterComponent
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.props.filterLogs(e);
        }
    };

    render() {
        /* istanbul ignore next  */
        const {
            showFilter,
            showFilterBlock,
            filterLogs,
            onInputChange,
            searchErrorMessage,
            errorMessage,
            disableFilter,
            disableReset,
            status,
            customer,
            procedureType,
            customerList = [],
            filterObj,
            salesRep,
            showSalesRep,
            salesRepList = [],
            searchString,
        } = this.props;

        let alertMessage = null;
        if (errorMessage) {
            alertMessage = <AlertMessage errorMessage={errorMessage} />;
        }

        return (
            <div>
                <div className='logs-list-top-btn'>
                    {showFilterBlock ? (
                        <button className='btn btn-primary' id='po-close-filter' onClick={showFilter}>
                            Close Filter
                        </button>
                    ) : (
                        <button className='btn btn-primary' id='po-show-filter' onClick={showFilter}>
                            Show Filter
                        </button>
                    )}
                </div>
                {showFilterBlock ? (
                    <div className='case-list-filter pre-op-filter ' id='po-filter-container'>
                        {alertMessage}
                        <form id='pofilter' ref={(el) => (this.filterRef = el)}>
                            <ul className='filter-options filter-options-wrapper filter-op-list '>
                                <li>
                                    Procedure Type
                                    <select
                                        id='po-procedureType'
                                        className='form-select mt-2'
                                        defaultValue={filterObj?.procedureType || procedureType}
                                        onChange={(e) => onInputChange(e, 'procedureType')}
                                    >
                                        <option value='all'>All Procedures</option>
                                        <option value={operativeProcedureTypes.TKA.text}>{operativeProcedureTypes.TKA.longText}</option>
                                        <option value={operativeProcedureTypes.UKR.text}>{operativeProcedureTypes.UKR.longText}</option>
                                    </select>
                                </li>
                                <li>
                                    Customer
                                    <select id='po-customer' className='form-select mt-2' defaultValue={filterObj?.customer || customer} onChange={(e) => onInputChange(e, 'customer')}>
                                        <option value='all'>All Customers</option>

                                        {customerList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.customerId}>
                                                    {item.customerName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </li>
                                <li className='hide-status-filter'>
                                    Status
                                    <select id='po-status' className='form-select mt-2' defaultValue={filterObj?.status || status } onChange={(e) => onInputChange(e, 'status')}>
                                        {opCaseStatus.map((item, index) => {
                                            return (
                                                <option key={index} value={item.value}>
                                                    {item.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </li>
                                {showSalesRep && (
                                    <li>
                                        Assigned User
                                        <select id='po-salesRep' className='form-select mt-2' defaultValue={filterObj.salesRep || salesRep} onChange={(e) => onInputChange(e, 'salesRep')}>
                                            <option value='all'>All Users</option>

                                            {salesRepList.map((item, index) => {
                                                return (
                                                    item.username && (
                                                        <option key={index} value={item.username}>
                                                            {item.salesRepName}
                                                        </option>
                                                    )
                                                );
                                            })}
                                        </select>
                                    </li>
                                )}
                                <li className='justify-content-between gap-2'>
                                    Search
                                    <input
                                        type='text'
                                        id='pre-op-search'
                                        className='form-control pre-op-search-input'
                                        placeholder={salesRepMessages.searchText}
                                        value={searchString}
                                        onChange={(e) => onInputChange(e, 'searchString')}
                                        onKeyDown={this.onEnterPress}
                                    />
                                    {searchErrorMessage && <p className='text-danger py-2 mb-0 pb-0'>{searchErrorMessage}</p>}
                                </li>
                            </ul>

                            <ul className='filter-options'>
                                <li className='filter-btns text-end mt-0'>
                                    <button className='btn btn-primary filter-btn-width' type='button' id='poFilter' disabled={disableFilter} onClick={filterLogs}>
                                        Filter
                                    </button>
                                    <button className='btn btn-primary filter-btn-width' type='button' id='poReset' disabled={disableReset} onClick={this.resetForm}>
                                        Reset
                                    </button>
                                </li>
                            </ul>
                        </form>
                    </div>
                ) : null}
            </div>
        );
    }
}
