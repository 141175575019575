import React, { Component } from 'react'
import RangeInput from '../../shared/RangeInput';
import { customRoundOff, getImgSrc, getSignedUnit, NAHelpText, tensionerForceRange } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock'
import { Dropdown } from 'react-bootstrap';
export default class TKAPlanningReviewTool extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialState: true,
			disableButtons: true,
			casePull: 'myCases',
			casePullValue: 'My Cases',
			implantValue: '',
			gapsDataValue: '',
			patientSex: [],
			tensionerForce: ['high', 'medium', 'low'],
			isAllSexChecked: true,
			checkBoxes: [
				{ value: 'Male', checked: false },
				{ value: 'Female', checked: false },
				{ value: 'Other', checked: false }
			],
			tensionerForceCheckbox: [
				{ value: 'high', checked: true },
				{ value: 'medium', checked: true },
				{ value: 'low', checked: true }
			],
			dropDownButtons: [
				{ value: 'myCases', label: 'My Cases' },
				{ value: 'globalCases', label: 'Global Cases' },
				{ value: 'country', label: 'My Country' },
				{ value: 'region', label: 'Regional' }
			]
		}
	}

	componentDidMount() {
		this.setState({ initialState: false });
	}

	/**
	* @description function to handle case pull data selection
	* @param {casePullType}
	* @memberof TKAPlanningReviewTool
	*/
	handleCasePull = (casePullType) => {
		let casePull = this.state.dropDownButtons.find(x => x.value === casePullType);
		this.setState({ casePull: casePullType, initialState: false, casePullValue: casePull.label });
		this.props.handleInputChange(casePullType, 'casePull');
	}

	/**
	* @description function to handle case pull data selection
	* @param {region}
	* @memberof TKAPlanningReviewTool
	*/
	/* istanbul ignore next  */
	handleRegionChange = (region) => {
		this.props.handleInputChange(region, 'selectedRegion');
	}

	/**
	* @description function to handle implant select change
	* @param {value, field}
	* @memberof TKAPlanningReviewTool
	*/
	handleSelectChange = (value, field) => {
		this.setState({ implantValue: value, initialState: false });
		this.props.handleInputChange(value, field);
		if (!value) {
			this.props.handleRangeChange([], 'femurSize');
		}
	}

	/**
	* @description function to handle gapscollected select change
	* @param {value, field}
	* @memberof TKAPlanningReviewTool
	*/
	handleGapscollectedChange = (value, field) => {
		this.setState({ gapsDataValue: value, initialState: false });
		this.props.handleInputChange(value, field);
	}

	/**
	* @description function to handle implant select change
	* @param {value, field}
	* @memberof TKAPlanningReviewTool
	*/
	handleRangeChange = (value, field) => {
		this.setState({ initialState: false });
		this.props.handleRangeChange(value, field);
	}

	/**
	* @description function to handle patient sex selection
	* @param {values, field}
	* @memberof TKAPlanningReviewTool
	*/
	handleCheckBox = (event) => {
		var value = event.target.value;
		if (value === 'All') {
			var isAllChecked = event.target.checked;
			var { checkBoxes } = this.state;
			if (isAllChecked) {
				checkBoxes.forEach(x => {
					x.checked = false;
				})
				this.setState({ isAllSexChecked: true, patientSex: [], initialState: false });
			}
			this.props.handleInputChange([], 'patientSex');
		} else {
			this.handlePatientSexSelection(value);
		}
	}

	/**
	* @description function to handle checkboxes for patient sex
	* @param {value}
	* @memberof TKAPlanningReviewTool
	*/
	handlePatientSexSelection = (value) => {
		var { checkBoxes } = this.state;
		var checkBox = checkBoxes.find(x => x.value === value);
		checkBox.checked = !checkBox.checked;
		var checkedList = checkBoxes.filter(x => x.checked).map(x => x.value);
		this.setState({ isAllSexChecked: checkedList.length === 0, patientSex: checkedList, initialState: false });
		this.props.handleInputChange(checkedList, 'patientSex');
	}
	/**
	* @description function to handle force range selection
	* @param {values, field}
	* @memberof TKAPlanningReviewTool
	*/
	handleCheckBoxTensioner = (event) => {
		var value = event.target.value;
		this.setState({ tensionerForce: value, initialState: false });
		this.props.handleInputChange([], 'tensionerForce');
		this.handletensionerForceSelection(value);
	}

	handletensionerForceSelection = (value) => {
		var { tensionerForceCheckbox } = this.state;
		var checkBox = tensionerForceCheckbox.find(x => x.value === value);
		checkBox.checked = !checkBox.checked;
		var checkedList = tensionerForceCheckbox.filter(x => x.checked).map(x => x.value);
		this.setState({ tensionerForce: checkedList, initialState: false });
		this.props.handleInputChange(checkedList, 'tensionerForce');
	}



	/**
	 * @description function to handle reset of filters
	 * @param {*}
	 * @memberof TKAPlanningReviewTool
	 */
	handleReset = () => {
		this.setState({
			initialState: true,
			casePull: 'myCases',
			casePullValue: 'My Cases',
			implantValue: '',
			gapsDataValue: '',
			patientSex: [],
			tensionerForce: ['high', 'medium', 'low'],
			checkBoxes: [
				{ value: 'Male', checked: false },
				{ value: 'Female', checked: false },
				{ value: 'Other', checked: false }
			],
			tensionerForceCheckbox: [
				{ value: 'high', checked: true },
				{ value: 'medium', checked: true },
				{ value: 'low', checked: true }
			]
		});
		this.props.handleReset();
	}

	render() {
		const { casePull, casePullValue, dropDownButtons, initialState, patientSex, isAllSexChecked, implantValue, gapsDataValue, tensionerForce } = this.state;
		const { caseData, implants, regions, selectedRegion, femurSizeRange, showResults, handleInputChange, handleSubmit, error, disableReset, defaults, planningToolError } = this.props;

		return (
			<div className="planning-review-tool">
				<div className="dashboard-blocks">
					<div className="dashboard-blocks-body planning-review-body">
						<div className="dashboard-block-title px-2">Planning Tool</div>
						<div className="planning-tool-inner-block p-2">
							<div className="row">
								<div className="col-sm-12">
									{error && <ErrorMsgBlockComponent className="p-0 planning-tool-error mb-1" errorObject={{ message: error }} />}
								</div>
							</div>

							{/* NEW DESIGN */}
							<div className="row">
								<div className="col-sm-4">
									<div className="form-group">
										<label className="mb-1">Implant <span className="mandatory">*</span></label>
										<select id="implant-select" className="form-select" name="implant" value={implantValue} onChange={(event) => this.handleSelectChange(event.target.value, 'implant')}>
											<option value="">Select Implant</option>
											{implants && implants.length ? implants.map((implant, index) => {
												return <option key={index} value={implant.name}>{implant.name}</option>;
											}) : ''}
										</select>
									</div>
									{implantValue &&
										<div className="form-group">
											<label className="mb-1">Femur Size</label>
											<RangeInput
												id="femur-size"
												step="1"
												showExtremeLabels={true}
												minExtremeLabel={femurSizeRange ? femurSizeRange.min : defaults.femurSize.min}
												maxExtremeLabel={femurSizeRange ? femurSizeRange.max : defaults.femurSize.max}
												min={femurSizeRange ? femurSizeRange.min : defaults.femurSize.min}
												max={femurSizeRange ? femurSizeRange.max : defaults.femurSize.max}
												minVal={femurSizeRange ? femurSizeRange.min : defaults.femurSize.min}
												maxVal={femurSizeRange ? femurSizeRange.max : defaults.femurSize.max}
												reset={initialState}
												implantValue={implantValue}
												handleChange={(values) => this.handleRangeChange(values, 'femurSize')}
											/>
										</div>}
								</div>
								<div className="col-sm-4 border-lr">
									<div className="form-group">
										<label className="mb-2">Patient Sex</label>
										<div className="d-flex align-items-center justify-content-between patient-sex-wrap">
											<div className="form-check">
												<input type="checkbox" className="form-check-input" id="sex-all" value="All" name="patientsex" checked={isAllSexChecked} onChange={this.handleCheckBox} />
												<label className="form-check-label" htmlFor="sex-all">All</label>
											</div>

											<div className="form-check">
												<input type="checkbox" className="form-check-input" id="sex-male" value="Male" name="patientsex" checked={patientSex.indexOf('Male') > -1} onChange={this.handleCheckBox} />
												<label className="form-check-label" htmlFor="sex-male">Male</label>
											</div>

											<div className="form-check">
												<input type="checkbox" className="form-check-input" id="sex-female" value="Female" name="patientsex" checked={patientSex.indexOf('Female') > -1} onChange={this.handleCheckBox} />
												<label className="form-check-label" htmlFor="sex-female">Female</label>
											</div>

											<div className="form-check">
												<input type="checkbox" className="form-check-input" id="sex-other" value="Other" name="patientsex" checked={patientSex.indexOf('Other') > -1} onChange={this.handleCheckBox} />
												<label className="form-check-label" htmlFor="sex-other">Other</label>
											</div>
										</div>
									</div>
									<div className="form-group">
										<label className="mb-1">Patient Age</label>
										<RangeInput
											id="patient-age"
											step="1"
											showExtremeLabels={true}
											openRange={true}
											minExtremeLabel={defaults.patientAge.min}
											maxExtremeLabel={defaults.patientAge.maxLabel}
											min={defaults.patientAge.min}
											max={defaults.patientAge.max}
											minVal={defaults.patientAge.min}
											maxVal={defaults.patientAge.max}
											openMinLabel="0"
											openMaxLabel="> 100"
											reset={initialState}
											handleChange={(values) => this.handleRangeChange(values, 'patientAge')}
										/>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-group">
										<label className="mb-1">Deformity</label>
										<RangeInput
											id="preop-deformity"
											step="1"
											min={defaults.preopDeformity.min}
											max={defaults.preopDeformity.max}
											openRange={true}
											showExtremeLabels={true}
											minExtremeLabel="Valgus"
											maxExtremeLabel="Varus"
											openMinLabel="< -7"
											openMaxLabel="> 7"
											minVal={defaults.preopDeformity.minVal}
											maxVal={defaults.preopDeformity.maxVal}
											reset={initialState}
											handleChange={(values) => this.handleRangeChange(values, 'preopDeformity')}
										/>
									</div>
									<div className="form-group">
										<label className="mb-1">Gaps Collected</label>
										<select id="gaps-select" className="form-select" value={gapsDataValue} onChange={(event) => this.handleGapscollectedChange(event.target.value, 'gapsCollectedPlanningTool')}>
											<option value="">All</option>
											<option value="Manual">Manual</option>
											<option value="Tensioner">Tensioner</option>
										</select>
									</div>
									{gapsDataValue && gapsDataValue === 'Tensioner' ?
										<div className={`form-group ${implantValue ? 'mt-2' : ''}`}>
											<label className={implantValue ? "mb-1" : "mb-3"}>Tensioner Force</label>
											<div className="d-flex align-items-center justify-content-between tensioner-force-wrap">
												<div className="form-check">
													<input type="checkbox" className="form-check-input" id="force-high" value="high" name="forcerange" checked={tensionerForce.indexOf('high') > -1} onChange={this.handleCheckBoxTensioner} />
													<label className="form-check-label" htmlFor="force-high">{tensionerForceRange(150)}</label>
												</div>

												<div className="form-check">
													<input type="checkbox" className="form-check-input" id="force-medium" value="medium" name="forcerange" checked={tensionerForce.indexOf('medium') > -1} onChange={this.handleCheckBoxTensioner} />
													<label className="form-check-label" htmlFor="force-medium">{tensionerForceRange(100)}</label>
												</div>

												<div className="form-check">
													<input type="checkbox" className="form-check-input" id="force-low" value="low" name="forcerange" checked={tensionerForce.indexOf('low') > -1} onChange={this.handleCheckBoxTensioner} />
													<label className="form-check-label" htmlFor="force-low">{tensionerForceRange(50)}</label>
												</div>
											</div>
										</div>
										: ''}
								</div>
							</div>

							<div className="row mt-3">
								<div className="col-sm-8">
									<div className="d-flex align-items-center justify-content-left">
										<div className="case-pull-dropdown-wrap d-flex align-items-center justify-content-left">
											<Dropdown>
												<Dropdown.Toggle variant="primary" id="dropdown-casepull" className="btn btn-outline-primary btn-dropdown-text">{casePullValue}</Dropdown.Toggle>
												<Dropdown.Menu>
													{dropDownButtons.map((button, index) => {
														return (
															<Dropdown.Item
																key={`item_${index}`}
																id={`item_${button.value}`}
																className={`case-pull-option ${casePull === button.value ? 'selected' : ''}`}
																eventKey={button.value}
																onClick={() => this.handleCasePull(button.value)}>
																{button.label}
															</Dropdown.Item>
														)
													})}
												</Dropdown.Menu>
											</Dropdown>
										</div>

										{casePull === 'region' ?
											<div className="region-dropdown-wrap ms-3">
												<Dropdown>
													<Dropdown.Toggle variant="primary" id="dropdown-regions" className="btn btn-outline-primary btn-dropdown-text">{selectedRegion}</Dropdown.Toggle>
													<Dropdown.Menu>
														{regions.map((region, index) => {
															return (
																<Dropdown.Item
																	key={`item_${index}`}
																	id={`item_${region.name}`}
																	className={`case-pull-option ${selectedRegion === region.name ? 'selected' : ''}`}
																	eventKey={region.name}
																	onClick={() => handleInputChange(region.name, 'selectedRegion')}>
																	{region.name}
																</Dropdown.Item>
															)
														})}
													</Dropdown.Menu>
												</Dropdown>
											</div>
											: ''}
									</div>
								</div>
								<div className="col-sm-4 text-end d-flex align-items-center justify-content-end">
									<button className="btn btn-primary me-2" id="btn-apply" onClick={handleSubmit}>Apply</button>
									<button className="btn btn-primary" id="btn-reset" disabled={disableReset} onClick={this.handleReset}>Reset</button>
								</div>
							</div>

							{/* Results section */}
							{showResults ?
								<div className="planning-tool-result-block mt-2">
									{!caseData && <div className="planning-review-body text-center mt-4">
										<ErrorMsgBlockComponent noData={true} />
									</div>}
									{caseData && <div className="planning-review-body">
										<div className="row">
											<div className="col-md-12 case-count">
												<p className="case-count-text">
													Average Plan Output: Based on {caseData.totalCount} procedure{caseData.totalCount > 1 && <span>s</span>}
												</p>
											</div>
										</div>

										<div className="d-flex align-items-stretch justify-content-between mt-4">
											<div className="case-component-div">
												<label className="case-label-align-top">Varus/Valgus</label>
												<div className="case-text-align-below">
													<span className="value">{customRoundOff(caseData.FemurVVComponent)}°</span>
													<span className="unit ps-1">{getSignedUnit(caseData.FemurVVComponent, 'alignment')}</span>
												</div>

												<div className="align-image-text">
													<div className="align-text-middle">
														<span className="color-orange-left">
															<span className="value">{customRoundOff(caseData.FemurDistalMedialResectionDepth)}</span> <span className="unit">mm</span>
														</span>
														<br />
														<span className="color-orange-left">
															<span className="value">{customRoundOff(caseData.TibiaMedialResectionDepth)}</span> <span className="unit">mm</span>
														</span>
													</div>
													<img className="img-bg-color" src={getImgSrc('LeftCoronalView.png')} alt="Resection Frontal Left" />
													<div className="align-text-middle">
														<span className="color-purple-right">
															<span className="value">{customRoundOff(caseData.FemurDistalLateralResectionDepth)}</span> <span className="unit">mm</span>
														</span>
														<br />
														<span className="color-purple-right">
															<span className="value">{customRoundOff(caseData.TibiaLateralResectionDepth)}</span> <span className="unit">mm</span>
														</span>
													</div>
												</div>
												<div className="case-text-align-below case-text-lower-row">
													<span className="value">{customRoundOff(caseData.TibiaVVComponent)}°</span>
													<span className="unit ps-1">{getSignedUnit(caseData.TibiaVVComponent, 'alignment')}</span>
												</div>
											</div>
											<div className="case-component-div">
												<label className="case-label-align-top">Rotation</label>
												<div className="case-text-align-below">
													<span className="value">{customRoundOff(caseData.FemurRotationComponent)}°</span>
													<span className="unit ps-1">{getSignedUnit(caseData.FemurRotationComponent, 'rotation')}</span>
												</div>

												<div className="align-image-text">
													<div className="align-text-middle-single-point">
														<span className="color-orange-left">
															<span className="value">{customRoundOff(caseData.FemurPosteriorMedialResectionDepth)}</span> <span className="unit">mm</span>
														</span>
													</div>
													<img className="img-bg-color" src={getImgSrc('LeftAxialView.png')} alt="Resection Transverse Left" />
													<div className="align-text-middle-single-point">
														<span className="color-purple-right">
															<span className="value">{customRoundOff(caseData.FemurPosteriorLateralResectionDepth)}</span> <span className="unit">mm</span>
														</span>
													</div>
												</div>
												{/* <div className="case-text-align-below case-text-lower-row">
													<span className="value">{customRoundOff(caseData.TibiaRotationComponent)}°</span>
													<span className="unit ps-1">{getSignedUnit(caseData.TibiaRotationComponent, 'rotation')}</span>
												</div> */}
											</div>
											<div className="case-component-div">
												<label className="case-label-align-top">Flexion / Slope</label>
												<div className="case-text-align-below">
													<span className="value">{customRoundOff(caseData.FemurFlexionComponent)}°</span>
													<span className="unit ps-1">{getSignedUnit(caseData.FemurFlexionComponent, 'flexion')}</span>
												</div>
												<img className="img-bg-color" src={getImgSrc('SagittalView.png')} alt="ResectionSag" />
												<div className="case-text-align-below case-text-lower-row">
													<span className="value">{customRoundOff(caseData.TibiaSlopeComponent)}°</span>
													<span className="unit ps-1">{getSignedUnit(caseData.TibiaSlopeComponent, 'slope')}</span>
												</div>
											</div>
										</div>
										<div className="mt-4">{NAHelpText()}</div>
									</div>}
								</div>
								: planningToolError && <ErrorMsgBlockComponent errorObject={planningToolError} />}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
