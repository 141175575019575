import React, { Component } from 'react'
import SalesRepComponent from '../../components/Dashboard/SalesRep/SalesRep'

export class SalesRepContainer extends Component {
  constructor(props) {
		super(props);
		this.state = {
			showAddCustomerModal: false,
			refreshSalesRepCustomerList: '',

		}
	}



	/**
	 * @description function to open clear search results 
	 * @param {*}
	 * @memberof SalesRepContainer
	 */
	clearSearch = () => {
		var errors = { ...this.state.errors }
		errors.searchKey = '';
		this.setState({ searchResults: '', searchKey: '', errors, searchResultsError: null })
	}

  /**
	 * @description function to open and close modal popup 
	 * @param {*}
	 * @memberof SalesRepContainer
	 */
	enablePreopCustomerSearchModal = (isShow) => {
		this.setState({ refreshSalesRepCustomerList: false });
		this.state.showAddCustomerModal ? this.setState({ showAddCustomerModal: false })
			: this.setState({ showAddCustomerModal: true });
		this.clearSearch();
	}

    /**
	* @description function to refresh sales rep customer list
	* @param {*}
	* @memberof SalesRepContainer
	*/
	refreshDataList = (status) => {
		this.setState({ refreshSalesRepCustomerList: status });
	}

  render() {
    return (
      <SalesRepComponent
		refreshDataList={this.refreshDataList}
		refreshSalesRepCustomerList={this.state.refreshSalesRepCustomerList}
		enablePreopCustomerSearchModal={this.enablePreopCustomerSearchModal}
		showAddCustomerModal={this.state.showAddCustomerModal}
      />
    )
  }
}

export default SalesRepContainer