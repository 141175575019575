import React, { Component } from 'react';
import { isPositiveNumber } from '../../helpers/GlobalFunctions';
import { promScoreMessages } from '../../helpers/messages';
import { PROMScoreTypes } from '../../containers/CaseDetails/constants';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
class AddProms extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }
    /* istanbul ignore next  */
    resetForm = () => {
        this.myFormRef.reset();
        this.props.resetForm();
    }
    /* istanbul ignore next  */
    componentDidUpdate() {
        if (this.props.promScoreSave === true) {
            this.resetForm();
        }
    }

     /**
	 * @description Function to make sure only numbers are input
	 * @memberof AddProms
	 */
    /* istanbul ignore next  */
    checkNumeric = e => {
        const isValid = isPositiveNumber(e);
        if (!isValid) {
            e.preventDefault();
            return false;
        }
    }

    render() {
        const { operativeProcedure, handleInputChange, addPromScore, formErrors, promScoreSave, promsErrorMsg, getUserRoleType, onEnterPress, disableReset, isCustomScore, scoreType, scoreFields, subScores, scoreValue } = this.props;
        const isDisabled =  getUserRoleType.includes('rar');
        const scoreTypeDropdown = PROMScoreTypes[operativeProcedure.toLowerCase()];

        return (<div className="proms-blocks">

            <div className={`proms-blocks-body ${isCustomScore ? 'custom-fields' : ''}`}>
                <div className="proms-block-title">Add PROM Score Entry</div>
                {promScoreSave === true ?
                    <div className="proms-success-msg">
                        <p>{promScoreMessages.saveData}</p>
                    </div>
                    : ''}
                {promScoreSave === false && promsErrorMsg ? <ErrorMsgBlockComponent className="pt-2 m-0" errorObject={promsErrorMsg} /> : ''}
                <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                    <div className="form-container">
                        <div className="proms-form-lable">
                            Score Type
                        </div>
                        <div className="proms-form-input">
                            <select id="scoresType" onChange={(e) => handleInputChange(e.target.value, 'scoreType')} className="form-select" disabled={isDisabled}>
                                <option value="">Select Score Type</option>
                                {scoreTypeDropdown && scoreTypeDropdown.length && scoreTypeDropdown.map((score, index) => <option key={`score-type-${index}`} value={score.value}>{score.label}</option>)}
                            </select>
                            {formErrors && formErrors.scoreType ?
                                <div className="proms-error-msg">
                                    <p>{formErrors.scoreType}</p>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="form-container">
                        <div className="proms-form-lable">
                            Time Frame
                        </div>
                        <div className="proms-form-input">
                            <select id="timeFrame" onChange={(e) => handleInputChange(e.target.value, 'timeFrame')} className="form-select" disabled={isDisabled}>
                                <option value="">Select Time Frame</option>
                                <option value="6 weeks" >6 Weeks</option>
                                <option value="12 weeks">12 Weeks</option>
                                <option value="6 months">6 Months</option>
                                <option value="12 months">12 Months</option>
                                <option value="2 years">2 Years</option>
                            </select>
                            {formErrors && formErrors.timeFrame ?
                                <div className="proms-error-msg">
                                    <p>{formErrors.timeFrame}</p>
                                </div>
                                : ''}
                        </div>
                    </div>

                    {isCustomScore ?
                        (scoreFields && scoreFields.length ?
                            <>
                                {scoreFields.map((x, i) => {
                                    return (
                                        <div className="form-container" key={`score-${i + 1}`}>
                                            <div className="proms-form-lable">{x.label}</div>
                                            <div className="proms-form-input">
                                                <input id={x.fieldName} type='number' min='0' max='100' autoComplete="off" value={!subScores || subScores[x.fieldName] === undefined ? '' : subScores[x.fieldName]}
                                                onKeyDown={e => this.checkNumeric(e)} onChange={(e) => handleInputChange(e.target.value, x.fieldName)} className="form-control" disabled={isDisabled} />
                                                {formErrors && formErrors[x.fieldName] ?
                                                    <div className="proms-error-msg">
                                                        <p>{formErrors[x.fieldName]}</p>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    )
                                })}

                                {formErrors && formErrors.manualScoreOneRequired ?
                                    <div className="form-container">
                                        <div className="proms-error-msg">
                                            <p>{formErrors.manualScoreOneRequired}</p>
                                        </div>
                                    </div>
                                    : ''}
                            </>
                            : '')
                        :
                        <div className="form-container">
                            <div className="proms-form-lable">{scoreType==="PROMIS-Global" ? 'T-Score':'Score'}</div>
                            <div className="proms-form-input">
                                <input id="scoreValue" type='number' min='0' max='100' autoComplete="off" value={scoreValue} disabled={isDisabled}
                                onKeyDown={e => this.checkNumeric(e)} onChange={(e) => handleInputChange(e.target.value, 'scoreValue')} className="form-control" />
                                {scoreType && ['KOOS JR.', 'HOOS JR.','PROMIS-Global'].indexOf(scoreType) > -1  ?
                                    <div className="proms-help-msg">
                                        <p>{promScoreMessages.scoreHelpText}</p>
                                    </div>
                                    : ''}
                                {formErrors && formErrors.scoreValue ?
                                    <div className="proms-error-msg">
                                        <p>{formErrors.scoreValue}</p>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    }
                </form>
                <div className="form-container">
                    <div className="proms-form-btn">
                        <button type="button" className="btn btn-primary proms-btn-color me-2" disabled={isDisabled} onClick={addPromScore} id="addProms">Save</button>
                        <button className="btn btn-primary proms-btn-color" disabled={disableReset || isDisabled} onClick={this.resetForm} id="resetProms">Clear</button>
                    </div>
                </div>
            </div>
        </div>);
    }
}
export default AddProms;

