export const definedRoutesForRoles = {
	common: [
		'/',
		'/login',
		'/home',
		'/dashboard',
		'/resetpassword',
		'/help',
		'/settings'
	],
	surgeon: [
		'/caselist',
		'/casedetails/:id',
		'/notifications',
		'/caseplanningtool'
	],
	rar: [
		'/surgeon',
		'/upload',
		'/fileslist',
		'/caselist',
		'/casedetails/:id',
		'/reviewcases',
		'/reviewcasedetails/:id',
		'/lookup'
	],
	admin: [
		'/customerlist',
		'/customer/:id',
		'/surgeonlist',
		'/surgeondetails/:id',
		'/users',
		'/logs',
		'/deviceList',
	],
	careteam: [
		'/surgeon',
		'/upload',
		'/fileslist',
		'/caselist',
		'/casedetails/:id',
		'/reviewcases',
		'/reviewcasedetails/:id'
	],
	salesrep: [
		'/planlist',
	],
	customersupport: [
        '/preOpPlans',
	]
}