import React, { Component } from 'react'
import AddDeviceContainer from '../../containers/DeviceDetails/AddDevice';
import CustomModal from '../../shared/CustomModal';

export default class AddDeviceModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewDeviceModal: false,
        }
    }
/* istanbul ignore next  */
    componentDidMount() {
        if (this.props.fromDashboard) {
            this.toggleNewDeviceModal();
        }
    }

    /**
     * @description Funtion to toggle new device modal
     * @memberof AddDeviceModalComponent
     */
    toggleNewDeviceModal = () => {
        this.setState(prevState => {
            return { showNewDeviceModal: !prevState.showNewDeviceModal }
        });
    }

    /**
     * @description Funtion to explicitly close new device modal
     * @memberof AddDeviceModalComponent
     */
    closeNewDeviceModal = () => {
        this.setState({ showNewDeviceModal: false });
    }

    /**
     * @description Funtion to handle situation when device added
     * @memberof AddDeviceModalComponent
     */
    /* istanbul ignore next  */
    deviceAdded = (userName) => {
        this.closeNewDeviceModal();
        this.props.deviceAdded(true);
    }

    render() {
        const { showNewDeviceModal } = this.state;
        const {isCustomerDisable,customerid}= this.props
        return (
            <>
                <button type="button" id="addDeviceBtn" className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.toggleNewDeviceModal(); }}>Add New</button>
                {/* Modal to add new device */}
                <CustomModal
                    id="add-device-modal"
                    show={showNewDeviceModal}
                    title="Add New Device"
                    isComponent={true}
                    dialogClassName="add-device-dialog"
                    closeAction={this.closeNewDeviceModal}>
                    <AddDeviceContainer id="add-device-container" handleCancel={this.closeNewDeviceModal} deviceAdded={this.deviceAdded} isCustomerDisable={isCustomerDisable}customerid={customerid}/>
                </CustomModal>
            </>
        )
    }
}
