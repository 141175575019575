import React, { Component } from 'react';
import { downTimeSettingsMessages } from '../../helpers/messages';
import DownTimeComponent from '../../components/DownTimeDashboard/DownTime';
import { getDowntimeSettings, setDowntimeSettings } from '../../services/java/java-services';
import { getTodaysDateTime } from '../../helpers/GlobalFunctions';

export default class DownTimeContainer extends Component {
	constructor(props) {
		super(props);
		let todayDateTime = getTodaysDateTime(new Date());
		this.state = {
			todayDate: todayDateTime,
			downtimeFromDate: '',
			downtimeTodate: '',
			errorMessage: '',
			status: false,
			successMessage: '',
			downTimeData: null,
			isDataPresent: false,
			loading: false,
			error: null
		}
	}

	componentDidMount() {
		this.fetchDowntimeData();
	}

	/**
	 * @description Function to fetch data to prepopulate input fields
	 * @memberof DownTimeContainer
	 */
	fetchDowntimeData = () => {
		this.setState({ loading: true });
		getDowntimeSettings((err, res) => {
			if (err) {
				this.setState({ downTimeData: null, error: err, loading: false });
			} else {
				this.setDownTimeData(res.data ? res.data : null);
			}
		})
	}

	/**
	 * @description Function to set data for populating input fields
	 * @param data Dataset
	 * @memberof DownTimeContainer
	 */
	setDownTimeData = (data, showSuccess = false) => {
		if (data && data.id) {
			this.setState({
				loading: false,
				error: null,
				downTimeData: data,
				downtimeFromDate: getTodaysDateTime(data.starttime),
				downtimeTodate: getTodaysDateTime(data.endtime),
				status: data.status,
				isDataPresent: data && !isNaN(data.id),
				successMessage: showSuccess ? downTimeSettingsMessages.success : '',
			});
			if (showSuccess) {
				setTimeout(() => {
					this.setState({ successMessage: '' });
				}, 10000);
			}
		} else {
			this.setState({ loading: false, error: null });
		}
	}

	/**
	 * @description Function to handle change in input fields
	 * @param e Input change event
	 * @param field Input field name
	 * @memberof DownTimeContainer
	 */
	onInputChange = (e, field) => {
		this.setState({ successMessage: '' });
		let value = field === 'status' ? e.target.checked : e.target.value;
		var stateObj = {};

		if (field) {
			stateObj[field] = value;
		}

		this.setState(stateObj);
	}

	/**
	 * @description Function to validate form inputs
	 * @memberof DownTimeContainer
	 * @returns Boolean. True if valid, false if not
	 */
	validateInput = () => {
		const { downtimeFromDate, downtimeTodate } = this.state;
		let isValid = true;
		let currentDateTime = Date.parse(new Date());
		let fromTimeParsed = Date.parse(downtimeFromDate);
		let toTimeParsed = Date.parse(downtimeTodate);

		if (fromTimeParsed < currentDateTime && toTimeParsed < currentDateTime) {
			isValid = false;
			this.setState({ errorMessage: downTimeSettingsMessages.bothEarlierThanToday });
		} else if (((fromTimeParsed) >= (toTimeParsed))) {
			isValid = false;
			this.setState({ errorMessage: downTimeSettingsMessages.toLessThanFrom });
		} else if ((downtimeFromDate && !downtimeTodate) || (!downtimeFromDate && downtimeTodate)) {
			isValid = false;
			this.setState({ errorMessage: downTimeSettingsMessages.requireField });
		} else if ((!downtimeFromDate && !downtimeFromDate.length) || (!downtimeTodate && !downtimeTodate.length)) {
			isValid = false;
			this.setState({ errorMessage: downTimeSettingsMessages.requireField });
		} else {
			this.setState({ errorMessage: "", successMessage: '' });
		}
		return isValid;
	}

	/**
	 * @description Function to save data
	 * @memberof DownTimeContainer
	 */
	saveDowntimeSettings = () => {
		this.setState({ loading: true });
		const { downtimeFromDate, downtimeTodate, status, isDataPresent, downTimeData } = this.state;
		var params = {
			starttime: downtimeFromDate ? new Date(downtimeFromDate).toISOString() : '',
			endtime: downtimeTodate ? new Date(downtimeTodate).toISOString() : '',
			status: status
		}

		if (isDataPresent) {
			params['id'] = downTimeData.id;
		}

		setDowntimeSettings(params, (err, result) => {
			if (err) {
				this.setState({ downTimeData: null, error: err, loading: false });
			} else {
				this.setDownTimeData(result.data, true);
			}
		})
	}

	/**
	 * @description Function to submit the form
	 * @param event Form submit event
	 * @memberof DownTimeContainer
	 */
	handleSubmit = (event) => {
		event.preventDefault();
		if (this.validateInput()) {
			this.saveDowntimeSettings();
		}
	}

	render() {
		const { loading, error, todayDate, downtimeFromDate, downtimeTodate, errorMessage, status, successMessage } = this.state;
		return (
			<DownTimeComponent
				handleSubmit={this.handleSubmit}
				todayDate={todayDate}
				downtimeFromDate={downtimeFromDate}
				downtimeTodate={downtimeTodate}
				errorMessage={errorMessage}
				onInputChange={this.onInputChange}
				status={status}
				successMessage={successMessage}
				loading={loading}
				error={error}
			/>
		);
	}
}
