import React, { Component } from 'react'
import { getPendingCasesCount } from '../../../services/java/java-services';
import { getUserName } from '../../../services/aws/aws-services';
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';
import { Link } from 'react-router-dom';


export class DashBoardPlanCount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pendingCasesCount: '',
            pendingCasesCountError: null
        }
    }
    componentDidMount() {
        this.getPreopPendingCasesCount();
    }

    /**
  * @description function to get total pending cases count for preop
  * @memberof DashBoardPlanCount
  */
    getPreopPendingCasesCount = () => {
        let salesRepUsername = getUserName();
        /* istanbul ignore next  */
        getPendingCasesCount(salesRepUsername, (err, result) => {
            
            if (err) {
                this.setState({ pendingCasesCountError: err });
            } else {
                this.setState({ pendingCasesCount: result.data.count, pendingCasesCountError: null })
            }
        })
    }
    render() {
        const { pendingCasesCountError, pendingCasesCount } = this.state
        return (
            <div className='dashboard-blocks'>
                <div className='rar-dashboard-blocks-body rar-dashboard-block-height d-flex flex-column justify-content-evenly'>
                    {pendingCasesCountError ? (
                        <ErrorMsgBlockComponent errorObject={pendingCasesCountError} />
                    ) : (
                        <>
                            <div className='rar-dashboard-block-title cases-count'>{pendingCasesCount || '0'}</div>
                            <div className='rar-dashboard-block-sub-title'>Cases Pending Customer Association</div>
                            <div className='rar-dashboard-btn'>
                                <Link className='btn btn-primary rar-btn-color' id='view-case' to={'/planlist?ispending=true'}>
                                    View Cases
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default DashBoardPlanCount