import React, { Component } from 'react';
import { Multiselect } from "multiselect-react-dropdown";

export default class MultiSelectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                chips: {
                  background: "#73bc51"
                },
                searchBox: {
                  "maxHeight": "80px",
                  "overflow": "auto",
                  "padding": "4px 5px"
                },
                option: {
                    "fontWeight": "normal"
                }
            }
        }
    }

    render() {
        const { id, options, singleSelect, className, placeholder, displayValue, selectedValues, handleSelectChange } = this.props;
        return (
            <div className="custom-multiselect">
                <Multiselect
                    id={id}
                    className={className}
                    style={this.state.style}
                    singleSelect={singleSelect}
                    options={options}
                    placeholder={placeholder || 'Select'}
                    displayValue={displayValue}
                    selectedValues={selectedValues}
                    showCheckbox={true}
                    closeOnSelect={false}
                    avoidHighlightFirstOption={true}
                    closeIcon="cancel"
                    onSelect={handleSelectChange}
                    onRemove={handleSelectChange}
                />
            </div>
        )
    }
}
