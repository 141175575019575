import React, { Component } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { logger } from '../../services/logger/logger-service';
import FAQContainer from '../../containers/FAQ';
import { actions, loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import ContactUsContainer from '../../containers/ContactUs';

let startDate;
export default class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loggerObj: {
                "EventOutcome": loggerEventOutcome.success,
                "EventType": loggerEventTypes.read,
                "EventName": loggerEventName.helpPage,
                "StartDate": new Date()
            },
            activeTab: "information" // set the default active tab
        }
    }

    /* istanbul ignore next  */
    componentDidMount() {
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }

    /* istanbul ignore next  */
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }
    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof Help
     */
    /* istanbul ignore next  */
    applicationLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.helpPage,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description Function to handle loader
     * @param loading True to show loader, false to hide
     * @memberof Help
     */
    toggleLoading = (loading) => {
        this.setState({ loading: loading });
    }

    /**
     * @description Function to handle tab change
     * @param eventKey The key of the newly active tab
     * @memberof Help
     */
    handleTabChange = (eventKey) => {
        this.setState({ activeTab: eventKey });
    }

    render() {
        const {activeTab}= this.state
        return (<div>
            {this.state.loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : ''}
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1 sm-side-width">
                            <SideMenu loggerObj={this.state.loggerObj} />
                        </div>
                        <div className="col-md-11 sm-col-width" >
                            <div className="data-list-tabs planning-tool-wrap help-page">
                                <Tabs activeKey={activeTab} onSelect={this.handleTabChange}>
                                    <Tab eventKey="information" title="Information">
                                        <div className="help-info">
                                            <p>If you have any questions, concerns, or comments, fill out the form to reach us.</p>
                                           {activeTab=== 'information' ? <ContactUsContainer toggleLoading={this.toggleLoading}/> : null }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="faq" title="FAQ">
                                        <FAQContainer toggleLoading={this.toggleLoading} />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
