import React, { Component } from 'react';
import { connect } from 'react-redux';
import CaseDetailsComponent from '../../components/CaseDetails/CaseDetails';
import {
    caseDetailsService, caseDetailsUpdateService, caseGapDataService,
    caseTimingDataService, addPromScoreService, promsOutcomeScoreService
} from '../../services/java/java-services';
import { getUserRoleType } from "../../services/aws/aws-services";
import { validateEQ5DScore, validateKOOSScore, validatePromFields, validatePromScore } from './ValidateProms';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { manualPromScoreFields, scoreTypesForManualScore } from './constants';
import { operativeProcedureTypes } from '../../helpers/constants';
let startDate;

class CaseDetailsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getUserRoleType: getUserRoleType(),
            caseDetails: null,
            selectedTab: '',
            loading: true,
            gapLoading: true,
            detailsLoading: true,
            gapData: null,
            timingData: null,
            timingDataLoading: false,
            caseTimingDataLoading: false,
            outComeScoreData: null,
            refreshDataList: '',
            caseId: this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : '',
            scoreType: '',
            outComeScoreType: 'KOOS',
            timeFrame: '',
            scoreValue: '',
            scoreValueMax: 100,
            promScoreSave: '',
            promsErrorMsg: null,
            notesError: null,
            notesSaved: '',
            isReadOnlyNotes: '',
            disableReset: true,
            isCustomScore: false,
            scoreFields: [],
            subScores: {},
            timingError: null,
            detailsError: null,
            gapError: null,
            getPROMGraphError: null,
            pageFrom: this.props.location.state && this.props.location.state.activeTab ? this.props.location.state.activeTab : '',
            errors: {
                scoreValue: '',
                scoreType: '',
                timeFrame: '',
                manualScoreOneRequired: ''
            },
            loggerObj: {
                "EventOutcome": loggerEventOutcome.success,
                "EventType": loggerEventTypes.read,
                "EventName": loggerEventName.caseSummaryReport,
                "StartDate": new Date()
            }
        }
    }

    componentDidMount() {
        this.getCaseDetails(this.props.match.params.id);
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof CaseDetailsContainer
     */
    applicationLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.caseSummaryReport,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description Function to get the default outcome score type base on operative procedure
     * @param operativeProcedure The operative procedure of the case
     * @memberof CaseDetailsContainer
     */
    getDefaultOutcomeScoreType = (operativeProcedure) => {
        switch (operativeProcedure.toLowerCase()) {
            case operativeProcedureTypes.TKA.inLowerCase:
                return 'KOOS';
            case operativeProcedureTypes.HIP.inLowerCase:
                return 'HOOS';
            default:
                return 'KOOS';
        }
    }

    /**
     * @description function to get the query params for rar & careteam users
     * @param {*}
     * @memberof CaseDetailsContainer
     */
    getQueryParams = () => {
        if (getUserRoleType()?.includes('rar') || getUserRoleType()?.includes('careteam')) {
            return {
                'surgeonname': this.props.location.state && this.props.location.state.surgeonName ? this.props.location.state.surgeonName : ''
            }
        }

        return {};
    }

    /**
     * @description function to get the case details
     * @param {*}
     * @memberof CaseDetailsContainer
     */
    getCaseDetails = async (caseId) => {
        const params = this.getQueryParams();
        caseDetailsService(caseId, params, (err, result) => {
            if (err) {
                this.setState({ loading: false, detailsLoading: false, detailsError: err });
            } else {
                const data = result.data && result.data.case && result.data.case.length && result.data.case[0] ? result.data.case[0] : null;
                let operativeProcedure = data && data.caseDetails && data.caseDetails.operativeProcedure ? data.caseDetails.operativeProcedure : '';
                if (operativeProcedure.toLowerCase() === operativeProcedureTypes.TKA.inLowerCase) {
                    this.getGapDataDetails(caseId);
                }

                this.setState({
                    loading: false,
                    detailsLoading: false,
                    caseDetails: data,
                    isReadOnlyNotes: '',
                    detailsError: null,
                    operativeProcedure: operativeProcedure,
                    outComeScoreType: data && data.caseDetails ? this.getDefaultOutcomeScoreType(operativeProcedure.toLowerCase()) : ''
                });
            }
        })
    }

    /**
    * @description function to get the gap data
    * @param {*}
    * @memberof CaseDetailsContainer
    */
    getGapDataDetails = async (caseId) => {
        this.setState({ gapLoading: true })
        const params = {
            'surgeonname': this.props.location.state && this.props.location.state.surgeonName ? this.props.location.state.surgeonName : ''
        }
        caseGapDataService(caseId, params, (err, result) => {
            if (err) {
                this.setState({ gapLoading: false, gapData: null, gapError: err });
            } else {
                this.setState({ gapData: result.data.case && result.data.case.length ? result.data.case[0] : null, gapLoading: false, gapError: null });
            }
        })
    }
    
    /**
     * @description function to get the timing data
     * @param {*}
     * @memberof CaseDetailsContainer
     */
    getTimingDataDetails = async (caseId, type) => {
        var stateObj = type === 'group' ? { timingDataLoading: true } : { caseTimingDataLoading: true };
        this.setState({ loading: true, ...stateObj })
        const params = this.getQueryParams();
        caseTimingDataService(caseId, params, type, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                let stateObj = { loading: false, timingError: err };
                if (type === 'group') {
                    stateObj['timingDataLoading'] = false;
                } else {
                    stateObj['caseTimingDataLoading'] = false;
                }
                this.setState(stateObj);
            } else {
                let timingData = result && result.data && result.data.case && result.data.case.length ? result.data.case[0].timingData : null
                
                if (timingData && Object.keys(timingData).length) {
                    timingData['setuptimePreference'] =  result && result.data && result.data.case && result.data.case.length ? result.data.case[0].setuptimePreference : false
                }

                type === 'group' ?
                    this.setState({ timingData, loading: false, timingDataLoading: false, timingError: null })
                    : this.setState({ caseTimingData: result && result.data && result.data.case && result.data.case.length ? result.data.case[0] : null, loading: false, caseTimingDataLoading: false, timingError: null })
            }
        })
    }

    /**
     * @description function to get the outcome score
     * @param {*}
     * @memberof CaseDetailsContainer
     */
    getOutComeScore = async (caseId, scoreType) => {
        this.setState({ loading: true });
        const params = {
            'surgeonname': this.props.location.state && this.props.location.state.surgeonName ? this.props.location.state.surgeonName : ''
        }
        promsOutcomeScoreService(caseId, params, scoreType, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false, getPROMGraphError: err })
            } else {
                this.setState({ loading: false, outComeScoreData: result.data && result.data.case && result.data.case.length ? result.data.case : null, getPROMGraphError: null })
            }
        })
    }

    /**
     * @description function to add Proms score to DB
     * @param {*}
     * @memberof CaseDetailsContainer
     */
    addPromScoreEntry = async (caseId, promsDetails) => {
        this.setState({ loading: true });
        addPromScoreService(caseId, promsDetails, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false, promScoreSave: false, promsErrorMsg: err });
            } else {
                this.setState({ loading: false, promScoreSave: true, refreshDataList: true });
                this.getOutComeScore(this.state.caseId, this.state.outComeScoreType);
            }
        })
    }

    /**
     * @description function to handle Outcome ScoreType
     * @param {*}
     * @memberof CaseDetailsContainer
     */
    handleOutcomeScoreType = (scoreType) => {
        this.setState({ promScoreSave: '', outComeScoreType: scoreType, refreshDataList: false });
        this.getOutComeScore(this.state.caseId, scoreType)
    }

    /**
    * @description function to refresh Outcome Score in bar chart
    * @param {*}
    * @memberof CaseDetailsContainer
    */
    refreshOutcomeScore = () => {
        this.setState({ refreshDataList: false });
        this.getOutComeScore(this.state.caseId, this.state.outComeScoreType);
    }

    /**
    * @description function to handle tabs selection
    * @param {*}
    * @memberof CaseDetailsContainer
    */
    handleTabSelect = (key) => {
        var errors = this.state.errors;
        errors.scoreType = '';
        errors.scoreValue = '';
        errors.timeFrame = '';
        this.setState({
            promScoreSave: '', 
            scoreType: '', 
            scoreValue: '', 
            subScores: {}, 
            selectedTab: key, 
            timingError: null, 
            refreshDataList: '',
            isCustomScore: false,
            scoreFields: [],
            errors: {
                scoreValue: '',
                scoreType: '',
                timeFrame: '',
                manualScoreOneRequired: ''
            },
        });
        if (!this.state.caseDetails) {
            return;
        }
        switch (key) {
            case 'Timing':
                if (!this.state.timingData) {
                    this.getTimingDataDetails(this.state.caseId, 'group');
                    if (this.state.operativeProcedure && this.state.operativeProcedure.toLowerCase() === operativeProcedureTypes.TKA.inLowerCase) {
                        this.getTimingDataDetails(this.state.caseId, 'states');
                    }
                }
                break;
            case 'proms':
                this.getOutComeScore(this.state.caseId, this.getDefaultOutcomeScoreType(this.state.operativeProcedure));
                break;
            default:
                break;
        }
    }

    /**
    * @description function for validate each input field
    * @param field Field name
    * @param value Field value
    * @returns Object with error messages if there is an error, true if not
    * @memberof CaseDetailsContainer
    */
    validateFields = (field, value, formSubmit = false) => {
        this.setState({ promScoreSave: '', refreshDataList: false });
        const { errors, scoreType, scoreValue, isCustomScore } = this.state;
        var validationRes;

        if (field === 'scoreValue') {
            if (scoreType && scoreTypesForManualScore.indexOf(scoreType) > -1) {
                let scoreFieldNames = manualPromScoreFields[scoreType] ? manualPromScoreFields[scoreType].map(x => x.fieldName) : [];
                if (scoreFieldNames.length) {
                    let obj = {};
                    scoreFieldNames.forEach(x => {
                        obj[x] = this.state[x];
                    });

                    validationRes = validatePromScore(scoreType, null, errors, formSubmit, obj);
                }
            } else {
                validationRes = validatePromScore(scoreType, value, errors, formSubmit);
            }
        } else if (field === 'scoreType') {
            if (value) {
                errors.scoreType = '';
            }
            if (scoreTypesForManualScore.indexOf(value) > -1) {
                let scoreFieldNames = manualPromScoreFields[value] ? manualPromScoreFields[value].map(x => x.fieldName) : [];
                if (scoreFieldNames.length) {
                    let obj = {};
                    scoreFieldNames.forEach(x => {
                        obj[x] = this.state[x];
                    });

                    validationRes = validatePromScore(value, null, errors, formSubmit, obj);
                }
            } else if (value && !isNaN(parseInt(scoreValue))) {
                validationRes = validatePromScore(value, scoreValue, errors, formSubmit);
            } else {
                validationRes = validatePromFields(field, value, errors);
            }

        } else {
            let scoreFieldNames = manualPromScoreFields[scoreType] ? manualPromScoreFields[scoreType].map(x => x.fieldName) : [];
            if (isCustomScore && scoreFieldNames.indexOf(field) > -1) {
                if (scoreType === 'KOOS' || scoreType === 'HOOS') {
                    validationRes = validateKOOSScore(field, value, errors);
                } else if (scoreType === "EQ5D") {
                    validationRes = validateEQ5DScore(field, value, errors);
                }
            } else {
                validationRes = validatePromFields(field, value, errors);
            }
        }

        this.setState(prevState => {
            return {
                errors: {
                    ...prevState.errors,
                    ...validationRes.errors
                }
            }
        });

        return validationRes.errorFlag ? validationRes.errors : true;
    }

    /**
    * @description function for validate Prom score entry
    * @param {*}
    * @memberof CaseDetailsContainer
    */
    formValidation() {
        const scoreTypeValidation = this.validateFields('scoreType', this.state.scoreType, true);
        const timeFrameValidation = scoreTypeValidation === true ? this.validateFields('timeFrame', this.state.timeFrame, true) : '';
        const scoreValueValidation = scoreTypeValidation === true && timeFrameValidation === true ? this.validateFields('scoreValue', this.state.scoreValue, true) : '';

        return scoreTypeValidation === true && timeFrameValidation === true && scoreValueValidation === true;
    }

    /**
    * @description function to get state object for custom scores
    * @param {*}
    * @memberof CaseDetailsContainer
    */
    getCustomScoreStateObject(scoreType) {
        var { scoreFields, errors } = this.state;
        var stateVars = manualPromScoreFields[scoreType] ? manualPromScoreFields[scoreType].map(x => x.fieldName) : [];
        var obj = { errors };
        if (scoreTypesForManualScore.indexOf(scoreType) > -1) {
            obj = { errors, isCustomScore: true, scoreFields: manualPromScoreFields[scoreType] };
            if (stateVars.length) {
                stateVars.forEach(el => {
                    obj[el] = '';
                    obj.errors[el] = '';
                });
            }
        } else {
            obj = { scoreFields: scoreFields, isCustomScore: false };
            if (stateVars.length) {
                stateVars.forEach(el => {
                    obj[el] = '';
                    obj.errors[el] = '';
                });
            }
        }

        return obj;
    }

    /**
     * @description function to handle input changes..
     * @param  {} inputType
     * @memberof CaseDetailsContainer
     */
    handleInputChange = async (inputValue, inputType) => {
        this.handleReset();
        var stateObj = {};
        if (inputType === 'scoreType') {
            stateObj = this.getCustomScoreStateObject(inputValue);
            this.setState({ scoreValue: '', subScores: {} })
        }
        this.setState(prevState => {
            return {
                [inputType]: inputValue,
                subScores: {
                    ...prevState.subScores,
                    [inputType]: inputValue
                },
                ...stateObj
            }
        });

        // Allowing time for the state to be set before field validations are triggered
        setTimeout(() => {
            this.validateFields(inputType, inputValue);
        }, 0);

    }

    /** @description function to add PROM score
     *  @param  {}
     *  @memberof CaseDetailsContainer
     */
    addPromScore = async () => {
        let verifyValidation = this.formValidation();
        if (verifyValidation === true) {
            let promsDetails = {
                "scoreType": this.state.scoreType,
                "timeIntervals": this.state.timeFrame,
                "source": "Manual"
            }

            if (scoreTypesForManualScore.indexOf(this.state.scoreType) > -1) {
                let scoreFields = manualPromScoreFields[this.state.scoreType] ? manualPromScoreFields[this.state.scoreType] : [];
                let subScoreType = [];
                if (scoreFields.length) {
                    scoreFields.forEach(score => {
                        if (this.state[score.fieldName] !== '') {
                            subScoreType.push({
                                type: score.type,
                                score: this.state[score.fieldName]
                            });
                        }
                    });
                    promsDetails['subScoreType'] = subScoreType;
                }
            } else {
                promsDetails['score'] = this.state.scoreValue;
            }

            await this.addPromScoreEntry(this.state.caseId, promsDetails);
        }
    }

    /** @description function to handle note input change
     *  @param  {}
     *  @memberof CaseDetailsContainer
     */
    handleNotesInputChange = () => {
        this.setState({ notesSaved: '', notesError: null })
    }

    /** @description function to handle note submission
     *  @param  {}
     *  @memberof CaseDetailsContainer
     */
    handleNoteSubmit = async (notes, isCharCountExceeded) => {
        this.handleNotesInputChange();
        if (!isCharCountExceeded) {
            // Save API call
            await this.addNotes(this.state.caseId, { "notes": notes ? notes.toString() : '' });
        }
    }

    /**
 * @description function to save notes in DB
 * @param {*}
 * @memberof CaseDetailsContainer
 */
    addNotes = async (caseId, notesObj) => {
        this.setState({ loading: true })
        caseDetailsUpdateService(caseId, notesObj, (err, result) => {
            if (err) {
                this.setState({ loading: false, notesSaved: false, notesError: err })
            } else {
                this.setState({ loading: false, notesSaved: true, isReadOnlyNotes: true, notesError: null });
                this.getCaseDetails(this.state.caseId);
                setTimeout(() => {
                    this.handleNotesInputChange();
                }, 10000)
            }
        })
    }

    /** @description function to add prom score by clicking on enter
     *  @param  {}
     *  @memberof CaseDetailsContainer
     */
    onEnterPress = (e) => {
        e.preventDefault();
        this.addPromScore();
    }

    /** @description function to handle disable functionality in prom score reset
     *  @param  {}
     *  @memberof CaseDetailsContainer
     */
    handleReset() {
        setTimeout(() => {
            const noInput = !this.state.scoreType && !this.state.scoreValue && !this.state.timeFrame;
            this.setState({ disableReset: noInput });
        }, 0)
    }

    /** @description function to handle reset functionality in prom score reset
     *  @param  {}
     *  @memberof CaseDetailsContainer
     */
    resetForm = () => {
        var errors = this.state.errors;
        errors.scoreType = '';
        errors.scoreValue = '';
        errors.timeFrame = '';
        errors.manualScoreOneRequired = ''
        this.setState({
            scoreValue: '',
            scoreType: '',
            timeFrame: '',
            disableReset: true,
            isCustomScore: false,
            promScoreSave: false,
            scoreFields: [],
            errors: {
                scoreValue: '',
                scoreType: '',
                timeFrame: '',
                manualScoreOneRequired: ''
            },
            promsErrorMsg: null
        });
    }

    render() {
        const surgeonName = this.state.caseDetails && this.state.caseDetails.caseDetails.surgeonName ? this.state.caseDetails.caseDetails.surgeonName : '';
        return (
            <div >
                <CaseDetailsComponent
                    handleInputChange={this.handleInputChange}
                    addPromScore={this.addPromScore}
                    promScoreSave={this.state.promScoreSave}
                    promsErrorMsg={this.state.promsErrorMsg}
                    isCustomScore={this.state.isCustomScore}
                    scoreType={this.state.scoreType}
                    scoreFields={this.state.scoreFields}
                    subScores={this.state.subScores}
                    scoreValue={this.state.scoreValue}
                    addPromsEntryCount={this.state.addPromsEntryCount}
                    formErrors={this.state.errors}
                    handleOutcomeScoreType={this.handleOutcomeScoreType}
                    refreshOutcomeScore={this.refreshOutcomeScore}
                    outComeScoreData={this.state.outComeScoreData}
                    getPROMGraphError={this.state.getPROMGraphError}
                    refreshDataList={this.state.refreshDataList}
                    caseData={this.state.caseDetails}
                    caseId={this.state.caseId}
                    operativeProcedure={this.state.operativeProcedure}
                    detailsError={this.state.detailsError}
                    isLoading={this.state.loading}
                    detailsLoading={this.state.detailsLoading}
                    gapLoading={this.state.gapLoading}
                    gapData={this.state.gapData}
                    gapError={this.state.gapError}
                    timingDataLoading={this.state.timingDataLoading}
                    caseTimingDataLoading={this.state.caseTimingDataLoading}
                    timingData={this.state.timingData}
                    caseTimingData={this.state.caseTimingData}
                    timingError={this.state.timingError}
                    handleNotesInputChange={this.handleNotesInputChange}
                    handleNoteSubmit={this.handleNoteSubmit}
                    notesError={this.state.notesError}
                    notesSaved={this.state.notesSaved}
                    isReadOnlyNotes={this.state.isReadOnlyNotes}
                    handleTabSelect={this.handleTabSelect}
                    surgeonUserName={this.props.location.state && this.props.location.state.surgeonName ? this.props.location.state.surgeonName : surgeonName}
                    getUserRoleType={this.state.getUserRoleType}
                    onEnterPress={this.onEnterPress}
                    disableReset={this.state.disableReset}
                    resetForm={this.resetForm}
                    loggerObj={this.state.loggerObj}
                    selectedTab={this.state.selectedTab}
                    pageFrom={this.state.pageFrom}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}


export default connect(mapStateToProps)(CaseDetailsContainer);
