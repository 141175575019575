import * as types from '../constants/actionTypes/login';
import { loginService, getUserName, getUserRoleType } from '../services/aws/aws-services';
import { logger } from '../services/logger/logger-service';
import { loggerEventOutcome, loggerEventTypes, loggerEventName, loggerEventMessage, errorMessages } from '../helpers/messages';
import { activityUpdater } from '../services/activity/activity-service';
import { removeLocalStorage, setLocalStorage, setSessionStorage } from '../services/storage/storage-service';


export function loginSuccess(res) {
	return { type: types.LOGIN_USER_SUCCESS, res };
}

export function loginError(err) {
	return { type: types.LOGIN_USER_ERROR, err };
}

export function newPasswordRequired(res) {
	return { type: types.NEW_PASSWORD_REQUIRED, res };
}
/**
 * @description function to handle application logs
 * @param {*}
 * @memberof loginaction
 */
/* istanbul ignore next  */
const applicationLogger = (role) => {
	let loggerObj = {
		"EventOutcome": loggerEventOutcome.success,
		"EventType": loggerEventTypes.login,
		"EventName": loggerEventName.login,
		"Content": {
			"Data": loggerEventMessage.login
		}
	}

	logger(loggerObj);
}

/**
 * @description function to handle user login activity
 * @param userObj Cognito User data
 * @memberof loginaction
 */
/* istanbul ignore next  */
const updateActivity = (userObj) => {
	var userName = getUserName() || userObj.username;
	if (userName) {
		const activityObj = {
			lastLoginTime: new Date().toISOString(),
			userName: userName
		}
		activityUpdater(activityObj);
	}
}

/**
 * @description function to handle after login details
 * @param userObj Cognito User data
 * @memberof loginaction
 */
/* istanbul ignore next  */
export const handleLogin = (userObj, shouldUpdateUser = true) => {
	removeLocalStorage('isFirstLogin');
	setSessionStorage('useractivestatus', true);
	setLocalStorage('cognito_data', JSON.stringify(userObj.attributes));
	applicationLogger(getUserRoleType());
	if (shouldUpdateUser) {
		updateActivity(userObj);
	}
}


/* istanbul ignore next  */
export function authUser(username, password) {
	return (dispatch) => {
		return loginService(username, password)
			.then(result => {
				if (result && result.challengeName) {
					if (result.challengeName === types.NEW_PASSWORD_REQUIRED) {
						const { userAttributes } = result.challengeParam;
						setSessionStorage('useractivestatus', true);
						setLocalStorage('cognito_data', JSON.stringify(userAttributes));
						setLocalStorage('isFirstLogin', "true");
						setLocalStorage('userCreds', JSON.stringify({ username, password }));
						return dispatch(newPasswordRequired(result));
					}

					return dispatch(loginError({ code: result.challengeName, message: errorMessages.NotAuthorizedException }));
				} else {
					handleLogin(result);
					return dispatch(loginSuccess(result));
				}
			})
			.catch(error => {
				return dispatch(loginError(error));
			})
	};
}
