import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Sidemenu from '../SideMenu/SideMenu';
import { DataTable } from '../DataTable/DataTable';
import { convertUTCToLocalDateTime, NAHelpText } from '../../helpers/GlobalFunctions';

class FilesListComponent extends Component {
	render() {
		/* istanbul ignore next  */
		const columns = [
			{
				// Make an expander cell
				Header: () => 'Case Details',
				id: 'expander', // It needs an ID
				Cell: ({ row }) => (
					<span {...row.getToggleRowExpandedProps()}>
						{row.isExpanded ?
							<svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" fill="#73BC51" className="bi bi-eye-fill" viewBox="0 0 16 16">
								<path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
								<path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
							</svg>
							: <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-eye-slash" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
								<path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
								<path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z" />
								<path fillRule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z" />
							</svg>}
					</span>
				),
				// We can override the cell renderer with a SubCell to be used with an expanded row
				SubCell: () => null // No expander on an expanded row
			},
			{
				Header: 'Upload Complete Date / Time',
				accessor: 'createdDate',
				Cell: ({ row }) => row.original.createdDate ? convertUTCToLocalDateTime(row.original.createdDate) : 'N/A',
				Filter: ''
			},
			{
				Header: 'Filename',
				accessor: 'filename',
				Cell: ({ row }) => (<span>{row.original.filename ? row.original.filename : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Status',
				Cell: ({ row }) => (<span> {row.original.status === "Warning" ? (<> Uploaded <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="#000000" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
					<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
					<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
				</svg> </>) : row.original.status} </span>),
				Filter: ''
			},
			{
				Header: 'Case Type',
				accessor: 'caseType',
				Cell: ({ row }) => (<span>{row.original.caseType ? row.original.caseType : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Source',
				accessor: 'source',
				Cell: ({ row }) => (<span>{row.original.source ? row.original.source : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Download',
				download: true
			},
		]
		return (
			<div>
				<Header />
				<div className="bgGray">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-1">
								<Sidemenu loggerObj={this.props.loggerObj} />
							</div>
							<div className="col-md-11 datalist-style">
								<div className="file-list">
									<div className="d-flex align-items-center justify-content-between">
										<h1 className="file-list-heading ms-0">Uploaded Files List</h1>
										{NAHelpText()}
									</div>
									<DataTable listType="file-list" showPagination={true} columns={columns} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(FilesListComponent);
