import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Sidemenu from '../SideMenu/SideMenu';
import { DataTable } from '../DataTable/DataTable';
import { convertUTCToLocalDateTime, formatDate } from '../../helpers/GlobalFunctions';
import { checkLocalStorage, removeLocalStorage } from '../../services/storage/storage-service';
import { operativeProcedureTypes } from '../../helpers/constants';

class ReviewCasesComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFilterBlock: false,
			filterObj: ''
		}
	}
	    /* istanbul ignore next  */
        componentWillUnmount() {
		/* istanbul ignore next  */
		if (checkLocalStorage('ReviewCustomer')) {
			removeLocalStorage('ReviewCustomer');
		}
		if (checkLocalStorage('ReviewSurgeon')) {
			removeLocalStorage('ReviewSurgeon');
		}
	}

	/**
	 * @description Function to get case ID for TKA and patient ID for THA for Case ID column
	 * @param data Row object with case data
	 * @returns Template with relevant data based on procedure type
	 */
	/* istanbul ignore next  */
	getCaseIDColumnData = (data) => {
		if (!data.operativeProcedure) {
			return 'N/A';
		}
		const operativeProcedure = data.operativeProcedure.toLowerCase();
		switch (operativeProcedure) {
			case operativeProcedureTypes.TKA.inLowerCase:
			case operativeProcedureTypes.TKA.text.toLowerCase():
				return data.caseID ? <div title={data.caseID}>{data.caseID.substring(0, 5) + '...'}</div> : 'N/A';

			case operativeProcedureTypes.HIP.inLowerCase:
				return data.patientId ? <div title={data.patientId}>{data.patientId.substring(0, 5) + '...'}</div> : 'N/A';

			default:
				return data.caseID ? <div title={data.caseID}>{data.caseID.substring(0, 5) + '...'}</div> : 'N/A';
		}
	}

	render() {
		/* istanbul ignore next  */
		const columns = [
			{
				Header: 'Case ID',
				accessor: 'caseID',
				Cell: ({ row }) => this.getCaseIDColumnData(row.original),
				Filter: ''
			},
			{
				Header: 'Surgery Date',
				accessor: 'dateOfSurgery',
				Cell: ({ row }) => (<span>{row.original.dateOfSurgery ? formatDate(row.original.dateOfSurgery) : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'System SN',
				accessor: 'product',
				Cell: ({ row }) => (<span>{row.original.product ? row.original.product : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Procedure Type',
				accessor: 'operativeProcedure',
				Cell: ({ row }) => (<span>{row.original.operativeProcedure ? row.original.operativeProcedure : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Surgeon',
				accessor: 'surgeonName',
				Cell: ({ row }) => (<span>{row.original.surgeonName ? row.original.surgeonName : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Customer',
				accessor: 'customerName',
				Cell: ({ row }) => (<span>{row.original.customerName ? row.original.customerName : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Upload Date / Time',
				accessor: 'uploadDate',
				Cell: ({ row }) => (<span>{row.original.uploadDate ? convertUTCToLocalDateTime(row.original.uploadDate) : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: ' ',
				ReviewCaseAction: true
			}

		];
		return (
			<div>
				<Header />
				<div className="bgGray">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-1">
								<Sidemenu loggerObj={this.props.loggerObj} />
							</div>
							<div className="col-md-11">
								<div className="file-list data-list-tabs px-3">
									<h1 className="file-list-heading pt-2">Review Cases List</h1>
									<DataTable listType="review-case-list" showPagination={true} columns={columns} filterObj={this.state.filterObj} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(ReviewCasesComponent);
