import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { noDataFound } from '../../helpers/messages';
import { graphContextMenuItems } from '../../helpers/constants';
import { exportAddOns } from '../../helpers/ChartExports';

class GapsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartConfig: null,
            plannedGraphShown: false,
            defaultToggleBtnTextPartial: 'Planned Gaps'
        }
    }

    componentDidMount() {
        this.createChart();
    }

    createChart = () => {
        const { gapData, title } = this.props;
        const { plannedGraphShown, defaultToggleBtnTextPartial } = this.state;
        var _currentState = this;
        const { postOpMedGapsBinned, postOpLatGapsBinned, preOpMedGapsBinned, preOpLatGapsBinned } = gapData;
        var showPlannedGaps = plannedGraphShown;
        /* istanbul ignore next  */
        var chartConfig = {
            title: {
                text: title ? title : 'Post-Op Gap Balance'
            },
            styledMode: true,
            chart: {
                events: {
                    load: function () {
                        var yy = this.yAxis[0].translate(0, false, false);
                        var xxLine = (-1) * yy;
                        var xxTicks = (-1.03) * yy;
                        this.xAxis[0].axisGroup.translate(0, xxTicks);
                        this.xAxis[0].labelGroup.translate(0, xxLine);
                        this.xAxis[0].axisLine.translate(0, -(xxTicks - xxLine));
                        this.xAxis[0].axisTitle.translate(0, -xxLine);
                    },
                    redraw: function () {
                        let yy = this.yAxis[0].translate(0, false, false);
                        var xxLine = (-1) * yy;
                        let xxTicks;
                        if (this.fullscreen && this.fullscreen.isOpen) {
                            xxTicks = (-1.02) * yy;
                        } else {
                            xxTicks = (-1.03) * yy;
                        }
                        this.xAxis[0].axisGroup.translate(0, xxTicks);
                        this.xAxis[0].labelGroup.translate(0, xxLine);
                        this.xAxis[0].axisLine.translate(0, -(xxTicks - xxLine));
                        this.xAxis[0].axisTitle.translate(0, -xxLine);
                    }
                },
            },
            exporting: {
                sourceWidth: 900,
                sourceHeight: 350,
                buttons: {
                    contextButton: {
                        menuItems: graphContextMenuItems
                    },
                    toggleLineOn: {
                        enabled: true,
                        text: `${showPlannedGaps ? 'Hide' : 'Show'} ${defaultToggleBtnTextPartial}`,
                        menuClassName: 'toggle-planned-btn',
                        onclick: function () {
                            let series = this.series.filter(serie => serie.options.type === 'spline');
                            showPlannedGaps = !showPlannedGaps;
                            series.forEach(serie => {
                                serie.update({
                                    visible: showPlannedGaps,
                                    connectNulls: showPlannedGaps,
                                    showInLegend: showPlannedGaps
                                });
                            });
                            _currentState.setState({ plannedGraphShown: showPlannedGaps });
                            var button = this.exportSVGElements.find(x => x && x.menuClassName && x.menuClassName === 'toggle-planned-btn');
                            if (button) {
                                button.attr({ text: `${showPlannedGaps ? 'Hide' : 'Show'} ${defaultToggleBtnTextPartial}` });
                            }
                        }
                    },
                },
                chartOptions: {
                    chart: {
                        marginTop: 100,
                        marginBottom: 80,
                        events: {
                            load: function () {
                                var yy = this.yAxis[0].translate(0, false, false);
                                var xxLine = (-1) * yy;
                                var xxTicks = (-1.04) * yy;
                                this.xAxis[0].axisGroup.translate(0, xxTicks);
                                this.xAxis[0].labelGroup.translate(0, xxLine);
                                this.xAxis[0].axisLine.translate(0, -(xxTicks - xxLine));
                                this.xAxis[0].axisTitle.translate(0, -xxLine);

                                exportAddOns(this);
                            }
                        }
                    }
                }
            },
            xAxis: {
                categories: [
                    "-20",
                    "-10",
                    "0",
                    "10",
                    "20",
                    "30",
                    "40",
                    "50",
                    "60",
                    "70",
                    "80",
                    "90",
                    "100",
                    "110",
                    "120"
                ],
                title: {
                    text: 'FLEXION (degrees)'
                },
                labels: {
                    enabled: true,
                },
                tickWidth: 1,
                ticklength: 20,
                tickmarkPlacement: 'on',
                tickColor: '#000',
                showLastLabel: true,
                startOnTick: true,
                endOnTick: true,
                max: 14,
            },
            yAxis: {
                min: -6,
                max: 6,
                title: {
                    text: 'OVERLAP / GAP (mm)'
                },
                plotLines: [{
                    value: 0,
                    width: 2,
                    color: '#000'
                }]
            },
            plotOptions: {
                series: {
                    pointPlacement: 'between',
                    pointRange: 1,
                    tooltip: {
                        headerFormat: '<b>{series.name}: ',
                        pointFormatter: function () {
                            return parseFloat(this.y).toFixed(1)
                        }
                    }
                },
                column: {
                    minPointLength: 3
                },
                spline: {
                    marker: {
                        radius: 5,
                        lineColor: '#fff',
                        lineWidth: 1.3,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    lineWidth: 2,
                    visible: false,
                    dashStyle: 'Dash',
                    showInLegend: false
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                floating: false,
                backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                borderWidth: 1,
                width: 150
            },
            series: [{
                name: 'Medial',
                type: 'column',
                color: '#FFA500',
                data: postOpMedGapsBinned
            }, {
                name: 'Lateral',
                type: 'column',
                color: '#800080',
                data: postOpLatGapsBinned

            }, {
                name: 'Planned Medial',
                type: 'spline',
                color: '#FFA500',
                data: preOpMedGapsBinned

            }, {
                name: 'Planned Lateral',
                type: 'spline',
                color: '#800080',
                data: preOpLatGapsBinned
            }],
        };

        this.setState({ chartConfig: chartConfig });
    }

    render() {
        var { chartConfig, plannedGraphShown, defaultToggleBtnTextPartial } = this.state;
        if (chartConfig) {
            chartConfig.exporting.buttons.toggleLineOn.text = `${plannedGraphShown ? 'Hide' : 'Show'} ${defaultToggleBtnTextPartial}`;
        }
        const { gapData } = this.props;
        return (
            <>
                {chartConfig && gapData ?
                    <div className="gaps-tabs">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartConfig}
                            containerProps={{ style: { height: '400px' } }}
                        />
                    </div> :
                    <div className="chart-blocks">
                        <div className="text-center text-danger">{noDataFound.noData}</div>
                    </div>}
            </>
        );
    }
}
export default GapsDetails;
