import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerDetails from '../../components/CustomerDetails/CustomerDetails';
import { customerMessages, licenseMessages, surgeonMapping, loggerEventOutcome, loggerEventTypes, loggerEventName,careteamMapping } from '../../helpers/messages';
import { getCountryList, getCustomerDetailsService, getCareteamListResults,searchSurgeonsService, updateCustomerDetails, licenseListService,searchCareteamService,
    customerAssociatedSaleRepService } from '../../services/java/java-services';
import { validateAlphanumeric, validateCustomerFields, validatePhoneNumber } from './ValidateCustomer';
import { logger } from '../../services/logger/logger-service';
let startDate;

class CustomerDetailsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            licenseListloading: false,
            careTeamListloading:false,
            associateSalesRepLoading:false,
            careTeamError:false,
            editMode: false,
            customerID: props.match.params.id || '',
            customerDetails: '',
            careteamList:'',
            licenseList: '',
            searchResults: '',
            searchResultCareteam:'',
            showAddSurgeonModal: false,
            showAddLicenseModal: false,
            showAddCareteamModal: false,
            refreshSurgeonList: false,
            disableSubmitBtn: false,
            licenseSuccessMsg: '',
            countryObj: [],
            stateObj: [],
            dialCodeObj: [],
            addressline1: '',
            addressline2: '',
            postalcode: '',
            country: '',
            state: '',
            countrydialcode: '',
            phonenumber: '',
            errors: {
                addressline1: '',
                addressline2: '',
                postalcode: '',
                country: '',
                state: '',
                countrydialcode: '',
                phonenumber: ''
            },
            loggerObj: {
                "EventOutcome": loggerEventOutcome.success,
                "EventType": loggerEventTypes.read,
                "EventName": loggerEventName.customerDetails,
                "StartDate": new Date()
            },
            licenseError: null,
            customerError: null,
            customerSaveError: null,
            searchError: null,
            associatedSalesRepList: [],
            associateSalesRepError: null
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        this.getCountryList();
        this.getLicenseDetails();
        this.getCareteamList();
        this.getAssociatedSalesRepList();
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof CustomerDetailsContainer
     */
    applicationLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.customerDetails,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description Function to get the country details
     * @param 
     * @memberof CustomerDetailsContainer
     */
    getCountryList = async () => {
        getCountryList((err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false })
            } else {
                const countryList = result.data && result.data.countryList && result.data.countryList.length ? result.data.countryList : [];
                this.setState({
                    countryObj: countryList
                });
                this.getCustomerDetails(this.state.customerID);
            }
        })
    }

    /**
     * @description Function to get the customer details
     * @param customerID Unique ID of the customer to be fetched
     * @memberof CustomerDetailsContainer
     */
    getCustomerDetails = async (customerID) => {
        this.setState({ loading: true })
        getCustomerDetailsService(customerID, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false, customerError: err })
            } else {
                const customer = result.data && result.data.user && result.data.user.length ? result.data.user[0] : '';
                this.handleInputChange('country', customer.country);
                this.setState({
                    loading: false,
                    customerError: null,
                    customerDetails: customer,
                    ...this.getEditObject(customer)
                });
            }
        })
    }
       /**
     * @description Function to get the  customer associated sales rep
     * @param customerID Unique ID of the license to be fetched
     * @memberof CustomerDetailsContainer
     */
       getAssociatedSalesRepList = async () => {
        this.setState({ associateSalesRepLoading: true });
        customerAssociatedSaleRepService(this.state.customerID, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ associateSalesRepLoading: false, associateSalesRepError: err })
            } else {
                let  list = result.data ? result.data.SalesRepUserResponse : [];
                list = list.map(x=>{
                    x['name'] = `${x.firstname} ${x.lastname}`
                    return x
                })
                this.setState({
                    associateSalesRepLoading: false,
                    associatedSalesRepList: list,
                    associateSalesRepError: null
                });
            }
        })
    }

    /**
     * @description Function to get the license details
     * @param customerID Unique ID of the license to be fetched
     * @memberof CustomerDetailsContainer
     */
    getLicenseDetails = async () => {
        this.setState({ licenseListloading: true });
        licenseListService(this.state.customerID, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ licenseListloading: false, licenseError: err })
            } else {
                const license = result.data.user ? result.data.user : [];
                this.setState({
                    licenseListloading: false,
                    licenseList: license,
                    licenseError: null
                });
            }
        })
    }

    /**
     * @description Function to get the careteam details
     * @param customerID Unique ID of the careteam to be fetched
     * @memberof CustomerDetailsContainer
     */
         getCareteamList = async () => {
            this.setState({ careTeamListloading: true });
            getCareteamListResults(this.state.customerID, (err, result) => {
                /* istanbul ignore next  */
                if (err) {
                    this.setState({ careTeamListloading: false, careTeamError: err })
                } else {
                    const careteam = result.data.user ? result.data.user : [];
                    this.setState({
                        careTeamListloading: false,
                        careteamList: careteam,
                        careTeamError: null
                    });
                }
            })
        }

    /**
     * @description Function to handle state when edit is cancelled
     * @param customerDetails Customer Details
     * @memberof CustomerDetailsContainer
     */
    getEditObject(customerDetails) {
        this.setState({customerSaveError:""})
        const editObj = {
            customername: customerDetails ? customerDetails.customername : '',
            addressline1: customerDetails ? customerDetails.addressline1 : '',
            addressline2: customerDetails ? customerDetails.addressline2 : '',
            postalcode: customerDetails ? customerDetails.postalcode : '',
            country: customerDetails ? customerDetails.country : '',
            state: customerDetails ? customerDetails.state : '',
            countrydialcode: customerDetails ? customerDetails.countrycode : '',
            phonenumber: customerDetails ? customerDetails.phonenumber : ''
        }
        return {
            editObj: editObj,
            ...editObj,
            errors: {
                addressline1: '',
                addressline2: '',
                postalcode: '',
                country: '',
                state: '',
                countrydialcode: '',
                phonenumber: ''
            }
        }
    }

    /**
     * @description Function to enable/disable edit mode
     * @memberof CustomerDetailsContainer
     */
    toggleEditMode = () => {
        this.setState(prevState => {
            const editObj = this.getEditObject(prevState.customerDetails);
            return {
                ...editObj,
                dialCodeObj: [prevState.countryObj.find(cntry => cntry.countryname === (editObj.country)).dialcode],
                stateObj: prevState.countryObj.find(cntry => cntry.countryname === (editObj.country)).states,
                editMode: !prevState.editMode,
                disableSubmitBtn: true,
                showErrorMsg: ''
            };
        });
    }

    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field name
     * @memberof CustomerDetailsContainer
     */
    handleInputChange = (field, value) => {
        var errorMessage = value ? '' : customerMessages[field];

        if (field === 'phonenumber' && value) {
            const phoneCheck = validatePhoneNumber(value);
            errorMessage = phoneCheck.error;
        }
        if (field === 'customername' && value) {
            const customerNameCheck = validateAlphanumeric(value);
            errorMessage = customerNameCheck.error;
        }
        if (field === 'addressline1' && value) {
            const addressline1Check = validateAlphanumeric(value);
            errorMessage = addressline1Check.error;
        }
        if (field === 'addressline2') {
            if (value) {
                const addressline2Check = validateAlphanumeric(value);
                errorMessage = addressline2Check.error;
            } else {
                errorMessage = ''
            }
        }
        if (field === 'postalcode' && value) {
            const postalcodeCheck = validateAlphanumeric(value);
            errorMessage = postalcodeCheck.error;
        }

        if (field === 'country' && value) {
            this.setState({ stateObj: [], dialCodeObj: [], state: '', countrydialcode: '' });
            this.setState({ dialCodeObj: [this.state.countryObj.find(cntry => cntry.countryname === (value)).dialcode] });
            this.setState({ stateObj: this.state.countryObj.find(cntry => cntry.countryname === (value)).states });
        } else if (field === 'country' && value === '') {
            this.setState({ stateObj: [], dialCodeObj: [], state: '', countrydialcode: '' })
        }
        this.setState(prevState => {
            return {
                [field]: value,
                editObj: {
                    ...prevState.editObj,
                    [field]: value
                },
                errors: {
                    ...prevState.errors,
                    [field]: errorMessage
                }
            }
        });

        setTimeout(() => {
            this.setSubmitDisabledState();
        }, 0);
    }

    /** 
     * @description Function determine if any field has been changed
     *  @memberof CustomerDetailsContainer
     */
    setSubmitDisabledState = () => {
        const { editObj, customerDetails } = this.state;
        const noFieldsChanged = editObj.customername === customerDetails.customername && editObj.addressline1 === customerDetails.addressline1 &&
            editObj.addressline2 === customerDetails.addressline2 && editObj.postalcode === customerDetails.postalcode && editObj.country === customerDetails.country &&
            editObj.state === customerDetails.state && editObj.countrydialcode === customerDetails.countrycode && editObj.phonenumber === customerDetails.phonenumber;

        this.setState({
            disableSubmitBtn: noFieldsChanged
        })
    }

    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof CustomerDetailsContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveCustomer();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof CustomerDetailsContainer
     */
    validateFields = () => {
        let { errorFlag, errors } = validateCustomerFields(this.state);
        if (!errorFlag && this.state.addressline1) {
            let addressline1 = validateAlphanumeric(this.state.addressline1);
            errorFlag = addressline1.errorFlag;
            errors['addressline1'] = addressline1.error;
        }
        if (!errorFlag && this.state.addressline2) {
            let addressline2 = validateAlphanumeric(this.state.addressline2);
            errorFlag = addressline2.errorFlag;
            errors['addressline2'] = addressline2.error;
        }
        if (!errorFlag && this.state.postalcode) {
            let postalcode = validateAlphanumeric(this.state.postalcode);
            errorFlag = postalcode.errorFlag;
            errors['postalcode'] = postalcode.error;
        }
        if (!errorFlag && this.state.phonenumber) {
            let phonenumber = validatePhoneNumber(this.state.phonenumber);
            errorFlag = phonenumber.errorFlag;
            errors['phonenumber'] = phonenumber.error;
        }
        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }

        return errorFlag;
    }

    /**
     * @description function to validate and save the case details
     * @param {*}
     * @memberof CustomerDetailsContainer
     */
    saveCustomer = () => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '' });
        const formHasErrors = this.validateFields();

        if (!formHasErrors) {
            this.setState({ isLoading: true });
            const getCustomerDetails = {
                "country": this.state.country,
                "state": this.state.state,
                "countrydialcode": this.state.countrydialcode,
                "addressline1": this.state.addressline1,
                "addressline2": this.state.addressline2,
                "postalcode": this.state.postalcode,
                "phonenumber": this.state.phonenumber
            }
            updateCustomerDetails(this.state.customerDetails.customerid, getCustomerDetails, (err, result) => {
                /* istanbul ignore next  */
                if (err) {
                    let duplicateFieldName = '';
                    let duplicateFieldMsg = '';
                    let showErrorMsg = '';
                    switch (err.errorCode) {
                        case 2022:
                            duplicateFieldName = 'customername';
                            duplicateFieldMsg = 'duplicateCustomerName';
                            showErrorMsg = customerMessages['duplicateProfile'];
                            break;
                        case 2023:
                            duplicateFieldName = 'addressline1';
                            duplicateFieldMsg = 'duplicateAddress';
                            showErrorMsg = customerMessages['duplicateProfile'];
                            break;
                        case 2024:
                            duplicateFieldName = 'phonenumber';
                            duplicateFieldMsg = 'duplicatePhoneNumber';
                            showErrorMsg = customerMessages['duplicateProfile'];
                            break;

                        default:
                            showErrorMsg = err.message;
                            break;
                    }

                    this.setState(prevState => {
                        return {
                            showErrorMsg: showErrorMsg,
                            isLoading: false,
                            customerSaveError: {
                                ...err,
                                message: showErrorMsg
                            },
                            errors: {
                                ...prevState.errors,
                                [duplicateFieldName]: customerMessages[duplicateFieldMsg]
                            }
                        }
                    });
                } else {
                    this.setState({ showSuccessMsg: customerMessages['saveCustomerMsg'], isLoading: false, editMode: false, showErrorMsg: '', customerSaveError: null });
                    const getCustomerId = result.data && result.data.customerId ? result.data.customerId : '';
                    this.getCustomerDetails(getCustomerId);
                }
            })
        }
    }


    /**
     * @description function to handle input changes..
     * @param  {} inputType
     * @memberof CustomerDetailsContainer
     */
    handleSearchInputChange = async (inputValue) => {
        var errors = { ...this.state.errors }
        errors.searchKey = '';
        this.setState({ errors })
        this.setState({ searchKey: inputValue.trim() });
    }

    /** @description function to find surgeons
     *  @param  {}
     *  @memberof CustomerDetailsContainer
     */
    findSurgeons = async () => {
        var errors = { ...this.state.errors }
        if (this.state.searchKey.trim() === '') {
            errors.searchKey = surgeonMapping.emptySearchBox;
            this.setState({ errors })
            return errors;
        } else {
            errors.searchKey = '';
            this.setState({ errors })
        }
        await this.getSearchResults(this.state.searchKey);
    }

    /** @description function to find careteam
     *  @param  {}
     *  @memberof CustomerDetailsContainer
     */
         findCareTeam = async () => {
            var errors = { ...this.state.errors }
            if (this.state.searchKey.trim() === '') {
                errors.searchKey = careteamMapping.emptySearchBox;
                this.setState({ errors })
                return errors;
            } else {
                errors.searchKey = '';
                this.setState({ errors })
            }
            await this.getCareteamResults(this.state.searchKey);
        }

    /** @description function to search surgeons and careteam by clicking on enter
    *  @param  {}
    *  @memberof CustomerDetailsContainer
    */
    onSearchEnterPress = (e) => {
        if (e.which === 13) {
            this.findSurgeons();
            this.findCareTeam()
        }
    }

    /**
    * @description function to get surgeons list based on search inputs 
    * @param {*}
    * @memberof CustomerDetailsContainer
    */
    getSearchResults = async (searchKey) => {
        this.setState({ loading: true, searchResults: '' })
        searchSurgeonsService(searchKey, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false, searchError: err })
            } else {
                const surgeonList = result.data && result.data.user && result.data.user.length && result.data.user[0].surgeonList ? result.data.user[0].surgeonList : [];
                this.setState({ loading: false, searchResults: surgeonList, searchError: null });
            }
        })
    }
        /**
    * @description function to get careteam list based on search inputs 
    * @param {*}
    * @memberof CustomerDetailsContainer
    */
         getCareteamResults = async (searchKey) => {
            this.setState({ loading: true, searchResultCareteam: '' })
            searchCareteamService(searchKey, (err, result) => {
                /* istanbul ignore next  */
                if (err) {
                    this.setState({ loading: false, searchError: err })
                } else {
                    const careTeamList = result.data && result.data.user && result.data.user.length && result.data.user[0].careTeamList ? result.data.user[0].careTeamList : [];
                    this.setState({ loading: false, searchResultCareteam: careTeamList, searchError: null });
                }
            })
        }

    /**
     * @description function to open and close modal popup 
     * @param {*}
     * @memberof CustomerDetailsContainer
     */
    enableAddSurgeonModal = (isShow) => {
        this.setState({ refreshSurgeonList: false });
        this.state.showAddSurgeonModal ? this.setState({ showAddSurgeonModal: false })
            : this.setState({ showAddSurgeonModal: true });
        this.clearSearch();
    }

        /**
     * @description function to open and close modal popup for care team 
     * @param {*}
     * @memberof CustomerDetailsContainer
     */
         enableCareTeamModal = (isShow) => {
            this.setState({ refreshSurgeonList: false, showAddSurgeonModal: false });           
             this.state.showAddCareteamModal ? this.setState({ showAddCareteamModal: false })
                : this.setState({ showAddCareteamModal: true });
            this.clearSearch();
        }

    /**
     * @description function to open and close modal popup  for new license
     * @param {*}
     * @memberof CustomerDetailsContainer
     */
    enableAddLicenseModal = (isShow) => {
        this.setState({ refreshSurgeonList: false, showAddSurgeonModal: false });
        this.state.showAddLicenseModal ? this.setState({ showAddLicenseModal: false })
            : this.setState({ showAddLicenseModal: true });
    }

    /**
     * @description function to open clear search results 
     * @param {*}
     * @memberof CustomerDetailsContainer
     */
    clearSearch = () => {
        var errors = { ...this.state.errors }
        errors.searchKey = '';
        this.setState({ searchResults: '', searchKey: '', errors, searchResultCareteam:'' })
    }

    /**
    * @description function to refresh surgeon list
    * @param {*}
    * @memberof CustomerDetailsContainer
    */
    refreshDataList = (status) => {
        this.setState({ refreshSurgeonList: status });
        if (status) {
            this.getCustomerDetails(this.state.customerID);
        }
    }

    /**
    * @description function to refresh license list
    * @param {*}
    * @memberof CustomerDetailsContainer
    */
    refreshLicenseDataList = (status) => {
        this.setState({ refreshSurgeonList: status });
        if (status) {
            this.getLicenseDetails();
            this.setState({ licenseSuccessMsg: licenseMessages['saveLicenseMsg'] });
            setTimeout(() => {
                this.setState({ licenseSuccessMsg: '' });
            }, 5000);

        }
    }

    /**
    * @description function to refresh surgeon list
    * @param {*}
    * @memberof CustomerDetailsContainer
    */
    refreshSurgeon = (status) => {
        if (status) {
            this.getCustomerDetails(this.state.customerID);
        }
    }
        /**
    * @description function to refresh careteam list
    * @param {*}
    * @memberof CustomerDetailsContainer
    */
         refreshCareteamList = (status) => {
            this.setState({ refreshSurgeonList: status });
            if (status) {
                this.getCustomerDetails(this.state.customerID);
                this.getCareteamList(this.state.customerID);
            }
        }

    render() {
        return (
            <CustomerDetails
                loading={this.state.loading}
                licenseListloading={this.state.licenseListloading}
                customer={this.state.customerDetails}
                customerError={this.state.customerError}
                editMode={this.state.editMode}
                editObj={this.state.editObj}
                formErrors={this.state.errors}
                resetStates={this.state.resetStates}
                countryObj={this.state.countryObj}
                stateObj={this.state.stateObj}
                dialCodeObj={this.state.dialCodeObj}
                country={this.state.country}
                custState={this.state.state}
                countrydialcode={this.state.countrydialcode}
                showErrorMsg={this.state.showErrorMsg}
                showSuccessMsg={this.state.showSuccessMsg}
                disableSubmitBtn={this.state.disableSubmitBtn}
                toggleEditMode={this.toggleEditMode}
                handleInputChange={this.handleInputChange}
                onEnterPress={this.onEnterPress}
                saveCustomer={this.saveCustomer}
                searchResults={this.state.searchResults}
                searchResultCareteam={this.state.searchResultCareteam}
                enableAddSurgeonModal={this.enableAddSurgeonModal}
                enableCareTeamModal={this.enableCareTeamModal}
                showAddSurgeonModal={this.state.showAddSurgeonModal}
                isLoading={this.state.loading}
                handleSearchInputChange={this.handleSearchInputChange}
                findSurgeons={this.findSurgeons}
                refreshSurgeonList={this.state.refreshSurgeonList}
                refreshDataList={this.refreshDataList}
                refreshLicenseDataList={this.refreshLicenseDataList}
                licenseSuccessMsg={this.state.licenseSuccessMsg}
                onSearchEnterPress={this.onSearchEnterPress}
                refreshSurgeon={this.refreshSurgeon}
                loggerObj={this.state.loggerObj}
                enableAddLicenseModal={this.enableAddLicenseModal}
                showAddLicenseModal={this.state.showAddLicenseModal}
                showAddCareteamModal={this.state.showAddCareteamModal}
                licenseList={this.state.licenseList}
                licenseError={this.state.licenseError}
                customerSaveError={this.state.customerSaveError}
                searchError={this.state.searchError}
                findCareTeam={this.findCareTeam}
                careTeamListloading={this.state.careTeamListloading}
                careTeamError={this.state.careTeamError}
                refreshCareteamList={this.refreshCareteamList}
                careteamList={this.state.careteamList}
                associatedSalesRepList = {this.state.associatedSalesRepList}
                associateSalesRepError= {this.state.associateSalesRepError}
                associateSalesRepLoading = {this.state.associateSalesRepLoading}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(CustomerDetailsContainer);