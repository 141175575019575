import React, { Component } from 'react';
import ImageViewer from 'react-simple-image-viewer';

export default class ImageViewerComponent extends Component {
    render() {
        const { images, isOpen, currentImg, closeImageViewer } = this.props;
        return (
            isOpen ?
                <ImageViewer
                    src={images}
                    currentIndex={currentImg}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
                : ''
        )
    }
}
