import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Roles } from '../../helpers/constants';
import { DataTable } from '../DataTable/DataTable';

class RarAssociationListComponent extends Component {
	/**
	 * @description Function to get user role
	 * @param user Row element for each user
 	*/
        /* istanbul ignore next  */
		getUserRole(user) {
		if (user && user.role) {
			const userRole = Roles.find(x => x.cognitoGroupName === user.role);
			return userRole ? userRole.specialLabel : user.role;
		}
		
		return 'N/A';
	}

	render() {
		/* istanbul ignore next  */
		const columns = [
			{
				Header: 'User',
				accessor: d => d.firstname ? d.firstname + " " + d.lastname : '',
				Filter: ''
			},
			{
				Header: 'Role',
				accessor: d => this.getUserRole(d),
				Filter: ''
			},
			{
				Header: ' ',
				switchToggle: true
			}
		]
		return (
			<div className="rar-request-list px-3 py-1">
				<h1 className="file-list-heading">Data Sharing Requests</h1>
				<div className="rar-request-list-table">
					<DataTable listType="rar-association" showPagination={false} disableColmSort={true} columns={columns} />
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(RarAssociationListComponent);
