import React, { Component } from 'react';
import { connect } from 'react-redux';
import FAQComponent from '../../components/Help/FAQ';
import { getFAQList } from '../../services/java/java-services';

class FAQContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            faqList: [],
            errorObject: null
        }
    }

    componentDidMount() {
        this.getFAQ();
    }

    /**
    * @description function to fetch FAQ list
    * @memberof FAQContainer
    */
    getFAQ() {
        const urlParams = {
            product: "RI.Insights",
            subproduct: "TKA",
            page: "ALL"
        }
        this.setState({ loading: true });
        this.props.toggleLoading(true);

        getFAQList(urlParams, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ loading: false, errorObject: err });
            } else {
                this.setState({ loading: false, errorObject: null, faqList: result.data && result.data.faq ? result.data.faq : [] });
            }
            
            this.props.toggleLoading(false);
        })
    }

    render() {
        return (
            <FAQComponent faqList={this.state.faqList} errorObject={this.state.errorObject} />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}


export default connect(mapStateToProps)(FAQContainer);
