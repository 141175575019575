import React, { Component } from 'react';
import { connect } from 'react-redux';
import { caseAnalyticsTimingDataService, getCasesAlighmentsService, totalGlobalCasesService, totalOutcomeScoreService } from '../../services/java/java-services';
import { outcomeScoreMessage } from '../../helpers/messages';
import TKADashboardComponent from '../../components/Dashboard/TKADashboard/TKADashboard';
import { operativeProcedureTypes } from '../../helpers/constants';

class TKADashboardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            averageCaseTime: '',
            totalTimeData: '',
            caseAlignmentDetails: '',
            caseAlignmentForAll: [],
            caseAlignmentForVarus: '',
            caseAlignmentForValgus: '',
            totalGlobalCasesCount: '',
            totalCasesDetails: '',
            totalCasesForAvg: '',
            totalCasesForMax: '',
            totalCasesForMin: '',
            scoreType: '', // default scoretype
            timeInterval: '', // default timeinterval
            defaultTimeInterval: '6 weeks',
            defaultScoreType: 'KOOS',
            activeTab: 'avg', // default activetab
            isLoading: true,
            selectedSurgeonId: props.surgeonId ? props.surgeonId : '',
            outcomeError: null,
            avgCasetimeError: null,
            globalCaseError: null,
            alignmentError: null,
            outcomeLoading: false,
            avgCasetimeLoading: false,
            globalCaseLoading: false,
            alignmentLoading: false,
            setuptimePreference: false
        }
    }

    componentDidMount() {
        this.getTotalOutcomeScore({ type: 'default' });
        this.getAverageCaseTime({ type: 'avg', daterange: this.props.timeFrameValue });
        this.getTotalGlobalCases({ type: 'avg', daterange: this.props.timeFrameValue });
        this.getCaseAlignmentData({ daterange: this.props.timeFrameValue });
    }

    componentDidUpdate(prevProps) {
        if (this.props.surgeonDetails !== prevProps.surgeonDetails) {
            this.handleSurgeonChange();
        }
        if (this.props.timeFrameValue !== prevProps.timeFrameValue) {
            this.handleTimeFrameChange();
        }
    }

    /**
     * @description function to get Average Case Time based on the timeframe input
     * @param {*}
     * @memberof TKADashboardContainer
     */
    getAverageCaseTime = (params) => {
        params['operativeProcedure'] = operativeProcedureTypes.TKA.raw;
        this.setState({ averageCaseTime: '', avgCasetimeError: null, avgCasetimeLoading: true, totalTimeData: '' });

        if (this.props.isRarView && this.props.surgeonDetails) {
            params['surgeonname'] = this.props.surgeonDetails.userName;
        }

        caseAnalyticsTimingDataService(params, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ avgCasetimeError: err, avgCasetimeLoading: false });
            } else {
                this.setState({
                    avgCasetimeLoading: false,
                    averageCaseTime: result.data.case[0] ? result.data.case[0] : [],
                    totalTimeData: result.data.totalTimeData ? result.data.totalTimeData : [],
                    setuptimePreference: result.data.setuptimePreference
                });
            }
        })
    }

    /**
     * @description function to get  total and global Case count based on the timeframe input
     * @param {*}
     * @memberof TKADashboardContainer
     */
    getTotalGlobalCases = (params) => {
        params['operativeProcedure'] = operativeProcedureTypes.TKA.raw;
        this.setState({ totalGlobalCasesCount: '', globalCaseError: null, globalCaseLoading: true });

        if (this.props.isRarView && this.props.surgeonDetails) {
            params['surgeonname'] = this.props.surgeonDetails.userName;
        }

        totalGlobalCasesService(params, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ globalCaseError: err, globalCaseLoading: false });
            } else {
                this.setState({ globalCaseLoading: false, totalGlobalCasesCount: result.data.case[0] ? result.data.case[0] : '' });
            }
        })
    }

    /**
    * @description function to get  total and global Case count in the outcome score
    * @param {*}
    * @memberof TKADashboardContainer
    */
    getTotalOutcomeScore = (params) => {
        params['operativeProcedure'] = operativeProcedureTypes.TKA.raw;
        this.setState({ totalCasesDetails: '', isLoading: true, outcomeError: null, outcomeLoading: true });

        if (this.props.isRarView && this.props.surgeonDetails) {
            params['surgeonname'] = this.props.surgeonDetails.userName;
        }

        totalOutcomeScoreService(params, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ isLoading: false, outcomeError: err, outcomeLoading: false });
            } else {
                const dataSet = result.data?.case?.length ? result.data.case.pop() : null;
                this.setState(prevState => {
                    return {
                        defaultScoreType: dataSet?.scoreType || prevState.defaultScoreType,
                        scoreType: dataSet?.scoreType || prevState.scoreType,
                        totalCasesDetails: dataSet,
                        isLoading: false,
                        outcomeLoading: false
                    }
                });
            }
        })
    }

    /**
    * @description Function to handle score type or time interval change for outcome scores quadrant
    * @param e The change event for the select input
    * @param type The score type selected
    * @memberof TKADashboardContainer
    */
    handleOutcomeScoreValueChange = (e, type) => {
        if (type === 'scoreType') {
            this.setState({ scoreType: e.target.value });
            this.getTotalOutcomeScore({ scoreType: e.target.value });
        }
    }

    /**
    * @description Function to handle tab change for outcome scores quadrant
    * @param key The identifier of the selected tab
    * @memberof TKADashboardContainer
    */
    handleTabs = (key) => {
        if (key) {
            this.setState({ activeTab: key });
        }
    }

    /**
    * @description function to get  total and global Case count based on the timeframe input
    * @param {*}
    * @memberof TKADashboardContainer
    */
    getCaseAlignmentData = (params) => {
        params['operativeProcedure'] = operativeProcedureTypes.TKA.raw;
        this.setState({ caseAlignmentDetails: '', alignmentError: null, alignmentLoading: true });
        if (this.props.isRarView && this.props.surgeonDetails) {
            params['surgeonname'] = this.props.surgeonDetails.userName;
        }
        getCasesAlighmentsService(params, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ alignmentError: err, alignmentLoading: false });
            } else {
                this.setState({ alignmentLoading: false, caseAlignmentForAll: result.data.case ? result.data.case : [], caseAlignmentDetails: result.data.case ? result.data.case : [] })
            }
        })
    }

    /**
    * @description function to get handle change of timeframe
    * @param {*}
    * @memberof TKADashboardContainer
    */
    handleTimeFrameChange = () => {
        this.getAverageCaseTime({ type: 'avg', daterange: this.props.timeFrameValue });
        this.getTotalGlobalCases({ type: 'avg', daterange: this.props.timeFrameValue });
        this.getCaseAlignmentData({ daterange: this.props.timeFrameValue });
    }

    /**
    * @description function to get handle change of alignment tabs
    * @param {*}
    * @memberof TKADashboardContainer
    */
    handleAlignmentTabs = (key) => {
        if (key === "all") {
            this.setState(prevState => {
                return { caseAlignmentDetails: prevState.caseAlignmentForAll }
            })
        }
        if (key === "varus") {
            let varusArry = [];
            this.state.caseAlignmentForAll.forEach(el => {
                if (el.plannedAverage > 0) {
                    varusArry.push(el);
                }
            })
            this.setState({ caseAlignmentDetails: varusArry })
        }
        if (key === "valgus") {
            let valgusArry = [];
            this.state.caseAlignmentForAll.forEach(el => {
                if (el.plannedAverage < 0) {
                    valgusArry.push(el);
                }
            })
            this.setState({ caseAlignmentDetails: valgusArry })
        }

    }

    /**
    * @description function to get handle change of surgeon selection by RAR
    * @param {*}
    * @memberof TKADashboardContainer
    */
    handleSurgeonChange = (e) => {
        this.handleTimeFrameChange();
        this.getTotalOutcomeScore({ type: 'default' });
    }

    render() {
        const { timeFrameValue, isRarView, surgeonDetails } = this.props;

        return (
            <TKADashboardComponent
                timeFrameValue={timeFrameValue}
                alignmentData={this.state.caseAlignmentDetails}
                averageCaseTime={this.state.averageCaseTime}
                totalTimeData={this.state.totalTimeData}
                totalGlobalCasesCount={this.state.totalGlobalCasesCount}
                handleOutcomeScoreValueChange={this.handleOutcomeScoreValueChange}
                totalCasesDetails={this.state.totalCasesDetails}
                defaultScoreType={this.state.scoreType ? this.state.scoreType : this.state.defaultScoreType}
                isLoading={this.state.isLoading}
                activeTab={this.state.activeTab}
                handleTabs={this.handleTabs}
                outcomeScoreMessage={outcomeScoreMessage}
                isRarView={isRarView}
                surgeonDetails={surgeonDetails}
                outcomeError={this.state.outcomeError}
                avgCasetimeError={this.state.avgCasetimeError}
                globalCaseError={this.state.globalCaseError}
                alignmentError={this.state.alignmentError}
                outcomeLoading={this.state.outcomeLoading}
                avgCasetimeLoading={this.state.avgCasetimeLoading}
                globalCaseLoading={this.state.globalCaseLoading}
                alignmentLoading={this.state.alignmentLoading}
                setuptimePreference={this.state.setuptimePreference}
            />
        )
    }
}


function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(TKADashboardContainer);