import { userMessages } from "../../helpers/messages";

/**
 * @description function for validate email of sales Rep
 * @param value email field
 * @returns Object with error messages
 */
export const validateSalesRepEmail = (value) => {
  const isValidEmailDomain = String(value.toLowerCase()).endsWith("@smith-nephew.com");

  return {
    errorFlag: !isValidEmailDomain ? true : false,
    error: !isValidEmailDomain ? userMessages.invalidSalesRepEmailDomain : "",
  };
};