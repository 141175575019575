import React, { Component } from 'react';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import { getLoadingTemplate } from '../../helpers/GlobalFunctions';
import { actions } from '../../helpers/messages';

export default class SurgeonPreferenceComponent extends Component {

    /**
     * @description function to handle radio button check and uncheck
     * @memberof SurgeonPreferenceComponent
     */
    /* istanbul ignore next  */
    handleOptionChange = (event) => {
        this.props.handleOptionChange(event)
    }

    render() {
        const { preferenceErr, errorMessage, successMessage, preferenceSaveLoading, preferences, thacasePreference, preferenceLoad, setuptimePreferenceLoad, setuptimePreference, handleCheckboxChange } = this.props;
        return (
            <div className="reset-password">
                <div className="reset-form w-40">
                    {errorMessage ? <div className="d-flex align-items-center justify-content-left m-0">
                        <ErrorMsgBlockComponent errorObject={errorMessage} /> </div> : ''}
                    {successMessage ? <p className="text-success m-0">{successMessage}</p> : ''}
                    {preferenceSaveLoading ? <p className="text-success m-0">{actions.wait}</p> : ''}
                    <div className="form-group  border-lt mb-0">
                        <label className='mb-0 mr-3'>Show Setup Time Data</label>
                        {setuptimePreferenceLoad ? (
                            getLoadingTemplate()
                        ) : (
                            <>
                                <label className="switch m-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onChange={handleCheckboxChange}
                                        // value={setuptimePreference}
                                        disabled={preferenceSaveLoading}
                                        defaultChecked={setuptimePreference} // Set the checkbox defaultChecked based on setuptimePreference
                                    />
                                    <div className="slider round">
                                        <span className="on">ON</span>
                                        <span className="off">OFF</span>
                                    </div>
                                </label>
                            </>
                        )}

                    </div>
                    <div className='border-lt mb-3'>
                        <div className="d-flex align-items-center mt-3">
                            <label className="mb-0 mr-3">Measured Hip Data</label>
                            {preferenceLoad ?
                                getLoadingTemplate()
                                : preferences ?
                                    <div className="radio-group">
                                        {preferences.map((pref, i) => {
                                            return (
                                                <div className="form-check" key={`${pref.preference}_${pref.id}`}>
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        disabled={preferenceSaveLoading}
                                                        value={pref.id}
                                                        id={`pref-${pref.id}`}
                                                        name="preference"
                                                        onChange={this.handleOptionChange}
                                                        defaultChecked={pref.preference === thacasePreference}
                                                    />
                                                    <label
                                                        htmlFor={`pref-${pref.id}`}
                                                        className={`form-check-label ${preferenceSaveLoading ? 'text-muted' : ''}`}>
                                                        {pref.preference}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    : preferenceErr ? <ErrorMsgBlockComponent errorObject={preferenceErr} /> : <ErrorMsgBlockComponent noData={true} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
