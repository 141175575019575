import { updateUserActivity } from '../java/java-services';

export const activityUpdater = (activityObj) => {      
    updateUserActivity(activityObj, (err, result) => {
        /* istanbul ignore next  */
          if (err) {
              return false;
          } else {
            return true;
          }
      });
}


export const activityUpdaterWithPromise = (activityObj) => {
    return new Promise((resolve, reject) => {
      updateUserActivity(activityObj, (err, result) => {
        /* istanbul ignore next  */
          if (err) {
            return reject({ message: err.message, errorCode: err.errorCode });
          } else {
            return resolve(result);
          }
      });
    })
}
