import React, { Component } from 'react'
import { parseHTML } from '../../helpers/GlobalFunctions';
import { userMessages } from '../../helpers/messages';
import CustomModal from '../../shared/CustomModal';

export default class UserStatusComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirmModal: false,
			enabled: props.user && props.user.enabled === true
		}
	}

	/**
	 * @description Function to handle open and close of modal
	 * @param enabled Boolean: false for uncheck, true for check
	 * @param isOpen Boolean: false to close, true to open
	 * @memberof UserStatusComponent
	 */
	toggleConfirmModal = (enabled, isOpen) => {
		this.setState({ enabled: enabled, showConfirmModal: isOpen });
	}

	/**
	 * @description Function to handle explicitly close modal
	 * @memberof UserStatusComponent
	 */
	 closeModal = () => {
		this.setState({ 
			enabled: this.props.user && this.props.user.enabled === true, 
			showConfirmModal: false 
		});
	}

	/**
	 * @description Function to handle confirmation
	 * @memberof UserStatusComponent
	 */
	changeUserStatus = () => {
		this.setState({ showConfirmModal: false });
		this.props.changeUserStatus(this.state.enabled);
	}

	render() {
		const { user } = this.props;
		const { enabled, showConfirmModal } = this.state;
		return (
			<>
				<div className="device-status-toggle" data-testid="userStatus">
					<label className="switch">
						<input onChange={(e) => this.toggleConfirmModal(e.target.checked, true)} type="checkbox" id={`user-item-${user.userName}`} value={enabled} checked={enabled} />
						<div className="slider round">
							<span className="on">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
									<path d="M7.5 1v7h1V1h-1z" />
									<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
								</svg>
							</span>
							<span className="off">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" className="bi bi-power" viewBox="0 0 16 16">
									<path d="M7.5 1v7h1V1h-1z" />
									<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
								</svg>
							</span>
						</div>
					</label>
				</div>

				{/* Modal to confirm activate/deactivate user */}
				<CustomModal
					id="user-status-modal"
					data-testid="user-status-modal"
					show={showConfirmModal}
					isConfirm={true}
					title={user && user.userName ? `User Status: ${user.userName}` : 'User Status'}
					body={parseHTML(userMessages.statusConfirmation(enabled))}
					closeAction={() => this.closeModal()}
					rejectAction={() => this.closeModal()}
					confirmAction={this.changeUserStatus}
				/>
			</>
		)
	}
}
