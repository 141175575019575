import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import AnalyticsScatterChart from './AnalyticsScatterChart';
import ChartDetails from './ChartDetails.json';
import { getLoadingTemplate } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

export default class TKAScatterChartsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PlannedvsPreopAlignment: null,
            FemurResectionDepth: null,
            FemurPosteriorResectionDepth: null,
            ProximalTibiaResections: null,
            FemurVVComponent: null,
            TibiaVVComponent: null,
            TibiaRotationComponent: null,
            TibiaSlopeComponent: null,
            FemurFlexionComponent: null,
            FemurRotationComponent: null,
        }
    }

    componentDidMount() {
        const { chartList, allAnalyticsData } = this.props;
        if (allAnalyticsData && allAnalyticsData.seriesdata && allAnalyticsData.seriesdata.length) {
            const stateObj = {};
            chartList.forEach(chart => {
                let seriesData = allAnalyticsData.seriesdata.find(x => x.name === chart) || {};
                let chartData = {
                    caseInfo: seriesData.series && seriesData.series.length ? allAnalyticsData.caseInfo : [],
                    name: seriesData.name,
                    series: seriesData.series && seriesData.series.length ? seriesData.series : []
                };
                stateObj[chart] = chartData;
            });
            this.setState({ ...stateObj });
        }
    }

    /**
     * @description Function to define template for no data scenario
     * @returns Template showing no data text
     * @memberof CaseAnalyticsComponent
     */
    noDataTemplate(chartName) {
        return (
            <div className="chart-blocks">
                <h3 className="text-center">{chartName}</h3>
                <ErrorMsgBlockComponent noData={true} />
            </div>
        )
    }

    /**
     * @description Function to define template for error scenario
     * @returns Template showing error message
     * @memberof CaseAnalyticsComponent
     */
    errorTemplate(chartName, errorObj) {
        return (
            <div className="chart-blocks">
                <h3 className="text-center">{chartName}</h3>
                <ErrorMsgBlockComponent errorObject={errorObj} />
            </div>
        )
    }

    render() {
        const { analyticsError } = this.props;
        const { PlannedvsPreopAlignment, FemurResectionDepth, FemurPosteriorResectionDepth, ProximalTibiaResections, FemurVVComponent, TibiaVVComponent, TibiaSlopeComponent,
            FemurFlexionComponent, FemurRotationComponent } = this.state;

        return (
            <>
                {/* Leg Alignment */}
                <LazyLoad height={200}>
                    {PlannedvsPreopAlignment ?
                        (PlannedvsPreopAlignment.caseInfo && PlannedvsPreopAlignment.caseInfo.length ?
                            <AnalyticsScatterChart spacingRight={0} legendMargin={49} analyticsData={PlannedvsPreopAlignment} showTextInToolTip={true}
                                chartDetails={ChartDetails.preopAndPlannedLegAlignmentVsCaseNumber} showUnit={true} rangeUnit={true}
                                lowUnit="&deg; Valgus" highUnit="&deg; Varus" />
                            : this.noDataTemplate(ChartDetails.preopAndPlannedLegAlignmentVsCaseNumber.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.preopAndPlannedLegAlignmentVsCaseNumber.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>

                {/* Distal Femur Resections */}
                <LazyLoad height={200} once offset={100}>
                    {FemurResectionDepth ?
                        (FemurResectionDepth.caseInfo && FemurResectionDepth.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={FemurResectionDepth} spacingRight={0} legendMargin={64} showTextInToolTip={true}
                                chartDetails={ChartDetails.medialLateralDistalFemurResectionDepth} showUnit={true} rangeUnit={true} lowUnit=" mm" highUnit=" mm" />
                            : this.noDataTemplate(ChartDetails.medialLateralDistalFemurResectionDepth.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.medialLateralDistalFemurResectionDepth.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>

                {/* Posterior Femur Resections */}
                <LazyLoad height={200} once offset={100}>
                    {FemurPosteriorResectionDepth ?
                        (FemurPosteriorResectionDepth.caseInfo && FemurPosteriorResectionDepth.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={FemurPosteriorResectionDepth} spacingRight={0} legendMargin={64} showTextInToolTip={true}
                                chartDetails={ChartDetails.posteriorFemurResectionDepth} showUnit={true} rangeUnit={true} lowUnit=" mm" highUnit=" mm" />
                            : this.noDataTemplate(ChartDetails.posteriorFemurResectionDepth.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.posteriorFemurResectionDepth.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>

                {/* Proximal Tibia Resections */}
                <LazyLoad height={200} once offset={100}>
                    {ProximalTibiaResections ?
                        (ProximalTibiaResections.caseInfo && ProximalTibiaResections.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={ProximalTibiaResections} spacingRight={0} legendMargin={64} showTextInToolTip={true}
                                chartDetails={ChartDetails.proximalTibiaResectionsDepth} showUnit={true} rangeUnit={true} lowUnit=" mm" highUnit=" mm" />
                            : this.noDataTemplate(ChartDetails.proximalTibiaResectionsDepth.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.proximalTibiaResectionsDepth.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>

                {/* Femur Component Varus/Valgus */}
                <LazyLoad height={200} once offset={100}>
                    {FemurVVComponent ?
                        (FemurVVComponent.caseInfo && FemurVVComponent.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={FemurVVComponent} spacingRight={0} legendMargin={8} showTextInToolTip={false}
                                chartDetails={ChartDetails.femurComponentVarusValgus} showUnit={true} rangeUnit={true} lowUnit="&deg; Valgus" highUnit="&deg; Varus" />
                            : this.noDataTemplate(ChartDetails.femurComponentVarusValgus.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.femurComponentVarusValgus.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>

                {/* Tibia Component Varus/Valgus */}
                <LazyLoad height={200} once offset={100}>
                    {TibiaVVComponent ?
                        (TibiaVVComponent.caseInfo && TibiaVVComponent.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={TibiaVVComponent} spacingRight={0} legendMargin={8} showTextInToolTip={false}
                                chartDetails={ChartDetails.tibiaComponentVarusValgus} showUnit={true} rangeUnit={true} lowUnit="&deg; Valgus" highUnit="&deg; Varus" />
                            : this.noDataTemplate(ChartDetails.tibiaComponentVarusValgus.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.tibiaComponentVarusValgus.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>

                {/* Femur Component Rotation */}
                <LazyLoad height={200} once offset={100}>
                    {FemurRotationComponent ?
                        (FemurRotationComponent.caseInfo && FemurRotationComponent.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={FemurRotationComponent} spacingRight={0} legendMargin={54} showTextInToolTip={false}
                                chartDetails={ChartDetails.femurRotation} showUnit={true} rangeUnit={true} lowUnit="&deg; External" highUnit="&deg; Internal" />
                            : this.noDataTemplate(ChartDetails.femurRotation.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.femurRotation.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>

                {/* Tibia Component Rotation*/}
                {/* <LazyLoad height={200} once offset={100}>
                    {TibiaRotationComponent ?
                        (TibiaRotationComponent.caseInfo && TibiaRotationComponent.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={TibiaRotationComponent} spacingRight={0} legendMargin={54} showTextInToolTip={false}
                                chartDetails={ChartDetails.tibialRotation} showUnit={true} rangeUnit={true} lowUnit="&deg; External" highUnit="&deg; Internal" />
                            : this.noDataTemplate(ChartDetails.tibialRotation.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.tibialRotation.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad> */}

                {/* Femur Component Flexion */}
                <LazyLoad height={200} once offset={100}>
                    {FemurFlexionComponent ?
                        (FemurFlexionComponent.caseInfo && FemurFlexionComponent.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={FemurFlexionComponent} spacingRight={0} legendMargin={46} showTextInToolTip={false}
                                chartDetails={ChartDetails.femurFlexion} showUnit={true} rangeUnit={true} lowUnit="&deg; Extension" highUnit="&deg; Flexion" />
                            : this.noDataTemplate(ChartDetails.femurFlexion.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.femurFlexion.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>

                {/* Tibial Component Slope */}
                <LazyLoad height={200} once offset={100}>
                    {TibiaSlopeComponent ?
                        (TibiaSlopeComponent.caseInfo && TibiaSlopeComponent.caseInfo.length ?
                            <AnalyticsScatterChart analyticsData={TibiaSlopeComponent} spacingRight={0} legendMargin={71} showTextInToolTip={false}
                                chartDetails={ChartDetails.tibialSlope} showUnit={true} rangeUnit={true} lowUnit="&deg; Posterior" highUnit="&deg; Anterior" />
                            : this.noDataTemplate(ChartDetails.tibialSlope.graphTitle))
                        : analyticsError ? this.errorTemplate(ChartDetails.tibialSlope.graphTitle, analyticsError) : getLoadingTemplate()}
                </LazyLoad>
            </>
        );
    }
}
