import React, { Component } from 'react';
import { connect } from 'react-redux';
import CaseFilterComponent from '../../components/CaseFilter/CaseFilter';
import { operativeProcedureTypes } from '../../helpers/constants';
import { caseFilterErrorMessages } from '../../helpers/messages';
import { getAllImplantsService, getSurgeonListService, getSurgeonListCareTeamService } from '../../services/java/java-services';
import { removeLocalStorage, checkLocalStorage, getLocalStorage, setLocalStorage } from '../../services/storage/storage-service';
import { getUserRoleType } from '../../services/aws/aws-services';

const DEFAULTS = {
	preopDeformity: { min: -8, max: 8, minVal: -8, maxVal: 8 },
	patientAge: { min: 0, max: 101, minVal: 0, maxVal: 0, maxLabel: "> 100" },
	pelvicTilt: { min: -16, max: 16, minVal: -16, maxVal: 16 }
}

const HIP_IMPLANTS = [
	{ "name": "Journey II HIP", "femurSize": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
	{ "name": "Journey II HR", "femurSize": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }
]
class CaseFilterContainer extends Component {
	constructor() {
		super()
		this.state = {
			showFilterBlock: true, updatedProps: true,
			surgeryFromDate: '', surgeryTodate: '',
			operativeProcedure: '', errorMessage: '',
			todayDate: this.getTodaysDate(),
			implants: [],
			tkaImplants: [],
			hipImplants: HIP_IMPLANTS,
			loading: false,
			patientSex: '',
			initialState: false,
			patinetSexList: [
				{ value: 'Male', checked: false, label: 'Male' },
				{ value: 'Female', checked: false, label: 'Female' },
				{ value: 'Other', checked: false, label: 'Other' }
			],
			patientAge: { min: DEFAULTS.patientAge.min, max: DEFAULTS.patientAge.max },
			preopDeformity: { min: DEFAULTS.preopDeformity.minVal, max: DEFAULTS.preopDeformity.maxVal },
			pelvicTilt: { min: DEFAULTS.pelvicTilt.minVal, max: DEFAULTS.pelvicTilt.maxVal },
			side: '',
			gapsCollectedData:'',
			implant: '',
			disableFilter: true,
			disableReset: true,
			isFiltered: false,
			noInputSelected: true,
			surgeons: [],
			surgeon: '',
			implantError: null,
			surgeonError: null,
			filterObjStateData: null,
		}
	}

	componentDidMount() {
		this.getAllImplants();

		if (checkLocalStorage('filterObjStateData')) {
			this.setFilterData();
		}
		const userRole = getUserRoleType();
		if (userRole.includes('rar')){
			this.getApprovedSurgeons();
		}else if(userRole.includes('careteam')){
			this.fetchSurgeonsforCareTeam();

		}

	}

	/**
	 * @description function to get the all localStorage
	 * @memberof CaseFilterComponent
	 */
	setFilterData() {
		let filterObjStateData = JSON.parse(getLocalStorage('filterObjStateData'));
		if (filterObjStateData) {
			this.setState({
				...filterObjStateData,
				filterObjStateData,
				initialState: true,
				disableFilter: false,
				disableReset: false,
				noInputSelected: false,
				isFiltered: true,
			});
			setTimeout(() => {
				this.setState({initialState: false})
			}, 200);
		}
	}

	/**
	 * @description function to get the all implant types
	 * @memberof CaseFilterComponent
	 */
	getAllImplants = async () => {
		this.setState({ loading: true });
		getAllImplantsService((err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ loading: false, implantError: err });
			} else {
				let allTKAImplants = result.data && result.data.case && result.data.case.length ? result.data.case : []
				this.setState({
					loading: false,
					implantError: null,
					tkaImplants: allTKAImplants,
					implants: allTKAImplants
				});
			}
		})
	}
	/**
	* @description function to fetch surgeons for careteam who have approved data sharing with logged in RAR
	* @param {*}
	* @memberof CaseFilterComponent
	*/
	fetchSurgeonsforCareTeam() {
		var urlParams = { type: 'Approved' };
		getSurgeonListCareTeamService(urlParams, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ surgeonError: err });
			} else {
				const surgeons = result.data.user ? result.data.user[0].surgeonList : [];
				this.setState({ surgeons, surgeonError: null });
			}
		})
	}

	/**
	* @description function to fetch surgeons who have approved data sharing with logged in RAR
	* @param {*}
	* @memberof CaseFilterComponent
	*/
	getApprovedSurgeons() {
		var urlParams = { type: 'Approved' };
		getSurgeonListService(urlParams, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ surgeonError: err });
			} else {
				const surgeons = result.data.user ? result.data.user[0].surgeonList : [];
				this.setState({ surgeons, surgeonError: null });
			}
		})
	}

	/**
	* @description function to get current date
	* @param {*}
	* @memberof CaseFilterComponent
	*/
	getTodaysDate = () => {
		let newDate = new Date();
		let d = newDate.getDate();
		let m = newDate.getMonth() + 1;
		let yyyy = newDate.getFullYear();
		let dd = d > 9 ? d.toString() : '0' + d.toString();
		let mm = m > 9 ? m.toString() : '0' + m.toString();
		var date = yyyy + '-' + mm + '-' + dd;
		return date;
	}

	/**
	 * @description function to set input filter values
	 * @param {*}
	 * @memberof CaseFilterComponent
	 */
	onInputChange = (e, field) => {
		let value = e.target.value;
		var stateObj = {};
		if (field) {
			stateObj[field] = value;
		}

		if (this.state.surgeryFromDate && (Date.parse(this.state.surgeryFromDate) >= this.state.surgeryToDate && Date.parse(this.state.surgeryToDate))) {
			stateObj['errorMessage'] = caseFilterErrorMessages.toLessThanFrom;
		} else {
			stateObj['errorMessage'] = '';
		}

		if (field === 'operativeProcedure') {
			switch (value) {
				case operativeProcedureTypes.TKA.raw:
					stateObj['implants'] = this.state.tkaImplants;
					break;
				case operativeProcedureTypes.HIP.raw:
					stateObj['implants'] = this.state.hipImplants;
					break;

				default:
					break;
			}
		}
		this.setState(stateObj);
		setTimeout(() => {
			this.toggleFilterDisable();
		}, 0);
	}

	/**
	 * @description function to set the filter button to enabled or disabled
	 * @param {*}
	 * @memberof CaseFilterComponent
	 */
	toggleFilterDisable() {
		setTimeout(() => {
			const noInputSelected = !this.state.surgeryFromDate && !this.state.surgeryToDate && !this.state.operativeProcedure && !this.state.patientSex
				&& !this.state.side && !this.state.implant && !this.state.surgeon && !this.state.patientAge.min && !this.state.gapsCollectedData
				&& !this.state.patientAge.max;

			const dateRangeNotSelected = (this.state.surgeryFromDate && !this.state.surgeryToDate) || (!this.state.surgeryFromDate && this.state.surgeryToDate)
			const disableFilter = (noInputSelected || dateRangeNotSelected) && !this.state.isFiltered;
			this.setState({ noInputSelected: noInputSelected, disableFilter: disableFilter, disableReset: noInputSelected && !this.state.isFiltered });
		}, 0)
	}

	/**
	 * @description function to validate filter form and submit
	 * @param {*}
	 * @memberof CaseFilterComponent
	 */
	filterCaseList = () => {
		const { surgeryFromDate, surgeryToDate, errorMessage, operativeProcedure, preopDeformity, pelvicTilt, implant, side, patientSex, patientAge, surgeon,gapsCollectedData } = this.state;
		let filterObj;
		let filterObjStateData = { surgeryFromDate, surgeryToDate, operativeProcedure, preopDeformity, pelvicTilt, implant, side, patientSex, patientAge, surgeon,gapsCollectedData };
		setLocalStorage('filterObjStateData', JSON.stringify(filterObjStateData));
		this.setState({ filterObjStateData: filterObjStateData })

		var getCurrentYear = new Date().getFullYear();
		var getFromDateYear = surgeryFromDate ? new Date(surgeryFromDate).getFullYear() : '';
		var getToDateYear = surgeryToDate ? new Date(surgeryToDate).getFullYear() : '';

		if (((Date.parse(getFromDateYear)) > (Date.parse(getCurrentYear)))) {
			this.setState({ 'errorMessage': caseFilterErrorMessages.fromGreaterThanCurrentYear });
		} else if (((Date.parse(getToDateYear)) > (Date.parse(getCurrentYear)))) {
			this.setState({ 'errorMessage': caseFilterErrorMessages.toGreaterThanCurrentYear });
		} else if (((Date.parse(surgeryFromDate)) > (Date.parse(surgeryToDate)))) {
			this.setState({ 'errorMessage': caseFilterErrorMessages.toLessThanFrom });
		} else if ((surgeryFromDate && !surgeryToDate) || (!surgeryFromDate && surgeryToDate)) {
			this.setState({ 'errorMessage': caseFilterErrorMessages.selectBoth });
		} else {
			this.setState({ 'errorMessage': "" });
			if (errorMessage === '') {
				filterObj = {
					fromdate: surgeryFromDate,
					todate: surgeryToDate,
					proceduretype: operativeProcedure,
					operativeside: side,
					gapsCollected: gapsCollectedData
				}

				switch (operativeProcedure) {
					case operativeProcedureTypes.TKA.raw:
						filterObj['implant'] = implant;
						filterObj['deformityfrom'] = preopDeformity.min !== '' ? preopDeformity.min : DEFAULTS.preopDeformity.minVal;
						filterObj['deformityto'] = preopDeformity.max !== '' ? preopDeformity.max : DEFAULTS.preopDeformity.maxVal;
						break;
					case operativeProcedureTypes.HIP.raw:
						filterObj['tiltfrom'] = pelvicTilt.min !== '' ? pelvicTilt.min : DEFAULTS.pelvicTilt.minVal;
						filterObj['tiltto'] = pelvicTilt.max !== '' ? pelvicTilt.max : DEFAULTS.pelvicTilt.maxVal;
						break;

					default:
						break;
				}

				if (parseInt(patientAge.min) >= 0 && parseInt(patientAge.max) > 0) {
					filterObj['agemin'] = patientAge.min ? patientAge.min : DEFAULTS.patientAge.minVal;
					filterObj['agemax'] = patientAge.max ? patientAge.max : DEFAULTS.patientAge.maxVal;
				}

				if (patientSex && patientSex.length) {
					filterObj['patientsex'] = patientSex.map(x => x.value).join(',');
				}

				if (surgeon) {
					filterObj['surgeonfilter'] = surgeon.map(x => x.userName).join(',');
				}

				setLocalStorage('filterObjStorage', JSON.stringify(filterObj));
				this.props.setCaseFilterObj(filterObj);
				this.setState(prevState => {
					return {
						disableReset: prevState.isFiltered && prevState.noInputSelected,
						isFiltered: !prevState.noInputSelected,
						disableFilter: prevState.isFiltered && prevState.noInputSelected
					}
				});
			}
		}
	}

	/**
	 * @description function to reset filter form
	 * @param {*}
	 * @memberof CaseFilterComponent
	 */
	resetCaseFilter = () => {
		this.setState({
			surgeryFromDate: '',
			surgeryToDate: '',
			operativeProcedure: '',
			patientSex: '',
			initialState: true,
			patientAge: { min: DEFAULTS.patientAge.min, max: DEFAULTS.patientAge.max },
			preopDeformity: { min: DEFAULTS.preopDeformity.minVal, max: DEFAULTS.preopDeformity.maxVal },
			pelvicTilt: { min: DEFAULTS.pelvicTilt.minVal, max: DEFAULTS.pelvicTilt.maxVal },
			side: '',
			gapsCollectedData:'',
			implant: '',
			surgeon: '',
			errorMessage: '',
			disableReset: true,
			isFiltered: false,
			filterObjStateData: null,
		})
		let filterObj = {
			fromdate: '',
			todate: '',
			proceduretype: '',
			deformityfrom: '',
			deformityto: '',
			implant: '',
			operativeside: '',
			gapsCollected:''

		}
		
		removeLocalStorage('filterObjStateData');
		removeLocalStorage('filterObjStorage');
		setTimeout(() => {
			if (!this.state.disableFilter) {
				this.setState({ disableFilter: true });
				this.props.setCaseFilterObj(filterObj);
			}
		}, 0);
	}
	/**
	 * @description function to show / hide filter block
	 * @param {*}
	 * @memberof CaseFilterComponent
	 */
	showFilter = () => {
		this.state.showFilterBlock ? this.setState({ showFilterBlock: false }) : this.setState({ showFilterBlock: true });
	}

	/**
	* @description Function to handle changes in the range input
	* @param values The min and max value selected in array format
	* @param field The field for which the range is being selected
	* @memberof CaseFilterComponent
	*/
	handleRangeChange = (values, field) => {
		let obj = {};
		if (values.length) {
			obj[field] = {
				min: values[0],
				max: values[1]
			}
			this.setState({ ...obj, initialState: false });
		} else {
			obj[field] = { min: '', max: '' }
			this.setState({ ...obj, initialState: false });
		}
		this.toggleFilterDisable();
	}

	/**
	* @description Function to handle changes in the surgeon multiselect
	* @param selectedList The selected values in array format
	* @memberof CaseFilterComponent
	*/
	handleSurgeonChange = (selectedList) => {
		this.setState({ surgeon: selectedList.length ? selectedList : '' })
		this.toggleFilterDisable();
	}

	/**
	* @description Function to handle changes in the patient sex multiselect
	* @param selectedList The selected values in array format
	* @memberof CaseFilterComponent
	*/
	handlePatientSexChange = (selectedList) => {
		this.setState({ patientSex: selectedList.length ? selectedList : '' })
		this.toggleFilterDisable();
	}

	render() {
		return (
			<div>
				<CaseFilterComponent
					showFilter={this.showFilter}
					onInputChange={this.onInputChange}
					filterCaseList={this.filterCaseList}
					resetCaseFilter={this.resetCaseFilter}
					showFilterBlock={this.state.showFilterBlock}
					disableFilter={this.state.disableFilter}
					disableReset={this.state.disableReset}
					errorMessage={this.state.errorMessage}
					todayDate={this.state.todayDate}
					surgeryFromDate={this.state.surgeryFromDate}
					surgeryToDate={this.state.surgeryToDate}
					operativeProcedure={this.state.operativeProcedure}
					defaults={DEFAULTS}
					initialState={this.state.initialState}
					patientSex={this.state.patientSex}
					handleRangeChange={this.handleRangeChange}
					implants={this.state.implants}
					implantValue={this.state.implant}
					side={this.state.side}
					gapsCollectedData={this.state.gapsCollectedData}
					preopDeformity={this.state.preopDeformity}
					pelvicTilt={this.state.pelvicTilt}
					patientAge={this.state.patientAge}
					surgeons={this.state.surgeons}
					selectedSurgeon={this.state.surgeon}
					handleSurgeonChange={this.handleSurgeonChange}
					patinetSexList={this.state.patinetSexList}
					handlePatientSexChange={this.handlePatientSexChange}
					userRoles={this.props.userRoles}
				/>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		state,
	};
}
export default connect(mapStateToProps)(CaseFilterContainer);
