import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { SurgeonList } from '../RAR/SurgeonList';
import AddSurgeon from "../RAR/AddSurgeon";
import CustomModal from '../../../shared/CustomModal';
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';

class CareTeamComponent extends Component {
    render() {
        const { sinceLastCaseUpload, reviewCaseCount, handleInputChange, findSurgeons, formErrors, searchResults, lastCaseUploadError, reviewCaseCountError, searchResultsError,
            enableAddSurgeonModal, showAddSurgeonModal, isLoading, refreshSurgeonList, refreshDataList, onEnterPress } = this.props;
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Filter: ''
            },
            {
                Header: 'No. of Cases',
                accessor: 'noOfcases',
                Filter: ''
            },
            {
                Header: ' ',
                action: true
            }
        ]
        return (<div className="data-list-tabs">
            <div className="row">
                <div className="col-md-3">
                    <div className="dashboard-blocks">
                        <div className="rar-dashboard-blocks-body careteam-dashboard-block-height d-flex flex-column justify-content-evenly" data-testid="rar-dashboard">
                            {reviewCaseCountError ? <ErrorMsgBlockComponent errorObject={reviewCaseCountError} /> :
                                <>
                                    <div className="rar-dashboard-block-title">{reviewCaseCount || '0'}</div>
                                    <div className="rar-dashboard-block-sub-title">cases flagged for review</div>
                                    <div className="rar-dashboard-btn">
                                        <Link className="btn btn-primary rar-btn-color" id="view-case" to={"/reviewcases"}>Review Cases</Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="dashboard-blocks">
                        <div className="rar-dashboard-blocks-body careteam-dashboard-block-height d-flex flex-column justify-content-evenly">
                            {lastCaseUploadError ? <ErrorMsgBlockComponent errorObject={lastCaseUploadError} /> : <>
                                <div className="rar-dashboard-block-title">{sinceLastCaseUpload}</div>
                                {sinceLastCaseUpload === 'No data uploaded' ? null :
                                    <div className="rar-dashboard-block-sub-title">since last case upload</div>}
                                <div className="rar-dashboard-btn">
                                    <Link className="btn btn-primary rar-btn-color" id="view-case" to={"/upload"}>Upload Cases</Link>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="dashboard-blocks">
                        <div className="rar-dashboard-blocks-body careteam-dashboard-surgeon-list-body d-flex flex-column justify-content-evenly">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="dashboard-block-title">Surgeons</div>
                                <div className="add-surgeon-btn mt-1">
                                    <div className="find-surgeon" data-testid="openSurgeonModal" id="openSurgeonModal" onClick={() => enableAddSurgeonModal(true)}>Find Surgeons {'>>'}</div>
                                </div>
                            </div>
                            <div className="rar-surgeon-list-block careteam-mapping-list">
                                <SurgeonList columns={columns} showPagination={false} disableColmSort={false} listType="surgeon-careteam-map" refreshSurgeonList={refreshSurgeonList} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-surgeon-modal">
                    {/* Modal to add surgeon to mapping list */}
                    <CustomModal
                        id="addSurgeonModal"
                        data-testid="addSurgeonModal"
                        dialogClassName="add-surgeon-dialog"
                        show={showAddSurgeonModal}
                        isComponent={true}
                        isAlert={true}
                        alertText="Close"
                        title="Add Surgeon"
                        closeAction={() => enableAddSurgeonModal(false)}>
                        <AddSurgeon
                            handleInputChange={handleInputChange}
                            action="careTeamSurgeonMap"
                            data-testid="careTeamSurgeonMap"
                            isLoading={isLoading}
                            onEnterPress={onEnterPress}
                            findSurgeons={findSurgeons}
                            searchError={searchResultsError}
                            formErrors={formErrors}
                            searchResults={searchResults}
                            refreshDataList={refreshDataList}
                        />
                    </CustomModal>
                </div>

            </div>

        </div>);
    }
}
const mapStateToProps = (state) => (
    {
        state
    }
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(CareTeamComponent);
