import { React, Component } from 'react'
import RangeInput from '../../../shared/RangeInput';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import PlacementSummaryShared from './PlacementSummaryShared';


class PlacementSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialState: true
        }
    }
    /**
	* @description Function to slide the input range
	* @memberof PlacementSummary
	*/
	handleRangeChange = (values, field) => {
		this.setState({ initialState: false });
		this.props.handleRangeChange(values, field);
	}

    render() {
        const { activePlacementTab, handlePlacementTabs, pelvicTiltRange, pelvicTiltValues, placementData } = this.props;      
        return (
            <div className="dashboard-blocks">
                <div className="dashboard-blocks-body" data-testid="placement-summary">
                    <div className="d-flex flex-column align-items-between justify-content-between h-100">
                        <div className="dashboard-block-title">Placement Summary</div>
                        <label className="fs-9-rem">Pelvic Tilt</label>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center justify-content-between">
                                    <label className="fs-9-rem mb-0">Anterior</label>
                                    <div className="hip-dashboard-tilt-range">
                                        <RangeInput
                                            id="pelvicTilt"
                                            data-testid="pelvicTilt"
                                            step="1"
                                            min={pelvicTiltRange.min}
                                            max={pelvicTiltRange.max}
                                            openRange={true}
                                            showExtremeLabels={true}
                                            minVal={pelvicTiltValues.min}
                                            openMinLabel="< -15"
                                            openMaxLabel="> 15"
                                            maxVal={pelvicTiltValues.max}
                                            reset={this.state.initialState}
                                            handleChange={(values) => this.handleRangeChange(values, 'pelvicTilt')}
                                        />
                                    </div>
                                    <label className="fs-9-rem mb-0">Posterior</label>
                                </div>
                            </div>
                        </div>

                        <div className="quadrant-tabs">
                            <Tab.Container defaultActiveKey={activePlacementTab} onSelect={handlePlacementTabs} >
                                <Tab.Content >
                                    <Tab.Pane eventKey="avg">
                                        <PlacementSummaryShared data={placementData.pelvictiltaverage}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="max">
                                        <PlacementSummaryShared data={placementData.pelvictiltmaximum} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="min">
                                        <PlacementSummaryShared data={placementData.pelvictiltMinimum} />
                                    </Tab.Pane>
                                </Tab.Content>
                                <Nav variant="pills" className="flex-row mt-2">
                                    <Nav.Item>
                                        <Nav.Link eventKey="avg">Average</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="max">Maximum</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="min">Minimum</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        state,
    };
}
export default connect(mapStateToProps)(PlacementSummary);