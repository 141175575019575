import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import AddCustomer from '../../components/CustomerDetails/AddCustomer';
import { customerMessages, loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { validateCustomerFields, validatePhoneNumber, validateAlphanumeric } from './ValidateCustomer';
import { getCountryList, saveCustomerDetails } from '../../services/java/java-services';
import { logger } from '../../services/logger/logger-service';
let startDate;
class AddCustomerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryObj: [],
            stateObj: [],
            dialCodeObj: [],
            customername: '',
            addressline1: '',
            addressline2: '',
            postalcode: '',
            country: '',
            state: '',
            selectedState: '',
            phonenumber: '',
            countrydialcode: '',
            showSuccessMsg: '',
            showErrorMsg: '',
            isLoading: false,
            countryError: null,
            errors: {
                customername: '',
                addressline1: '',
                addressline2: '',
                postalcode: '',
                country: '',
                state: '',
                phonenumber: '',
                countrydialcode: ''
            },
            customerSaveError: null
        }
    }
    componentDidMount() {
        this.getCountryList();
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof AddCustomerContainer
     */
    applicationLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.customerCreate,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description Function to get the country details
     * @param 
     * @memberof CustomerDetailsContainer
     */
    getCountryList = async () => {
        getCountryList((err, result) => {
            /* istanbul ignore next  */
            if (err) {
                this.setState({ countryError: err });
            } else {
                const countryList = result.data && result.data.countryList && result.data.countryList.length ? result.data.countryList : [];
                this.setState({
                    countryObj: countryList,
                    countryError: null
                });
            }
        })
    }

    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field name
     * @memberof AddCustomerContainer
     */
    handleInputChange = (field, value) => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '' });
        var errorMessage = value ? '' : customerMessages[field];
        if (field === 'phonenumber' && value) {
            const phoneCheck = validatePhoneNumber(value);
            errorMessage = phoneCheck.error;
        }
        if (field === 'customername' && value) {
            const customerNameCheck = validateAlphanumeric(value);
            errorMessage = customerNameCheck.error;
        }
        if (field === 'addressline1' && value) {
            const addressline1Check = validateAlphanumeric(value);
            errorMessage = addressline1Check.error;
        }
        if (field === 'addressline2') {
            if (value) {
                const addressline2Check = validateAlphanumeric(value);
                errorMessage = addressline2Check.error;
            } else {
                errorMessage = ''
            }
        }
        if (field === 'postalcode' && value) {
            const postalcodeCheck = validateAlphanumeric(value);
            errorMessage = postalcodeCheck.error;
        }

        if (field === 'country' && value) {
            if (value) {
                let selectedCountryObj = this.state.countryObj.find(cntry => cntry.countryname === (value));
                let selectedCountryStates = selectedCountryObj && selectedCountryObj.states && selectedCountryObj.states.length ? selectedCountryObj.states : [];
                let dialCode = selectedCountryObj && selectedCountryObj.dialcode ? selectedCountryObj.dialcode : null;
                this.setState({ stateObj: selectedCountryStates, dialCodeObj: dialCode ? [dialCode] : [], state: '', selectedState: '', countrydialcode: dialCode });
            } else {
                this.setState({ stateObj: [], dialCodeObj: [], state: '', selectedState: '', countrydialcode: '' });
            }
        }

        this.setState(prevState => {
            return {
                [field]: value,
                errors: {
                    ...prevState.errors,
                    [field]: errorMessage
                }
            }
        });
    }

    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof AddCustomerContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveCustomer();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof AddCustomerContainer
     */
    validateFields = () => {
        const cuurentStateObj = {
            ...this.state,
            state: this.state.selectedState
        }
        let { errorFlag, errors } = validateCustomerFields(cuurentStateObj);
        errors['selectedState'] = errors['state'];
        if (!errorFlag && this.state.customername) {
            let custname = validateAlphanumeric(this.state.customername);
            errorFlag = custname.errorFlag;
        }
        if (!errorFlag && this.state.addressline1) {
            let addressline1 = validateAlphanumeric(this.state.addressline1);
            errorFlag = addressline1.errorFlag;
        }
        if (!errorFlag && this.state.addressline2) {
            let addressline2 = validateAlphanumeric(this.state.addressline2);
            errorFlag = addressline2.errorFlag;
        }
        if (!errorFlag && this.state.postalcode) {
            let postalcode = validateAlphanumeric(this.state.postalcode);
            errorFlag = postalcode.errorFlag;
        }
        if (!errorFlag && this.state.phonenumber) {
            let phonenumber = validatePhoneNumber(this.state.phonenumber);
            errorFlag = phonenumber.errorFlag;
        }
        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }
        return errorFlag;
    }

    /**
     * @description function to validate and save the case details
     * @param {*}
     * @memberof AddCustomerContainer
     */
    saveCustomer = () => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '' , customerSaveError:'' });
        const formHasErrors = this.validateFields();
        let getFieldName = ['customername', 'addressline1', 'postalcode', 'country', 'selectedState', 'phonenumber', 'countrydialcode'];
        var hasValueArry = [];
        getFieldName.forEach(field => {
            if (this.state[field] === "") {
                hasValueArry.push(false);
                return true
            } else {
                hasValueArry.push(true);
            }
        });
        // verify the Mandatory  field values
        let hasValue = hasValueArry.every(v => v === true);
        if (!formHasErrors && hasValue) {
            this.setState({ isLoading: true });
            const getCustomerDetails = {
                "customername": this.state.customername,
                "country": this.state.country,
                "state": this.state.selectedState,
                "countrydialcode": this.state.countrydialcode,
                "addressline1": this.state.addressline1,
                "addressline2": this.state.addressline2,
                "postalcode": this.state.postalcode,
                "phonenumber": this.state.phonenumber
            }
            
            saveCustomerDetails(getCustomerDetails, (err, result) => {
                /* istanbul ignore next  */
                if (err) {
                    let duplicateFieldName = '';
                    let duplicateFieldMsg = '';
                    let showErrorMsg = '';
                    switch (err.errorCode) {
                        case 2022:
                            duplicateFieldName = 'customername';
                            duplicateFieldMsg = 'duplicateCustomerName';
                            showErrorMsg = customerMessages['duplicateProfile'];
                            break;
                        case 2023:
                            duplicateFieldName = 'addressline1';
                            duplicateFieldMsg = 'duplicateAddress';
                            showErrorMsg = customerMessages['duplicateProfile'];
                            break;
                        case 2024:
                            duplicateFieldName = 'phonenumber';
                            duplicateFieldMsg = 'duplicatePhoneNumber';
                            showErrorMsg = customerMessages['duplicateProfile'];
                            break;

                        default:
                            showErrorMsg = err.message;
                            break;
                    }

                    this.setState(prevState => {
                        return {
                            showErrorMsg: showErrorMsg,
                            isLoading: false,
                            customerSaveError: {
                                ...err,
                                message: showErrorMsg
                            },
                            errors: {
                                ...prevState.errors,
                                [duplicateFieldName]: customerMessages[duplicateFieldMsg]
                            }
                        }
                    });
                } else {
                    this.setState({ showSuccessMsg: customerMessages['saveCustomerMsg'], isLoading: false });
                    const getCustomerId = result.data && result.data.customerId ? result.data.customerId : '';
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: `customer/${getCustomerId}`
                        })
                    }, 1000);
                }
            })
        }
    }


    /** @description function to cancel PROM score
     *  @param  {}
     *  @memberof AddCustomerContainer
     */
    cancelAction = () => {
        this.props.cancelAction();
    }

    render() {
        return (
            <AddCustomer
                formErrors={this.state.errors}
                toggleEditMode={this.toggleEditMode}
                handleInputChange={this.handleInputChange}
                onEnterPress={this.onEnterPress}
                saveCustomer={this.saveCustomer}
                cancelAction={this.cancelAction}
                resetStates={this.state.resetStates}
                countryObj={this.state.countryObj}
                country={this.state.country}
                stateObj={this.state.stateObj}
                selectedState={this.state.selectedState}
                dialCodeObj={this.state.dialCodeObj}
                countrydialcode={this.state.countrydialcode}
                showErrorMsg={this.state.showErrorMsg}
                showSuccessMsg={this.state.showSuccessMsg}
                isLoading={this.state.isLoading}
                customerSaveError={this.state.customerSaveError}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default withRouter(connect(mapStateToProps)(AddCustomerContainer));