import React, { Component } from 'react';
import CustomModal from '../../shared/CustomModal';
import AddUserContainer from '../../containers/UserDetails/AddUser';
import UserDetailsContainer from '../../containers/UserDetails';

export default class AddUserModalComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showNewUserModal: false,
			showUserDetailsModal: false,
			userName: '',
			fromDashboard: props.fromDashboard
		}
	}
  /* istanbul ignore next */
   componentDidMount() {
		if (this.props.fromDashboard) {
			this.toggleNewUserModal();
		}
	}

	/**
	 * @description Funtion to toggle new user modal
	 * @memberof AddUserModalComponent
	 */
	toggleNewUserModal = () => {
		this.setState(prevState => {
			return { showNewUserModal: !prevState.showNewUserModal }
		});
	}

	/**
	 * @description Funtion to toggle user details modal
	 * @memberof AddUserModalComponent
	 */
	/* istanbul ignore next  */
	toggleUserDetailsModal = (userName) => {
		this.setState(prevState => {
			return { userName: userName, showUserDetailsModal: !prevState.showUserDetailsModal }
		});
	}

	/**
	 * @description Funtion to explicitly close new user modal
	 * @memberof AddUserModalComponent
	 */
	closeNewUserModal = () => {
		this.setState({ showNewUserModal: false })
	}

	/**
	 * @description Funtion to explicitly close user details modal
	 * @memberof AddUserModalComponent
	 */
	/* istanbul ignore next  */
	closeUserDetailsModal = () => {
		this.setState({ showUserDetailsModal: false })
	}

	/**
	 * @description Funtion to handle situation when user added
	 * @memberof AddUserModalComponent
	 */
	/* istanbul ignore next  */
	userAdded = (userName) => {
		this.closeNewUserModal();
		setTimeout(() => {
			this.toggleUserDetailsModal(userName);
			this.props.userUpdated(true, userName);
		}, 0);
	}

	render() {
		const { showNewUserModal, showUserDetailsModal, userName } = this.state;
		const { userUpdated } = this.props;
		return (
			<>
				<button type="button" data-testid="addUserBtn" id="addUserBtn" className="btn btn-primary mt-3" onClick={(e) => { e.preventDefault(); this.toggleNewUserModal(); }}>Add New</button>
				{/* Modal to add new user */}
				<CustomModal
					id="add-user-modal"
					show={showNewUserModal}
					title="Add New User"
					isComponent={true}
					dialogClassName="user-details-dialog"
					closeAction={this.closeNewUserModal}>
					<AddUserContainer id="add-user-container" handleCancel={this.closeNewUserModal} userAdded={this.userAdded} />
				</CustomModal>

				{/* Modal to show user details */}
				<CustomModal
					id="user-details-modal"
					show={showUserDetailsModal}
					title="User Details"
					isComponent={true}
					dialogClassName="user-details-dialog"
					closeAction={this.closeUserDetailsModal}>
					<UserDetailsContainer
						userName={userName}
						userUpdated={userUpdated}
						handleCancel={this.closeUserDetailsModal}
					/>
				</CustomModal>
			</>
		);
	}
}
