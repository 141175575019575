import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_brokenAxis from "highcharts/modules/broken-axis";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportdata from "highcharts/modules/export-data";
import { graphContextMenuItems } from "../../helpers/constants";
import { exportAddOns } from "../../helpers/ChartExports";
import { logger } from '../../services/logger/logger-service';
import { loggerEventOutcome, loggerEventTypes, loggerEventName, noDataFound } from '../../helpers/messages';
import { getTimingDisclaimer } from "../../helpers/GlobalFunctions";
let startDate;
HC_brokenAxis(Highcharts);
drilldown(Highcharts);
exporting(Highcharts);
exportdata(Highcharts);

class THATimingDetails extends Component {
    constructor() {
        super();
        this.state = {
            pieChart: "",
            timingData: "",
        };
    }

    componentDidMount = async () => {
        this.createPieChart();
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    };

    /* istanbul ignore next  */
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof THATimingDetails
     */
    /* istanbul ignore next  */
    applicationLogger = (key) => {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.caseSummeryTiming,
            "Content": {
                "TimeSpent": timeSinceLoad,
                "CaseId": this.props.caseId ? this.props.caseId : '',
            }
        }
        logger(loggerObj);
    }

    /**
     * @description function to check and create the values to be plotted in the pie chart
     * @param value Raw value coming from the API
     * @memberof THATimingDetails
     */
    getPieChartPlotValues(value) {
        if (isNaN(parseFloat(value))) {
            return null;
        }

        return value;
    }

    /**
     * @description function to Create chart with Highcharts
     * @param {*}
     * @memberof THATimingDetails
     */
    /* istanbul ignore next  */
    createPieChart() {
        var getTimingData = this.props.timingData;

        if (getTimingData && Object.keys(getTimingData).length) {
            // rearrange timing data by surgery order
            const timingDataBySurgeryOrder = {
                "Registration": this.getPieChartPlotValues(getTimingData.registration),
                "Navigation": this.getPieChartPlotValues(getTimingData.navigation)
            };

            this.setState({ timingData: timingDataBySurgeryOrder });
            let timingData = [];
            var totalMin = 0;

            for (const [key, value] of Object.entries(timingDataBySurgeryOrder)) {
                var getMin = parseFloat(value / 60);
				var getMinForSum = value && !isNaN(parseFloat(value)) ? parseFloat((parseFloat(value) / 60).toFixed(1)) : null;
				totalMin += !isNaN(getMinForSum) ? getMinForSum : 0;

                var dataDict = {
                    name: key,
                    y: parseFloat(getMin),
                    drilldown: key,
                }; // Convert from second to minute
                timingData.push(dataDict);
            }

            /* istanbul ignore next  */
            var pieChartConfig = {
                exporting: {
                    sourceWidth: 900,
                    sourceHeight: 350,
                    buttons: {
                        contextButton: {
                            menuItems: graphContextMenuItems
                        },
                    },
                    chartOptions: {
                        chart: {
                            marginTop: 50,
                            marginBottom: 50,
                            spacingBottom: 60,
                            spacingRight: 10,
                            events: {
                                load: function () {
                                    exportAddOns(this);
                                }
                            }
                        },
                        title: {
                            y: 35,
                            style: {
                                fontSize: 12
                            }
                        },
                        legend: {
                            width: '13%',
                            y: 20
                        },
                        plotOptions: {
                            pie: {
                                size: 200,
                                innerSize: 100,
                                dataLabels: {
                                    connectorWidth: 0.5,
                                    x: 0,
                                    y: 4,
                                    style: {
                                        fontSize: 6,
                                    }
                                }
                            }
                        },
                    }
                },
                chart: {
                    type: "pie",
                    spacingRight: 50,
                    events: {
                        load: function () {
                            this.reflow();
                        }
                    }
                },
                plotOptions: {
                    pie: {
                        center: ["50%", "50%"],
                        colors: ["#45b864", "#e2333b"],
                        innerSize: 150,
                        showInLegend: true
                    },
                },
                tooltip: {
                    enabled: true,
                    formatter: function () {
                        return (
                            "<b>" +
                            this.point.name +
                            "</b>: " +
                            parseFloat(this.point.y).toFixed(1) +
                            " MINUTES (" +
                            Math.round(Highcharts.numberFormat(this.percentage, 2)) +
                            "%)"
                        );
                    },
                },
                legend: {
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    floating: true,
                    backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                    borderWidth: 1
                },
                title: {
                    text: "TOTAL  <br>" + parseFloat(totalMin).toFixed(1) + " Min",
                    align: "center",
                    verticalAlign: "middle",
                    y: 30,
                    x: 0,
                    style: {
                        fontWeight: "bold",
                    },
                },
                subtitle: {
                    text: "Case Time",
                    style: {
                        fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
                        color: '#333333',
                        fontSize: 18,
                        textAlign: 'center',
                    }
                },
                series: [
                    {
                        name: "Timing Data",
                        data: timingData,
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return parseFloat(this.point.y).toFixed(1);
                            },
                        },
                    },
                ],
                responsive: {
                    rules: [{
                        condition: {
                            minWidth: 901,
                            maxWidth: 1200
                        },
                        chartOptions: {
                            legend: {
                                width: '12%'
                            }
                        }
                    }]
                }
            };

            this.setState({ pieChart: pieChartConfig });
        }
    }

    /**
     * @description function to get template when there is no data to display
     * @param {*}
     * @memberof THATimingDetails
     */
    noDataTemplate(chartName) {
        return (
            <div className="chart-blocks">
                <h3 className="text-center">{chartName}</h3>
                <div className="text-center text-danger mb-4 mt-4">{noDataFound.noData}</div>
            </div>
        )
    }

    render() {
        const { timingData } = this.props;
        const { pieChart } = this.state;
        const styleObj = { style: { height: '400px' } };

        return (
            <div className="timing-data-display">
                {this.state.pieChart && timingData && Object.keys(timingData).length ?
                    <>
                        <HighchartsReact highcharts={Highcharts} options={pieChart} containerProps={styleObj} />
                        <div className="ms-4">{getTimingDisclaimer()}</div>
                    </>
                    : this.noDataTemplate('Case Time')}
            </div>
        );
    }
}
export default THATimingDetails;
