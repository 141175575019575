import React, { Component } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header';
import { connect } from 'react-redux';
import { actions } from '../../helpers/messages';
import { Link } from 'react-router-dom';
import { convertUTCToLocalDateTime, isPositiveNumber } from '../../helpers/GlobalFunctions';
import CustomModal from '../../shared/CustomModal';
import { AssociatedCustomerList } from './AssociatedCustomerList';
import AssociateCustomer from './AssociateCustomer';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';

class SurgeonDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.myFormRef = React.createRef();
	}

	/**
	 * @description Function to disable edit mode while restting the form
	 * @memberof SurgeonDetailsComponent
	 */
	/* istanbul ignore next  */
	cancelEdit = () => {
		this.myFormRef.reset();
		this.props.toggleEditMode();
	}

	/**
	 * @description Function to make sure only numbers are input
	 * @memberof SurgeonDetailsComponent
	 */
	/* istanbul ignore next  */
	checkNumeric = e => {
		const isValid = isPositiveNumber(e);
		if (!isValid) {
			e.preventDefault();
			return false;
		}

		if (e.keyCode === 13) {
			this.props.onEnterPress(e);
		}
	}

	render() {
		const { surgeon, loading, editMode, toggleEditMode, editObj, handleInputChange, onEnterPress, showSuccessMsg, showErrorMsg, formErrors, saveSurgeon,
			customers, refreshCustomer, handleSearchInputChange, onSearchEnterPress, findCustomers, searchResults, refreshDataList, enableAddCustomerModal,
			showAddCustomerModal, surgeonError, surgeonCustomerError, searchCustomerError, searchListLoading } = this.props;
		const columns = [
			{
				Header: 'Name',
				accessor: 'name',
				Filter: ''
			},
			{
				Header: 'Address',
				accessor: 'combinedAddress',
				Filter: ''
			},
			{
				Header: 'Phone No',
				accessor: 'phoneNumber',
				Filter: ''
			},
			{
				Header: 'Customer ID',
				accessor: 'customerid',
				Filter: ''
			},
			{
				Header: ' ',
				AssociatedCustomerAction: true
			}
		];
		return (
			<>
				{loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : ''}
				<div>
					<Header />
					<div className="bgGray">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-1">
									<SideMenu />
								</div>
								<div className="col-md-11">
									<div className="device-list data-list-tabs review-case-details-wrap">
										<div className="case-details-top-block align-items-center justify-content-between">
											<h1 className="case-details-heading">Surgeon Profile</h1>
											<div className="case-details-back-btn text-end">
												<Link className="btn btn-primary case-action-btn" id="view-case" to={"/surgeonlist"}> Back to Surgeon List</Link>
											</div>
										</div>

										{loading ? '' : surgeon ?
											<div className="customer-fields surgeon-details">
												<div className="case-details-container">
													<div className="card-body">
														<h2 className="card-title pb-2">Surgeon Details</h2>
														<form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
															<div className="row">
																{showSuccessMsg ?
																	<div className="col-sm-12">
																		<div className="customer-save-success-msg">
																			<p>{showSuccessMsg}</p>
																		</div>
																	</div>
																	: ''}
																{showErrorMsg ?
																	<div className="col-sm-12">
																		<div className="customer-save-error-msg">
																			<p>{showErrorMsg}</p>
																		</div>
																	</div>
																	: ''}
															</div>
															<fieldset className="customer-fields" disabled={!editMode}>
																<div className="row">
																	<div className="col-sm-4">
																		<div className="form-group row">
																			<label className="col-sm-4 col-form-label">First Name{editMode ? <span className="red">*</span> : ''}</label>
																			<div className="col-sm-8">
																				<input
																					type="text"
																					id="firstName"
																					autoComplete="off"
																					className={`form-control ${formErrors && formErrors.firstName ? 'has-error' : ''}`}
																					value={editObj && editObj.firstName ? editObj.firstName : ''}
																					onChange={(e) => handleInputChange('firstName', e.target.value)}
																				/>
																				{formErrors && formErrors.firstName ?
																					<div className="customer-error-msg">
																						<p>{formErrors.firstName}</p>
																					</div>
																					: ''}
																			</div>
																		</div>
																	</div>
																	<div className="col-sm-4">
																		<div className="form-group row">
																			<label className="col-sm-4 col-form-label shrink-label">Last Name{editMode ? <span className="red">*</span> : ''}</label>
																			<div className="col-sm-8">
																				<input
																					type="text"
																					id="lastName"
																					autoComplete="off"
																					className={`form-control ${formErrors && formErrors.lastName ? 'has-error' : ''}`}
																					value={editObj && editObj.lastName ? editObj.lastName : ''}
																					onChange={(e) => handleInputChange('lastName', e.target.value)}
																				/>
																				{formErrors && formErrors.lastName ?
																					<div className="customer-error-msg">
																						<p>{formErrors.lastName}</p>
																					</div>
																					: ''}
																			</div>
																		</div>
																	</div>
																	<div className="col-sm-4">
																		<div className="form-group row">
																			<label className="col-sm-4 col-form-label shrink-label">Email{editMode ? <span className="red">*</span> : ''}</label>
																			<div className="col-sm-8">
																				<input
																					type="email"
																					id="email"
																					autoComplete="off"
																					className={`form-control ${formErrors && formErrors.email ? 'has-error' : ''}`}
																					value={editObj && editObj.email ? editObj.email : ''}
																					onChange={(e) => handleInputChange('email', e.target.value)}
																				/>
																				{formErrors && formErrors.email ?
																					<div className="customer-error-msg">
																						<p>{formErrors.email}</p>
																					</div>
																					: ''}
																			</div>
																		</div>
																	</div>
																</div>
															</fieldset>
															<div className="row">
																<div className="col-sm-4">
																	<fieldset className="customer-fields" disabled={!editMode}>
																		<div className="form-group row">
																			<label className="col-sm-4 col-form-label">Surgeon ID{editMode ? <span className="red">*</span> : ''}</label>
																			<div className="col-sm-8">
																				<input
																					type="text"
																					id="surgeonID"
																					autoComplete="off"
																					maxLength="10"
																					className={`form-control ${formErrors && formErrors.surgeonID ? 'has-error' : ''}`}
																					value={editObj && editObj.surgeonID ? editObj.surgeonID : ''}
																					onKeyDown={e => this.checkNumeric(e)}
																					onChange={(e) => handleInputChange('surgeonID', e.target.value)}
																				/>
																				{formErrors && formErrors.surgeonID ?
																					<div className="customer-error-msg">
																						<p>{formErrors.surgeonID}</p>
																					</div>
																					: ''}
																			</div>
																		</div>
																	</fieldset>
																</div>

																<div className="col-sm-4">
																	<div className="form-group row">
																		<label className="col-sm-4 col-form-label shrink-label">Username</label>
																		<div className="col-sm-8">
																			<span className="form-control disabled">{surgeon.userName || 'N/A'}</span>
																		</div>
																	</div>
																</div>
																<div className="col-sm-4">
																	<div className="form-group row">
																		<label className="col-sm-4 col-form-label shrink-label">Country</label>
																		<div className="col-sm-8">
																			<span className="form-control disabled">{surgeon.country || 'N/A'}</span>
																		</div>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-sm-4">
																	<div className="form-group row">
																		<label className="col-sm-4 col-form-label">Date Created</label>
																		<div className="col-sm-8">
																			<span className="form-control disabled">{surgeon.userCreatedDate ? convertUTCToLocalDateTime(surgeon.userCreatedDate) : 'N/A'}</span>
																		</div>
																	</div>
																</div>
																<div className="col-sm-4">
																	<div className="form-group row">
																		<label className="col-sm-4 col-form-label shrink-label">Last Seen</label>
																		<div className="col-sm-8">
																			<span className="form-control disabled">{surgeon.lastLogin ? convertUTCToLocalDateTime(surgeon.lastLogin) : 'N/A'}</span>
																		</div>
																	</div>
																</div>
																<div className="col-sm-4">
																	<div className="form-group row">
																		<label className="col-sm-4 col-form-label shrink-label">Last Password Reset</label>
																		<div className="col-sm-8">
																			<span className="form-control disabled">{surgeon.lastPasswordModified ? convertUTCToLocalDateTime(surgeon.lastPasswordModified) : 'N/A'}</span>
																		</div>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-sm-12 text-end">
																	{!editMode ? <button type="button" id="edit-btn" className="btn btn-primary case-action-btn me-3" onClick={toggleEditMode}>Edit</button> : ''}
																	{editMode ?
																		<>
																			<button type="button" id="save-btn" className="btn btn-primary case-action-btn" onClick={saveSurgeon}>Save</button>
																			<button type="button" id="cancel-btn" className="btn btn-secondary ms-2" onClick={this.cancelEdit}>Cancel</button>
																		</>
																		: ''}
																</div>
															</div>
														</form>
													</div>
												</div>

												<div className="case-details-container">
													<div className="card-body mt-4">
														<div className="d-flex align-items-center justify-content-between mb-2">
															<h2 className="card-title pb-2 mb-0">Associated Customers</h2>
															<div className="btn btn-primary" id="openCustomerModal" onClick={(e) => enableAddCustomerModal(true)}>Add New</div>
														</div>

														<div className="customer-surgeon-list table-wrap">
															<AssociatedCustomerList
																listType="surgeon-customers"
																columns={columns}
																showPagination={false}
																disableColmSort={false}
																searchData={customers}
																surgeonUsername={surgeon.userName}
																error={surgeonCustomerError}
																refreshCustomer={refreshCustomer}
																refreshDataList={false}
															/>
														</div>
													</div>
												</div>
											</div>
											:
											<div className="customer-fields">
												<div className="case-details-container">
													<div className="card-body">
														{surgeonError ? <ErrorMsgBlockComponent errorObject={surgeonError} /> : <ErrorMsgBlockComponent noData={true} />}
													</div>
												</div>
											</div>
										} 
										<div className="add-customer-modal">
											{/* Modal to add customer to mapping list */}
											<CustomModal
												id="addCustomerModal"
												dialogClassName="add-surgeon-dialog"
												show={showAddCustomerModal}
												isComponent={true}
												isAlert={true}
												alertText="Close"
												title="Add Customer"
												closeAction={() => enableAddCustomerModal(false)}>
												<AssociateCustomer
													handleInputChange={handleSearchInputChange}
													isLoading={searchListLoading && !searchResults}
													onEnterPress={onSearchEnterPress}
													action="adminSurgeonCustomerMap"
													findCustomers={findCustomers}
													formErrors={formErrors}
													searchResults={searchResults}
													searchError={searchCustomerError}
													refreshDataList={refreshDataList}
													surgeonUsername={surgeon && surgeon.userName}
												/>
											</CustomModal>

										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SurgeonDetailsComponent);