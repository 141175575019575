import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DataTable } from "../../DataTable/DataTable";
import { getLicenseTypeName, getLoadingTemplate } from "../../../helpers/GlobalFunctions";
import ErrorMsgBlockComponent from "../../Error/ErrorMessageBlock";

class CustomerSupportComponent extends Component {
  render() {
    
    const { loading, activePlansErr, totalActivePlansCount } = this.props;
    
    /* istanbul ignore next */
    const columns = [
      {
        Header: "Customer Name",
        accessor: "customername",
        Filter: "",
        Cell: ({ row }) => <div className='text-capitalize'>{row.original.customername}</div>,
      },
      {
        Header: "Type",
        accessor: "licensetype",
        Cell: ({ row }) => row.original.licensetype ? getLicenseTypeName(row.original.licensetype) : '',
        Filter: "",
      },
      {
        Header: "Date of Expiry",
        accessor: "enddate",
        Filter: "",
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: "",
      },
    ];

		let content;
		/* istanbul ignore next */
		if (loading) {
			content = getLoadingTemplate();
		} else if (totalActivePlansCount) {
			content = <h2 className="device-count">{totalActivePlansCount}</h2>;
		} else if (activePlansErr) {
			content = <ErrorMsgBlockComponent errorObject={activePlansErr} />;
		} else {
			content = <ErrorMsgBlockComponent noData={true} />;
		}


		return (
			<div className="data-list-tabs">
				<div className="row">
					<div className="col-md-3">
						<div className="dashboard-blocks">
							<div className="dashboard-blocks-body">
								<div className="d-flex flex-column align-items-between justify-content-between h-100">
									<div className="dashboard-block-title">Active Plans</div>
									<div className="text-center">
										{content}
									</div>
									<Link
										className="btn btn-primary rar-btn-color"
										id="view-devices"
										to={"/preOpPlans?activeAll=true"}
									>
										View All
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="dashboard-blocks">
							<div className="rar-dashboard-blocks-body rar-dashboard-surgeon-list-body license-body">
								<div className="dashboard-block-title mb-4">
									Expiration List
								</div>
								{
									/* istanbul ignore next */
									loading ? (
										""
									) : (
										<DataTable
											listType="license-expiry-list"
											columns={columns}
											showPagination={false}
											disableColmSort={false}
											viewActionType="customersupport"
										/>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	state,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomerSupportComponent);
