import React from 'react';

function AlertMessage(props) {
    return (
        <div className={`alert alert-danger ${props.className}`}>
            {props.errorMessage}
        </div>
    )
}

export default AlertMessage;