import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserListComponent from '../../components/UserList/UserList';

class UserListContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fromDashboard: (props.location && props.location.state && props.location.state.fromDashboard === true),
			refreshUserList: '',
			loading: false,
			newUserName: '',
			searchString: '',
			searchSubmit: '',
		}
	}

	componentDidMount() {

	}

	/** 
	 * 	@description Function to handle search submit
	 *  @param searchString The value in the input field
	 *  @memberof UserListContainer
	 */
	handleSearchSubmit = (searchString) => {
		this.setState({ searchString: searchString, searchSubmit: true, refreshUserList: '' });
	}

	/** 
	 *  @description Function to handle search reset
	 *  @memberof UserListContainer
	 */
	handleReset = () => {
		this.setState({ searchString: '', searchSubmit: '', refreshUserList: '' });
	}

	/** 
	 *  @description Function to handle user updated after creation
	 *  @memberof UserListContainer
	 */
	userUpdated = (status, username = '') => {
		this.setState({ refreshUserList: status, newUserName: username });
	}

	/** 
	 *  @description Function to handle user status updated after creation
	 *  @memberof UserListContainer
	 */
	userStatusUpdated = (status) => {
		this.setState({ refreshUserList: status, searchSubmit: true });
	}

	/** 
	 *  @description Function to toggle loader from sub components
	 *  @memberof UserListContainer
	 */
	toggleLoader = (status) => {
		this.setState({ loading: status, refreshUserList: false, searchSubmit: false });
	}

	render() {
		return (
			<UserListComponent
				refreshUserList={this.state.refreshUserList}
				loading={this.state.loading}
				toggleLoader={this.toggleLoader}
				searchString={this.state.searchString}
				searchSubmit={this.state.searchSubmit}
				handleSearchSubmit={this.handleSearchSubmit}
				handleReset={this.handleReset}
				userUpdated={this.userUpdated}
				userStatusUpdated={this.userStatusUpdated}
				newUserName={this.state.newUserName}
				fromDashboard={this.state.fromDashboard}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(UserListContainer);