import React, { Component } from 'react';
import THADashboardComponent from '../../components/Dashboard/THADashboard/THADashboard';
import { caseAnalyticsTimingDataService, totalGlobalCasesService, totalOutcomeScoreService, getPelvictiltResultService } from '../../services/java/java-services';
import { outcomeScoreMessage } from '../../helpers/messages';
import { operativeProcedureTypes } from '../../helpers/constants';
const DEFAULTS = {
	pelvicTilt: { min: -16, max: 16, minVal: -16, maxVal: 16 },
}
export default class THADashboardContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			averageCaseTime: '',
			totalCasesDetails: '',
			totalTimeData:'',
			totalCasesForAvg: '',
			totalCasesForMax: '',
			totalCasesForMin: '',
			totalGlobalCasesCount: '',
			placementData: null,
			scoreType: '', // default scoretype
			timeInterval: '', // default timeinterval
			defaultTimeInterval: '6 weeks',
			defaultScoreType: 'HOOS',
			activeTab: 'avg', // default activetab
			activePlacementTab: 'avg', // default activePlacementTab
			selectedSurgeonId: props.surgeonId ? props.surgeonId : '',
			outcomeError: null,
			avgCasetimeError: null,
			globalCaseError: null,
			placementError: null,
			outcomeLoading: false,
			avgCasetimeLoading: false,
			globalCaseLoading: false,
			placementLoading: false,
			pelvicTilt: { min: DEFAULTS.pelvicTilt.minVal, max: DEFAULTS.pelvicTilt.maxVal },
			initialState: false,
		}

	}

	componentDidMount() {
		this.getTotalOutcomeScore({ type: 'default' });
		this.getAverageCaseTime({ type: 'avg', daterange: this.props.timeFrameValue });
		this.getTotalGlobalCases({ type: 'avg', daterange: this.props.timeFrameValue });
		this.getPelvicTiltResults({ daterange: this.props.timeFrameValue });
	}

	componentDidUpdate(prevProps) {
		if (this.props.surgeonDetails !== prevProps.surgeonDetails) {
			this.handleSurgeonChange();
		}
		if (this.props.timeFrameValue !== prevProps.timeFrameValue) {
			this.handleTimeFrameChange();
		}
	}

	/**
	 * @description function to get Average Case Time based on the timeframe input
	 * @param {*}
	 * @memberof THADashboardContainer
	 */
	getAverageCaseTime = (params) => {
		params['operativeProcedure'] = operativeProcedureTypes.HIP.raw;
		this.setState({ averageCaseTime: '', avgCasetimeError: null, avgCasetimeLoading: true,totalTimeData:'' });

		if (this.props.isRarView && this.props.surgeonDetails) {
			params['surgeonname'] = this.props.surgeonDetails.userName;
		}

		caseAnalyticsTimingDataService(params, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ avgCasetimeError: err, avgCasetimeLoading: false });
			} else {
				this.setState({ avgCasetimeLoading: false, averageCaseTime: result.data.case[0] ? result.data.case[0] : [],totalTimeData: result.data.totalTimeData ? result.data.totalTimeData: []});
			}
		})
	}

	/**
	 * @description function to get  total and global Case count based on the timeframe input
	 * @param {*}
	 * @memberof THADashboardContainer
	 */
	getTotalGlobalCases = (params) => {
		params['operativeProcedure'] = operativeProcedureTypes.HIP.raw;
		this.setState({ totalGlobalCasesCount: '', globalCaseError: null, globalCaseLoading: true });

		if (this.props.isRarView && this.props.surgeonDetails) {
			params['surgeonname'] = this.props.surgeonDetails.userName;
		}

		totalGlobalCasesService(params, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ globalCaseError: err, globalCaseLoading: false });
			} else {
				this.setState({ globalCaseLoading: false, totalGlobalCasesCount: result.data.case[0] ? result.data.case[0] : '' });
			}
		})
	}

	/**
	* @description function to get  total and global Case count in the outcome score
	* @param {*}
	* @memberof THADashboardContainer
	*/
	getTotalOutcomeScore = (params) => {
		params['operativeProcedure'] = operativeProcedureTypes.HIP.raw;
		this.setState({ totalCasesDetails: '', isLoading: true, outcomeError: null, outcomeLoading: true });

		if (this.props.isRarView && this.props.surgeonDetails) {
			params['surgeonname'] = this.props.surgeonDetails.userName;
		}

		totalOutcomeScoreService(params, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				this.setState({ isLoading: false, outcomeError: err, outcomeLoading: false });
			} else {
				const dataSet = result.data?.case?.length ? result.data.case.pop() : null;
				this.setState(prevState => {
                    return {
                        defaultScoreType: dataSet?.scoreType || prevState.defaultScoreType,
                        scoreType: dataSet?.scoreType || prevState.scoreType,
                        totalCasesDetails: dataSet,
                        isLoading: false,
                        outcomeLoading: false
                    }
                });
			}
		})
	}

	/**
	* @description Function to handle score type or time interval change for outcome scores quadrant
	* @param e The change event for the select input
	* @param type The score type selected
	* @memberof THADashboardContainer
	*/
	handleOutcomeScoreValueChange = (e, type) => {
		if (type === 'scoreType') {
			this.setState({ scoreType: e.target.value });
			this.getTotalOutcomeScore({ scoreType: e.target.value });
		}
	}

	/**
	* @description Function to handle tab change for outcome scores quadrant
	* @param key The identifier of the selected tab
	* @memberof THADashboardContainer
	*/
	handleTabs = (key) => {
		if (key) {
			this.setState({ activeTab: key });
		}
	}

	/**
	* @description Function to handle tab change for pelvic tilt quadrant
	* @param key The identifier of the selected tab
	* @memberof THADashboardContainer
	*/
	handlePlacementTabs = (key) => {
		if (key) {
			this.setState({ activePlacementTab: key });
		}
	}

	/**
	* @description function to get handle change of timeframe
	* @param {*}
	* @memberof THADashboardContainer
	*/
	handleTimeFrameChange = () => {
		this.getAverageCaseTime({ type: 'avg', daterange: this.props.timeFrameValue });
		this.getTotalGlobalCases({ type: 'avg', daterange: this.props.timeFrameValue });
		this.getPelvicTiltResults({ daterange: this.props.timeFrameValue });

	}

	/**
	* @description function to get handle change of surgeon selection by RAR
	* @param {*}
	* @memberof THADashboardContainer
	*/
	handleSurgeonChange = (e) => {
		this.handleTimeFrameChange();
		this.getTotalOutcomeScore({ type: 'default' });
	}

	/**
	* @description Function to fetch results bsed on filters
	* @memberof THADashboardContainer
	*/
	getPelvicTiltResults(params) {
		const { pelvicTilt } = this.state;

		var filterObj = {
			...params,
			pelvicTiltFrom: pelvicTilt.min !== '' ? pelvicTilt.min : DEFAULTS.pelvicTilt.min,
			pelvicTiltTo: pelvicTilt.max !== '' ? pelvicTilt.max : DEFAULTS.pelvicTilt.max
		}

		if (parseInt(pelvicTilt.min) >= 0 && parseInt(pelvicTilt.max) > 0) {
			filterObj['pelvicTiltFrom'] = pelvicTilt.min ? pelvicTilt.min : DEFAULTS.pelvicTilt.min;
			filterObj['pelvicTiltTo'] = pelvicTilt.max ? pelvicTilt.max : DEFAULTS.pelvicTilt.max;
		}
		
		if (this.props.isRarView && this.props.surgeonDetails) {
			filterObj['surgeonname'] = this.props.surgeonDetails.userName;
		}

		this.setState({ placementLoading: true })

		getPelvictiltResultService(filterObj, (err, result) => {
			if (err) {
				this.setState({ placementLoading: false, placementError: err, placementData: null })
			} else {
				const placementData = result && result.data && result.data.case && result.data.case.length ? result.data.case[0].pelvictiltsummary : null;
				this.setState({
					placementLoading: false,
					placementError: null,
					placementData: placementData
				})
			}

		})
	}
	/**
	* @description Function to slide the input range
	* @memberof THADashboardContainer
	*/
	handleRangeChange = (values, field) => {
		let obj = {};
		if (values.length) {
			obj[field] = {
				min: values[0],
				max: values[1]
			}
		} else {
			obj[field] = { min: DEFAULTS.pelvicTilt.min, max: DEFAULTS.pelvicTilt.max }
		}
		
		this.setState({ ...obj, initialState: false });
		// Allowing time for the state to be set before calling the API
		setTimeout(() => {
			if (field === 'pelvicTilt') {
				this.getPelvicTiltResults({ daterange: this.props.timeFrameValue });
			}
		}, 0);
	}

	render() {
		const { isRarView, surgeons, surgeonDetails, timeFrameValue } = this.props;
		return (
			<div >
				<THADashboardComponent
					timeFrameValue={timeFrameValue}
					averageCaseTime={this.state.averageCaseTime}
					totalTimeData={this.state.totalTimeData}
					totalGlobalCasesCount={this.state.totalGlobalCasesCount}
					timeFrameChange={this.handleTimeFrameChange}
					handleOutcomeScoreValueChange={this.handleOutcomeScoreValueChange}
					totalCasesDetails={this.state.totalCasesDetails}
					defaultScoreType={this.state.scoreType ? this.state.scoreType : this.state.defaultScoreType}
					isLoading={this.state.isLoading}
					activeTab={this.state.activeTab}
					handleTabs={this.handleTabs}
					outcomeScoreMessage={outcomeScoreMessage}
					isRarView={isRarView}
					surgeons={surgeons}
					surgeonId={this.state.selectedSurgeonId}
					surgeonDetails={surgeonDetails}
					outcomeError={this.state.outcomeError}
					avgCasetimeError={this.state.avgCasetimeError}
					globalCaseError={this.state.globalCaseError}
					placementError={this.state.placementError}
					outcomeLoading={this.state.outcomeLoading}
					avgCasetimeLoading={this.state.avgCasetimeLoading}
					globalCaseLoading={this.state.globalCaseLoading}
					placementData={this.state.placementData}
					activePlacementTab={this.state.activePlacementTab}
					handlePlacementTabs={this.handlePlacementTabs}
					placementLoading={this.state.placementLoading}
					pelvicTiltValues={this.state.pelvicTilt}
					pelvicTiltRange={DEFAULTS.pelvicTilt}
					handleRangeChange={this.handleRangeChange}
				/>
			</div>
		);
	}
}
