import React, { Component } from 'react';
import { connect } from 'react-redux';
import AnalyticsBarChart from './AnalyticsBarChart';
import ChartDetails from './ChartDetails.json';
import { trendsAlert } from '../../helpers/messages';
import { getLoadingTemplate } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import TKAScatterChartsComponent from './TKAScatterCharts';
import THAScatterChartsComponent from './THAScatterCharts';
import { operativeProcedureTypes } from '../../helpers/constants';

class CaseAnalyticsComponent extends Component {
	/**
	 * @description Function to define template for no data scenario
	 * @returns Template showing no data text
	 * @memberof CaseAnalyticsComponent
	 */
	noDataTemplate(chartName) {
		return (
			<div className="chart-blocks">
				<h3 className="text-center">{chartName}</h3>
				<ErrorMsgBlockComponent noData={true} />
			</div>
		)
	}

	/**
	 * @description Function to define template for error scenario
	 * @returns Template showing error message
	 * @memberof CaseAnalyticsComponent
	 */
	errorTemplate(chartName, errorObj) {
		return (
			<div className="chart-blocks">
				<h3 className="text-center">{chartName}</h3>
				<ErrorMsgBlockComponent errorObject={errorObj} />
			</div>
		)
	}

	/**
	 * @description Function to get charts based on procedure type
	 * @returns Template for all charts
	 * @memberof CaseAnalyticsComponent
	 */
	getScatterTemplate() {
		const { selectedProcedure, chartList, allAnalyticsData, analyticsError } = this.props;
		switch (selectedProcedure) {
			case operativeProcedureTypes.TKA.raw:
				return <TKAScatterChartsComponent chartList={chartList} allAnalyticsData={allAnalyticsData} analyticsError={analyticsError} />;
			case operativeProcedureTypes.HIP.raw:
				return <THAScatterChartsComponent chartList={chartList} allAnalyticsData={allAnalyticsData} analyticsError={analyticsError} />;
		
			default:
				break;
		}
	}

	render() {
		const { selectedProcedure, totalCount, timingData, timingError, allAnalyticsData, analyticsError } = this.props;
		
		return (
			<div className="case-analytics-block">
				{totalCount > 30 ? <div className="col-sm-12 alert alert-danger">{trendsAlert.info}</div> : null}

				{/* Case Timing */}
				{timingData ?
					(timingData.caseInfo && timingData.caseInfo.length ?
						<AnalyticsBarChart selectedProcedure={selectedProcedure} timingData={this.props.timingData} chartDetails={ChartDetails.timingVsCaseNumber} />
						: this.noDataTemplate(ChartDetails.timingVsCaseNumber.graphTitle))
					: timingError ? this.errorTemplate(ChartDetails.timingVsCaseNumber.graphTitle, timingError) : getLoadingTemplate()}

				{allAnalyticsData || analyticsError ? this.getScatterTemplate() : getLoadingTemplate()}
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(CaseAnalyticsComponent);
