import React, { Component } from 'react';
import BillOfMaterials from '../../components/BillOfMaterials/BillOfMaterials';
import environment from '../../config/environment';
import { getSSMParamters } from '../../services/aws/aws-services';

export default class BillOfMaterialsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            materials: null
        }
    }

    componentDidMount() {
        this.getBOM();
    }
    
    /**
	 * @description Function to get fetch microservice versions from SSM Param Store
	 * @memberof BillOfMaterialsContainer
	 */
    getBOM = () => {
        this.setState({ loading: true });
        getSSMParamters(environment.BOMKeys).then(res => {
            const allParams = res.Parameters && res.Parameters.length ? res.Parameters : null;
            var materials = [
                { name: 'Case', id: 'case-service', version: '' },
                { name: 'User', id: 'users-service', version: '' },
                { name: 'Notification', id: 'notification-service', version: '' },
                { name: 'Procedure Metadata', id: 'procedure-metadata', version: '' },
                { name: 'Hip Data Process', id: 'hipdataprocess-service', version: '' },
                { name: 'FAQ', id: 'faq-service', version: '' },
                { name: 'Logger', id: 'logger-service', version: '' },
                { name: 'NSYNC', id: 'kpop-nsync-service', version: '' },
                { name: 'File Transfer', id: 'file-transfer-service', version: '' }
            ]
            if (allParams) {
                allParams.forEach(param => {
                    let material = materials.find(x => param.Name.toString().includes(x.id));
                    if (material) {
                        material.version = param.Value;
                    }
                });
            }
            this.setState({ loading: false, materials });
        }).catch(err => {
            this.setState({ loading: false, error: err });
        })
    }

    render() {
        const { loading, materials, error } = this.state;
        return (
            <BillOfMaterials loading={loading} materials={materials} error={error} />
        )
    }
}
