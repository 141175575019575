import React, { Component } from 'react';
import { DataTable } from '../DataTable/DataTable';
import CSVSurgeonContainer from '../../containers/SurgeonList/CSVSurgeonContainer';
import { getUserRoleType } from '../../services/aws/aws-services';


export default class SurgeonListTable extends Component {
    constructor() {
        super()
        this.state = {
            activeUserRole: getUserRoleType()
        }
    }
    render() {
        const isAdmin = this.state.activeUserRole.includes('admin');
        const { fromDashboard, handleSearchSubmit, handleReset, refreshSurgeonListTable, searchString, searchSubmit } = this.props;

        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }) => (<span>{row.original.name ? row.original.name : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Username',
                accessor: 'userName',
                Cell: ({ row }) => (<span>{row.original.userName ? row.original.userName : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({ row }) => (<span>{row.original.email ? row.original.email : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Surgeon ID',
                accessor: 'surgeonId',
                Cell: ({ row }) => (<span>{row.original.surgeonId ? row.original.surgeonId : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: ' ',
                surgeonAction: isAdmin 
            }

        ];
        return (
            <>
                <CSVSurgeonContainer 
                fromDashboard={fromDashboard}
                handleReset={handleReset}
                handleSearchSubmit={handleSearchSubmit}/>
                
                <DataTable listType="surgeon-list" showPagination={true}
                 columns={columns}
                 searchString={searchString && searchSubmit ? searchString : ''}
                 refreshDataList={searchSubmit || refreshSurgeonListTable} 
                 />
            </>
        );
    }
}