import React from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { getSurgeonListService, mapSurgeonsService, removeSurgeonService, mapCustomerSurgeonsService, deleteSurgeonService, mapCustomerCareteamService, mapSurgeonsCareTeamService, getSurgeonListCareTeamService, removeCareteamService, deleteCareteamService, mapSalesRepCustomerService } from "../../../services/java/java-services";
import { surgeonMapping, actions, customerMessages, loggerEventName, careteamMapping, careTeamMessages, salesRepMapping } from '../../../helpers/messages';
import { Link } from "react-router-dom";
import CustomModal from "../../../shared/CustomModal";
import ErrorMsgBlockComponent from "../../Error/ErrorMessageBlock";
import { getUserName } from "../../../services/aws/aws-services";

// Define a default UI for filtering
/* istanbul ignore next  */
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows && preFilteredRows.length ? preFilteredRows.length : 0;
    return (
        <input
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}
/** @description function to create UI for Data list
 *  @param  {}
 */
function Table({
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    totalCount,
    listType,
    disableColmSort,
    showSuccessMsg,
    showErrorMsg,
    isApiRequest,
    showConfirmModal,
    showSurgeonAssociationConfirmModal,
    showCareteamAssociationConfirmModal,
    surgeonValues,
    careteamValues,
    showHideConfirmModal,
    showHideConfirmCustomerSurgeonModal,
    showHideConfirmCustomerCareteamModal,
    removeSurgeon,
    deleteSurgeon,
    deleteCareteam,
    action,
    showConfirmSurgeonModal,
    showConfirmCareteamModal,
    showHideConfirmSurgeonModal,
    showHideConfirmCareteamModal,
    confirmMappingSurgeon,
    confirmMappingCareteam
}) {
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, sortBy: [] },
            manualPagination: true,
            autoResetPage: false,
            manualSortBy: false,
            disableFilters: false,
            disableSortBy: disableColmSort ? disableColmSort : false,
            pageCount: controlledPageCount,
            totalCount: totalCount,
        },
        useFilters,
        useSortBy,
        usePagination
    );
    // Listen for changes in pagination and use the state to fetch our new data
    /* istanbul ignore next  */
    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    // function for returning success message based on action performed
    /* istanbul ignore next  */
    function successMessages(actionName) {
        let message;
        if (actionName === 'adminCustomerSurgeonMap') {
            message = customerMessages.surgeonMapSuccess;
        } else if (actionName === 'adminCustomerCareteamMap') {
            message = careTeamMessages.careTeamMapSuccess;
        } else if (actionName === 'salesRepCustomerMap') {
            message = salesRepMapping.mappingSuccess;
        } else { message = surgeonMapping.mappingSuccess }
        return message
    }

    // Render the UI for your table
    /* istanbul ignore next  */
    return (
        <>
            {(data && data.length) || loading ?
                <>
                    {/* Code for displaying messages based on actions goes here */}
                    {showSuccessMsg === 'Success' ? (
                        <div className="proms-success-msg">
                            <p>{successMessages(action)}</p>
                        </div>
                    ) : null}
                    {showErrorMsg ? (
                        <div className="data-list-error-msg">
                            <ErrorMsgBlockComponent className="pt-2 m-0" errorObject={showErrorMsg} />
                        </div>
                    ) : null}
                    {isApiRequest ? (
                        <div className="loading-overlay-upload add-surgeon-overlay">
                            <span>{actions.request}</span>
                        </div>
                    ) : null}
                    {showSuccessMsg === 'surgeonRemove' ? (
                        <div className="proms-success-msg">
                            <p>{surgeonMapping.removeSurgeon}</p>
                        </div>
                    ) : null}
                    {showSuccessMsg === 'careteamRemove' ? (
                        <div className="proms-success-msg">
                            <p>{careteamMapping.removeCareteamMember}</p>
                        </div>
                    ) : null}
                    <div className="table-wrapper">
                        <table
                             role="table"
                            {...getTableProps()}
                            id={listType}
                            className={
                                listType
                                    ? listType + " table-bordered table-hover"
                                    : "table-bordered table-hover w-100"
                            }
                        >
                            <thead className="rt-thead">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                title=""
                                            >
                                                {column.render("Header")}
                                                {/* Add a sort direction indicator */}
                                                <span>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <svg
                                                                className="bi bi-chevron-down"
                                                                width="1.2em"
                                                                height="1.2em"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="bi bi-chevron-up"
                                                                width="1.2em"
                                                                height="1.2em"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                                <div>
                                                    {column.canFilter ? column.render("Filter") : null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            {loading ? (
                                <tbody className="rt-tbody tr-refresh">
                                    {[...Array(10)].map((e, i) => (
                                        <tr key={i}>
                                            {[...Array(headerGroups && headerGroups.length ? headerGroups[0].headers.length : 1)].map((f, j) => (
                                                <td className="loading-cell" key={j}>
                                                    <span></span>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="10000">{actions.load}</td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody {...getTableBodyProps()} className="rt-tbody">
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                    <tr className="result-count">
                                        <td colSpan="10000">
                                            Showing {page && page.length ? page.length : 0} of {totalCount} results
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>

                        {/* Modal to remove surgeon from mapping list */}
                        <CustomModal
                            id="remove-surgeon-modal"
                            show={showConfirmModal}
                            isConfirm={true}
                            title="Alert"
                            body={surgeonMapping.removeAlert}
                            closeAction={() => showHideConfirmModal(surgeonValues)}
                            rejectAction={() => showHideConfirmModal(surgeonValues)}
                            confirmAction={() => removeSurgeon()}
                        />

                        {/* Modal to confirm surgeon mapping with customer */}
                        <CustomModal
                            id="confirm-surgeon-modal"
                            show={showConfirmSurgeonModal}
                            isConfirm={true}
                            title="Alert"
                            body={customerMessages.confirmMapping}
                            closeAction={() => showHideConfirmSurgeonModal()}
                            rejectAction={() => showHideConfirmSurgeonModal()}
                            confirmAction={() => confirmMappingSurgeon()}
                        />

                        {/* Modal to confirm careteam mapping with customer */}
                        <CustomModal
                            id="confirm-surgeon-modal"
                            show={showConfirmCareteamModal}
                            isConfirm={true}
                            title="Alert"
                            body={customerMessages.confirmMapping}
                            closeAction={() => showHideConfirmCareteamModal()}
                            rejectAction={() => showHideConfirmCareteamModal()}
                            confirmAction={() => confirmMappingCareteam()}
                        />

                        {/* Modal to delete surgeon from customer profile */}
                        <CustomModal
                            id="delete-surgeon-modal"
                            show={showSurgeonAssociationConfirmModal}
                            isConfirm={true}
                            title="Alert"
                            body={surgeonMapping.removeAlert}
                            closeAction={() => showHideConfirmCustomerSurgeonModal(surgeonValues)}
                            rejectAction={() => showHideConfirmCustomerSurgeonModal(surgeonValues)}
                            confirmAction={() => deleteSurgeon(true)}
                        />
                        {/* Modal to delete care team member from customer profile */}
                        <CustomModal
                            id="delete-surgeon-modal"
                            show={showCareteamAssociationConfirmModal}
                            isConfirm={true}
                            title="Alert"
                            body={careteamMapping.removeAlert}
                            closeAction={() => showHideConfirmCustomerCareteamModal(careteamValues)}
                            rejectAction={() => showHideConfirmCustomerCareteamModal(careteamValues)}
                            confirmAction={() => deleteCareteam(true)}
                        />
                    </div>
                </>
                : <ErrorMsgBlockComponent noData={true} />}
        </>
    );
}

function SurgeonList(props) {
    let getColumnsFromProps = props.columns ? props.columns : ""; // get columns and datalist type as props
    // eslint-disable-next-line
    let columnsList = [];
    // add the button based on the columns input
    getColumnsFromProps &&
        getColumnsFromProps.forEach((element) => {
            /* istanbul ignore next  */

            if (element.action) {
                let surgeonAction = {
                    Header: element.Header,
                    Cell: ({ row }) => (
                        <div >
                            {row.original.status === "Approved" ?
                                <Link to={{ pathname: "/surgeon", state: { surgeonID: row.original.id } }} >
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
                                        <path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                    </svg>
                                </Link> :
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye-slash" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z" />
                                    <path fillRule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z" />
                                </svg>}

                            <svg onClick={() => showHideConfirmModal(row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash delete-icon cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </div>
                    ),
                };
                columnsList.push(surgeonAction);
            } else if (element.SearchAction) {
                let searchActions = {
                    Header: element.Header,
                    Cell: ({ row }) => (
                        <div className="case-actions">
                            <svg id="openSurgeonModal" onClick={() => mapSurgeon(row.original)} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus-circle cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                        </div>
                    ),
                };
                columnsList.push(searchActions);
            } else if (element.searchSalesRepCustomerAction) {
                let searchActions = {
                    Header: element.Header,
                    Cell: ({ row }) => (
                        <div className="case-actions">
                            <svg id="openSurgeonModal" onClick={() => mapSurgeon(row.original)} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus-circle cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                        </div>
                    ),
                };
                columnsList.push(searchActions);
            } else if (element.SearchCareteamAction) {
                let SearchCareteamAction = {
                    Header: element.Header,
                    Cell: ({ row }) => (
                        <div className="case-actions">
                            <svg id="openCareTeamModal" onClick={() => mapCareteam(row.original)} width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus-circle cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                        </div>
                    ),
                };
                columnsList.push(SearchCareteamAction);
            } else if (element.AssociatedSurgeonAction) {
                let AssociatedSurgeonAction = {
                    Header: element.Header,
                    Cell: ({ row }) => (
                        <div>
                            <Link to={`/surgeondetails/${row.original.userName}`}>
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
                                    <path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            </Link>

                            <svg onClick={() => showHideConfirmCustomerSurgeonModal(row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash delete-icon cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </div>
                    ),
                };
                columnsList.push(AssociatedSurgeonAction);
            } else if (element.AssociatedCareteamAction) {
                let AssociatedCareteamAction = {
                    Header: element.Header,
                    Cell: ({ row }) => (
                        <div>
                            <svg onClick={() => showHideConfirmCustomerCareteamModal(row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash delete-icon cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </div>
                    ),
                };
                columnsList.push(AssociatedCareteamAction);
            } else if (element.Filter) {
                // add column filter
                let filter = {
                    Header: element.Header,
                    accessor: element.accessor,
                    Filter: DefaultColumnFilter,
                };
                columnsList.push(filter);
            } else {
                columnsList.push(element);
            }
        });
    const columns = React.useMemo(() => columnsList, [columnsList]);
    // We'll start our table without any data
    const [surgeonList, setSurgeonList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [isApiRequest, setIsApiRequest] = React.useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = React.useState('');
    const [removeObj, setRemoveObj] = React.useState([]);
    const [removeObjCareteam, setRemoveObjCareteam] = React.useState([]);
    const [surgeonRemoveObj, setSurgeonRemoveObj] = React.useState([]);
    const [careteamRemoveObj, setCareteamRemoveObj] = React.useState([]);
    const surgeonValues = React.useState(false);
    const careteamValues = React.useState(false);
    const [showErrorMsg, setShowErrorMsg] = React.useState(null);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showSurgeonAssociationConfirmModal, setShowSurgeonAssociationConfirmModal] = React.useState(false);
    const [showCareteamAssociationConfirmModal, setShowCareteamAssociationConfirmModal] = React.useState(false);
    const [showConfirmSurgeonModal, setShowConfirmSurgeonModal] = React.useState(false);
    const [showConfirmCareteamModal, setShowConfirmCareteamModal] = React.useState(false);
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedSurgeonDetails, setSelectedSurgeonDetails] = React.useState('');
    const [selectedCareteamDetails, setSelectedCareteamDetails] = React.useState('');
    const fetchIdRef = React.useRef(0);
    const [errorObj, setErrorObj] = React.useState(null);

    /**
     * @description Function to get mapped surgeon list for RAR
     * @param  { }
     */
    /* istanbul ignore next  */
    const getSurgeonsList = (params) => {
        setLoading(true);
        var urlParams = {};
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlParams[key.toLowerCase()] = params[key];
            }
        }
        getSurgeonListService(urlParams, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                setErrorObj(err);
                setLoading(false);
            } else {
                const surgeons = result.data.user ? result.data.user[0].surgeonList : [];
                const resCount = result.data.response_metadata ? result.data.response_metadata.total : 0;
                setErrorObj(null);
                setSurgeonList(surgeons);
                setTotalCount(resCount);
                setLoading(false);
            }
        })
    }
    /**
     * @description Function to get mapped surgeon list for Care Team
     * @param  { }
     */
    /* istanbul ignore next  */
    const getSurgeonsListCareTeam = (params) => {
        setLoading(true);
        var urlParams = {};
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                urlParams[key.toLowerCase()] = params[key];
            }
        }
        getSurgeonListCareTeamService(urlParams, (err, result) => {
            /* istanbul ignore next  */
            if (err) {
                setErrorObj(err);
                setLoading(false);
            } else {
                const surgeons = result.data.user ? result.data.user[0].surgeonList : [];
                const resCount = result.data.response_metadata ? result.data.response_metadata.total : 0;
                setErrorObj(null);
                setSurgeonList(surgeons);
                setTotalCount(resCount);
                setLoading(false);
            }
        })
    }

    /**
    * @description Function to remove surgeon from DB
    * @param  { }
    */
    /* istanbul ignore next  */
    const removeSurgeonAPI = (surgeonDetails) => {
        setLoading(true);
        removeSurgeonService(surgeonDetails, (err) => {
            /* istanbul ignore next  */
            if (err) {
                setShowErrorMsg(err);
                setLoading(false);
                setTimeout(() => {
                    setShowErrorMsg(null);
                }, 5000);
            } else {
                setShowSuccessMsg('surgeonRemove');
                getSurgeonsList();
            }
        })
        setTimeout(() => {
            setShowSuccessMsg('');
        }, 10000);
    }
    /**
    * @description Function to remove surgeon from DB for careteam user
    * @param  { }
    */
    /* istanbul ignore next  */
    const removeCareteamAPI = (careteamDetails) => {
        setLoading(true);
        removeCareteamService(careteamDetails, (err) => {
            /* istanbul ignore next  */
            if (err) {
                setShowErrorMsg(err);
                setLoading(false);
                setTimeout(() => {
                    setShowErrorMsg(null);
                }, 5000);
            } else {
                setShowSuccessMsg('surgeonRemove');
                getSurgeonsListCareTeam();
            }
        })
        setTimeout(() => {
            setShowSuccessMsg('');
        }, 10000);
    }

    /**
    * @description Function to confirm remove surgeon
    * @param  { }
    */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const showHideConfirmModal = React.useCallback(({ id, userName }) => {
        setShowSuccessMsg('');
        let getRemoveObj = {
            id: id,
            surgeonname: userName,
        }
        setRemoveObj(getRemoveObj)
        let getRemoveObjCareteam = {
            id: id,
            surgeonname: userName,
        }
        setRemoveObjCareteam(getRemoveObjCareteam)
        showConfirmModal ? setShowConfirmModal(false) : setShowConfirmModal(true);
    });

    /**
      * @description Function to confirm mapping surgeon with customer
     * @param  { }
    */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const showHideConfirmSurgeonModal = React.useCallback(() => {
        if (showConfirmSurgeonModal) {
            setSelectedSurgeonDetails('');
            setShowErrorMsg(null);
            setShowSuccessMsg('');
            setIsApiRequest(false);
            setShowConfirmSurgeonModal(false);
        } else {
            setShowConfirmSurgeonModal(true);
        }
    });

    /**
      * @description Function to confirm mapping careteam with customer
     * @param  { }
    */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const showHideConfirmCareteamModal = React.useCallback(() => {
        if (showConfirmCareteamModal) {
            setSelectedCareteamDetails('');
            setShowErrorMsg(null);
            setShowSuccessMsg('');
            setIsApiRequest(false);
            setShowConfirmCareteamModal(false);
        } else {
            setShowConfirmCareteamModal(true);
        }
    });
    /**
     * @description Function to remove surgeon mapping
     * @param  { }
    */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const removeSurgeon = React.useCallback(() => {
        setShowConfirmModal(false);
        setLoading(true);
        /* istanbul ignore next  */
        switch (props.listType) {
            case "surgeon-map":
                removeSurgeonAPI(removeObj);
                return true;
            case "surgeon-careteam-map":
                removeCareteamAPI(removeObjCareteam);
                return true;
            default:
                break;
        }
    });

    /**
    * @description Function to delete surgeon from customer profile
    * @param  { }
    */
    /* istanbul ignore next  */
    const deleteSurgeonAPI = (surgeonDetails, isCustomer) => {
        setLoading(true);
        const loggerObj = {
            eventName: isCustomer === true ? loggerEventName.removeSurgeonFromCustomer : ''
        }
        deleteSurgeonService(surgeonDetails, loggerObj, (err) => {
            if (err) {
                setShowErrorMsg(err);
                setLoading(false);
                setTimeout(() => {
                    setShowErrorMsg(null);
                }, 5000);
            } else {
                setShowSuccessMsg('surgeonRemove');
                setTimeout(() => {
                    props.refreshSurgeon(true);
                }, 1000);
                setLoading(false);
            }
        })
    }
    /**
 * @description Function to delete careteam from customer profile
 * @param  { }
 */
    /* istanbul ignore next  */
    const deleteCareteamAPI = (careTeamDetails, isCustomer) => {
        setLoading(true);
        const loggerObj = {
            eventName: isCustomer === true ? loggerEventName.removeCareteamMemberFromCustomer : ''
        }
        deleteCareteamService(careTeamDetails, loggerObj, (err) => {
            if (err) {
                setShowErrorMsg(err);
                setLoading(false);
                setTimeout(() => {
                    setShowErrorMsg(null);
                }, 5000);
            } else {
                setShowSuccessMsg('careteamRemove');
                setTimeout(() => {
                    props.refreshCareteamList(true);
                }, 1000);
                setLoading(false);
            }
        })
    }

    /**
      * @description Function to confirm delete surgeon from customer profile
     * @param  { }
    */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const showHideConfirmCustomerSurgeonModal = React.useCallback(({ userName }) => {
        setShowSuccessMsg('');
        let getSurgeonRemoveObj = {
            customerName: props.customer.customername,
            surgeonName: userName,
        }
        setSurgeonRemoveObj(getSurgeonRemoveObj)
        showSurgeonAssociationConfirmModal ? setShowSurgeonAssociationConfirmModal(false) : setShowSurgeonAssociationConfirmModal(true);
    });

    /**
      * @description Function to confirm delete caretem from customer profile
     * @param  { }
    */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const showHideConfirmCustomerCareteamModal = React.useCallback(({ username }) => {
        setShowSuccessMsg('');
        let getCareteamRemoveObj = {
            customerId: props.customer.customerid,
            username: username,
        }
        setCareteamRemoveObj(getCareteamRemoveObj)
        showCareteamAssociationConfirmModal ? setShowCareteamAssociationConfirmModal(false) : setShowCareteamAssociationConfirmModal(true);
    });

    /**
     * @description Function to delete surgeon from customer profile
     * @param  { }
    */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const deleteSurgeon = React.useCallback((isCustomer) => {
        setShowSurgeonAssociationConfirmModal(false);
        setLoading(true);
        deleteSurgeonAPI(surgeonRemoveObj, isCustomer);
    });
    /** 
     * @description Function to delete careteam from customer profile
     * @param  { }
     */
    /* istanbul ignore next  */
    // eslint-disable-next-line
    const deleteCareteam = React.useCallback((isCustomer) => {
        setShowCareteamAssociationConfirmModal(false);
        setLoading(false);
        deleteCareteamAPI(careteamRemoveObj, isCustomer);
    });

    /**
   * @description Function to map surgeons
   * @param  { }
    */
    /* istanbul ignore next  */
    const reponseHandling = (err) => {
        if (err) {
            setShowErrorMsg(err);
            setIsApiRequest(false);
            setTimeout(() => {
                setShowErrorMsg(null);
            }, 5000);
        } else {
            setShowSuccessMsg('Success');
            props.refreshDataList(true);
            setIsApiRequest(false);
            setTimeout(() => {
                setShowSuccessMsg('');
            }, 5000);
        }
    }

    /**
     * @description Function to map surgeons
     * @param  { }
    */
    /* istanbul ignore next  */
    const confirmMappingSurgeon = () => {
        setShowErrorMsg(null);
        setShowSuccessMsg('');
        setIsApiRequest(true);
        setShowConfirmSurgeonModal(false);
        const customerSurgeonObj = {
            "customerName": props.customer.customername,
            "surgeonName": selectedSurgeonDetails.userName
        }
        props.refreshDataList(false);
        mapCustomerSurgeonsService(customerSurgeonObj, (err) => {
            /* istanbul ignore next  */
            reponseHandling(err);
        });
    }

    /**
  * @description Function to map careteam
  * @param  { }
 */
    /* istanbul ignore next  */
    const confirmMappingCareteam = () => {
        setShowErrorMsg(null);
        setShowSuccessMsg('');
        setIsApiRequest(true);
        setShowConfirmCareteamModal(false);
        const customerCareteamObj = {
            "careteamId": selectedCareteamDetails.userId,
            "customerId": props.customer.customerid

        }
        props.refreshDataList(false);
        mapCustomerCareteamService(customerCareteamObj, (err) => {
            /* istanbul ignore next  */
            reponseHandling(err);
        });
    }

    /**
     * @description Function to map surgeons
     * @param  { }
    */
    /* istanbul ignore next  */
    const mapSurgeon = (surgeonDetails) => {
        switch (props.action) {
            case 'adminCustomerSurgeonMap':
                if (props.customer) {
                    setSelectedSurgeonDetails(surgeonDetails);
                    setShowConfirmSurgeonModal(true);
                }
                break;
            case 'careTeamSurgeonMap':
                setShowErrorMsg(null);
                setShowSuccessMsg('');
                setIsApiRequest(true);
                const surgeonCTObj = {
                    "surgeonname": surgeonDetails.userName,
                    "requeststatus": "Requested"
                }
                props.refreshDataList(false);

                mapSurgeonsCareTeamService(surgeonCTObj, (err) => {
                    /* istanbul ignore next  */
                    reponseHandling(err);
                });
                break;
            case 'salesRepCustomerMap':
                setShowErrorMsg(null);
                setShowSuccessMsg();
                setIsApiRequest(true);
                const salesRepParams = {
                    customerId: surgeonDetails.customerId,
                    salesRepName: getUserName(),
                }
                props.refreshDataList(false)

                mapSalesRepCustomerService(salesRepParams, (err) => {
                    /* istanbul ignore next  */
                    reponseHandling(err);
                })
                break;
            default:
                setShowErrorMsg(null);
                setShowSuccessMsg('');
                setIsApiRequest(true);
                const surgeonObj = {
                    "surgeonname": surgeonDetails.userName,
                    "requeststatus": "Requested"
                }
                props.refreshDataList(false);

                mapSurgeonsService(surgeonObj, (err) => {
                    /* istanbul ignore next  */
                    reponseHandling(err);
                });
                break;
        }
        if (props.action === 'adminCustomerSurgeonMap' && props.customer) {

        } else {

        }
    }

    /**
     * @description Function to map careteam
     * @param  {}
     */
    /* istanbul ignore next  */
    const mapCareteam = async (careteammember) => {
        if (props.action === 'adminCustomerCareteamMap' && props.customer) {
            setSelectedCareteamDetails(careteammember);
            setShowConfirmCareteamModal(true);
        }
    }
    const fetchData = React.useCallback(
        () => {
            // Give this fetch an ID
            const fetchId = ++fetchIdRef.current;
            // Set the loading state
            if (props.refreshSurgeonList === false) {
                setLoading(false);
                return true;
            }

            if (props.refreshSurgeonList === true) {
                switch (props.listType) {
                    case "surgeon-map":
                        getSurgeonsList({});
                        return true;
                    case "surgeon-careteam-map":
                        getSurgeonsListCareTeam({});
                        return true;
                    default:
                        break;
                }
            }
            /* istanbul ignore next  */
            if (fetchId === fetchIdRef.current) {
                switch (props.listType) {
                    case "surgeon-map":
                        getSurgeonsList({});
                        break;
                    case "surgeon-careteam-map":
                        getSurgeonsListCareTeam({});
                        break;
                    case "surgeon-search":
                    case "customer-surgeons":
                    case "customer-License":
                    case "license-list":
                    case "careteam-search":
                    case "careteam-customers":
                    case "customer-search":
                    case "customer-associate-sales-rep":
                        /* istanbul ignore next  */
                        if (props.error) {
                            setErrorObj(props.error);
                        } else {
                            setErrorObj(null);
                            setTotalCount(props.searchData ? props.searchData.length : 0)
                            setSurgeonList(props.searchData || []);
                        }

                        break;

                    default:
                        break;
                }
                setShowSuccessMsg('');
            }
        },
        [props.listType, props.refreshSurgeonList, props.searchData, props.error]
    );

    return (
        <>
            {errorObj ? <ErrorMsgBlockComponent errorObject={errorObj} /> :
                <Table
                    columns={columns}
                    data={surgeonList}
                    fetchData={fetchData}
                    loading={loading}
                    totalCount={totalCount}
                    showPagination={props.showPagination}
                    disableColmSort={props.disableColmSort}
                    showConfirmModal={showConfirmModal}
                    showSurgeonAssociationConfirmModal={showSurgeonAssociationConfirmModal}
                    showCareteamAssociationConfirmModal={showCareteamAssociationConfirmModal}
                    showHideConfirmModal={showHideConfirmModal}
                    showConfirmSurgeonModal={showConfirmSurgeonModal}
                    showConfirmCareteamModal={showConfirmCareteamModal}
                    showHideConfirmSurgeonModal={showHideConfirmSurgeonModal}
                    showHideConfirmCareteamModal={showHideConfirmCareteamModal}
                    showHideConfirmCustomerSurgeonModal={showHideConfirmCustomerSurgeonModal}
                    showHideConfirmCustomerCareteamModal={showHideConfirmCustomerCareteamModal}
                    confirmMappingSurgeon={confirmMappingSurgeon}
                    confirmMappingCareteam={confirmMappingCareteam}
                    removeSurgeon={removeSurgeon}
                    deleteSurgeon={deleteSurgeon}
                    deleteCareteam={deleteCareteam}
                    showSuccessMsg={showSuccessMsg}
                    showErrorMsg={showErrorMsg}
                    isApiRequest={isApiRequest}
                    searchData={props.searchData}
                    surgeonValues={surgeonValues}
                    careteamValues={careteamValues}
                    removeObj={removeObj}
                    surgeonRemoveObj={surgeonRemoveObj}
                    removeObjCareteam={removeObjCareteam}
                    careteamRemoveObj={careteamRemoveObj}
                    action={props.action}
                />
            }
        </>
    );
}
export { SurgeonList, Table };
