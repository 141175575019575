import React, { Component } from 'react'
import RARLookupComponent from '../../components/RARLookup/RARLookup'

export default class RARLookupContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'surgeons',
			customerSearchString: '',
			customerSearchSubmit: '',
			refreshCustomerListTable: '',
			surgeonSearchString: '',
			surgeonSearchSubmit: '',
			refreshSurgeonListTable: ''


		}
	}

	/**
	 * @description Function to handle tabs selection
	 * @param key Eventkey attribute of the selected tab
	 * @memberof RARLookupContainer
	 */
	handleTabSelect = (key) => {
		this.setState({ activeTab: key });
	}

	/** 
	 * 	@description Function to handle search submit
	 *  @param searchString The value in the input field
	 *  @memberof RARLookupContainer
	 */
	handleCustomerSearchSubmit = (searchString) => {
		this.setState({ customerSearchString: searchString, customerSearchSubmit: true, refreshCustomerListTable: '' });
	}

	/** 
	 *  @description Function to handle search reset
	 *  @memberof RARLookupContainer
	 */
	handleCustomerReset = () => {
		this.setState({ customerSearchString: '', customerSearchSubmit: '', refreshCustomerListTable: '' });
	}
	/** 
	 * 	@description Function to handle search submit
	 *  @param searchString The value in the input field
	 *  @memberof RARLookupContainer
	 */
	handleSurgeonSearchSubmit = (searchString) => {
		this.setState({ surgeonSearchString: searchString, surgeonSearchSubmit: true, refreshSurgeonListTable: '' });
	}

	/** 
	 *  @description Function to handle search reset
	 *  @memberof RARLookupContainer
	 */
	handleSurgeonReset = () => {
		this.setState({ surgeonSearchString: '', surgeonSearchSubmit: '', refreshSurgeonListTable: '' });
	}

	render() {
		const { activeTab, customerSearchString, customerSearchSubmit, refreshCustomerListTable, surgeonSearchString, surgeonSearchSubmit, refreshSurgeonListTable } = this.state;
		return (
			<RARLookupComponent
				activeTab={activeTab}
				handleTabSelect={this.handleTabSelect}
				handleCustomerSearchSubmit={this.handleCustomerSearchSubmit}
				handleCustomerReset={this.handleCustomerReset}
				refreshCustomerListTable={refreshCustomerListTable}
				customerSearchString={customerSearchString}
				customerSearchSubmit={customerSearchSubmit}
				handleSurgeonSearchSubmit={this.handleSurgeonSearchSubmit}
				handleSurgeonReset={this.handleSurgeonReset}
				refreshSurgeonListTable={refreshSurgeonListTable}
				surgeonSearchString={surgeonSearchString}
				surgeonSearchSubmit={surgeonSearchSubmit}
			/>
		)
	}
}
